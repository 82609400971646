import {
  APPROVE,
  CANCEL,
  CANCEL_CONFIRM,
  CHECK_DELETE_TASK,
  CONFIRM,
  REJECT,
  SAVE_MIN_STAFFING_RISK,
  SAVE_TASKS,
  SELECT_REQUEST,
  SET_ADMIN_COMMENT,
  SET_CONFIRMING,
  SET_MISSING_COMMENT,
  SET_PROCESSING,
  SET_PROCESSING_TYPE,
  SUBMIT_MODERATION,
  UNSET_CONFIRMING,
  UNSET_MISSING_COMMENT,
  UPDATE_TASKS
} from "./mutation_types";
import { SET_ERRORS } from "../request/mutation_types";
import { QUEUE_PERIOD, RESET_STATE } from "../client/mutation_types";
import { initial_state } from "./state";
import cloneDeep from "lodash.clonedeep";
import { SET_TASKS_UPDATE_REQUESTED, SET_VALUE } from "../../mutation-types";
import * as Sentry from "@sentry/browser";
import { ZonedDateTime } from "@js-joda/core";
import { _get_client_first_day_of_month } from "../../time";
import Vue from "vue";
import { setScope } from "../../utils";
export default {
  async [APPROVE]({ commit }, payload) {
    await commit(APPROVE, payload);
  },
  async [RESET_STATE]({ commit }) {
    let state_keys = Object.keys(initial_state);
    for (let key of state_keys) {
      await commit(SET_VALUE, {
        key: key,
        value: cloneDeep(initial_state[key])
      });
    }
  },
  async [CANCEL]({ commit }, payload) {
    //console.log("cancel action", payload);
    await commit(CANCEL, payload);
  },
  async [CANCEL_CONFIRM]({ commit }, payload) {
    //console.log("cancel confirm action", payload);
    await commit(CANCEL_CONFIRM, payload);
  },
  async [CHECK_DELETE_TASK]({ commit }, payload) {
    //console.log("check delete", payload);
    commit(CHECK_DELETE_TASK, payload);
  },
  async [CONFIRM]({ commit, dispatch, getters, state }, payload) {
    //console.log("confirm action", payload);
    await commit(CONFIRM, payload);
    if (
      getters.commentRequired(payload) &&
      state.tasks[payload].adminComment.replace(/\s+/g, "") === ""
    ) {
      commit(SET_MISSING_COMMENT, payload);
    } else {
      commit(UNSET_MISSING_COMMENT, payload);
      dispatch(SUBMIT_MODERATION, payload);
    }
  },
  async [REJECT]({ commit }, payload) {
    await commit(REJECT, payload);
  },
  async [SAVE_MIN_STAFFING_RISK]({ commit }, payload) {
    await commit(SAVE_MIN_STAFFING_RISK, payload);
  },
  async [SELECT_REQUEST]({ commit }, payload) {
    await commit(SELECT_REQUEST, payload);
  },
  async [SET_ADMIN_COMMENT]({ commit }, payload) {
    await commit(SET_ADMIN_COMMENT, payload);
  },
  async [SET_CONFIRMING]({ commit }, payload) {
    await commit(SET_CONFIRMING, payload);
  },
  async [SUBMIT_MODERATION]({ commit, dispatch, rootState, state }, payload) {
    let confirmation_type = state.tasks[payload].confirmationType;
    let approvalStatus = 0;
    if (confirmation_type === "first_level") {
      approvalStatus = 0;
    } else if (confirmation_type === "approve") {
      approvalStatus = 1;
    } else if (confirmation_type === "reject") {
      approvalStatus = 2;
    } else if (confirmation_type === "cancel") {
      approvalStatus = 3;
    }
    commit(SET_PROCESSING_TYPE, confirmation_type);
    commit(SET_PROCESSING, true);
    // COMMENTED BECAUSE MISSING ON PRODUCTION
    let action_object = {
      shift_change_id: payload,
      moderator_id: rootState.auth.person.id,
      administrator_note: state.tasks[payload].adminComment,
      approval_status: approvalStatus,
      ip_address: "94.110.7.244"
    };
    let shift_change = rootState.shifts.changes[payload];
    let url = rootState.api_url + `/moderate/change/`;
    setScope(
      url,
      action_object,
      null,
      null,
      null,
      null,
      shift_change,
      rootState.shifts.shifts[shift_change.shift_1_id]
    );
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + rootState.auth.access_token
      },
      body: JSON.stringify(action_object)
    })
      .then(async response => {
        Sentry.configureScope(scope => {
          scope.setExtra("api_response", response);
        });

        if (response.status === 500) {
          commit(SET_ERRORS, [
            "Sorry, there was an error when processing your request. The developers have been notified and will fix this bug ASAP"
          ]);

          commit(SET_PROCESSING, false);
        } else if (response.status === 400) {
          commit(SET_ERRORS, [await response.json().error_message]);
          commit(SET_PROCESSING, false);
        } else if (response.status === 401) {
          dispatch(
            "auth/REFRESH_LOGIN",
            { action: "task/SUBMIT_MODERATION", payload: payload },
            { root: true }
          );
        } else if (response.status === 200) {
          let jd = response.json();
          return jd.then(j => {
            Sentry.configureScope(scope => {
              scope.setExtra("json_response", j);
            });
            if (j) {
              commit(SET_PROCESSING, false);
            } else {
              commit(SET_ERRORS, j);
            }
          });
        }
      })
      .catch(err => {
        Sentry.captureException(err);
        console.log(err);
        commit(SET_PROCESSING, false);
        commit(SET_ERRORS, [
          "Sorry, there was an error when processing your request. The developers have been notified and will fix this bug ASAP"
        ]);
      });
  },
  async [UNSET_CONFIRMING]({ commit }, payload) {
    await commit(UNSET_CONFIRMING, payload);
  },
  // eslint-disable-next-line no-unused-vars
  async [UPDATE_TASKS]({ commit, dispatch, rootState }) {
    //console.log("update tasks");
    let url = rootState.api_url + `/tasks/` + rootState.client.id + `/`;
    await dispatch(SET_TASKS_UPDATE_REQUESTED, false, { root: true });
    setScope(url);

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + rootState.auth.access_token
      }
    })
      .then(async response => {
        Sentry.configureScope(scope => {
          scope.setExtra("api_response", response);
        });
        if (response.status === 500) {
          commit(SET_ERRORS, [
            "Sorry, there was an error when processing your request. The developers have been notified and will fix this bug ASAP"
          ]);
          return;
        }
        if (response.status === 401) {
          dispatch(
            "auth/REFRESH_LOGIN",
            { action: "task/UPDATE_TASKS" },
            { root: true }
          );
          return;
        }
        if (response.status === 200) {
          response
            .json()
            .then(async jd => {
              Sentry.configureScope(scope => {
                scope.setExtra("json_response", jd);
              });
              let periods_to_load = [];
              for (let task of Object.values(jd.tasks)) {
                Vue.nextTick(() => {
                  jd.tasks[task.id].start = ZonedDateTime.parse(
                    task.start_datetime
                  );
                  let fdom = _get_client_first_day_of_month(task.start);
                  const fdom_string = fdom.toString();
                  if (!periods_to_load.includes(fdom_string)) {
                    periods_to_load.push(fdom_string);
                    dispatch(
                      "client/" + QUEUE_PERIOD,
                      {
                        client_id: rootState.client.id,
                        period: fdom_string,
                        month: fdom.monthValue(),
                        year: fdom.year(),
                        day: fdom.dayOfMonth()
                      },
                      { root: true }
                    );
                  }
                });
              }
              await commit(SAVE_TASKS, jd);
            })
            .catch(err => {
              Sentry.captureException(err);
              console.log(err);
            });
        }
      })
      .catch(err => {
        Sentry.captureException(err);
        console.log(err);
        commit(SET_ERRORS, [
          "Sorry, there was an error when processing your request. The developers have been notified and will fix this bug ASAP"
        ]);
      });
  }
};
