import {
  new_client,
  new_change,
  new_person,
  new_role,
  new_shift,
  new_shift_time,
  new_time_off_type,
  new_user,
  reset_ids,
  new_title,
  custom_forms,
  new_group,
  new_schedule,
  new_asset,
  new_shift_asset
} from "./mock_setup";

import {
  LocalDateTime,
  LocalDate,
  DateTimeFormatter,
  ZoneId /*, ChronoUnit*/
} from "@js-joda/core";
import {
  CHANGE_TYPE_TIME_OFF,
  SHIFT_CHANGE_TIME_OFF_TYPE_COMP_TIME,
  SHIFT_CHANGE_TIME_OFF_TYPE_OVERTIME,
  SHIFT_CHANGE_TIME_OFF_TYPE_SPECIAL_ASSIGNMENT
} from "../../src/constants/shiftChange";
import { SHIFT_TYPE_PENDING } from "../../src/constants/shift";
export const now = LocalDateTime.now();
export const one_hour_ago = now.minusHours(1);
export const seven_hours_from_now = now.plusHours(7);
// Use hours to cross timezones. days does not perform calculations.
export const now_but_tomorrow = now.plusHours(24);
export const now_but_yesterday = now.minusHours(24);
export const todays_date = LocalDate.from(now);
export const yesterdays_date = LocalDate.from(now_but_yesterday);
export const tomorrows_date = LocalDate.from(now_but_tomorrow);
export const this_period_dt = LocalDateTime.parse(
  todays_date.withDayOfMonth(1).toString() + "T01:00:00"
);
export const this_period = LocalDate.from(this_period_dt);
export const prev_period_dt = this_period_dt.minusMonths(1);
export const next_period_dt = this_period_dt.plusMonths(1);
export const previous_period = LocalDate.from(prev_period_dt);
export const next_period = LocalDate.from(next_period_dt);
export const tomorrow_start = now.plusHours(24);
export const tomorrow_end = tomorrow_start.plusHours(8);

export let base_path = {
  path: "/"
};
// Store dicts with key's referencing the type of test covered.
export let mock_schedules = {};
export let mock_shifts = {};
export let mock_shift_times = {};
export let mock_changes = {};
export let mock_time_off_types = {};
export let mock_users = {};
export let mock_clients = {};
export let mock_people = {};
export let mock_roles = {};
export let mock_locations = {};
export let mock_tasks = {};
export let mock_titles = {};
export let mock_groups = {};
export let mock_assets = {};
// Build some generic data types.
const mock_locations_data = {
  station_1: {
    name: "Station 1",
    abbreviation: "S1"
  }
};
const mock_titles_data = {
  community_officer: {
    abbreviation: "CSO",
    name: "Community Service Officer",
    ordinal_number: 1
  },
  deputy_chief: {
    abbreviation: "DC",
    name: "Deputy Chief",
    ordinal_number: 2
  },
  detective: {
    abbreviation: "DET",
    name: "Detective",
    ordinal_number: 3
  },
  officer: {
    abbreviation: "OFC",
    name: "Officer",
    ordinal_number: 4
  },
  records: {
    abbreviation: "REC",
    name: "Records",
    ordinal_number: 5
  },
  sergeant: {
    abbreviation: "SGT",
    name: "Sergeant",
    ordinal_number: 6
  }
};
const mock_assets_data = {
  bike: {
    asset_type: "Bikes",
    name: "BIKE #1",
    options: {
      show_daily: true,
      notes: "",
      serial_number: "123456789",
      barcode: "123456789"
    }
  }
};
const mock_schedules_data = {
  main: {
    abbreviation: "MAIN",
    name: "Main Schedule",
    groups: ["patrol"],
    options: {
      grid_job_type_subtotals_enabled: true,
      ordinal_number: 1000
    }
  } /*,
  patrol: {

  }*/
};
const mock_groups_data = {
  Day_Shift: {
    abbreviation: "DS",
    name: "Day Shift",
    people: ["supervisor", "subordinate"],
    options: {
      is_implied_group: false,
      manpower_end_time: null,
      manpower_start_time: null,
      non_group_manpower_counts_as_support: true,
      ordinal_number: 1
    }
  }
};
// eslint-disable-next-line no-unused-vars
const mock_tasks_data = {};
const mock_roles_data = {
  patrol: {
    abbreviation: "P",
    background_color: 7,
    beats_enabled: true,
    color: 0,
    id: 1,
    is_supervisor_job_type: false,
    name: "Patrol",
    omit_job_type_from_reports: false,
    ordinal_number: 1,
    people: [1],
    seniority_list: "[1]"
  }
};
const start_end_time_pattern = "HH:mm:ss";
const start_end_time_formatter = DateTimeFormatter.ofPattern(
  start_end_time_pattern
);
const mock_start_end_times = {
  sliding: {
    start: one_hour_ago.format(start_end_time_formatter),
    end: seven_hours_from_now.format(start_end_time_formatter)
  },
  day: {
    start: "06:00:00",
    end: "14:00:00"
  },
  midnight: {
    start: "22:00:00",
    end: "06:00:00"
  },
  morning: {
    start: "09:00:00",
    end: "17:00:00"
  },
  afternoon: {
    start: "14:00:00",
    end: "22:00:00"
  },
  standard_1: {
    start: "08:00:00",
    end: "16:00:00"
  },
  standard_2: {
    start: "09:00:00",
    end: "17:00:00"
  },
  investigations: {
    start: "07:30:00",
    end: "15:30:00"
  }
};

const mock_start_end_date_times = {
  sliding: {
    start: one_hour_ago,
    end: seven_hours_from_now
  },
  this_morning: {
    start: LocalDateTime.parse(
      todays_date + "T" + mock_start_end_times.morning.start
    ),
    end: LocalDateTime.parse(
      todays_date + "T" + mock_start_end_times.morning.end
    )
  },
  tomorrow_morning: {
    start: LocalDateTime.parse(
      tomorrows_date + "T" + mock_start_end_times.morning.start
    ),
    end: LocalDateTime.parse(
      tomorrows_date + "T" + mock_start_end_times.morning.end
    )
  },
  yesterday_morning: {
    start: LocalDateTime.parse(
      yesterdays_date + "T" + mock_start_end_times.morning.start
    ),
    end: LocalDateTime.parse(
      yesterdays_date + "T" + mock_start_end_times.morning.end
    )
  }
};
const mock_shift_times_data = {
  Sliding: {
    abbreviation: "S",
    end_time: mock_start_end_times.sliding.end,
    start_time: mock_start_end_times.sliding.start,
    options: {}
  },
  Day_Shift: {
    abbreviation: "D",
    end_time: mock_start_end_times.day.end,
    start_time: mock_start_end_times.day.start,
    options: {}
  },
  Midnight_Shift: {
    abbreviation: "M",
    end_time: mock_start_end_times.midnight.end,
    start_time: mock_start_end_times.midnight.start,
    options: {}
  },
  Administration: {
    abbreviation: "AD",
    end_time: mock_start_end_times.standard_1.end,
    start_time: mock_start_end_times.standard_1.start,
    options: {}
  },
  Investigations: {
    abbreviation: "INV",
    end_time: mock_start_end_times.investigations.end,
    start_time: mock_start_end_times.investigations.start,
    options: {}
  },
  CSO: {
    abbreviation: "CSO",
    end_time: mock_start_end_times.standard_1.end,
    start_time: mock_start_end_times.standard_1.start,
    options: {}
  }
};

const mock_changes_data_to_setup = {
  comp_time_court: {},
  comp_time_vacation: {},
  sick_time: {},
  regular_day_off: {},
  trade: {},
  early_arrival: {},
  early_dismissal: {},
  late_arrival: {},
  late_dismissal: {},
  overtime: {
    mock_time_off_type_key: "bike_patrol",
    shift_change_type: SHIFT_CHANGE_TIME_OFF_TYPE_OVERTIME,
    options: { overtime_payment_type: 1 }
  }
};

const mock_shifts_data_to_setup = {
  today_open_bike_patrol_shift: {
    shift: {
      day: todays_date.toString(),
      local_day: todays_date,
      start: mock_start_end_date_times.this_morning.start,
      end: mock_start_end_date_times.this_morning.end,
      job: "patrol",
      location: "station_1",
      shift_type: SHIFT_TYPE_PENDING,
      shift_time: "Day_Shift",
      assets: ["bike"],
      options: { is_extra_shift: true, assigned_to: null, note: "Note test." }
    },
    changes: {
      today_open_shift_bike_patrol_overtime: {
        mock_time_off_type_key: "bike_patrol",
        change: mock_changes_data_to_setup.overtime,
        start: mock_start_end_date_times.this_morning.start,
        end: mock_start_end_date_times.this_morning.end
      }
    }
  },
  tomorrow_open_bike_patrol_shift: {
    shift: {
      day: tomorrows_date.toString(),
      local_day: tomorrows_date,
      start: mock_start_end_date_times.tomorrow_morning.start,
      end: mock_start_end_date_times.tomorrow_morning.end,
      job: "patrol",
      location: "station_1",
      shift_type: SHIFT_TYPE_PENDING,
      shift_time: "Day_Shift",
      assets: ["bike"],
      options: { is_extra_shift: true, assigned_to: null }
    },
    changes: {
      tomorrow_open_shift_bike_patrol_overtime: {
        mock_time_off_type_key: "bike_patrol",
        change: mock_changes_data_to_setup.overtime,
        start: mock_start_end_date_times.tomorrow_morning.start,
        end: mock_start_end_date_times.tomorrow_morning.end
      }
    }
  },
  today_supervisor_bike_patrol_shift: {
    shift: {
      day: todays_date.toString(),
      local_day: todays_date,
      start: mock_start_end_date_times.this_morning.start,
      end: mock_start_end_date_times.this_morning.end,
      job: "patrol",
      location: "station_1",
      shift_type: SHIFT_TYPE_PENDING,
      shift_time: "Day_Shift",
      assets: ["bike"],
      options: { assigned_to: "regular_user_supervisor" }
    },
    changes: {
      today_supervisor_shift_bike_patrol_overtime: {
        mock_time_off_type_key: "bike_patrol",
        change: mock_changes_data_to_setup.overtime,
        start: mock_start_end_date_times.this_morning.start,
        end: mock_start_end_date_times.this_morning.end
      }
    }
  },
  sliding_supervisor_bike_patrol_shift: {
    shift: {
      day: todays_date.toString(),
      local_day: todays_date,
      start: mock_start_end_date_times.sliding.start,
      end: mock_start_end_date_times.sliding.end,
      job: "patrol",
      location: "station_1",
      shift_type: SHIFT_TYPE_PENDING,
      shift_time: "Sliding",
      assets: ["bike"],
      options: { assigned_to: "regular_user_supervisor" }
    },
    changes: {
      sliding_supervisor_shift_bike_patrol_overtime: {
        mock_time_off_type_key: "bike_patrol",
        change: mock_changes_data_to_setup.overtime,
        start: mock_start_end_date_times.sliding.start,
        end: mock_start_end_date_times.sliding.end
      }
    }
  },
  tomorrow_supervisor_bike_patrol_shift: {
    shift: {
      day: tomorrows_date.toString(),
      local_day: tomorrows_date,
      start: mock_start_end_date_times.tomorrow_morning.start,
      end: mock_start_end_date_times.tomorrow_morning.end,
      job: "patrol",
      location: "station_1",
      shift_type: SHIFT_TYPE_PENDING,
      shift_time: "Day_Shift",
      assets: ["bike"],
      options: { assigned_to: "regular_user_supervisor" }
    },
    changes: {
      tomorrow_supervisor_shift_bike_patrol_overtime: {
        mock_time_off_type_key: "bike_patrol",
        change: mock_changes_data_to_setup.overtime,
        start: mock_start_end_date_times.tomorrow_morning.start,
        end: mock_start_end_date_times.tomorrow_morning.end
      }
    }
  },
  today_subordinate_bike_patrol_shift: {
    shift: {
      day: todays_date.toString(),
      local_day: todays_date,
      start: mock_start_end_date_times.this_morning.start,
      end: mock_start_end_date_times.this_morning.end,
      job: "patrol",
      location: "station_1",
      shift_type: SHIFT_TYPE_PENDING,
      shift_time: "Day_Shift",
      assets: ["bike"],
      options: { assigned_to: "regular_user_sub", note: "A test note" }
    },
    changes: {
      today_subordinate_shift_bike_patrol_overtime: {
        mock_time_off_type_key: "bike_patrol",
        change: mock_changes_data_to_setup.overtime,
        start: mock_start_end_date_times.this_morning.start,
        end: mock_start_end_date_times.this_morning.end
      }
    }
  },
  tomorrow_subordinate_bike_patrol_shift: {
    shift: {
      day: tomorrows_date.toString(),
      local_day: tomorrows_date,
      start: mock_start_end_date_times.tomorrow_morning.start,
      end: mock_start_end_date_times.tomorrow_morning.end,
      job: "patrol",
      location: "station_1",
      shift_type: SHIFT_TYPE_PENDING,
      shift_time: "Day_Shift",
      assets: ["bike"],
      options: { assigned_to: "regular_user_sub", note: "assigned user" }
    },
    changes: {
      tomorrow_subordinate_shift_bike_patrol_overtime: {
        mock_time_off_type_key: "bike_patrol",
        change: mock_changes_data_to_setup.overtime,
        start: mock_start_end_date_times.tomorrow_morning.start,
        end: mock_start_end_date_times.tomorrow_morning.end
      }
    }
  }

  /*,
  monday_open_shift: {
    shift: {
      options: {}
    },
    changes: []
  }*/
};
const mock_time_off_types_data = {
  bike_patrol: {
    abbreviation: "BIKE",
    name: "Bike Patrol",
    category: SHIFT_CHANGE_TIME_OFF_TYPE_SPECIAL_ASSIGNMENT,
    options: {
      comp_time_bank: null,
      custom_form: null,
      description: false,
      minute_mapping: null,
      modify_enable: true,
      modify_hours_prior: null,
      ordinal_number: 1,
      payout_rate: 1,
      people: [1],
      rescind_enabled: true,
      rescind_hours_period: null,
      time_off_bank: null
    }
  }
};
const mock_users_data = {
  admin_user: { email: "admin@mock.user", options: { is_admin: true } },
  regular_user_sub: { email: "sub_user@mock.user", options: {} },
  regular_user_supervisor: { email: "supervisor_user@mock.user", options: {} }
};
const mock_people_data = {
  admin: {
    mock_user_key: "admin_user",
    first_name: "Admin",
    last_name: "Mock",
    mock_title_key: "deputy_chief",
    options: {}
  },
  supervisor: {
    mock_user_key: "regular_user_supervisor",
    first_name: "Supervisor",
    mock_title_key: "sergeant",
    last_name: "Mock",
    options: { is_admin: false, add_notes: true }
  },
  subordinate: {
    mock_user_key: "regular_user_sub",
    first_name: "Subordinate",
    mock_title_key: "officer",
    last_name: "Mock",
    options: { is_admin: false, add_notes: false }
  }
};
const mock_clients_data = {
  mobile_enabled: {
    name: "Demo Enabled",
    mobile_app_enabled: true,
    options: {
      settings: {
        timezone: ZoneId.of("US/Central"),
        change_request_types: { additional_time: false },
        extra_shift_deadline: null
      }
    }
  },
  additional_time_enabled: {
    name: "Demo Enabled",
    mobile_app_enabled: true,
    options: {
      settings: {
        timezone: ZoneId.of("US/Central"),
        change_request_types: { additional_time: true },
        extra_shift_deadline: null
      }
    }
  },
  mobile_disabled: {
    name: "Demo Disabled",
    mobile_app_enabled: false,
    options: {
      settings: {
        timezone: ZoneId.of("US/Central"),
        change_request_types: { additional_time: true },
        extra_shift_deadline: null
      }
    }
  }
};
let user_clients = {};
// Declare variables to use in mock store.
let shifts = {};
let changes = {};
let time_off_types = {};
let shift_times = {};
let people = {};
let periods = {};
let users = {};
let roles = {};
let clients = {};
let tasks = {};
let groups = {};
let schedules = {};
let locations = {};
let titles = {};
let assets = {};

export function build_user_data() {
  for (let [user_key, mock_user_data] of Object.entries(mock_users_data)) {
    let new_mock_user = new_user(mock_user_data.email, mock_user_data.options);
    mock_users[user_key] = new_mock_user.id;
    user_clients[new_mock_user.id] = [];
    users[new_mock_user.id] = new_mock_user;
  }
}

export function reset_data() {
  shifts = {};
  changes = {};
  time_off_types = {};
  shift_times = {};
  people = {};
  users = {};
  user_clients = {};
  roles = {};
  periods = {};
  tasks = {};
  locations = {};
  schedules = {};
  reset_ids();
}
let mock_user_client_person = {};
export function build_people_data(client) {
  for (let [person_key, mock_person_data] of Object.entries(mock_people_data)) {
    let new_mock_person = new_person(
      mock_person_data.first_name,
      mock_person_data.last_name,
      users[mock_users[mock_person_data.mock_user_key]],
      titles[mock_titles[mock_person_data.mock_title_key]],
      client,
      mock_person_data.options
    );
    mock_people[person_key] = new_mock_person.id;
    mock_user_client_person[client.id + mock_person_data.mock_user_key] =
      new_mock_person.id;
    people[new_mock_person.id] = new_mock_person;
    user_clients[mock_users[mock_person_data.mock_user_key]].push(client.id);
  }
}
export function build_titles_data() {
  for (let [title_key, mock_title_data] of Object.entries(mock_titles_data)) {
    let new_mock_title = new_title(
      mock_title_data.name,
      mock_title_data.abbreviation,
      mock_title_data.ordinal_number
    );
    mock_titles[title_key] = new_mock_title.id;
    titles[new_mock_title.id] = new_mock_title;
  }
}

export function build_assets_data() {
  for (let [asset_key, mock_asset_data] of Object.entries(mock_assets_data)) {
    let new_mock_asset = new_asset(
      mock_asset_data.name,
      mock_asset_data.asset_type,
      mock_asset_data.options
    );
    mock_assets[asset_key] = new_mock_asset.id;
    assets[new_mock_asset.id] = new_mock_asset;
  }
}

export function build_clients_data() {
  for (let [client_key, mock_client_data] of Object.entries(
    mock_clients_data
  )) {
    let new_mock_client = new_client(
      mock_client_data.name,
      mock_client_data.mobile_app_enabled,
      mock_client_data.options
    );
    mock_clients[client_key] = new_mock_client.id;
    clients[new_mock_client.id] = new_mock_client;
    build_people_data(new_mock_client);
  }
}

// Generate everything and add to reference dicts.
export function build_period_data(client, period) {
  let this_period_data = {
    changes: [],
    shifts: [],
    roles: [],
    groups: [],
    schedules: [],
    locations: [],
    time_off_types: [],
    shift_times: [],
    notes: []
  };
  for (let [location_key, mock_location_data] of Object.entries(
    mock_locations_data
  )) {
    let new_mock_location = new_title(
      mock_location_data.name,
      mock_location_data.abbreviation
    );
    mock_locations[period + location_key] = new_mock_location.id;
    locations[new_mock_location.id] = new_mock_location;
    this_period_data.locations.push(new_mock_location.id);
  }
  for (let [role_key, mock_role_data] of Object.entries(mock_roles_data)) {
    let new_mock_role = new_role(
      mock_role_data.abbreviation,
      mock_role_data.name,
      mock_role_data.options
    );
    mock_roles[period + role_key] = new_mock_role.id;
    roles[new_mock_role.id] = new_mock_role;
    this_period_data.roles.push(new_mock_role.id);
  }

  for (let [shift_time_key, mock_shift_time_data] of Object.entries(
    mock_shift_times_data
  )) {
    let new_mock_shift_time = new_shift_time(
      mock_shift_time_data.abbreviation,
      mock_shift_time_data.start_time,
      mock_shift_time_data.end_time,
      shift_time_key.replace("_", " "),
      mock_shift_time_data.options
    );
    shift_times[new_mock_shift_time.id] = new_mock_shift_time;
    this_period_data.shift_times.push(new_mock_shift_time.id);
    mock_shift_times[period + shift_time_key] = new_mock_shift_time.id;
  }

  for (let [group_key, mock_group_data] of Object.entries(mock_groups_data)) {
    let people_in_group = [];
    for (let person of Object.values(mock_group_data.people)) {
      people_in_group.push(mock_people[person]);
    }
    let new_mock_group = new_group(
      mock_group_data.name,
      mock_group_data.abbreviation,
      people_in_group,
      mock_group_data.options
    );
    groups[new_mock_group.id] = new_mock_group;
    this_period_data.groups.push(new_mock_group.id);
    mock_groups[period + group_key] = new_mock_group.id;
  }
  for (let [schedule_key, mock_schedule_data] of Object.entries(
    mock_schedules_data
  )) {
    let groups_in_schedule = [];
    for (let group of Object.values(mock_schedule_data.groups)) {
      groups_in_schedule.push(mock_groups[period + group]);
    }
    let new_mock_schedule = new_schedule(
      mock_schedule_data.name,
      mock_schedule_data.abbreviation,
      groups_in_schedule,
      mock_schedule_data.options
    );
    schedules[new_mock_schedule.id] = new_mock_schedule;
    this_period_data.schedules.push(new_mock_schedule.id);
    mock_schedules[period + schedule_key] = new_mock_schedule.id;
  }

  for (let [time_off_type_key, mock_time_off_type_data] of Object.entries(
    mock_time_off_types_data
  )) {
    let new_mock_time_off_type = new_time_off_type(
      mock_time_off_type_data.abbreviation,
      mock_time_off_type_data.name,
      mock_time_off_type_data.category,
      client,
      mock_time_off_type_data.options
    );
    time_off_types[new_mock_time_off_type.id] = new_mock_time_off_type;
    mock_time_off_types[time_off_type_key] = new_mock_time_off_type.id;
    this_period_data.time_off_types.push(new_mock_time_off_type.id);
  }
  for (let [shift_key, mock_shifts_data] of Object.entries(
    mock_shifts_data_to_setup
  )) {
    if (mock_shifts_data.shift.options.assigned_to) {
      mock_shifts_data.shift.options.assigned_to_obj =
        people[
          mock_user_client_person[
            client.id + mock_shifts_data.shift.options.assigned_to
          ]
        ];
      mock_shifts_data.shift.options.assigned_to_id =
        mock_shifts_data.shift.options.assigned_to_obj.id;
    }
    let new_mock_shift = new_shift(
      mock_shifts_data.shift.day,
      mock_shifts_data.shift.local_day,
      mock_shifts_data.shift.start.atZone(client.settings.timezone),
      mock_shifts_data.shift.end.atZone(client.settings.timezone),
      mock_roles[period + mock_shifts_data.shift.job],
      mock_locations[period + mock_shifts_data.shift.location],
      mock_shifts_data.shift.shift_type,
      mock_shift_times[period + mock_shifts_data.shift.shift_time],
      mock_shifts_data.shift.options
    );

    let shift_assets = [];
    for (let asset of mock_shifts_data.shift.assets) {
      shift_assets.push(
        new_shift_asset(assets[mock_assets[asset]], new_mock_shift)
      );
    }
    new_mock_shift.assets = shift_assets;
    for (let [change_key, mock_change_data] of Object.entries(
      mock_shifts_data.changes
    )) {
      let change = mock_change_data.change;
      let new_mock_change = new_change(
        new_mock_shift,
        mock_shifts_data.shift.options.assigned_to_obj,
        mock_change_data.start.atZone(client.settings.timezone),
        mock_change_data.end.atZone(client.settings.timezone),
        change.shift_change_type,
        mock_change_data.options
      );
      new_mock_shift.changes.push(new_mock_change.id);
      changes[new_mock_change.id] = new_mock_change;
      mock_changes[shift_key + "_" + change_key] = new_mock_change.id;
      this_period_data.changes.push(new_mock_change.id);
    }
    this_period_data.shifts.push(new_mock_shift.id);
    mock_shifts[shift_key] = new_mock_shift.id;
    shifts[new_mock_shift.id] = new_mock_shift;
  }
  return this_period_data;
}

export let vacation_change = {
  id: 1,
  person_time_off_bank: 1,
  time_off_type: 1,
  overtime_payment_type: 1,
  opt_split_comp_time_num_minutes: null,
  opt_split_overtime_num_minutes: null,
  type: SHIFT_CHANGE_TIME_OFF_TYPE_COMP_TIME,
  shift_change_type: CHANGE_TYPE_TIME_OFF
};

export function setup() {
  build_titles_data();
  build_user_data();
  build_clients_data();
  build_assets_data();
}
export function generate_state(
  logged_in_user_key,
  client,
  period_selected,
  authenticated,
  day_selected = todays_date,
  stale_session = false,
  app_version = "1.0.16"
) {
  /*
  console.log(
    "Generating new state for: ",
    logged_in_user_key,
    client,
    period_selected,
    authenticated,
    stale_session,
    app_version
  );*/
  let logged_in_user_clients = {};
  let person = null;
  if (authenticated) {
    for (let client_id of Object.values(
      user_clients[mock_users[logged_in_user_key]]
    )) {
      logged_in_user_clients[client_id] = clients[client_id];
    }
    if (client) {
      periods[this_period] = build_period_data(
        clients[mock_clients[client]],
        this_period
      );
      periods[previous_period] = build_period_data(
        clients[mock_clients[client]],
        previous_period
      );
      periods[next_period] = build_period_data(
        clients[mock_clients[client]],
        next_period
      );
      if (
        period_selected !== this_period &&
        period_selected !== previous_period &&
        period_selected !== next_period
      ) {
        periods[period_selected] = build_period_data(
          clients[mock_clients[client]],
          period_selected
        );
      }
      for (let client_person of Object.values(people)) {
        if (client_person.user_id === mock_users[logged_in_user_key]) {
          person = client_person;
        }
      }
    }
  }

  let state = {
    auth: {
      access_token: authenticated
        ? "valid_access_token"
        : "invalid_access_token",
      authenticated: authenticated,
      authenticating: false,
      clients: logged_in_user_clients,
      errors: [],
      last_version_check: null,
      loading: false,
      person: person,
      refresh_token: stale_session
        ? "invalid_refresh_token"
        : "valid_refresh_token",
      selected_client_id: mock_clients[client],
      slides: [],
      submitted: false,
      user: {
        permissions: {
          admin: users[mock_users[logged_in_user_key]].is_admin
        }
      }
    },
    calendar: {
      selected_period: period_selected,
      selected_day: day_selected,
      selected_schedule: null,
      department_view: false,
      lockouts: {},
      shortages: {},
      shortage_load_queue: [],
      selected_locations: []
    },
    env: {
      env: {
        version: app_version
      }
    },
    shifts: {
      shifts: shifts,
      changes: changes
    },
    client: {
      periods: periods,
      shift_times: shift_times,
      people: people,
      time_off_types: time_off_types,
      assets: assets, // assets,
      banks: {}, // banks,
      locations: locations, //locations,
      groups: groups, //groups,
      schedules: schedules,
      titles: titles,
      settings: clients[mock_clients[client]].settings, //settings,
      roles: roles
    },
    task: { tasks: tasks, min_staffing_risk: {} },
    cachedNow: now.atZone(clients[mock_clients[client]].settings.timezone),
    cachedNowDate: now
      .atZone(clients[mock_clients[client]].settings.timezone)
      .toLocalDate(),
    cachedNowDateString: now
      .atZone(clients[mock_clients[client]].settings.timezone)
      .toLocalDate()
      .toString()
  };
  return state;
}
export let mock_state = {
  auth: {
    access_token: "access_token",
    authenticated: true,
    authenticating: true,
    clients: {
      1: {
        id: 1,
        mobile_app_enabled: true,
        name: "Demo Mock"
      }
    },
    errors: [],
    last_version_check: null,
    loading: true,
    person: Object,
    refresh_token: "refresh_token",
    selected_client_id: null,
    slides: [],
    submitted: true,
    user: {
      permissions: {
        admin: true
      }
    }
  },
  calendar: {
    department_view: false,
    selected_day: null,
    selected_period: "2019-12-01",
    selected_schedule: 6964,
    selected_locations: [],
    shortage_load_queue: [],
    shortages: {
      "2019-12-01": {
        groups: {
          26661: {
            reqs: {
              REQ1: {
                actual: 0,
                end_time: "2019-12-01 14:00:00-06:00",
                name: "Supervisors",
                ordinal_number: 1,
                required: 1,
                roles: ["S"],
                specials: ["OIC"],
                start_time: "2019-12-01 06:00:00-06:00",
                REQ2: {
                  actual: 4,
                  end_time: "2019-12-01 14:00:00-06:00",
                  name: "Officers",
                  ordinal_number: 1,
                  required: 5,
                  roles: ["P"],
                  specials: [],
                  start_time: "2019-12-01 06:00:00-06:00"
                }
              }
            }
          }
        }
      }
    }
  },
  client: {
    assets: {
      1: {
        asset_type: "Guns",
        barcode: "1234",
        id: 1,
        name: "Rifle #1234",
        notes: ""
      }
    },
    banks: {
      1: {
        abbreviation: "V",
        active: true,
        balance_cap: 60000,
        carryover_cap: 24000,
        carryover_cap_date: null,
        id: 1,
        linked_time_off_bank: null,
        linked_time_off_bank_relationship: null,
        name: "Vacation Bank",
        ordinal_number: 1,
        time_off_bank_type: 1,
        withdrawal_timing: 2
      },
      2: {
        abbreviation: "S",
        active: true,
        balance_cap: 60000,
        carryover_cap: 30000,
        carryover_cap_date: null,
        id: 2,
        linked_time_off_bank: null,
        linked_time_off_bank_relationship: null,
        name: "Sick Bank",
        ordinal_number: 2,
        time_off_bank_type: 1,
        withdrawal_timing: 2
      },
      3: {
        abbreviation: "CT",
        active: true,
        balance_cap: 2880,
        carryover_cap: 0,
        carryover_cap_date: null,
        id: 3,
        linked_time_off_bank: null,
        linked_time_off_bank_relationship: null,
        name: "Comp Time Bank",
        ordinal_number: 3,
        time_off_bank_type: 1,
        withdrawal_timing: 2
      }
    },
    custom_forms: custom_forms,
    day_notes: {},
    groups: {
      1: {
        abbreviation: "DS",
        id: 1,
        is_implied_group: false,
        manpower_end_time: null,
        manpower_start_time: null,
        name: "Day Shift",
        non_group_manpower_counts_as_support: true,
        ordinal_number: 1,
        people: [1],
        shift_times: "1",
        show_even_if_no_people: false,
        show_extra_support: true,
        show_on_calendar: true
      }
    },
    id: 1,
    locations: {
      1: {
        abbreviation: "S1",
        client: 1,
        id: 1,
        name: "Station 1"
      }
    },
    name: "Demo Mock",
    settings: {
      am_pm: false,
      assignment_name: "Beat",
      calendar_type: 0,
      change_request_types: {
        additional_time: true,
        additional_time_overtime: true,
        additional_time_special_assignments: true,
        benefit_time_trade: false,
        change: true,
        claim: true,
        comp_time: true,
        cover: false,
        holdover: true,
        holdover_overtime: undefined,
        overtime: true,
        partial_trade: false,
        release: false,
        release_help_text: "",
        special_assignment: true,
        time_off: true,
        trade: true,
        tradeable_benefit_time_bank: 1
      },
      change_requests_enabled: true,
      change_requests_modify_enabled: true,
      change_requests_require_comment_for_rejection: undefined,
      change_requests_rescind_enabled: true,
      create_empty_shifts_enabled: true,
      current_period: "2019-12-01",
      daily_shifts_include_assets: true,
      daily_shifts_include_assignments: true,
      holdover_choices: [1, 2, 3, 4, 5],
      holdover_enabled: true,
      holdover_length_minute_increment: 15,
      holdover_name: "Early/Late Arrival/Dismissal",
      mapping: {
        assignments: true,
        overtime: true,
        shift_time: undefined,
        time_off: true
      },
      on_call_enabled: true,
      only_admin_sees_shift_notes: undefined,
      partial_shift_change_requests_enabled: true,
      request_overtime_as_comp_time_enabled: true,
      shift_length_minute_increment: 15,
      special_assignments_name: "Special Assignment",
      timebanks: true,
      timezone: "US/Central"
    },
    periods: periods /*{
      "2019-12-01": {
        changes: [],
        groups: [],
        notes: [],
        roles: [1],
        schedules: [],
        shift_times: [1, 2, 3, 4, 5],
        shifts: [1, 2, 3],
        time_off_types: [1]
      }
    },*/,
    people: people,
    period_list: ["2019-12-01", "2020-01-01", "2019-11-01"],
    roles: {
      1: {
        abbreviation: "P",
        background_color: 7,
        beats_enabled: true,
        color: 0,
        id: 1,
        is_supervisor_job_type: false,
        name: "Patrol",
        omit_job_type_from_reports: false,
        ordinal_number: 1,
        people: [1],
        seniority_list: "[1]"
      }
    },
    schedules: {
      1: {
        abbreviation: "MAIN",
        grid_job_type_subtotals_enabled: true,
        groups: [1],
        id: 1,
        name: "Main Schedule",
        ordinal_number: 1000
      }
    },
    time_off_types: time_off_types,
    skin: 2,
    shift_times: shift_times,
    subdomain: "demo",
    titles: titles,
    subordinates: {
      modify_schedules: false
    }
  },
  form: {
    changeTimeSelected: "full",
    changeTypeSelected: "new",
    claimShiftTime: null,
    claimShiftType: "work",
    customField: null,
    customForm: [],
    customFormData: {},
    customTimeEnd: null,
    customTimeStart: null,
    dismissalSelected: null,
    holdoverLength: 0,
    invalidTimeEnd: false,
    invalidTimeStart: false,
    locationSelected: 0,
    modChange: 0,
    modNewType: 0,
    note: null,
    payPreference: null,
    releaseType: 0,
    shiftEditState: {
      customTimeEnd: {
        HH: "14",
        mm: "00"
      },
      customTimeStart: {
        HH: "06",
        mm: "00"
      },
      id: 0,
      loaded: false,
      note: "",
      shiftAssets: [],
      shiftChanges: [],
      shiftLocation: 0,
      shiftRole: 0,
      showModal: false
    },
    splitPayPreferencePaid: null,
    splitPayPreferenceTime: null,
    targetCustomTimeEnd: null,
    targetCustomTimeStart: null,
    targetInvalidTimeEnd: false,
    targetInvalidTimeStart: false,
    targetShift: null,
    targetUser: null,
    timeOffTypeSelected: 0,
    tradeTarget: 0,
    tradeType: null,
    validFieldValues: true
  },
  request: {
    activePeriod: null,
    dismissCountDown: 0,
    dismissSecs: 5,
    errors: [],
    min_staffing_risk: null,
    processingRequest: false,
    requestType: null,
    selectedSupervisors: [],
    shiftSelected: null,
    supervisors: [],
    validated: false,
    validating: false
  },
  shifts: {
    shifts: shifts,
    changes: changes,
    min_staffing_risk: {},
    processing_shortage_request: false
  },
  task: {
    min_staffing_risk: {},
    tasks: {}
  },
  titlebar_header: "Today"
};
