export const CHECK_LOCKOUT = "CHECK_LOCKOUT";
export const DELETE_LOCKOUT = "DELETE_LOCKOUT";
export const DELETE_SHORTAGE = "DELETE_SHORTAGE";
export const DELETE_SHORTAGES = "DELETE_SHORTAGES";
export const DELETE_SHORTAGE_FROM_QUEUE = "DELETE_SHORTAGE_FROM_QUEUE";
export const LOAD_DEFAULT_SCHEDULE = "LOAD_DEFAULT_SCHEDULE";
export const SET_DEFAULT_SCHEDULE = "SET_DEFAULT_SCHEDULE";
export const POP_SHORTAGE_REQUEST = "POP_SHORTAGE_REQUEST";
export const QUEUE_SHORTAGE_REQUEST = "QUEUE_SHORTAGE_REQUEST";
export const RESET_STATE = "RESET_STATE";
export const SAVE_SHORTAGE = "SAVE_SHORTAGE";
export const SAVE_SHORTAGES = "SAVE_SHORTAGES";
export const SET_ACTIVE_PERIOD = "SET_ACTIVE_PERIOD";
export const SET_DEPARTMENT_VIEW = "SET_DEPARTMENT_VIEW";
export const SET_LOCATIONS = "SET_LOCATIONS";
export const SET_PICKER_STALE = "SET_PICKER_STALE";
export const SET_SELECTED_DAY = "SET_SELECTED_DAY";
export const SET_SELECTED_SCHEDULE = "SET_SELECTED_SCHEDULE";
export const SET_SELECTED_PERIOD = "SET_SELECTED_PERIOD";
export const SET_SHORTAGES = "SET_SHORTAGES";
export const SET_SHORTAGE_LOAD_QUEUE = "SET_SHORTAGE_LOAD_QUEUE";
export const SET_LOCKOUTS = "SET_LOCKOUTS";
export const TOGGLE_DEPARTMENT_VIEW = "TOGGLE_DEPARTMENT_VIEW";
export const TOGGLE_LOCATION = "TOGGLE_LOCATION";
export const UPDATE_LOCKOUT = "UPDATE_LOCKOUT";
export const UPDATE_SELECTED_SCHEDULE = "UPDATE_SELECTED_SCHEDULE";
export const UPDATE_SHORTAGES = "UPDATE_SHORTAGES";
export const SELECT_MONTH = "SELECT_MONTH";
export const SELECT_YEAR = "SELECT_YEAR";
