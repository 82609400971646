// LIBS
import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { sync } from "vuex-router-sync";
import VueNativeSock from "vue-native-websocket";
import VCalendar from "v-calendar";
//import "v-calendar/lib/v-calendar.min.css";
import VueTouch from "vue-touch";
import VueAnalytics from "vue-analytics";
import VueToast from "vue-toast-notification";
import {
  AlertPlugin,
  BadgePlugin,
  ButtonPlugin,
  CardPlugin,
  CollapsePlugin,
  FormCheckboxPlugin,
  IconsPlugin,
  LayoutPlugin,
  ModalPlugin,
  SpinnerPlugin,
  TabsPlugin,
  VBTooltipPlugin
} from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue2-timepicker/dist/VueTimepicker.css";
import "vue-toast-notification/dist/theme-sugar.css";
require("@js-joda/timezone");
import "@js-joda/locale_en-us";
import "./cycle";
// UI VENDORS
import * as Uiv from "uiv";

if (!window.APP_NAME) {
  window.APP_NAME = "pacescheduler.com";
}
window.was_offline_at_some_point = false;
window.was_offline_callback = () => {};
const hasNetwork = async isOnline => {
  if (!isOnline) {
    window.was_offline_at_some_point = true;
    window.was_offline_callback();
  } else {
    if (window.was_offline_at_some_point) {
      await fetch(`https://mobileapp.${window.APP_NAME}`, {
        cache: "reload" //,
        //credentials: "include"
      }).catch(e => {
        console.log(e);
      });
      await fetch(`https://mobileapp.${window.APP_NAME}/auth`, {
        cache: "reload" //,
        //credentials: "include"
      }).catch(e => {
        console.log(e);
      });
      await fetch(`https://mobileapp.${window.APP_NAME}/index_bundle.js`, {
        cache: "reload" //,
        //credentials: "include"
      }).catch(e => {
        console.log(e);
      });
      window.location = `https://mobileapp.${window.APP_NAME}/`;
    }
  }
  return false;
};

console.log(process.env.NODE_ENV);
window.addEventListener("load", async () => {
  await hasNetwork(navigator.onLine);

  window.addEventListener("online", async () => {
    await hasNetwork(true);
  });

  window.addEventListener("offline", async () => {
    await hasNetwork(false);
  });
});

if (process.env.NODE_ENV === "production") {
  try {
    Sentry.init({
      Vue,
      release: "pacescheduler-mobile@" + process.env.VUE_APP_VERSION,
      dsn:
        "https://1a793eeca2d24494b45328c81463be83@o197135.ingest.sentry.io/5644572",
      maxBreadcrumbs: 25,
      integrations: [new Integrations.BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      //tracesSampleRate: 0.5,
      logErrors: true,
      beforeSend(event) {
        //console.log("sending sentry event with: ", event);
        // event.contexts.vue has all the metadata
        if (event.exception.values && event.exception.values.length) {
          const exception_value = event.exception.values[0].value;
          if (exception_value.includes("Navigation cancelled")) return null;
          if (exception_value.includes("network connection")) return null;
        }
        return event;
      }
    });
  } catch (e) {
    console.log("sentry error", e);
  }
}
Vue.config.productionTip = false;

// LIB INITIALIZERS
Vue.use(AlertPlugin);
Vue.use(BadgePlugin);
Vue.use(ButtonPlugin);
Vue.use(CardPlugin);
Vue.use(CollapsePlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(IconsPlugin);
Vue.use(LayoutPlugin);
Vue.use(ModalPlugin);
Vue.use(SpinnerPlugin);
Vue.use(TabsPlugin);
Vue.use(VBTooltipPlugin);
Vue.use(Uiv, { prefix: "uiv" });
Vue.use(VueTouch);

// Use v-calendar, v-date-picker & v-popover components
Vue.use(VCalendar, {
  componentPrefix: "vc",
  firstDayOfWeek: 1,
  dayCellNotInMonth: {
    opacity: 0
  }
});

VueTouch.config.swipe = {
  direction: "horizontal"
};

// SCRIPTS
import { store } from "@/store";
import router from "@/router";

// const isProd = process.env.NODE_ENV === "production";
Vue.use(VueNativeSock, "ws://localhost.com:8001", {
  format: "json",
  store: store,
  connectManually: true,
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  reconnectionAttempts: 10, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 3000 // (Number) how long to initially wait before attempting a new (1000)
});

Vue.use(VueAnalytics, {
  id: "UA-139080312-1",
  router,
  autoTracking: {
    exception: true
  },
  debug: {
    enabled: false, //process.env.NODE_ENV !== "production",
    sendHitTask: false //process.env.NODE_ENV !== "production" // isProd
  }
});

Vue.use(VueToast);

router.beforeEach((to, from, next) => {
  if (from.path.startsWith("/request") && !to.path.startsWith("/request")) {
    store.dispatch("request/RESET_STATE");
    store.dispatch("form/RESET_STATE");
  }
  if (to.path.startsWith("/request") && !from.path.startsWith("/request")) {
    store.dispatch("request/SET_FROM_SCREEN", {
      name: from.name,
      params: from.params
    });
  }
  if (to.path.startsWith("/create") && !from.path.startsWith("/create")) {
    store.dispatch("request/SET_FROM_SCREEN", {
      name: from.name,
      params: from.params
    });
  }
  if (store.state.auth.authenticated === false) {
    if (to.path !== "/auth" && to.path !== "/2fa") {
      next("/auth");
    } else {
      next();
    }
  } else {
    next();
  }
});

sync(store, router);

// PARTIALS
import Scheduler from "@/Scheduler";

new Vue({
  router,
  el: document.getElementById("app"),
  store,
  render: h => h(Scheduler)
});
