import Vue from "vue";
import {
  DONE_LOADING,
  SET_LAST_PING,
  SET_APP_ACTIVE,
  SET_SCHEDULER_BUSY,
  SET_SIDEBAR_VISIBLE,
  SET_DATA_SYNCING,
  SOCKET_AUTHED,
  SET_TASK_PROCESSING,
  SET_SOCKET_NEEDS_AUTH,
  SET_CURRENT_LOCATION,
  SET_REQUEST_DATA,
  SET_REQUEST_SUBMIT,
  SET_SOCKET,
  SET_FETCHING_REQUEST_DATA,
  SET_VALUE,
  TOGGLE_DEV_MODE,
  DEV_MODE_INCREMENT,
  SET_DEV_MODE,
  SET_DEV_MODE_COUNTER,
  SET_API_URL,
  SET_WEBSOCKET_URL,
  SET_DISMISS_SECS,
  SET_DISMISS_COUNT_DOWN,
  SET_TASKS_UPDATE_REQUESTED,
  TOGGLE_LOCAL_MODE,
  SET_LOCAL_DEV_MODE,
  SET_LOCAL_DEV_SERVER,
  SET_SHOW_DAYS_OFF,
  SET_SHOW_CALENDAR_DAYS_OFF,
  SET_USE_IP
} from "../mutation-types";
import { Vueset } from "../vuehack/reactivehack";
import { _now } from "../time";
import {
  DISMISS_ERROR_COUNTDOWN,
  SET_ERRORS,
  SHOW_ERROR_ALERT
} from "./request/mutation_types";

export default {
  async [DONE_LOADING](state) {
    Vueset(state, "data_syncing", false);
  },
  [SET_DISMISS_SECS](state, value) {
    Vueset(state, "dismiss_secs", value);
  },
  [SET_DISMISS_COUNT_DOWN](state, value) {
    Vueset(state, "dismiss_count_Down", value);
  },
  [DISMISS_ERROR_COUNTDOWN](state, value) {
    Vueset(state, "dismiss_count_down", value);
  },
  [SET_ERRORS](state, value) {
    Vueset(state, "errors", value);
  },
  [SHOW_ERROR_ALERT](state) {
    Vueset(state, "dismiss_count_down", state.dismiss_secs);
  },
  async [SET_SHOW_DAYS_OFF](state, payload) {
    Vueset(state, "show_days_off", payload);
  },
  async [SET_SHOW_CALENDAR_DAYS_OFF](state, payload) {
    Vueset(state, "show_calendar_days_off", payload);
  },
  async [SET_VALUE](state, payload) {
    Vueset(state, payload.key, payload.value);
  },
  async [SET_SIDEBAR_VISIBLE](state, payload) {
    Vueset(state, "sidebar_visible", payload);
  },
  async [SET_SOCKET_NEEDS_AUTH](state, payload) {
    Vueset(state, "socket_needs_auth", payload);
  },
  async [SET_CURRENT_LOCATION](state, payload) {
    Vueset(state, "current_location", payload);
  },
  async [SET_REQUEST_DATA](state, payload) {
    Vueset(state, "request_data", payload);
  },
  async [SET_TASKS_UPDATE_REQUESTED](state, payload) {
    Vueset(state, "tasks_update_requested", payload);
  },
  async [SET_REQUEST_SUBMIT](state, payload) {
    Vueset(state, "request_submit", payload);
  },
  async [SET_SOCKET](state, payload) {
    Vueset(state, "socket", payload);
  },
  async [SET_FETCHING_REQUEST_DATA](state, payload) {
    Vueset(state, "fetching_request_data", payload);
  },
  SOCKET_ONOPEN(state, event) {
    //console.log("Socket is open");
    Vue.prototype.$socket = event.currentTarget;
    Vueset(state.socket, "isConnected", true);
  },
  // eslint-disable-next-line no-unused-vars
  SOCKET_ONCLOSE(state, event) {
    //console.log("Socket is closed");
    Vueset(state, "socket_needs_auth", true);
    Vueset(state.socket, "isConnected", false);
  },
  SOCKET_ONERROR(state, event) {
    console.error(state, event);
    Vueset(state, "socket_needs_auth", true);
    console.log("Socket error");
  },
  // default handler called for all methods
  SOCKET_ONMESSAGE(state, message) {
    //console.log("received message from ws.", message);
    Vueset(state.socket, "message", message);
  },
  // mutations for reconnect methods
  SOCKET_RECONNECT(state, count) {
    console.info(state, count);
    console.log("Socket reconnect");
  },
  SOCKET_RECONNECT_ERROR(state) {
    Vueset(state.socket, "reconnectError", true);
    console.log("Socket reconnect error");
  },
  [SOCKET_AUTHED](state) {
    Vueset(state, "socket_needs_auth", false);
  },
  SET_CLAIM_CUSTOM_HOURS: function(state, value) {
    Vueset(state, "claimCustomHours", value);
  },
  SET_CLAIM_INVALID_TIME: function(state, value) {
    Vueset(state, "claim_invalid_time", value);
  },
  FETCHING_REQUEST: function(state, val) {
    Vueset(state, "fetching_request_data", val);
  },
  SAVE_REQUEST: function(state, request) {
    Vueset(state, "request_data", request);
  },
  SAVE_FORM: function(state, formState) {
    Vueset(state, "requestState", formState);
  },
  SET_TITLEBAR_HEADER: function(state, val) {
    if (val) {
      Vueset(state, "titlebar_header", val);
    }
  },
  [SET_APP_ACTIVE]: function(state, val) {
    Vueset(state, "app_active", val);
  },
  [SET_LAST_PING]: function(state) {
    Vueset(state, "last_ping", _now());
  },
  [SET_LOCAL_DEV_MODE]: function(state, val) {
    Vueset(state, "local_dev_mode", val);
  },
  [SET_LOCAL_DEV_SERVER]: function(state, val) {
    Vueset(state, "local_dev_server", val);
  },
  [SET_DATA_SYNCING]: function(state, val) {
    Vueset(state, "data_syncing", val);
  },
  START_TASK_PROCESSING: function(state) {
    Vueset(state, "task_processing", true);
  },
  END_TASK_PROCESSING: function(state) {
    Vueset(state, "task_processing", false);
  },
  [SET_TASK_PROCESSING]: function(state, payload) {
    Vueset(state, "task_processing", payload);
  },
  SET_LOGOUT_REASON: function(state, value) {
    if (state.logout_reason !== null) {
      state.logout_reason = state.logout_reason + " | " + value;
    } else {
      state.logout_reason = value;
    }
  },
  SET_PARTIAL_CHANGE_START: function(state, value) {
    state.partialChangeStart = value;
    state.request_data["changeStart"] = state.partialChangeStart;
  },
  SET_PARTIAL_CHANGE_END: function(state, value) {
    state.partialChangeEnd = value;
    state.request_data["changeEnd"] = state.partialChangeEnd;
  },
  SET_INVALID_CHANGE_START: function(state, value) {
    state.invalidChangeStart = value;
  },
  SET_INVALID_CHANGE_END: function(state, value) {
    state.invalimodeldChangeEnd = value;
  },
  SET_REQUEST_TIME_TYPE: function(state, value) {
    state.requestTimeType = value;
  },
  SET_SHIFT_TIME: function(state, value) {
    state.shift_time = value;
    state.request_data["shiftTime"] = state.shift_time;
  },
  SET_CLAIM_DAY: function(state, value) {
    state.claimDay = value;
  },
  SET_CLAIM_TARGET: function(state, value) {
    state.claimTarget = value;
  },
  SET_REQUEST_NOTES: function(state, value) {
    state.requestNotes = value;
    state.request_data["notes"] = state.requestNotes;
  },
  SET_PAY_PREF: function(state, value) {
    state.payPref = value;
    state.request_data["payPref"] = state.payPref;
  },
  SET_TRADE_LENGTH: function(state, value) {
    state.tradeLength = value;
    state.request_data["tradeLength"] = value;
  },
  CLEAR_PAY_PREF: function(state) {
    state.payPref = "null";
    state.splitPrefTime = 0;
    state.splitPrefPaid = 0;
    state.request_data["payPref"] = state.payPref;
    state.request_data["splitPrefTime"] = state.splitPrefTime;
    state.request_data["splitPrefPaid"] = state.splitPrefPaid;
  },
  SET_BANK_CHANGE: function(state, value) {
    Vueset(state, "bankChange", value);
    Vueset(state.request_data, "bankChange", state.bankChange);
  },
  CLEAR_SELECTED_SUPERVISORS: function(state) {
    Vueset(state, "selectedSupervisors", []);
  },
  SET_MOD_NEW_TYPE: function(state, value) {
    Vueset(state, "modNewType", value);
  },
  SET_REMOVE_SHIFT: function(state, value) {
    Vueset(state, "remove_shift", value);
  },
  SHOW_EDIT_MODAL: function(state) {
    Vueset(state.shiftEditState, "showModal", true);
  },
  HIDE_EDIT_MODAL: function(state) {
    Vueset(state.shiftEditState, "showModal", false);
  },
  async [SET_SCHEDULER_BUSY](state, payload) {
    Vueset(state, "scheduler_busy", payload);
  },
  async [SET_DEV_MODE](state, payload) {
    Vue.set(state, "dev_mode", payload);
  },
  async [SET_DEV_MODE_COUNTER](state, payload) {
    Vue.set(state, "dev_mode_counter", payload);
  },
  async [SET_API_URL](state, payload) {
    Vue.set(state, "api_url", payload);
  },
  async [SET_USE_IP](state, payload) {
    Vue.set(state, "use_ip", payload);
  },
  async [SET_WEBSOCKET_URL](state, payload) {
    Vue.set(state, "websocket_url", payload);
  },
  async [TOGGLE_DEV_MODE](state) {
    if (state.dev_mode) {
      if (window.location.hostname.endsWith(".pacescheduler.com")) {
        Vue.set(state, "api_url", "https://api.pacescheduler.com/api/v1");
        Vue.set(
          state,
          "websocket_url",
          "wss://mobileapp.wss.pacescheduler.com/"
        );
        Vue.set(state, "local_dev_mode", false);
      } else if (window.location.hostname.endsWith(".pace-demo.com")) {
        Vue.set(state, "api_url", "https://api.pace-demo.com/api/v1");
        Vue.set(state, "websocket_url", "wss://mobileapp.wss.pace-demo.com/");
        Vue.set(state, "local_dev_mode", false);
      } else if (window.location.hostname.endsWith(".pace-staging.com")) {
        Vue.set(state, "api_url", "https://api.pace-staging.com/api/v1");
        Vue.set(
          state,
          "websocket_url",
          "wss://mobileapp.wss.pace-staging.com/"
        );
        Vue.set(state, "local_dev_mode", false);
      }
    } else {
      // Used to be demo
      Vue.set(state, "api_url", "https://api.pace-demo.com/api/v1");
      Vue.set(state, "websocket_url", "wss://mobileapp.wss.pace-demo.com/");
    }
    Vue.set(state, "dev_mode", !state.dev_mode);
    Vue.set(state, "dev_mode_counter", 0);
  },
  async [TOGGLE_LOCAL_MODE](state) {
    if (state.local_dev_mode) {
      // Used to be demo
      Vue.set(state, "api_url", "https://api.pace-demo.com/api/v1");
      Vue.set(state, "websocket_url", "wss://mobileapp.wss.pace-demo.com/");
    } else {
      if (!state.use_ip) {
        Vue.set(state, "api_url", "http://api.localhost.com:8000/api/v1");
        Vue.set(state, "websocket_url", "ws://localhost.com:8001/");
      } else {
        Vue.set(
          state,
          "api_url",
          `http://${state.local_dev_server}:8000/api/v1`
        );
        Vue.set(state, "websocket_url", `ws://${state.local_dev_server}:8001/`);
      }
    }
    Vue.set(state, "local_dev_mode", !state.local_dev_mode);
  },
  async [DEV_MODE_INCREMENT](state) {
    Vue.set(state, "dev_mode_counter", state.dev_mode_counter + 1);
  }
};
