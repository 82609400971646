export const ADD_SELECTED_SUPERVISOR = "ADD_SELECTED_SUPERVISOR";
export const CANCEL_SUBMISSION = "CANCEL_SUBMISSION";
export const REFRESH_SUPERVISORS = "REFRESH_SUPERVISORS";
export const REMOVE_SELECTED_SUPERVISOR = "REMOVE_SELECTED_SUPERVISOR";
export const RESET_CANCELLED = "RESET_CANCELLED";
export const RESET_STATE = "RESET_STATE";
export const RESET_MIN_STAFFING_RISK = "RESET_MIN_STAFFING_RISK";
export const SET_ACTIVE_PERIOD = "SET_ACTIVE_PERIOD";
export const SET_ERRORS = "SET_ERRORS";
export const SHOW_ERROR_ALERT = "SHOW_ERROR_ALERT";
export const DISMISS_ERROR_COUNTDOWN = "DISMISS_ERROR_COUNTDOWN";
export const SET_CANCELLED = "SET_CANCELLED";
export const SET_FROM_SCREEN = "SET_FROM_SCREEN";
export const SET_MIN_STAFFING_RISK = "SET_MIN_STAFFING_RISK";
export const SET_PROCESSING_REQUEST = "SET_PROCESSING_REQUEST";
export const SET_RELEASE_TYPE = "SET_RELEASE_TYPE";
export const SET_REQUEST_TYPE = "SET_REQUEST_TYPE";
export const SET_SHIFT_SELECTED = "SET_SHIFT_SELECTED";
export const SET_SUPERVISORS = "SET_SUPERVISORS";
export const SET_VALIDATING = "SET_VALIDATING";
export const SET_VALIDATED = "SET_VALIDATED";
export const SOFT_RESET_STATE = "SOFT_RESET_STATE";
export const SET_SUBMIT_AS_PENDING = "SET_SUBMIT_AS_PENDING";
export const SUBMIT_CREATE_REQUEST = "SUBMIT_CREATE_REQUEST";
export const SUBMIT_REQUEST = "SUBMIT_REQUEST";
export const VALIDATE_REQUEST = "VALIDATE_REQUEST";
export const VALIDATE_CHANGE_REQUEST = "VALIDATE_CHANGE_REQUEST";
export const VALIDATE_NEW_CHANGE_REQUEST = "VALIDATE_NEW_CHANGE_REQUEST";
export const VALIDATE_MODIFY_CHANGE_REQUEST = "VALIDATE_MODIFY_CHANGE_REQUEST";
export const VALIDATE_RESCIND_CHANGE_REQUEST =
  "VALIDATE_RESCIND_CHANGE_REQUEST";
export const VALIDATE_CLAIM_REQUEST = "VALIDATE_CLAIM_REQUEST";
export const VALIDATE_EXTRA_REQUEST = "VALIDATE_EXTRA_REQUEST";
export const VALIDATE_HOLDOVER_REQUEST = "VALIDATE_HOLDOVER_REQUEST";
export const VALIDATE_RELEASE_REQUEST = "VALIDATE_RELEASE_REQUEST";
export const VALIDATE_TRADE_REQUEST = "VALIDATE_TRADE_REQUEST";
export const VALIDATION_RESULT = "VALIDATION_RESULT";
