import { cloneDeep } from "bootstrap-vue/esm/utils/clone-deep";
import {
  UPDATE_SHIFT,
  UPDATE_SHIFT_CHANGE
} from "../store/shifts/mutation_types";

export const SAVE_MODEL = "SAVE_MODEL";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
/*
export const UPDATE_ASSET = "UPDATE_ASSET";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const UPDATE_PERSON = "UPDATE_PERSON";
export const UPDATE_PERSON_TIME_OFF_TYPE = "UPDATE_PERSON_TIME_OFF_TYPE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const UPDATE_SCHEDULE = "UPDATE_SCHEDULE";
export const UPDATE_TIME_BANK = "UPDATE_TIME_BANK";
export const UPDATE_TITLE = "UPDATE_TITLE";
export const UPDATE_TTFORM = "UPDATE_TTFORM";
export const UPDATE_TTFORMRESPONSE = "UPDATE_TTFORMRESPONSE";*/

export const UPDATE_TTFORMELEMENT = "UPDATE_TTFORMELEMENT";
export const UPDATE_MONTHLY_MODEL_VALUE = "UPDATE_MONTHLY_MODEL_VALUE";
export const DELETE_MODEL = "DELETE_MODEL";
//import Vue from "vue";
import { store } from "../store/index";
import { Vueset, del } from "../vuehack/reactivehack";

export const UpdateBase = {
  update_processor: null,
  model: null,
  module: null,
  url_part: null,
  update_url(model_id) {
    return `${store.state.api_url}/${this.url_part}/${model_id}/`;
  },
  update_mutation(state, payload) {
    let data = payload.data;
    if (this.update_processor) {
      data = this.update_processor(data);
    }
    Vueset(state[this.model], data.id, data);
    Vueset(state, this.model, state[this.model]);
  },
  delete_mutation(state, payload) {
    del(state[this.model], payload.data.id);
    Vueset(state, this.model, state[this.model]);
  }
};

export const UpdateValueBase = Object.assign(cloneDeep(UpdateBase), {
  value: null,
  update_mutation(state, payload) {
    let data = payload.data;
    if (this.update_processor) {
      data = this.update_processor(data);
    }
    Vueset(state[this.model][this.value], data.id, data);
    // might need just this - Vueset(state[this.model], this.value, state[this.model][this.value]);
    Vueset(state, this.model, state[this.model]);
  },
  delete_mutation(state, payload) {
    let obj = state[this.model][this.value];
    if (typeof obj === typeof Object) {
      del(state[this.model][this.value], payload.data.id);
    } else if (typeof obj === typeof Array) {
      del(state[this.model][this.value], payload.data.id);
      for (let i = 0; i <= obj.length - 1; i++) {
        if (obj[i] === payload.data.id) {
          obj.splice(i, 1);
        }
      }
    }
    Vueset(state, this.model, state[this.model]);
  }
});

export const UpdateTTFormElementValue = Object.assign(cloneDeep(UpdateBase), {
  value: null,
  update_mutation(state, payload) {
    let data = payload.data;
    if (this.update_processor) {
      data = this.update_processor(data);
    }
    Vueset(state[this.model][data.form][this.value], data.id, data);
    // might need just this - Vueset(state[this.model], this.value, state[this.model][this.value]);
    Vueset(state[this.model], data.form, state[this.model][data.form]);
    Vueset(state, this.model, state[this.model]);
  },
  delete_mutation(state, payload) {
    for (let form of Object.keys(state[this.model])) {
      let obj = state[this.model][form][this.value];
      if (typeof obj === "object") {
        del(state[this.model][form][this.value], payload.data.id);
      } else if (typeof obj === typeof Array) {
        del(state[this.model][form][this.value], payload.data.id);
        for (let i = 0; i <= obj.length - 1; i++) {
          if (obj[i] === payload.data.id) {
            obj.splice(i, 1);
          }
        }
      }
      Vueset(
        state[this.model][form],
        this.value,
        state[this.model][form][this.value]
      );
      Vueset(state[this.model], form, state[this.model][form]);
    }
    Vueset(state, this.model, state[this.model]);
  }
});

export const UpdateMonthlyBase = Object.assign(cloneDeep(UpdateValueBase), {
  update_mutation(state, payload) {
    let data = payload.data;
    if (this.update_processor) {
      data = this.update_processor(data);
    }
    Vueset(state[this.model], data.id, data);
    if (!state.periods[payload.period][this.model].includes(data.id)) {
      state.periods[payload.period][this.model].push(data.id);
    }
    Vueset(state, this.model, state[this.model]);
    Vueset(state.periods, payload.period, state.periods[payload.period]);
  },
  delete_mutation(state, payload) {
    del(state[this.model], payload.data.id);
    for (
      let i = 0;
      i <= state.periods[payload.period][this.model].length - 1;
      i++
    ) {
      if (state.periods[payload.period][this.model][i] === payload.data.id) {
        state.periods[payload.period][this.model].splice(i, 1);
      }
    }
    Vueset(state, this.model, state[this.model]);
    Vueset(state.periods, payload.period, state.periods[payload.period]);
  }
});

export const time_off_type_processor = payload => {
  payload.comp_time_bank =
    payload.comp_time_bank_id !== undefined
      ? payload.comp_time_bank_id
      : payload.comp_time_bank;
  payload.time_off_bank =
    payload.time_off_bank_id !== undefined
      ? payload.time_off_bank_id
      : payload.time_off_bank;
  payload.custom_form =
    payload.custom_form_id !== undefined
      ? payload.custom_form_id
      : payload.custom_form;
  return payload;
};

export const MODEL_UPDATE_MAP = {
  change: ["shifts", UPDATE_SHIFT_CHANGE, "shiftchanges"],
  shift: ["shifts", UPDATE_SHIFT, "shifts"],
  client: ["client", UPDATE_CLIENT, "clients"],
  asset: Object.assign(cloneDeep(UpdateBase), {
    module: "client",
    model: "assets",
    url_part: "assets"
  }),
  group: Object.assign(cloneDeep(UpdateMonthlyBase), {
    module: "client",
    model: "groups",
    url_part: "groups"
  }),
  location: Object.assign(cloneDeep(UpdateMonthlyBase), {
    module: "client",
    model: "locations",
    url_part: "locations"
  }),
  person: Object.assign(cloneDeep(UpdateBase), {
    module: "client",
    model: "people",
    url_part: "persons"
  }),
  person_time_off_type: Object.assign(cloneDeep(UpdateValueBase), {
    module: "client",
    model: "people",
    value: "time_off_types",
    url_part: "persontimeofftypes"
  }),
  role: Object.assign(cloneDeep(UpdateMonthlyBase), {
    module: "client",
    model: "roles",
    url_part: "jobtypes"
  }),
  schedule: Object.assign(cloneDeep(UpdateMonthlyBase), {
    module: "client",
    model: "schedules",
    url_part: "schedules"
  }),
  shift_time: Object.assign(cloneDeep(UpdateMonthlyBase), {
    module: "client",
    model: "shift_times",
    url_part: "shifttimes"
  }),
  time_off_bank: Object.assign(cloneDeep(UpdateBase), {
    module: "client",
    model: "banks",
    url_part: "timeoffbanks"
  }),
  time_off_type: Object.assign(cloneDeep(UpdateMonthlyBase), {
    module: "client",
    model: "time_off_types",
    url_part: "timeofftypes",
    update_processor: time_off_type_processor
  }),
  title: Object.assign(cloneDeep(UpdateBase), {
    module: "client",
    model: "titles",
    url_part: "titles"
  }),
  tt_form: Object.assign(cloneDeep(UpdateBase), {
    module: "client",
    model: "custom_forms",
    url_part: "timetrackingforms"
  }),
  //tt_form_element: ["client", UPDATE_TTFORMELEMENT, "timetrackingformelements"],

  tt_form_element: Object.assign(cloneDeep(UpdateTTFormElementValue), {
    module: "client",
    model: "custom_forms",
    value: "elements",
    url_part: "timetrackingformelements"
  })
};
// tt_form: ["client", UPDATE_TTFORM, "timetrackingforms"],
// tt_form_element: ["client", UPDATE_TTFORMELEMENT, "timetrackingformelements"],
