export const APPROVE = "APPROVE";
export const CANCEL = "CANCEL";
export const CANCEL_CONFIRM = "CANCEL_CONFIRM";
export const CHECK_DELETE_TASK = "CHECK_DELETE_TASK";
export const CONFIRM = "CONFIRM";
export const UPDATE_TASKS = "UPDATE_TASKS";
export const REJECT = "REJECT";
export const RESET_STATE = "RESET_STATE";
export const SAVE_MIN_STAFFING_RISK = "SAVE_MIN_STAFFING_RISK";
export const SAVE_TASKS = "SAVE_TASKS";
export const SUBMIT_MODERATION = "SUBMIT_MODERATION";
export const SELECT_REQUEST = "SELECT_REQUEST";
export const SET_ADMIN_COMMENT = "SET_ADMIN_COMMENT";
export const SET_CONFIRMING = "SET_CONFIRMING";
export const SET_MISSING_COMMENT = "SET_MISSING_COMMENT";
export const SET_PROCESSING_TYPE = "SET_PROCESSING_TYPE";
export const SET_PROCESSING = "SET_PROCESSING";
export const SET_TASKS = "SET_TASKS";
export const SET_MIN_STAFFING_RISK = "SET_MIN_STAFFING_RISK";
export const UNSET_CONFIRMING = "UNSET_CONFIRMING";
export const UNSET_MISSING_COMMENT = "UNSET_MISSING_COMMENT";
