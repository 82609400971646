import {
  RESET_CHANGE_FORM,
  RESET_CLAIM_FORM,
  RESET_EDIT_FORM,
  RESET_EXTRA_FORM,
  RESET_HOLDOVER_FORM,
  RESET_PAY_PREFERENCE,
  RESET_RELEASE_FORM,
  RESET_STATE,
  RESET_TRADE_FORM,
  SET_CHANGE_TIME,
  SET_CHANGE_TYPE,
  SET_CLAIM_SHIFT_TIME,
  SET_CLAIM_SHIFT_TYPE,
  SET_CUSTOM_TIME_END,
  SET_CUSTOM_TIME_START,
  SET_CUSTOM_TIME_STARTS_ON_PREVIOUS_DAY,
  SET_EDIT_SHIFT_ASSET_EXPANDED,
  SET_EDIT_SHIFT_ASSET_REMOVE,
  SET_EDIT_SHIFT_CHANGE_EXPANDED,
  SET_EDIT_SHIFT_CHANGE_REMOVE,
  SET_EDIT_SHIFT_CUSTOM_TIME_END,
  SET_EDIT_SHIFT_CUSTOM_TIME_START,
  SET_EDIT_SHIFT_GROUP,
  SET_EDIT_SHIFT_LOCATION,
  SET_EDIT_SHIFT_NOTE,
  SET_EDIT_SHIFT_ROLE,
  SET_EDIT_SHIFT_STATE,
  SET_EDIT_SHIFT_TIME,
  SET_HOLDOVER_LENGTH,
  SET_INVALID_CUSTOM_TIME_END,
  SET_INVALID_CUSTOM_TIME_START,
  SET_INVALID_TARGET_CUSTOM_TIME_END,
  SET_INVALID_TARGET_CUSTOM_TIME_START,
  SET_IS_EXTRA_SHIFT,
  SET_NOTE,
  SET_MOD_CHANGE,
  SET_MOD_NEW_TYPE,
  SET_PARTIAL_CLAIM_OPTION,
  SET_PAY_PREFERENCE,
  SET_PERSON_COVERING,
  SET_RELEASE_TYPE,
  SET_SELECTED_DATE,
  SET_SINGLE_TIME_OFF_TYPE,
  SET_SPLIT_PAY_PREFERENCE_PAID,
  SET_SPLIT_PAY_PREFERENCE_TIME,
  SET_TARGET_CUSTOM_TIME_END,
  SET_TARGET_CUSTOM_TIME_START,
  SET_TARGET_SHIFT,
  SET_TIME_OFF_TYPE,
  SET_TIME_OFF_TYPE_REMAINING_TIME,
  SET_TRADE_TARGET,
  SET_TRADE_TYPE,
  UPDATE_CUSTOM_FORM,
  UPDATE_CUSTOM_FORM_VALUE,
  SET_SHIFT_TIME,
  SET_SHIFT_OTHER_TIME_START,
  SET_SHIFT_OTHER_TIME_END,
  SET_SHIFT_CHANGE_END,
  SET_SHIFT_CHANGE_START,
  SHOW_EDIT_MODAL,
  HIDE_EDIT_MODAL,
  SET_FDOM,
  SET_CLAIM_DAY,
  ADD_TIME_OFF_TYPE,
  REMOVE_TIME_OFF_TYPE,
  ADD_SPLIT_TIME_OPTION
} from "./mutation_types";
import Vue from "vue";
import { SET_VALUE } from "../../mutation-types";
import { Vueset } from "../../vuehack/reactivehack";
//import { Vue.set } from "../../vuehack/reactivehack";

export default {
  [ADD_SPLIT_TIME_OPTION](state, payload) {
    let has_option_already = false;
    let i = 0;
    for (const option of state.splitOptionsSelected) {
      if (option.index === payload.index) {
        has_option_already = true;
        break;
      }
      i += 1;
    }
    if (!has_option_already) {
      state.splitOptionsSelected.push(payload);
    } else {
      state.splitOptionsSelected.splice(i, 1);
    }
    Vue.set(state, "splitOptionsSelected", state.splitOptionsSelected);
    //console.log(state);
  },
  [ADD_TIME_OFF_TYPE](state, payload) {
    const timeOffTypesSelected = state.timeOffTypesSelected;
    timeOffTypesSelected.push(payload);
    Vue.set(state, "timeOffTypesSelected", timeOffTypesSelected);
    Vue.set(state, "timeOffTypeSelected", 0);
  },
  [REMOVE_TIME_OFF_TYPE](state, payload) {
    const timeOffTypesSelected = state.timeOffTypesSelected;
    for (const tot of timeOffTypesSelected) {
      if (tot.id === payload) {
        const index = timeOffTypesSelected.indexOf(tot);
        timeOffTypesSelected.splice(index, 1);
        break;
      }
    }
    Vue.set(state, "timeOffTypesSelected", timeOffTypesSelected);
  },
  // eslint-disable-next-line no-unused-vars
  [RESET_CHANGE_FORM](state) {
    //console.log("RESET_CHANGE", state);
  },
  [RESET_CLAIM_FORM](state) {
    //console.log("RESET_CLAIM", state);
    // Claim Shift
    Vue.set(state, "claimShiftType", "work");
    Vue.set(state, "claimShiftTime", null);

    Vue.set(state, "timeOffTypeSelected", 0);
    // Custom Time
    Vue.set(state, "customTimeStart", null);
    Vue.set(state, "customTimeEnd", null);
    Vue.set(state, "customTimeStartsOnPreviousDay", false);
    Vue.set(state, "invalidTimeStart", false);
    Vue.set(state, "invalidTimeEnd", false);

    // Pay Prefs
    Vue.set(state, "payPreference", null);
    Vue.set(state, "splitPayPreferenceTime", null);
    Vue.set(state, "splitPayPreferencePaid", null);
    /*

      this.$store.commit("SET_REQUEST_TIME_TYPE", 0);
      this.$store.commit("SET_MOD_NEW_TYPE", 0);
      this.selected_day = null;
      this.selectedTarget = null;
      this.claimShiftType = "work";
      this.claimShift = "null";
      this.claimTarget = 0;
      this.resetPayPref();

      // Partial Change
      // this.changeStart = { HH: "06", mm: "00" };
      // this.changeEnd = { HH: "14", mm: "00" };
      this.validFieldValues = false;
      this.postRequestData();
     */
  },
  [RESET_EDIT_FORM](state) {
    Vue.set(state, "shiftEditState", null);
    Vue.set(state, "shiftEditState", {
      id: 0,
      loaded: false,
      showModal: false,
      shiftAssets: [],
      shiftLocation: 0,
      shiftRole: 0,
      shiftChanges: [],
      customTimeStart: { HH: "06", mm: "00" },
      customTimeEnd: { HH: "14", mm: "00" },
      note: ""
    });
  },
  // eslint-disable-next-line no-unused-vars
  [RESET_EXTRA_FORM](state) {
    //console.log("RESET_EXTRA", state);
    /*
      this.$store.commit("CLEAR_PAY_PREF");
      this.$store.commit("SET_REQUEST_TIME_TYPE", 0);
      this.$store.commit("SET_MOD_NEW_TYPE", 0);
      //this.customForm = [];
      this.validFieldValues = false;
      this.postRequestData();

     */
  },
  [RESET_HOLDOVER_FORM](state) {
    //console.log("RESET_HOLDOVER_FORM", state);
    // Pay Prefs
    Vue.set(state, "payPreference", null);
    Vue.set(state, "splitPayPreferenceTime", null);
    Vue.set(state, "splitPayPreferencePaid", null);
    Vue.set(state, "timeOffTypeSelected", 0);
    Vue.set(state, "customForm", []);
    Vue.set(state, "customFormData", {});
    Vue.set(state, "personCovering", 0);
    /*
      this.$store.commit("SET_HOLDOVER_LENGTH", 0);
      this.$store.commit("SET_REQUEST_TIME_TYPE", 0);
      this.$store.commit("SET_MOD_NEW_TYPE", 0);
      this.resetPayPref();
      this.customForm = [];

      this.minStaffingRisk();
      this.validFieldValues = false;
      this.postRequestData();
     */
  },
  // eslint-disable-next-line no-unused-vars
  [RESET_PAY_PREFERENCE](state) {
    //console.log("RESET_PAY_PREFERENCE", state);
  },
  // eslint-disable-next-line no-unused-vars
  [RESET_RELEASE_FORM](state, payload) {
    //console.log("RESET_RELEASE_FORM", state);
    /*
      this.personCovering = null;
      this.releaseType = 0;
      this.validFieldValues = false;
      this.$store.commit("SET_REQUEST_TIME_TYPE", 0);
      this.$store.commit("SET_MOD_NEW_TYPE", 0);*/
    if (payload.isCover) {
      Vue.set(state, "releaseType", "1");
    }
  },
  [RESET_TRADE_FORM](state, settings) {
    //console.log("RESET_TRADE_FORM", state);
    if (settings.change_request_types.partial_trade) {
      Vue.set(state, "requestTime", "full");
    }
    if (settings.change_request_types.benefit_time_trade) {
      Vue.set(state, "tradeType", "shift");
    }
    Vue.set(state, "tradeTarget", 0);
  },
  [RESET_STATE](state, settings) {
    Vue.set(state, "isExtraShift", false);
    Vue.set(state, "shiftOtherTimeStart", { HH: "06", mm: "00" });
    Vue.set(state, "shiftOtherTimeEnd", { HH: "14", mm: "00" });
    Vue.set(state, "shiftChangeStart", null);
    Vue.set(state, "shiftChangeEnd", null);
    Vue.set(state, "selectedShiftTime", 0);

    // Change Type
    Vue.set(state, "changeTimeSelected", "full");
    Vue.set(state, "changeTypeSelected", "new");
    // Change Mod
    Vue.set(state, "modChange", 0);
    Vue.set(state, "modNewType", 0);

    // Claim Shift
    Vue.set(state, "claimShiftType", "work");
    Vue.set(state, "claimShiftTime", null);

    // Custom Time
    Vue.set(state, "customTimeStart", null);
    Vue.set(state, "customTimeStartsOnPreviousDay", false);
    Vue.set(state, "customTimeEnd", null);
    Vue.set(state, "invalidTimeStart", false);
    Vue.set(state, "invalidTimeEnd", false);

    // Custom Field
    Vue.set(state, "customField", null);

    // Dismissal Field
    Vue.set(state, "dismissalSelected", null);

    // Note
    Vue.set(state, "note", null);

    // Pay Prefs
    Vue.set(state, "payPreference", null);
    Vue.set(state, "splitPayPreferenceTime", null);
    Vue.set(state, "splitPayPreferencePaid", null);

    Vue.set(state, "shiftEditState", {
      id: 0,
      loaded: false,
      showModal: false,
      shiftAssets: [],
      shiftLocation: 0,
      shiftRole: 0,
      shiftChanges: [],
      customTimeStart: { HH: "06", mm: "00" },
      customTimeEnd: { HH: "14", mm: "00" },
      note: ""
    });

    // Release
    Vue.set(state, "releaseType", 0);

    // Target Shift
    Vue.set(state, "targetShift", null);

    // Target User
    Vue.set(state, "targetUser", null);

    // Time Type
    Vue.set(state, "timeOffTypeSelected", 0);

    // Validation
    Vue.set(state, "validFieldValues", true);
    if (settings.change_request_types) {
      if (settings.change_request_types.partial_trade) {
        Vue.set(state, "requestTime", "full");
      }
      if (settings.change_request_types.benefit_time_trade) {
        Vue.set(state, "tradeType", "shift");
      }
    }
  },
  async [SET_VALUE](state, payload) {
    if (payload.key === "fdom") return;
    if (payload.key === "claimDay") return;
    Vue.set(state, payload.key, payload.value);
  },
  [SET_CHANGE_TIME](state, value) {
    Vue.set(state, "changeTimeSelected", value);
  },
  [SET_CHANGE_TYPE](state, value) {
    Vue.set(state, "changeTypeSelected", value);
  },
  [SET_CLAIM_DAY](state, value) {
    Vue.set(state, "claimDay", value);
  },
  [SET_CLAIM_SHIFT_TIME](state, value) {
    Vue.set(state, "claimShiftTime", value);
  },
  [SET_CLAIM_SHIFT_TYPE](state, value) {
    Vue.set(state, "claimShiftType", value);
  },
  [SET_CUSTOM_TIME_END](state, value) {
    Vue.set(state, "customTimeEnd", value);
  },
  [SET_CUSTOM_TIME_START](state, value) {
    Vue.set(state, "customTimeStart", value);
  },
  [SET_CUSTOM_TIME_STARTS_ON_PREVIOUS_DAY](state, value) {
    Vue.set(state, "customTimeStartsOnPreviousDay", value);
  },
  [SET_EDIT_SHIFT_ASSET_EXPANDED](state, payload) {
    let changeIndex = state.shiftEditState.shiftAssets.indexOf(
      state.shiftEditState.shiftAssets.filter(asset => {
        return asset.id === payload;
      })[0]
    );
    Vue.set(
      state.shiftEditState.shiftAssets[changeIndex],
      "expanded",
      !state.shiftEditState.shiftAssets[changeIndex].expanded
    );

    if (!state.shiftEditState.shiftAssets[changeIndex].expanded) {
      Vue.set(state.shiftEditState.shiftAssets[changeIndex], "remove", false);
    }
  },
  [SET_EDIT_SHIFT_ASSET_REMOVE](state, payload) {
    let changeIndex = state.shiftEditState.shiftAssets.indexOf(
      state.shiftEditState.shiftAssets.filter(asset => {
        return asset.id === payload;
      })[0]
    );
    Vue.set(
      state.shiftEditState.shiftAssets[changeIndex],
      "remove",
      !state.shiftEditState.shiftAssets[changeIndex].remove
    );
  },
  [SET_EDIT_SHIFT_CHANGE_EXPANDED](state, payload) {
    let changeIndex = state.shiftEditState.shiftChanges.indexOf(
      state.shiftEditState.shiftChanges.filter(change => {
        return change.id === payload;
      })[0]
    );
    state.shiftEditState.shiftChanges[changeIndex].expanded = !state
      .shiftEditState.shiftChanges[changeIndex].expanded;

    if (!state.shiftEditState.shiftChanges[changeIndex].expanded) {
      state.shiftEditState.shiftChanges[changeIndex].remove = false;
    }
    Vue.set(state, "shiftEditState", state.shiftEditState);
  },
  [SET_EDIT_SHIFT_CHANGE_REMOVE](state, payload) {
    let changeIndex = state.shiftEditState.shiftChanges.indexOf(
      state.shiftEditState.shiftChanges.filter(change => {
        return change.id === payload;
      })[0]
    );
    state.shiftEditState.shiftChanges[changeIndex].remove = !state
      .shiftEditState.shiftChanges[changeIndex].remove;
    Vue.set(state, "shiftEditState", state.shiftEditState);
  },
  [SET_EDIT_SHIFT_CUSTOM_TIME_END](state, value) {
    Vue.set(state.shiftEditState, "customTimeEnd", value);
  },
  [SET_EDIT_SHIFT_CUSTOM_TIME_START](state, value) {
    Vue.set(state.shiftEditState, "customTimeStart", value);
  },
  [SET_EDIT_SHIFT_GROUP](state, value) {
    Vue.set(state.shiftEditState, "shiftGroup", value);
  },
  [SET_EDIT_SHIFT_LOCATION](state, value) {
    Vue.set(state.shiftEditState, "shiftLocation", value);
  },
  [SET_EDIT_SHIFT_NOTE](state, value) {
    Vue.set(state.shiftEditState, "note", value);
  },
  [SET_EDIT_SHIFT_ROLE](state, value) {
    Vue.set(state.shiftEditState, "shiftRole", value);
  },
  [SET_EDIT_SHIFT_STATE](state, payload) {
    Vue.set(state, "shiftEditState", payload);
  },
  [SET_EDIT_SHIFT_TIME](state, payload) {
    Vue.set(state.shiftEditState, "shiftTime", payload);
  },
  [SET_FDOM](state, payload) {
    Vue.set(state, "fdom", payload);
  },
  [SET_HOLDOVER_LENGTH](state, value) {
    Vue.set(state, "holdoverLength", value);
  },
  [SET_INVALID_CUSTOM_TIME_END](state, value) {
    Vue.set(state, "invalidTimeEnd", value);
  },
  [SET_INVALID_CUSTOM_TIME_START](state, value) {
    Vue.set(state, "invalidTimeStart", value);
  },
  [SET_INVALID_TARGET_CUSTOM_TIME_END](state, value) {
    Vue.set(state, "targetInvalidTimeEnd", value);
  },
  [SET_INVALID_TARGET_CUSTOM_TIME_START](state, value) {
    Vue.set(state, "targetInvalidTimeStart", value);
  },
  [SET_IS_EXTRA_SHIFT](state, value) {
    Vue.set(state, "isExtraShift", value);
  },
  [SET_MOD_CHANGE](state, value) {
    Vue.set(state, "modChange", value);
  },
  [SET_MOD_NEW_TYPE](state, value) {
    Vue.set(state, "modNewType", value);
  },
  [SET_NOTE](state, value) {
    Vue.set(state, "note", value);
  },
  [SET_PARTIAL_CLAIM_OPTION](state, value) {
    Vue.set(state, "selectedPartialClaimOption", value);
  },
  [SET_PAY_PREFERENCE](state, value) {
    Vue.set(state, "payPreference", value);
  },
  [SET_PERSON_COVERING](state, value) {
    Vue.set(state, "personCovering", value);
  },
  [SET_RELEASE_TYPE](state, value) {
    Vue.set(state, "releaseType", value);
  },
  [SET_SELECTED_DATE](state, value) {
    Vue.set(state, "selectedDate", value);
  },
  [SET_SHIFT_TIME](state, value) {
    Vue.set(state, "selectedShiftTime", value);
  },
  [SET_SHIFT_CHANGE_END](state, value) {
    Vue.set(state, "shiftChangeEnd", value);
  },
  [SET_SHIFT_CHANGE_START](state, value) {
    Vue.set(state, "shiftChangeStart", value);
  },
  [SET_SHIFT_OTHER_TIME_START](state, value) {
    Vue.set(state, "shiftOtherTimeStart", value);
  },
  [SET_SHIFT_OTHER_TIME_END](state, value) {
    Vue.set(state, "shiftOtherTimeEnd", value);
  },
  [SET_SINGLE_TIME_OFF_TYPE](state, value) {
    Vue.set(state, "singleTimeOffType", value);
    if (!value) {
      Vue.set(state, "timeOffTypesSelected", []);
    } else {
      Vue.set(state, "timeOffTypeTimeRemaining", 0.0);
    }
  },
  [SET_SPLIT_PAY_PREFERENCE_PAID](state, value) {
    Vue.set(state, "splitPayPreferencePaid", value);
  },
  [SET_SPLIT_PAY_PREFERENCE_TIME](state, value) {
    Vue.set(state, "splitPayPreferenceTime", value);
  },
  [SET_TARGET_CUSTOM_TIME_END](state, value) {
    Vue.set(state, "targetCustomTimeEnd", value);
  },
  [SET_TARGET_CUSTOM_TIME_START](state, value) {
    Vue.set(state, "targetCustomTimeStart", value);
  },
  [SET_TARGET_SHIFT](state, value) {
    Vue.set(state, "targetShift", value);
  },
  [SET_TIME_OFF_TYPE](state, value) {
    Vue.set(state, "timeOffTypeSelected", value);
  },
  [SET_TIME_OFF_TYPE_REMAINING_TIME](state, value) {
    Vue.set(state, "timeOffTypeTimeRemaining", value);
  },
  [SET_TRADE_TARGET](state, value) {
    Vue.set(state, "tradeTarget", value);
  },
  [SET_TRADE_TYPE](state, value) {
    Vue.set(state, "tradeType", value);
  },
  [SHOW_EDIT_MODAL]: function(state) {
    Vue.set(state.shiftEditState, "showModal", true);
  },
  [HIDE_EDIT_MODAL]: function(state) {
    Vue.set(state.shiftEditState, "showModal", false);
  },
  [UPDATE_CUSTOM_FORM](state, value) {
    Vue.set(state, "customForm", value);
  },
  [UPDATE_CUSTOM_FORM_VALUE](state, value) {
    let new_value = value.target.value;
    if (value.target.type === "checkbox") {
      new_value = value.target.checked;
    }
    Vue.set(state.customFormData, value.target.id, new_value);
  }
};
