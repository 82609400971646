import {
  ChronoUnit,
  DateTimeFormatter,
  Duration,
  LocalDate,
  LocalDateTime,
  TemporalAdjusters,
  ZonedDateTime
} from "@js-joda/core";
import { Locale } from "@js-joda/locale_en-us";
import { store } from "./store";
import {
  CALENDAR_14_DAY_START,
  CALENDAR_28_DAY_START,
  CT_14_DAY,
  CT_28_DAY,
  CT_MONTHLY
} from "./constants/client";
export const date_with_slashes_pattern = "M/d/YYYY";
export const day_month_with_slashes_pattern = "M/d";
export const regular_time_pattern = "h:mm a";
export const military_time_pattern = "H:mm";
export const military_time_pattern_double = "HH:mm";
export const month_with_day_pattern = "MMMM d";
export const hours_pattern = "HH";
export const minutes_pattern = "mm";
export const pretty_day_pattern = "EE";
export const month_pattern = "MM";
export const day_pattern = "dd";
export const year_pattern = "YYYY";
export const date_pattern = "YYYY-MM-dd";
export const months = [
  "", // date.monthValue() returns integers 1-12, so the 0 index should be blank
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];
export function day_of_week(day, month, year) {
  if (month < 3) {
    month = month + 12;
    year = year - 1;
  }

  const zero_based_century = Math.floor(year / 100);
  const year_of_century = year - 100 * zero_based_century;

  const S =
    Math.floor(2.6 * month - 5.39) +
    Math.floor(year_of_century / 4) +
    Math.floor(zero_based_century / 4) +
    day +
    year_of_century -
    2 * zero_based_century;

  const day_number = S - 7 * Math.floor(S / 7);
  switch (day_number) {
    case 0:
      return "Sun";
    case 1:
      return "Mon";
    case 2:
      return "Tue";
    case 3:
      return "Wed";
    case 4:
      return "Thu";
    case 5:
      return "Fri";
    default:
      return "Sat";
  }
}
//export const request_pattern = "YYYY-MM-DDTHH:mm:ss";
export const date_with_slashes_formatter = DateTimeFormatter.ofPattern(
  date_with_slashes_pattern
).withLocale(Locale.ENGLISH);
export const day_month_with_slashes_formatter = DateTimeFormatter.ofPattern(
  day_month_with_slashes_pattern
).withLocale(Locale.ENGLISH);
export const date_with_military_time_formatter = DateTimeFormatter.ofPattern(
  date_with_slashes_pattern + " @ " + military_time_pattern
).withLocale(Locale.ENGLISH);
export const date_with_regular_time_formatter = DateTimeFormatter.ofPattern(
  date_with_slashes_pattern + " @ " + regular_time_pattern
).withLocale(Locale.ENGLISH);
export const military_time_formatter = DateTimeFormatter.ofPattern(
  military_time_pattern
);
export const military_time_double_formatter = DateTimeFormatter.ofPattern(
  military_time_pattern_double
);
export const regular_time_formatter = DateTimeFormatter.ofPattern(
  regular_time_pattern
);
export const hours_formatter = DateTimeFormatter.ofPattern(hours_pattern);
export const minutes_formatter = DateTimeFormatter.ofPattern(minutes_pattern);
export const month_formatter = DateTimeFormatter.ofPattern(
  month_pattern
).withLocale(Locale.ENGLISH);
export const pretty_day_formatter = DateTimeFormatter.ofPattern(
  pretty_day_pattern
).withLocale(Locale.ENGLISH);
export const year_formatter = DateTimeFormatter.ofPattern(
  year_pattern
).withLocale(Locale.ENGLISH);
export const day_formatter = DateTimeFormatter.ofPattern(
  day_pattern
).withLocale(Locale.ENGLISH);
export const month_with_day_formatter = DateTimeFormatter.ofPattern(
  month_with_day_pattern
).withLocale(Locale.ENGLISH);
export const date_formatter = DateTimeFormatter.ofPattern(
  date_pattern
).withLocale(Locale.ENGLISH);
//export const request_timestamp_formatter = DateTimeFormatter.ofPattern(
//  request_pattern
//);

export function _formatHHMM(datetime) {
  return datetime.format(military_time_double_formatter);
}

export function _formatTime(datetime, am_pm) {
  if (am_pm) {
    return datetime.format(regular_time_formatter);
  } else {
    if (typeof datetime === "string") datetime = LocalDateTime.parse(datetime);
    return datetime.format(military_time_formatter);
  }
}

export function _formatDate(datetime, am_pm) {
  if (am_pm) {
    return datetime.format(date_with_regular_time_formatter);
  } else {
    return datetime.format(date_with_military_time_formatter);
  }
}

export function _formatDateOnly(datetime, am_pm) {
  if (am_pm) {
    return datetime.format(date_with_slashes_formatter);
  } else {
    return datetime.format(date_with_slashes_formatter);
  }
}

export function _formatRequest(datetime) {
  return datetime.toISOString(); //.format(request_timestamp_formatter);
}

export function _duration(start, end) {
  if (!start || !end) return null;
  return Duration.between(start, end).toMinutes();
}

export function _now(zone_id) {
  if (zone_id) {
    return ZonedDateTime.now(zone_id);
  }
  return LocalDateTime.now();
}
export function _month_from_day(day) {
  let local_date_time = null;
  if (day.monthValue() < 10) {
    local_date_time = LocalDateTime.parse(
      day.year() + "-0" + day.monthValue() + "-01T01:00:00"
    );
  } else {
    local_date_time = LocalDateTime.parse(
      day.year() + "-" + day.monthValue() + "-01T01:00:00"
    );
  }
  return LocalDate.from(local_date_time);
}
export function _this_month(zone_id) {
  let right_now = _now(zone_id);
  if (right_now.monthValue() < 10) {
    return LocalDate.from(
      LocalDateTime.parse(
        right_now.year() + "-0" + right_now.monthValue() + "-01T01:00:00"
      )
    );
  } else {
    return LocalDate.from(
      LocalDateTime.parse(
        right_now.year() + "-" + right_now.monthValue() + "-01T01:00:00"
      )
    );
  }
}
export function _last_month(zone_id) {
  return _this_month(zone_id).minusMonths(1);
}
export function _next_month(zone_id) {
  return _this_month(zone_id).plusMonths(1);
}

export function _is_calendar_monthly() {
  return store.state.client.settings.calendar_type === CT_MONTHLY;
}

export function _is_calendar_28_day() {
  return store.state.client.settings.calendar_type === CT_28_DAY;
}

export function _is_calendar_14_day() {
  return store.state.client.settings.calendar_type === CT_14_DAY;
}

export function _get_calendar_28_day_start() {
  let day_offset = store.state.client.settings.calendar_day_offset;
  if (day_offset) {
    return CALENDAR_28_DAY_START.plusDays(day_offset);
  }
  return CALENDAR_28_DAY_START;
}

export function _get_calendar_14_day_start() {
  let day_offset = store.state.client.settings.calendar_day_offset;
  if (day_offset) {
    return CALENDAR_14_DAY_START.plusDays(day_offset);
  }
  return CALENDAR_14_DAY_START;
}

export function _get_days_range(start_date, end_date) {
  /* list of days from start_date to end_date

  @param date start_date:
  @param date end_date:
  @return list(date):
  */
  let days = [];
  let current = start_date;
  while (current.isBefore(end_date) || current.isEqual(end_date)) {
    days.push(current);
    current = current.plusDays(1);
  }
  return days;
}

export function _get_all_first_day_of_months(first_day_of_month = null) {
  let first_fdom = first_day_of_month ? first_day_of_month : _get_first_fdom();
  let last_fdom = _get_last_fdom();
  let fdom_list = [];
  let fdom = first_fdom;
  while (fdom.isBefore(last_fdom)) {
    fdom_list.push(fdom);
    fdom = _get_next_calendar_month(fdom);
  }
  let years = [];
  let map = new Map();
  for (let fdom of fdom_list) {
    if (!map.has(fdom.year())) {
      map.set(fdom.year(), true);
      years.push(fdom.year());
    }
  }
  let yearly_set = [];
  for (let year of years) {
    let yearly_fdom_list = fdom_list.filter(list_fdom => {
      return list_fdom.year() === year;
    });
    yearly_set.push((year, yearly_fdom_list));
  }
  return yearly_set;
}

export function _get_fdoms_range(start_date, end_date) {
  /*
  Returns a list of unique first_day_of_month dates
  for all dates in the range.
  */
  let days = _get_days_range(start_date, end_date);
  let fdoms = [];
  let map = new Map();
  for (let day of days) {
    // noinspection JSUnusedLocalSymbols
    // eslint-disable-next-line no-unused-vars
    let [fdom, ldom] = _get_client_month_range(day);
    if (!map.has(fdom.toString())) {
      map.set(fdom.toString(), true);
      fdoms.push(fdom);
    }
  }
  return fdoms;
}

export function _get_fdoms_for_month(d) {
  // Return list of fdoms for any given date for the month of the date.
  return _get_fdoms_range(
    d.withDayOfMonth(1),
    d.with(TemporalAdjusters.lastDayOfMonth())
  );
}

export function _get_first_fdom() {
  // noinspection JSUnusedLocalSymbols
  // eslint-disable-next-line no-unused-vars
  let [fdom, ldom] = _get_client_month_range(new LocalDate(2014, 11, 1));
  return fdom;
}

export function _get_last_fdom() {
  /*Returns the last fdom that we care about. i.e. the last
   month where we automatically create recurring objects.
   */
  let now_date = _now().toLocalDate();
  let last_fdom = null;
  let fdom = null;
  // eslint-disable-next-line no-unused-vars
  let ldom = null;
  let days = 0;
  if (_is_calendar_28_day()) {
    [fdom, ldom] = _get_client_month_range(now_date);
    days = 25 * 28;
    last_fdom = fdom.plusDays(days);
  } else if (_is_calendar_14_day()) {
    [fdom, ldom] = _get_client_month_range(now_date);
    days = 50 * 14;
    last_fdom = fdom.plusDays(days);
  } else {
    last_fdom = now_date.plusMonths(25);
    last_fdom = last_fdom.withDayOfMonth(1);
  }
  return last_fdom;
}

export function _get_calendar_offset(dt) {
  let first_date = new LocalDate(2015, 1, 1);
  return first_date.until(dt, ChronoUnit.DAYS);
}

export function _get_client_month_range_monthly(dt) {
  if (dt.toLocalDate) {
    dt = dt.toLocalDate();
  }
  let fdom = dt.withDayOfMonth(1);
  // eslint-disable-next-line no-unused-vars
  let last_day = fdom.lengthOfMonth();
  let ldom = dt.withDayOfMonth(last_day);
  return [fdom, ldom];
}
export function _get_client_month_range_n_day(dt, start, n_days) {
  // Do date check. Might not be needed here. Probably needed here...
  let diff_days = start.until(dt, ChronoUnit.DAYS);
  let nth_month = Math.floor(diff_days / n_days);
  let fdom = start.plusDays(nth_month * n_days);
  let ldom = fdom.plusDays(n_days - 1);
  return [fdom, ldom];
}
export function _get_client_month_range(dt, calendar_type = null) {
  /*
given a date, get first_day_of_month and last_day_of_month from that date
considering client's calendar type
*/
  if (calendar_type === null) {
    calendar_type = store.state.client.settings.calendar_type;
  }
  let start = null;
  if (calendar_type === CT_28_DAY) {
    start = _get_calendar_28_day_start();
    return _get_client_month_range_n_day(dt, start, 28);
  } else if (calendar_type === CT_14_DAY) {
    start = _get_calendar_14_day_start();
    return _get_client_month_range_n_day(dt, start, 14);
  } else {
    return _get_client_month_range_monthly(dt);
  }
}
export function _get_client_first_day_of_month(dt, calendar_type = null) {
  return _get_client_month_range(dt, calendar_type)[0];
}

export function _get_next_calendar_month(fdom, months = 1) {
  let next_fdom = null;
  if (_is_calendar_monthly()) {
    next_fdom = fdom.plusMonths(months);
  } else {
    let days = 0;
    if (_is_calendar_28_day()) {
      days = months * 28;
    } else if (_is_calendar_14_day()) {
      days = months * 14;
    }
    next_fdom = fdom.plusDays(days);
  }
  return next_fdom;
}
export function _get_prev_calendar_month(fdom, months = 1) {
  let prev_fdom = null;
  if (_is_calendar_monthly()) {
    prev_fdom = fdom.minusMonths(months);
  } else {
    let days = 0;
    if (_is_calendar_28_day()) {
      days = months * 28;
    } else if (_is_calendar_14_day()) {
      days = months * 14;
    }
    prev_fdom = fdom.minusDays(days);
  }
  return prev_fdom;
}
