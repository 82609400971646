import { cloneDeep } from "bootstrap-vue/esm/utils/clone-deep";

export const initial_state = {
  shifts: {},
  day_shifts: {},
  changes: {},
  min_staffing_risk: {},
  processing_shortage_request: false
};

export default cloneDeep(initial_state);
