export const ADD_SPLIT_TIME_OPTION = "ADD_SPLIT_TIME_OPTION";
export const ADD_TIME_OFF_TYPE = "ADD_TIME_OFF_TYPE";
export const HIDE_EDIT_MODAL = "HIDE_EDIT_MODAL";
export const REMOVE_TIME_OFF_TYPE = "REMOVE_TIME_OFF_TYPE";
export const RESET_STATE = "RESET_STATE";
export const RESET_CHANGE_FORM = "RESET_CHANGE_FORM";
export const RESET_CLAIM_FORM = "RESET_CLAIM_FORM";
export const RESET_EDIT_FORM = "RESET_EDIT_FORM";
export const RESET_EXTRA_FORM = "RESET_EXTRA_FORM";
export const RESET_HOLDOVER_FORM = "RESET_HOLDOVER_FORM";
export const RESET_PAY_PREFERENCE = "RESET_PAY_PREFERENCE";
export const RESET_RELEASE_FORM = "RESET_RELEASE_FORM";
export const RESET_TRADE_FORM = "RESET_TRADE_FORM";
export const SET_ACTIVE_PERIOD = "SET_ACTIVE_PERIOD";
export const SET_CHANGE_TIME = "SET_CHANGE_TIME";
export const SET_CHANGE_TYPE = "SET_CHANGE_TYPE";
export const SET_FDOM = "SET_FDOM";
export const SET_EDIT_SHIFT_ASSET_EXPANDED = "SET_EDIT_SHIFT_ASSET_EXPANDED";
export const SET_EDIT_SHIFT_ASSET_REMOVE = "SET_EDIT_SHIFT_ASSET_REMOVE";
export const SET_EDIT_SHIFT_CHANGE_EXPANDED = "SET_EDIT_SHIFT_CHANGE_EXPANDED";
export const SET_EDIT_SHIFT_CHANGE_REMOVE = "SET_EDIT_SHIFT_CHANGE_REMOVE";
export const SET_EDIT_SHIFT_CUSTOM_TIME_END = "SET_EDIT_SHIFT_CUSTOM_TIME_END";
export const SET_EDIT_SHIFT_CUSTOM_TIME_START =
  "SET_EDIT_SHIFT_CUSTOM_TIME_START";
export const SET_EDIT_SHIFT_GROUP = "SET_EDIT_SHIFT_GROUP";
export const SET_EDIT_SHIFT_LOCATION = "SET_EDIT_SHIFT_LOCATION";
export const SET_EDIT_SHIFT_NOTE = "SET_EDIT_SHIFT_NOTE";
export const SET_EDIT_SHIFT_ROLE = "SET_EDIT_SHIFT_ROLE";
export const SET_EDIT_SHIFT_STATE = "SET_EDIT_SHIFT_STATE";
export const SET_EDIT_SHIFT_TIME = "SET_EDIT_SHIFT_TIME";
export const SET_CLAIM_SHIFT_TYPE = "SET_CLAIM_SHIFT_TYPE";
export const SET_CLAIM_DAY = "SET_CLAIM_DAY";
export const SET_CLAIM_SHIFT_TIME = "SET_CLAIM_SHIFT_TIME";
export const SET_CUSTOM_TIME_START = "SET_CUSTOM_TIME_START";
export const SET_CUSTOM_TIME_STARTS_ON_PREVIOUS_DAY =
  "SET_CUSTOM_TIME_STARTS_ON_PREVIOUS_DAY";
export const SET_CUSTOM_TIME_END = "SET_CUSTOM_TIME_END";
export const SET_HOLDOVER_LENGTH = "SET_HOLDOVER_LENGTH";
export const SET_INVALID_CUSTOM_TIME_END = "SET_INVALID_CUSTOM_TIME_END";
export const SET_INVALID_CUSTOM_TIME_START = "SET_INVALID_CUSTOM_TIME_START";
export const SET_INVALID_TARGET_CUSTOM_TIME_END =
  "SET_INVALID_TARGET_CUSTOM_TIME_END";
export const SET_INVALID_TARGET_CUSTOM_TIME_START =
  "SET_INVALID_TARGET_CUSTOM_TIME_START";
export const SET_MOD_CHANGE = "SET_MOD_CHANGE";
export const SET_MOD_NEW_TYPE = "SET_MOD_NEW_TYPE";
export const SET_NOTE = "SET_NOTE";
export const SET_PAY_PREFERENCE = "SET_PAY_PREFERENCE";
export const SET_PERSON_COVERING = "SET_PERSON_COVERING";
export const SET_RELEASE_TYPE = "SET_RELEASE_TYPE";
export const SET_SELECTED_DATE = "SET_SELECTED_DATE";
export const SET_SHIFT_TIME = "SET_SHIFT_TIME";
export const SET_SHIFT_CHANGE_START = "SET_SHIFT_CHANGE_START";
export const SET_PARTIAL_CLAIM_OPTION = "SET_PARTIAL_CLAIM_OPTION";
export const SET_SHIFT_CHANGE_END = "SET_SHIFT_CHANGE_END";
export const SET_SHIFT_OTHER_TIME_START = "SET_SHIFT_OTHER_TIME_START";
export const SET_SHIFT_OTHER_TIME_END = "SET_SHIFT_OTHER_TIME_END";
export const SET_SINGLE_TIME_OFF_TYPE = "SET_SINGLE_TIME_OFF_TYPE";
export const SET_SPLIT_PAY_PREFERENCE_TIME = "SET_SPLIT_PAY_PREFERENCE_TIME";
export const SET_SPLIT_PAY_PREFERENCE_PAID = "SET_SPLIT_PAY_PREFERENCE_PAID";
export const SET_TARGET_CUSTOM_TIME_START = "SET_TARGET_CUSTOM_TIME_START";
export const SET_TARGET_CUSTOM_TIME_END = "SET_TARGET_CUSTOM_TIME_END";
export const SET_TARGET_SHIFT = "SET_TARGET_SHIFT";
export const SET_TIME_OFF_TYPE = "SET_TIME_OFF_TYPE";
export const SET_TIME_OFF_TYPE_REMAINING_TIME =
  "SET_TIME_OFF_TYPE_REMAINING_TIME";
export const SET_TRADE_TARGET = "SET_TRADE_TARGET";
export const SET_TRADE_TYPE = "SET_TRADE_TYPE";
export const SET_IS_EXTRA_SHIFT = "SET_IS_EXTRA_SHIFT";
export const SHOW_EDIT_MODAL = "SHOW_EDIT_MODAL";
export const UPDATE_CUSTOM_FORM = "UPDATE_CUSTOM_FORM";
export const UPDATE_CUSTOM_FORM_VALUE = "UPDATE_CUSTOM_FORM_VALUE";
export const UPDATE_PAY_PREFERENCE_SPLIT = "UPDATE_PAY_PREFERENCE_SPLIT";
