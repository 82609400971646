// SCRIPTS
//import router from "../router";
import Vue from "vue";
import {
  CLOSE_SIDEBAR,
  DONE_LOADING,
  NEEDS_AUTH,
  SET_LAST_PING,
  RESET_STATE,
  SET_SCHEDULER_BUSY,
  SET_SIDEBAR_VISIBLE,
  SET_DATA_SYNCING,
  SOCKET_AUTHED,
  TOGGLE_SIDEBAR,
  SET_SOCKET_NEEDS_AUTH,
  SOFT_RESET_STATE,
  DEV_MODE_INCREMENT,
  TOGGLE_DEV_MODE,
  SET_TASKS_UPDATE_REQUESTED,
  TOGGLE_LOCAL_MODE,
  SET_SHOW_DAYS_OFF,
  SET_SHOW_CALENDAR_DAYS_OFF
} from "../mutation-types";
import { initial_state } from "./state";
import cloneDeep from "lodash.clonedeep";
import {
  DISMISS_ERROR_COUNTDOWN,
  SHOW_ERROR_ALERT
} from "./request/mutation_types";
import * as Sentry from "@sentry/browser";

export default {
  sendMessage: function(context, message) {
    try {
      Vue.prototype.$socket.send(message);
    } catch (error) {
      Sentry.captureException(error);
      console.log("error sending message", error);
    }
  },
  async [SET_SHOW_DAYS_OFF]({ commit }, payload) {
    commit(SET_SHOW_DAYS_OFF, payload);
  },
  async [SET_SHOW_CALENDAR_DAYS_OFF]({ commit }, payload) {
    commit(SET_SHOW_CALENDAR_DAYS_OFF, payload);
  },
  async [DISMISS_ERROR_COUNTDOWN]({ commit }, payload) {
    commit(DISMISS_ERROR_COUNTDOWN, payload);
  },
  async [DONE_LOADING]({ commit }) {
    await commit(DONE_LOADING);
  },
  async [NEEDS_AUTH]({ commit }) {
    await commit(SET_SOCKET_NEEDS_AUTH, true);
  },
  async [DEV_MODE_INCREMENT]({ commit, state }) {
    if (state.dev_mode_counter < 19) {
      await commit(DEV_MODE_INCREMENT);
    } else {
      await commit(TOGGLE_DEV_MODE);
    }
  },
  async [TOGGLE_DEV_MODE]({ commit }) {
    await commit(TOGGLE_DEV_MODE);
  },
  async [SET_LAST_PING]({ commit, dispatch, rootState }) {
    await commit(SET_LAST_PING);
    if (window.was_offline_at_some_point) return;
    let url = rootState.api_url + `/ping/`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + rootState.auth.access_token
      }
    })
      .then(response => {
        if (!rootState.last_ping) return;
        Sentry.configureScope(scope => {
          scope.setExtra("api_response", response);
        });
      })
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  },
  async [RESET_STATE]({ commit, dispatch }) {
    for (let key in initial_state) {
      await commit(`SET_${key.toUpperCase()}`, cloneDeep(initial_state[key]));
    }
    let show_off_shifts = window.localStorage.getItem("showDaysOff") === "true";
    let show_off_shifts_on_calendar =
      window.localStorage.getItem("showDaysOffOnCalendar") === "true";
    await dispatch("SET_SHOW_DAYS_OFF", show_off_shifts, { root: true });
    await dispatch(SET_SHOW_CALENDAR_DAYS_OFF, show_off_shifts_on_calendar, {
      root: true
    });
    await dispatch("auth/RESET_STATE", {}, { root: true });
    await dispatch("client/RESET_STATE", {}, { root: true });
    await dispatch("calendar/RESET_STATE", {}, { root: true });
    await dispatch("form/RESET_STATE", {}, { root: true });
    await dispatch("request/RESET_STATE", {}, { root: true });
    await dispatch("shifts/RESET_STATE", {}, { root: true });
    await dispatch("task/RESET_STATE", {}, { root: true });
  },
  async [SOFT_RESET_STATE]({ commit }) {
    commit(SET_DATA_SYNCING, true);
  },
  async [SET_SCHEDULER_BUSY]({ commit }, payload) {
    await commit(SET_SCHEDULER_BUSY, payload);
  },
  async [SET_DATA_SYNCING]({ commit }, payload) {
    await commit(SET_DATA_SYNCING, payload);
  },
  async [SET_TASKS_UPDATE_REQUESTED]({ commit }, payload) {
    await commit(SET_TASKS_UPDATE_REQUESTED, payload);
  },
  async [SHOW_ERROR_ALERT]({ commit }) {
    commit(SHOW_ERROR_ALERT);
  },
  async [SOCKET_AUTHED]({ commit }) {
    await commit(SOCKET_AUTHED);
  },
  async [TOGGLE_LOCAL_MODE]({ commit }) {
    await commit(TOGGLE_LOCAL_MODE);
  },
  async [TOGGLE_SIDEBAR]({ commit, state }) {
    await commit(SET_SIDEBAR_VISIBLE, !state.sidebar_visible);
  },
  async [CLOSE_SIDEBAR]({ commit }) {
    await commit(SET_SIDEBAR_VISIBLE, false);
  }
};
