export const CHANGE_TYPE_TRADE = 0;
export const CHANGE_TYPE_COVER = 1;
export const CHANGE_TYPE_CLAIM = 2;
export const CHANGE_TYPE_RELEASE = 3;
export const CHANGE_TYPE_PARTIAL_TRADE = 4;
export const CHANGE_TYPE_PARTIAL_COVER = 5;
export const CHANGE_TYPE_PARTIAL_CLAIM = 6;
export const CHANGE_TYPE_PARTIAL_RELEASE = 7;
export const CHANGE_TYPE_TIME_OFF = 8;
export const CHANGE_TYPE_PARTIAL_TIME_OFF = 9;
export const CHANGE_TYPE_EXTRA_TIME = 10;
export const CHANGE_TYPE_MODIFY = 11;
export const CHANGE_TYPE_RESCIND = 12;
export const APPROVAL_PENDING = 0;
export const APPROVAL_APPROVED = 1;
export const APPROVAL_REJECTED = 2;
export const APPROVAL_CANCELLED = 3;
export const APPROVAL_CANCELLED_DUE_TO_CONFLICT = 4;
export const APPROVAL_PENDING_FIRST_LEVEL = 5;
export const APPROVAL_PENDING_OTHER_USER = 8;
export const APPROVAL_REJECTED_BY_OTHER_USER = 9;
export const ALL_PENDINGS = [
  APPROVAL_PENDING,
  APPROVAL_PENDING_OTHER_USER,
  APPROVAL_PENDING_FIRST_LEVEL
];
export const APPROVAL_RESCINDED = 6;
export const APPROVAL_REMOVED = 7;
export const SHIFT_CHANGE_TYPE_CLASS_MAP = {
  0: "trade",
  1: "cover",
  2: "claim",
  3: "release",
  4: "partial-trade",
  5: "partial-cover",
  6: "partial-claim",
  7: "partial-release",
  8: "full-tot-change",
  9: "partial-tot-change",
  10: "extra-time",
  11: "modify",
  12: "rescind"
  /*,
  trade: 0,
  cover: 1,
  claim: 2,
  release: 3,
  "partial-trade": 4,
  "partial-cover": 5,
  "partial-claim": 6,
  "partial-release": 7,
  "full-tot-change": 8,
  "partial-tot-change": 9,
  "extra-time": 10,
  modify: 11,
  rescind: 12
   */
};
export const SHIFT_CHANGE_TIME_OFF_TYPE_TIME_OFF = 1;
export const SHIFT_CHANGE_TIME_OFF_TYPE_SPECIAL_ASSIGNMENT = 2;
export const SHIFT_CHANGE_TIME_OFF_TYPE_OVERTIME = 3;
export const SHIFT_CHANGE_TIME_OFF_TYPE_COMP_TIME = 4;
export const SHIFT_CHANGE_TIME_OFF_TYPE_AREA = 5;
export const SHIFT_CHANGE_TIME_OFF_TYPE_EVEN_TRADE_WORKED = 6;
export const SHIFT_CHANGE_TIME_TYPE_MAP = {
  1: "Off",
  2: "Assignment",
  3: "Overtime",
  4: "Off",
  5: "Area",
  6: "EvenTrade"
};
export const TIME_OFF = SHIFT_CHANGE_TIME_OFF_TYPE_TIME_OFF;
export const COMP_TIME = SHIFT_CHANGE_TIME_OFF_TYPE_COMP_TIME;
export const SHIFT_CHANGE_TIME_TYPE_CLASS_MAP = {
  1: "time-off",
  2: "assignment",
  3: "overtime",
  4: "time-off",
  5: "area",
  6: "even-trade"
};
export const TIME_ADJUSTMENT_EARLY_ARRIVAL = 1;
export const TIME_ADJUSTMENT_LATE_ARRIVAL = 2;
export const TIME_ADJUSTMENT_EARLY_DISMISSAL = 3;
export const TIME_ADJUSTMENT_LATE_DISMISSAL = 4;
export const TIME_ADJUSTMENT_OFF_MIDDLE_SHIFT = 5;

export const TIME_ADJUSTMENT_TYPES = {
  1: 1, // "early_arrival",
  2: 2, //"late_arrival",
  3: 3, //"early_dismissal",
  4: 4, //"late_dismissal",
  5: 5, //"off_middle_shift",
  early_arrival: 1,
  late_arrival: 2,
  early_dismissal: 3,
  late_dismissal: 4,
  off_middle_shift: 5
};
export const TIME_ADJUSTMENT_TYPES_DISPLAY = {
  1: "Early Arrival",
  2: "Late Arrival",
  3: "Early Dismissal",
  4: "Late Dismissal",
  5: "Off During Middle of Shift",
  early_arrival: "Early Arrival",
  late_arrival: "Late Arrival",
  early_dismissal: "Early Dismissal",
  late_dismissal: "Late Dismissal",
  off_middle_shift: "Off During Middle of Shift"
};

export const TIME_ADJUSTMENT_EXTRA_TIMES = [
  TIME_ADJUSTMENT_EARLY_ARRIVAL,
  TIME_ADJUSTMENT_LATE_DISMISSAL
];

export const OPT_OVERTIME = 0;
export const OPT_OVERTIME_AS_COMP_TIME = 1;
export const OPT_SPLIT = 2;
export const OVERTIME_PAYMENT_TYPES = {
  [OPT_OVERTIME]: "OT Pay",
  [OPT_OVERTIME_AS_COMP_TIME]: "Comp",
  [OPT_SPLIT]: "SPLIT(OT Pay/Deposit into COMP_TIME_NAME)"
};
