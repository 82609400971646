import { cloneDeep } from "bootstrap-vue/esm/utils/clone-deep";

const get_default_api_url = () => {
  if (window.location.hostname.endsWith(".pacescheduler.com")) {
    return "https://api.pacescheduler.com/api/v1";
  } else if (window.location.hostname.endsWith(".pace-demo.com")) {
    return "https://api.pace-demo.com/api/v1";
  } else if (window.location.hostname.endsWith(".pace-staging.com")) {
    return "https://api.pace-staging.com/api/v1";
  }
  return "http://api.localhost.com:8000/api/v1";
};

const get_default_websocket_url = () => {
  if (window.location.hostname.endsWith(".pacescheduler.com")) {
    return "wss://mobileapp.wss.pacescheduler.com/";
  } else if (window.location.hostname.endsWith(".pace-demo.com")) {
    return "wss://mobileapp.wss.pace-demo.com/";
  } else if (window.location.hostname.endsWith(".pace-staging.com")) {
    return "wss://mobileapp.wss.pace-staging.com/";
  }
  return "ws://localhost.com:8001/";
};

export const initial_state = {
  // used as indexes:
  socket: {
    isConnected: false,
    message: "",
    reconnectError: false
  },
  api_url: get_default_api_url(),
  websocket_url: get_default_websocket_url(),
  dev_mode: process.env.NODE_ENV !== "production",
  local_dev_mode: false,
  local_dev_server: "localhost.com",
  dev_mode_counter: 0,
  dismiss_secs: 5,
  use_ip: false,
  dismiss_count_down: 0,
  errors: [],
  scheduler_busy: false,
  show_days_off: false,
  show_calendar_days_off: false,
  tasks_update_requested: false,
  socket_needs_auth: false,
  app_active: false,
  current_location: null,
  logout_reason: null,
  claim_invalid_time: false,
  data_syncing: true,
  fetching_request_data: false,
  request_data: {},
  request_submit: false,
  task_processing: false,
  shift_time: "null",
  // claimShift: "null",
  // claimShiftType: "work",
  // claimTarget: 0,
  // claimDay: 0,
  // used as objects:
  titlebar_header: "Today",
  //  partialChangeStart: null,
  //  partialChangeEnd: null,
  //  tradeChangeStart: null,
  //  tradeChangeEnd: null,
  //  requestTime: "full",
  //  holdoverReleaseType: 0,
  //  holdoverLength: 0,
  //  tradeTarget: 0,
  //  tradeType: null,
  //  tradeLength: "null",
  //  targetShift: null,
  //  selectedSupervisors: [],
  //  invalidChangeStart: false,
  //  invalidChangeEnd: false,
  //  requestNotes: "",
  //  bankChange: 0,
  //  requestTimeType: 0,
  //  payPref: "null",
  //  splitPrefTime: 0,
  //  splitPrefPaid: 0,
  remove_shift: false,
  last_ping: null,
  sidebar_visible: false
};

export default cloneDeep(initial_state);
