import { mapGetters } from "vuex";
import {
  LocalDate,
  //LocalDateTime,
  Duration,
  ZonedDateTime,
  ChronoUnit
  //LocalDateTime
} from "@js-joda/core";
import {
  _formatTime,
  _formatDate,
  _now,
  date_with_slashes_formatter,
  month_with_day_formatter,
  _last_month,
  _next_month,
  _this_month,
  //date_formatter,
  _formatDateOnly
} from "../time";
export var activeDayMixin = {
  computed: {
    activeDay() {
      if (this.$store.state.calendar.selected_day) {
        return this.$store.state.calendar.selected_day;
      }
      return LocalDate.from(_now(this.$store.state.client.settings.timezone));
    }
  }
};
export var timeFormatMixin = {
  computed: {
    ...mapGetters("client", ["client"])
  },
  methods: {
    formatTime(datetime) {
      return _formatTime(datetime, this.client.settings.am_pm);
    },
    formatDate(datetime) {
      return _formatDate(datetime, this.client.settings.am_pm);
    },
    taskFormatDate(datetime) {
      return (
        _formatTime(
          datetime.withZoneSameInstant(this.client.settings.timezone),
          this.client.settings.am_pm
        ) +
        " on " +
        _formatDateOnly(
          datetime.withZoneSameInstant(this.client.settings.timezone),
          this.client.settings.am_pm
        )
      );
    }
  }
};

export const currentTimeMixin = (() => {
  if (process.env.NODE_ENV === "production") {
    return {
      props: ["cachedNow", "cachedNowDate", "cachedNowDateString"]
    };
  } else {
    return {
      props: {
        cachedNow: {
          required: true
        },
        cachedNowDate: {
          required: true
        },
        cachedNowDateString: {
          required: false
        }
      }
    };
  }
})();

export var timeMixin = {
  mixins: [timeFormatMixin],
  computed: {
    now() {
      return _now(this.$store.state.client.settings.timezone);
    },
    nowDate() {
      return this.now.toLocalDate(); //.format(date_formatter);
    },
    nowDateString() {
      return this.nowDate.toString();
    }
  },
  methods: {
    this_month() {
      return _this_month(this.$store.state.client.settings.timezone);
    },
    last_month() {
      return _last_month(this.$store.state.client.settings.timezone);
    },
    next_month() {
      return _next_month(this.$store.state.client.settings.timezone);
    },
    today() {
      return LocalDate.from(this.now);
    },
    parse(value) {
      return ZonedDateTime.parse(value);
    },
    duration(start, end) {
      if (!start || !end) return 0;
      return Duration.between(start, end).toMinutes();
    },
    formatDay(day) {
      return LocalDate.parse(day).format(date_with_slashes_formatter);
    },
    formatDayFromDateTime(day) {
      return ZonedDateTime.parse(day).format(date_with_slashes_formatter);
    },
    formatDayFromZDateTime(day) {
      return day.format(date_with_slashes_formatter);
    },
    minutesToHours(min) {
      return Math.round((min / 60) * 100) / 100;
    },
    timeConvert(numMin) {
      let minutes_duration = Duration.of(numMin, ChronoUnit.MINUTES);
      let duration_string = minutes_duration.toString();
      duration_string = duration_string.substr(2, duration_string.length - 2);
      let hours_position = duration_string.indexOf("H");
      let minutes_position = duration_string.indexOf("M");
      let hours_length = 0;
      let hours = 0;
      let minutes = 0;
      if (hours_position && minutes_position) {
        hours_length = minutes_position - hours_position;
      } else if (hours_position) {
        hours_length = duration_string.length - hours_position;
      }
      if (hours_length) {
        hours = parseInt(duration_string.substr(0, hours_position));
        duration_string = duration_string.substr(
          hours_position + 1,
          duration_string.length - hours_position
        );
      }
      minutes_position = duration_string.indexOf("M");
      if (minutes_position) {
        minutes = parseInt(
          duration_string.substr(
            0,
            duration_string.length - minutes_position + 1
          )
        );
      }
      let hrs = "";
      if (hours === 1) {
        hrs = "1 hr ";
      } else {
        if (hours) {
          hrs = hours + " hrs ";
        }
      }

      let mins = "";
      if (minutes === 1) {
        mins = "1 min";
      } else if (minutes) {
        mins = minutes + " mins";
      }
      return hrs + mins;
    },
    startDate(start) {
      return start //this.startTime(start)
        .format(month_with_day_formatter)
        .toUpperCase();
    },
    endDate(end) {
      return end //this.endTime(seconds)
        .format(month_with_day_formatter)
        .toUpperCase();
    },
    timeForced(start_seconds, end_seconds) {
      return (
        this.formatTime(start_seconds) + " - " + this.formatTime(end_seconds)
      );
    }
  }
};
