export const SHIFT_TYPE_REGULAR_SHIFT = 1;
export const SHIFT_TYPE_REGULAR_DAY_OFF = 2;
export const SHIFT_TYPE_TIME_OFF = 3;
export const SHIFT_TYPE_PENDING = 4;
export const SHIFT_TYPE_ON_CALL = 5;
export const SHIFT_TYPE_CANCELLED = 6;

// Extra shift split options  # PACE-2678 / PACE-4222
export const SO_FULL = 1;
export const SO_HALF = 2;
export const SO_QUARTER = 3;
export const SO_EIGHTH = 4;
export const SO_ANY = 5;
export const SO_CHOICES = [
  [SO_FULL, "Full Shift Only"],
  [SO_HALF, "Half Shift"],
  [SO_QUARTER, "Quarter Shift"],
  [SO_EIGHTH, "Eighth Shift"],
  [SO_ANY, "Use can claim any part of the shift they choose"]
];
