import { cloneDeep } from "bootstrap-vue/esm/utils/clone-deep";

export const initial_state = {
  selected_id: null,
  groups: {},
  locations: {},
  people: {},
  periods: {},
  roles: {},
  schedules: {},
  shift_times: {},
  smart_shift_time_names: {},
  time_off_types: {},
  settings: {
    time_banks: true
  },
  day_notes: {},
  period_queue: [],
  queued_period_data: {},
  period_list: [],
  time_bank_balances: {},
  initial_period_loaded: false,
  person_phone_numbers: {}
};

export default cloneDeep(initial_state);
