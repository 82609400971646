import { cloneDeep } from "bootstrap-vue/esm/utils/clone-deep";

export const initial_state = {
  user: {},
  person: {
    slides: []
  },
  clients: {},
  slides: [],
  authenticated: false,
  authenticating: false,
  submitted: false,
  errors: [],
  access_token: "",
  refresh_token: "",
  last_token_refresh: null,
  last_version_check: null,
  selected_client_id: null,
  needs_two_factor: false,
  type_two_factor: "device",
  has_two_factor: false,
  label_two_factor: "Please enter your two factor code.",
  loading: false,
  sso_providers: {}
};

export default cloneDeep(initial_state);
