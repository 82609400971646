import { cloneDeep } from "bootstrap-vue/esm/utils/clone-deep";

export const initial_state = {
  requests: {
    change: false,
    claim: false,
    holdover: false,
    additional_time: false,
    cover: false,
    release: false
  }
};

export default cloneDeep(initial_state);
