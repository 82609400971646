// LIBS
import Vue from "vue";
import VueRouter from "vue-router";

// LIB INITIALIZERS
Vue.use(VueRouter);

const routes = [
  {
    path: "/auth",
    name: "auth",
    component: () =>
      import(/* webpackChunkName: "auth" */ "@/components/screens/Auth")
  },
  {
    path: "/2fa",
    name: "2fa",
    component: () =>
      import(
        /* webpackChunkName: "authTwoFactor" */ "@/components/screens/AuthTwoFactor"
      )
  },
  {
    path: "/",
    component: () =>
      import(
        /* webpackChunkName: "dashLayout" */ "@/components/screens/DashLayout"
      ),
    props: true,
    children: [
      {
        path: "",
        alias: "dashboard",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/components/dashboard/Dashboard"
          ),
        name: "dashboard",
        props: true
      },
      {
        path: "calendar",
        component: () =>
          import(
            /* webpackChunkName: "calendar" */ "@/components/calendar/Calendar"
          ),
        name: "calendar",
        props: true
      },
      {
        path: "tasks",
        component: () =>
          import(/* webpackChunkName: "tasks" */ "@/components/tasks/Tasks"),
        name: "tasks",
        props: true
      },
      {
        path: "settings",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/components/screens/Settings"
          ),
        name: "settings",
        props: true
      },
      {
        path: "inbox",
        component: () =>
          import(/* webpackChunkName: "inbox" */ "@/components/messages/Inbox"),
        name: "inbox",
        props: true
      },
      {
        path: "new",
        component: () =>
          import(
            /* webpackChunkName: "newMessage" */ "@/components/messages/NewMessage"
          ),
        name: "new-message",
        props: true
      },
      {
        path: "message",
        component: () =>
          import(
            /* webpackChunkName: "message" */ "@/components/messages/Message"
          ),
        name: "message",
        props: true
      },
      {
        path: "settings",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/components/screens/Settings"
          ),
        name: "settings",
        props: true
      },
      {
        path: "help",
        component: () =>
          import(/* webpackChunkName: "help" */ "@/components/screens/Help"),
        name: "help",
        props: true
      }
    ]
  },
  {
    path: "/request/",
    name: "request",
    component: () =>
      import(/* webpackChunkName: "request" */ "@/components/request/Request"),
    props: true,
    children: [
      {
        path: "change",
        component: () =>
          import(
            /* webpackChunkName: "changeRequest" */ "@/components/request/ChangeRequest"
          ),
        name: "change-request",
        alias: "request",
        props: true
      },
      {
        path: "trade",
        component: () =>
          import(
            /* webpackChunkName: "tradeRequest" */ "@/components/request/TradeRequest"
          ),
        name: "trade-request",
        props: true
      },
      {
        path: "holdover",
        component: () =>
          import(
            /* webpackChunkName: "holdoverRequest" */ "@/components/request/HoldoverRequest"
          ),
        name: "holdover-request",
        props: true
      },
      {
        path: "release",
        component: () =>
          import(
            /* webpackChunkName: "releaseRequest" */ "@/components/request/ReleaseRequest"
          ),
        name: "release-request",
        props: true
      },
      {
        path: "claim",
        component: () =>
          import(
            /* webpackChunkName: "claimRequest" */ "@/components/request/ClaimRequest"
          ),
        name: "claim-request",
        props: true
      },
      {
        path: "extra",
        component: () =>
          import(
            /* webpackChunkName: "extraRequest" */ "@/components/request/ExtraRequest"
          ),
        name: "extra-request",
        props: true
      }
    ]
  },
  {
    path: "/camera",
    name: "camera",
    component: () =>
      import(
        /* webpackChunkName: "camera" */ "@/components/screens/CameraView"
      ),
    props: true
  },
  {
    path: "/guide",
    name: "guide",
    component: () =>
      import(/* webpackChunkName: "guide" */ "@/components/screens/Guide"),
    props: true
  },
  {
    path: "/create",
    name: "create-shift",
    component: () =>
      import(
        /* webpackChunkName: "createShift" */ "@/components/screens/CreateShift"
      ),
    props: true
  },
  {
    path: "*",
    component: () =>
      import(
        /* webpackChunkName: "notFound" */ "@/components/screens/NotFound"
      ),
    props: true
  }
];

const router = new VueRouter({
  routes: routes,
  mode: "history",
  linkExactActiveClass: "active"

  /*
      Not sure that we actually need this.
  scrollBehavior: function(to, from, savedPosition) {
    let position = { x: 0, y: 0 };
    // Keep scroll position when using browser buttons
    if (savedPosition) {
      position = savedPosition;
    }

    // Workaround for transitions scrolling to the top of the page
    // However, there are still some problems being fixed by the vue team
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(position);
      }, 1000);
    });

  }
     */
});

export default router;
