export const MUTATE = "MUTATE";
export const SET_SCHEDULER_BUSY = "SET_SCHEDULER_BUSY";
export const SOCKET_AUTHED = "SOCKET_AUTHED";
export const NEEDS_AUTH = "NEEDS_AUTH";
export const DONE_LOADING = "DONE_LOADING";
export const SET_DATA_SYNCING = "SET_DATA_SYNCING";
export const RESET_STATE = "RESET_STATE";
export const SOFT_RESET_STATE = "SOFT_RESET_STATE";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR";
export const SET_APP_ACTIVE = "SET_APP_ACTIVE";
export const SET_SHOW_DAYS_OFF = "SET_SHOW_DAYS_OFF";
export const SET_SHOW_CALENDAR_DAYS_OFF = "SET_SHOW_CALENDAR_DAYS_OFF";
export const SET_SOCKET_NEEDS_AUTH = "SET_SOCKET_NEEDS_AUTH";
export const SET_CURRENT_LOCATION = "SET_CURRENT_LOCATION";
export const SET_DISMISS_SECS = "SET_DISMISS_SECS";
export const SET_DISMISS_COUNT_DOWN = "SET_DISMISS_COUNT_DOWN";
export const SET_SIDEBAR_VISIBLE = "SET_SIDEBAR_VISIBLE";
export const SET_LOGOUT_REASON = "SET_LOGOUT_REASON";
export const SET_TASKS_UPDATE_REQUESTED = "SET_TASKS_UPDATE_REQUESTED";
export const SET_REQUEST_DATA = "SET_REQUEST_DATA";
export const SET_REQUEST_SUBMIT = "SET_REQUEST_SUBMIT";
export const SET_SHIFT_TIME = "SET_SHIFT_TIME";
export const SET_REMOVE_SHIFT = "SET_REMOVE_SHIFT";
export const SET_LAST_PING = "SET_LAST_PING";
export const SET_LOCAL_DEV_MODE = "SET_LOCAL_DEV_MODE";
export const SET_LOCAL_DEV_SERVER = "SET_LOCAL_DEV_SERVER";
export const SET_SOCKET = "SET_SOCKET";
export const SET_TITLEBAR_HEADER = "SET_TITLEBAR_HEADER";
export const SET_TASK_PROCESSING = "SET_TASK_PROCESSING";
export const SET_USE_IP = "SET_USE_IP";
export const SET_FETCHING_REQUEST_DATA = "SET_FETCHING_REQUEST_DATA";
// TODO: Move to forms.
export const SET_CLAIM_INVALID_TIME = "SET_CLAIM_INVALID_TIME";
export const SET_VALUE = "SET_VALUE";
export const TOGGLE_DEV_MODE = "TOGGLE_DEV_MODE";
export const DEV_MODE_INCREMENT = "DEV_MODE_INCREMENT";
export const SET_DEV_MODE = "SET_DEV_MODE";
export const SET_DEV_MODE_COUNTER = "SET_DEV_MODE_COUNTER";
export const SET_API_URL = "SET_API_URL";
export const SET_WEBSOCKET_URL = "SET_WEBSOCKET_URL";
export const TOGGLE_LOCAL_MODE = "TOGGLE_LOCAL_MODE";
