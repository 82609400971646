import {
  ADD_SELECTED_SUPERVISOR,
  CANCEL_SUBMISSION,
  DISMISS_ERROR_COUNTDOWN,
  REFRESH_SUPERVISORS,
  REMOVE_SELECTED_SUPERVISOR,
  RESET_CANCELLED,
  RESET_STATE,
  RESET_MIN_STAFFING_RISK,
  SET_ACTIVE_PERIOD,
  SET_CANCELLED,
  SET_ERRORS,
  SET_FROM_SCREEN,
  SET_MIN_STAFFING_RISK,
  SET_PROCESSING_REQUEST,
  SET_SHIFT_SELECTED,
  SET_SUPERVISORS,
  SET_RELEASE_TYPE,
  SET_REQUEST_TYPE,
  SET_VALIDATING,
  SET_VALIDATED,
  SHOW_ERROR_ALERT,
  SUBMIT_REQUEST,
  VALIDATE_REQUEST,
  VALIDATE_CHANGE_REQUEST,
  VALIDATE_NEW_CHANGE_REQUEST,
  VALIDATE_MODIFY_CHANGE_REQUEST,
  VALIDATE_RESCIND_CHANGE_REQUEST,
  VALIDATE_CLAIM_REQUEST,
  VALIDATE_EXTRA_REQUEST,
  VALIDATE_HOLDOVER_REQUEST,
  VALIDATE_RELEASE_REQUEST,
  VALIDATE_TRADE_REQUEST,
  VALIDATION_RESULT,
  SOFT_RESET_STATE,
  SUBMIT_CREATE_REQUEST,
  SET_SUBMIT_AS_PENDING
} from "./mutation_types";
import router from "../../router";
import { initial_state } from "../client/state";
import { SET_VALUE } from "../../mutation-types";
import cloneDeep from "lodash.clonedeep";
import * as Sentry from "@sentry/browser";
import { setScope } from "../../utils";

export default {
  async [ADD_SELECTED_SUPERVISOR]({ commit }, payload) {
    commit(ADD_SELECTED_SUPERVISOR, payload.target.value);
  },
  async [CANCEL_SUBMISSION]({ commit }) {
    await commit(SET_VALIDATED, false);
    await commit(SET_VALIDATING, false);
    await commit(SET_CANCELLED, true);
  },
  async [RESET_CANCELLED]({ commit }) {
    await commit(SET_CANCELLED, false);
  },
  async [DISMISS_ERROR_COUNTDOWN]({ commit }, payload) {
    commit(DISMISS_ERROR_COUNTDOWN, payload);
  },
  // eslint-disable-next-line no-unused-vars
  async [REFRESH_SUPERVISORS]({ commit, dispatch, state, rootState }, payload) {
    let url =
      rootState.api_url +
      `/permissions/get_person_supervisors/${payload}/?permission_strings=show_up_in_dropdown`;

    setScope(
      url,
      null,
      null,
      null,
      null,
      state,
      null,
      rootState.shifts.shifts[state.shiftSelected]
    );

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + rootState.auth.access_token
      }
    })
      .then(async response => {
        Sentry.configureScope(scope => {
          scope.setExtra("api_response", response);
        });
        if (response.status === 500) {
          await commit(SET_ERRORS, [
            "Sorry, there was an error when processing your request. The developers have been notified and will fix this bug ASAP"
          ]);
          await commit(SHOW_ERROR_ALERT);
          return;
        }
        if (response.status === 401) {
          dispatch(
            "auth/REFRESH_LOGIN",
            { action: "request/REFRESH_SUPERVISORS", payload: payload },
            { root: true }
          );
        } else if (response.status === 200) {
          response
            .json()
            .then(jd => {
              Sentry.configureScope(scope => {
                scope.setExtra("json_response", jd);
              });
              let supervisors = Object.keys(jd.supervisors);
              commit(SET_SUPERVISORS, supervisors);
              if (supervisors.length === 1) {
                commit(ADD_SELECTED_SUPERVISOR, supervisors[0]);
              }
            })
            .catch(error => {
              Sentry.captureException(error);
              console.log(error);
            });
        }
      })
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  },
  async [REMOVE_SELECTED_SUPERVISOR]({ commit }, payload) {
    commit(REMOVE_SELECTED_SUPERVISOR, payload);
  },
  async [RESET_STATE]({ commit }) {
    let state_keys = Object.keys(initial_state);
    for (let key of state_keys) {
      await commit(SET_VALUE, {
        key: key,
        value: cloneDeep(initial_state[key])
      });
    }
  },
  async [RESET_MIN_STAFFING_RISK]({ commit }) {
    commit(RESET_MIN_STAFFING_RISK);
  },
  async [SET_ACTIVE_PERIOD]({ commit }, payload) {
    commit(SET_ACTIVE_PERIOD, payload);
  },
  async [SET_FROM_SCREEN]({ commit }, payload) {
    commit(SET_FROM_SCREEN, payload);
  },
  async [SET_MIN_STAFFING_RISK]({ commit }, payload) {
    commit(SET_MIN_STAFFING_RISK, payload);
  },
  async [SET_RELEASE_TYPE]({ commit }, payload) {
    commit(SET_RELEASE_TYPE, payload);
  },
  async [SET_REQUEST_TYPE]({ commit }, payload) {
    commit(SET_REQUEST_TYPE, payload);
  },
  async [SET_SHIFT_SELECTED]({ commit }, payload) {
    commit(SET_SHIFT_SELECTED, payload);
  },
  async [SET_SUBMIT_AS_PENDING]({ commit }, payload) {
    commit(SET_SUBMIT_AS_PENDING, payload.target.value);
  },
  async [SHOW_ERROR_ALERT]({ commit }) {
    commit(SHOW_ERROR_ALERT);
  },
  // eslint-disable-next-line no-unused-vars
  async [SUBMIT_REQUEST]({ commit, dispatch, getters, state, rootState }) {
    const shift = state.shiftSelected
      ? rootState.shifts.shifts[state.shiftSelected]
      : null;
    let url = rootState.api_url + `/request/submit/`;
    setScope(url, null, null, rootState.form, state, null, shift);

    let formatted_request = [];
    if (
      rootState.form.timeOffTypesSelected.length ||
      rootState.form.splitOptionsSelected.length
    ) {
      formatted_request = getters.formattedRequests;
    } else {
      formatted_request.push(getters.formattedRequest);
    }
    if (process.env.NODE_ENV !== "production") {
      console.log("Submitted request ", formatted_request);
    }
    commit(SET_PROCESSING_REQUEST, true);
    setScope(
      url,
      formatted_request,
      null,
      null,
      rootState.form,
      state,
      null,
      shift
    );
    Sentry.configureScope(scope => {
      scope.setExtra("url", url);
      scope.setExtra("payload", formatted_request);
      scope.setExtra("api_response", null);
      scope.setExtra("json_response", null);
      scope.setExtra("form", rootState.form);
      scope.setExtra("request", state);
      scope.setExtra("shift_change", null);
      scope.setExtra("shift", shift);
    });
    let success = false;
    for (const req of formatted_request) {
      /*
       * TODO: This should ideally block to get the success status.
       *   I'm not sure how to do that using then()/catch() syntax.
       *   But it should be investigated.
       */

      const payload = JSON.stringify(req);
      const resp = fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + rootState.auth.access_token
        },
        body: payload
      });
      let response = await Promise.resolve(resp);
      Sentry.configureScope(scope => {
        scope.setExtra("api_response", response);
      });
      if (response.status === 500) {
        await commit(SET_ERRORS, [
          "Sorry, there was an error when processing your request. The developers have been notified and will fix this bug ASAP"
        ]);
        await commit(SHOW_ERROR_ALERT);
        break;
      }
      let jd = await Promise.resolve(await response.json());
      Sentry.configureScope(scope => {
        scope.setExtra("json_response", jd);
      });
      switch (response.status) {
        case 200:
          success = true;
          break;
        case 401:
          dispatch(
            "auth/REFRESH_LOGIN",
            { action: "request/SUBMIT_REQUEST", payload: {} },
            { root: true }
          );
          return; // We would want to break the loop here.
        case 400:
          await commit(SET_ERRORS, jd.errors.__all__);
          await commit(SHOW_ERROR_ALERT);
          success = false;
          break;
        default:
          await commit(SET_ERRORS, [
            "Sorry, there was an error when processing your request. The developers have been notified and will fix this bug ASAP"
          ]);

          await commit(SHOW_ERROR_ALERT);
          success = false;
          break;
      }
    }
    if (success) {
      dispatch(`form/${RESET_STATE}`, {}, { root: true });

      // PACE-5861 - Apparently this is a bug now...
      //let is_claim_request = state.requestType === "extra";
      commit(SOFT_RESET_STATE);
      await router.push(state.fromScreen);
      /*
      if (!rootState.auth.user.permissions.admin) await router.push(state.fromScreen);
      else {
        if (is_claim_request) {
          router.push({
            name: "change-request",
            props: { shiftId: state.shiftSelected }
          });
        }
      }*/
    }
    await commit(SET_PROCESSING_REQUEST, false);
  },
  async [SUBMIT_CREATE_REQUEST](
    // eslint-disable-next-line no-unused-vars
    { commit, dispatch, getters, state, rootState },
    payload
  ) {
    payload.client_id = rootState.client.id;
    if (process.env.NODE_ENV !== "production") {
      console.log("Submitted create request ", payload);
    }
    Sentry.configureScope(scope => {
      scope.setExtra("payload", payload);
    });
    await commit(SET_PROCESSING_REQUEST, true);
    let url = rootState.api_url + `/request/submit/`;
    setScope(
      url,
      payload,
      null,
      null,
      rootState.form,
      state,
      null,
      rootState.shifts.shifts[state.shiftSelected]
    );
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + rootState.auth.access_token
      },
      body: JSON.stringify(payload)
    })
      .then(async response => {
        Sentry.configureScope(scope => {
          scope.setExtra("api_response", response);
        });
        if (response.status === 500) {
          await commit(SET_ERRORS, [
            "Sorry, there was an error when processing your request. The developers have been notified and will fix this bug ASAP"
          ]);
          await commit(SHOW_ERROR_ALERT);
          await commit(SET_PROCESSING_REQUEST, false);
          return;
        }
        if (response.status === 200) {
          response
            .json()
            .then(async jd => {
              Sentry.configureScope(scope => {
                scope.setExtra("json_response", jd);
              });

              if (jd.shift_id) {
                await dispatch("form/" + RESET_STATE, {}, { root: true });
                await commit(SOFT_RESET_STATE);
                //this.$router.push("/request");
                //commit("FETCHING_REQUEST", false);
                //commit("CLEAR_REQUEST");
              }
              await commit(SET_PROCESSING_REQUEST, false);
              if (router.currentRoute.name === "create-shift") {
                router.go(-1);
              }
            })
            .catch(async err => {
              await commit(SET_ERRORS, [
                "Sorry, there was an error when processing your request. The developers have been notified and will fix this bug ASAP"
              ]);
              await commit(SHOW_ERROR_ALERT);
              await commit(SET_PROCESSING_REQUEST, false);
            });
        } else if (response.status === 400) {
          await commit(SET_PROCESSING_REQUEST, false);
        } else if (response.status === 401) {
          dispatch(
            "auth/REFRESH_LOGIN",
            { action: "request/SUBMIT_CREATE_REQUEST", payload: payload },
            { root: true }
          );
        }
      })
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
        commit(SET_ERRORS, [
          "Sorry, there was an error when processing your request. The developers have been notified and will fix this bug ASAP"
        ]);
        commit(SHOW_ERROR_ALERT);
        commit(SET_PROCESSING_REQUEST, false);
      });
  },
  // eslint-disable-next-line no-unused-vars
  async [VALIDATE_REQUEST]({ commit, dispatch, state, rootState }) {
    // TODO: Actually block if time off blocked?
    // FUTURE IF CLIENT SETTINGS REQUIRE SUPERVISOR SELECTION
    // if (this.selectedSupervisors.length == 0 && !this.canEdit) {
    //   this.missingSupervisors = true;
    // }
    commit(SET_VALIDATING, true);
    setScope(
      null,
      null,
      null,
      null,
      rootState.form,
      state,
      null,
      rootState.shifts.shifts[state.shiftSelected]
    );

    switch (state.requestType) {
      case "change":
        dispatch(VALIDATE_CHANGE_REQUEST);
        break;
      case "claim":
        dispatch(VALIDATE_CLAIM_REQUEST);
        break;
      case "extra":
        dispatch(VALIDATE_EXTRA_REQUEST);
        break;
      case "holdover":
        dispatch(VALIDATE_HOLDOVER_REQUEST);
        break;
      case "release":
        dispatch(VALIDATE_RELEASE_REQUEST);
        break;
      case "trade":
        dispatch(VALIDATE_TRADE_REQUEST);
        break;
      default:
        commit(SET_VALIDATING, false);
        console.log("Unhandled request type! ", state.requestType, state);
        commit(SET_ERRORS, [
          "Unhandled request type! ",
          state.requestType,
          state
        ]);
        dispatch(SHOW_ERROR_ALERT);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [VALIDATE_CHANGE_REQUEST]({ commit, dispatch, rootGetters }) {
    // If there's a custom form, ensure all required fields are filled out
    let custom_form = rootGetters["form/customForm"];
    let custom_form_data = rootGetters["form/customFormData"];
    if (custom_form.length) {
      let errors = [];
      for (let entry of custom_form) {
        if (entry.required && !custom_form_data(entry.ordinal_number)) {
          errors.push("Missing required field: " + entry.name);
        }
      };
      if (errors.length) {
        dispatch(VALIDATION_RESULT, errors);
        return;
      }
    }

    // Otherwise, handle validate the request based on type
    let change_type = rootGetters["form/changeType"];
    switch (change_type) {
      case "new":
        await dispatch(VALIDATE_NEW_CHANGE_REQUEST);
        break;
      case "modify":
        await dispatch(VALIDATE_MODIFY_CHANGE_REQUEST);
        break;
      case "rescind":
        await dispatch(VALIDATE_RESCIND_CHANGE_REQUEST);
        break;
      default:
        await commit(SET_VALIDATING, false);
        //console.log("Unhandled change request type!", change_type);
        await commit(SET_ERRORS, [
          "Unhandled change request type! ",
          change_type
        ]);
        await dispatch(SHOW_ERROR_ALERT);
    }
  },
  async [VALIDATE_NEW_CHANGE_REQUEST]({ dispatch, rootGetters }) {
    let errors = [];
    let using_custom_time = rootGetters["form/changeTime"] === "partial";
    let custom_time_valid = true;
    let time_off_type_valid = !rootGetters["form/invalidChangeTimeOffType"];
    let pay_preference_valid = !rootGetters["form/payPreferenceInvalid"];
    if (using_custom_time) {
      custom_time_valid =
        !rootGetters["form/invalidStart"] && !rootGetters["form/invalidEnd"];
    }
    if (!custom_time_valid) {
      errors.push("Invalid custom time!");
    }
    if (!time_off_type_valid) {
      errors.push("Invalid time off type selected!");
    }
    if (!pay_preference_valid) {
      errors.push("Invalid payment preference selected");
    }
    dispatch(VALIDATION_RESULT, errors);
  },
  async [VALIDATE_MODIFY_CHANGE_REQUEST]({ dispatch, rootGetters }) {
    let errors = [];
    let mod_change_valid = !rootGetters["form/modChangeInvalid"];
    let mod_new_type_valid = !rootGetters["form/modNewTypeInvalid"];
    let using_custom_time = rootGetters["form/changeTime"] === "partial";
    let custom_time_valid = true;
    if (using_custom_time) {
      custom_time_valid =
        !rootGetters["form/invalidStart"] && !rootGetters["form/invalidEnd"];
    }
    let pay_pref_valid = true;
    if (!mod_change_valid) {
      errors.push("Invalid change selected!");
    }
    if (rootGetters["form/changePayPreferenceVisible"]) {
      pay_pref_valid = !rootGetters["form/payPreferenceInvalid"];
    }
    if (!pay_pref_valid) {
      errors.push("Invalid payment preference selected!");
    }
    if (!custom_time_valid) {
      errors.push("Invalid custom time!");
    }
    if (!mod_new_type_valid) {
      errors.push("Invalid time off type selected!");
    }
    dispatch(VALIDATION_RESULT, errors);
  },
  async [VALIDATE_RESCIND_CHANGE_REQUEST]({ dispatch, rootGetters }) {
    let errors = [];
    let mod_change_valid = !rootGetters["form/modChangeInvalid"];
    if (!mod_change_valid) {
      errors.push("Invalid change selected!");
    }
    dispatch(VALIDATION_RESULT, errors);
  },
  // eslint-disable-next-line no-unused-vars
  async [VALIDATE_CLAIM_REQUEST]({ dispatch, rootGetters, rootState }) {
    let errors = [];
    let change_time = rootGetters["form/changeTime"];
    let using_custom_time = change_time === "partial";
    let custom_time_valid = true;
    if (using_custom_time) {
      custom_time_valid =
        !rootGetters["form/invalidStart"] && !rootGetters["form/invalidEnd"];
    }
    let change_time_valid = true;
    let shift_time_valid = true;
    let shift_time = rootGetters["form/claimShiftTime"];
    if (change_time === "full") {
      if (!shift_time) {
        shift_time_valid = false;
      }
    }
    if (!["full", "partial"].includes(change_time)) {
      change_time_valid = false;
    }
    let time_off_type_valid = true;
    let time_off_type_visible = rootGetters["form/claimTimeOffTypeVisible"];
    if (time_off_type_visible) {
      time_off_type_valid = !rootGetters["form/invalidClaimTimeOffType"];
    }
    let pay_preference_valid = true;
    let pay_preference_visible = rootGetters["form/claimPayPreferenceVisible"];
    if (pay_preference_visible) {
      pay_preference_valid = !rootGetters["form/payPreferenceInvalid"];
    }
    if (!shift_time_valid) {
      errors.push("Invalid shift time!");
    }
    if (!change_time_valid) {
      errors.push("Invalid change time!");
    }
    if (!custom_time_valid) {
      errors.push("Invalid custom time!");
    }
    if (!time_off_type_valid) {
      errors.push("Invalid time off type selected!");
    }
    if (!pay_preference_valid) {
      errors.push("Invalid pay preference selected!");
    }
    dispatch(VALIDATION_RESULT, errors);
  },
  async [VALIDATE_EXTRA_REQUEST]({ dispatch, rootGetters }) {
    let errors = [];
    let pay_preference_valid = true;
    let pay_preference_visible = rootGetters["form/claimPayPreferenceVisible"];
    if (pay_preference_visible) {
      pay_preference_valid = !rootGetters["form/payPreferenceInvalid"];
    }
    if (!pay_preference_valid) {
      errors.push("Invalid pay preference!");
    }
    let split_options_valid = !rootGetters["form/splitOptionsInvalid"];
    if (!split_options_valid) {
      errors.push("Must selected one or more time slots.");
    }
    dispatch(VALIDATION_RESULT, errors);
  },
  async [VALIDATE_HOLDOVER_REQUEST]({ dispatch, rootGetters }) {
    let errors = [];
    let release_type_valid = !rootGetters["form/releaseTypeInvalid"];
    let holdover_length_valid = !rootGetters["form/holdoverLengthInvalid"];

    let pay_preference_valid = true;
    let pay_preference_visible =
      rootGetters["form/holdoverPayPreferenceVisible"];
    if (pay_preference_visible) {
      pay_preference_valid = !rootGetters["form/payPreferenceInvalid"];
    }
    if (!release_type_valid) {
      errors.push("Invalid release type selected!");
    }
    if (!holdover_length_valid) {
      errors.push("Invalid holdover length selected!");
    }
    if (!pay_preference_valid) {
      errors.push("Invalid pay preference selected!");
    }
    dispatch(VALIDATION_RESULT, errors);
  },
  async [VALIDATE_RELEASE_REQUEST]({ dispatch, rootState /*, rootGetters */ }) {
    let errors = [];
    /*
    This is set statically when formatting the request.
    let release_type_valid = !rootGetters["form/releaseTypeInvalid"];
    if (!release_type_valid) {
      errors.push("Invalid release type selected!");
    }*/
    if (
      !rootState.client.settings.change_request_types.release &&
      (!rootState.form.personCovering || rootState.form.personCovering === 0)
    ) {
      errors.push("You have to select a person to cover!");
    }
    dispatch(VALIDATION_RESULT, errors);
  },
  async [VALIDATE_TRADE_REQUEST]({ dispatch, rootGetters }) {
    let errors = [];
    let trade_target_valid = !rootGetters["form/tradeTargetInvalid"];
    if (!trade_target_valid) {
      errors.push("Choose a valid person to trade with.");
    }
    let trade_length_valid = true;
    let trade_length_visible = rootGetters["form/tradeLengthVisible"];
    if (trade_length_visible) {
      trade_length_valid = !rootGetters["form/changeTimeInvalid"];
    }
    if (!trade_length_valid) {
      errors.push("Invalid trade length selected");
    }
    let using_custom_time = rootGetters["form/changeTime"] === "partial";
    let custom_time_valid = true;
    if (using_custom_time) {
      custom_time_valid =
        !rootGetters["form/invalidStart"] && !rootGetters["form/invalidEnd"];
    }
    if (!custom_time_valid) {
      errors.push("Partial time must be within start & end times of shift.");
    }
    let trade_type_visible = rootGetters["form/tradeTypeVisible"];
    let trade_type_valid = true;
    if (trade_type_visible) {
      trade_type_valid = !rootGetters["form/tradeTypeInvalid"];
    }
    if (!trade_type_valid) {
      errors.push("Choose a valid trade type");
    }
    let target_shift_visible = rootGetters["form/targetShiftVisible"];
    let target_shift_valid = true;
    if (target_shift_visible) {
      target_shift_valid = !rootGetters["form/targetShiftInvalid"];
    }
    if (!target_shift_valid) {
      errors.push("Choose a valid shift to trade with.");
    }
    let target_custom_time_valid = true;
    if (using_custom_time) {
      target_custom_time_valid = !rootGetters["form/targetTimeInvalid"];
    }
    if (!target_custom_time_valid) {
      errors.push("Partial time must be within start & end times of shift.");
    }
    dispatch(VALIDATION_RESULT, errors);
  },
  async [VALIDATION_RESULT]({ commit, dispatch }, errors) {
    let valid = true;
    if (errors.length > 0) {
      valid = false;
    }
    commit(SET_ERRORS, errors);
    commit(SET_VALIDATED, valid);
    commit(SET_VALIDATING, false);
    if (valid) {
      if (process.env.NODE_ENV !== "production") console.log("form is valid!");
    } else {
      console.log("Invalid form!", errors);
      dispatch(SHOW_ERROR_ALERT);
    }
  }
};
