import { _formatTime } from "../../time";
import {
  CHANGE_TYPE_PARTIAL_RELEASE,
  CHANGE_TYPE_RELEASE
} from "../../constants/shiftChange";

export default {
  /*
  groupShifts: state => group_id => {
    let shifts = state.shifts.filter(shift => {
      return shift.group_id === group_id;
    });
    //Object.freeze(shifts);
    return shifts;
  },*/
  // eslint-disable-next-line no-unused-vars

  myShifts: (state, getters, rootState) => {
    const my_id = parseInt(rootState.auth.person.id);
    return Object.values(state.shifts).filter(shift => {
      return parseInt(shift.assigned_to) === my_id;
    });
  },
  shiftsArray: state => {
    return Object.values(state.shifts);
  },
  openShifts: (state, getters) => {
    let shifts = getters.shiftsArray.filter(shift => {
      if (shift.changes) {
        // eslint-disable-next-line no-unused-vars
        let has_release = shift.changes.find(change_id => {
          const change = state.changes[change_id];
          return (
            change &&
            [CHANGE_TYPE_RELEASE, CHANGE_TYPE_PARTIAL_RELEASE].includes(
              change.shift_change_type
            )
          );
        });
        if (has_release) return false;
      }
      return shift.assigned_to === null;
    });

    //Object.freeze(shifts);
    return shifts.sort(function(a, b) {
      if (!a.start) return -1;
      if (a.start.isBefore(b.start)) {
        return -1;
      } else if (a.start.isAfter(b.start)) {
        return 1;
      }
      return 0;
    });
  },
  shifts: state => {
    return state.shifts;
  },
  shift: state => shift_id => {
    return state.shifts[shift_id];
  },
  changes: state => {
    return state.changes;
  },
  change: state => change_id => {
    return state.changes[change_id];
  },
  // eslint-disable-next-line no-unused-vars
  shiftChangeTimeOffType: (state, getters, rootState) => change_id => {
    let shift_change = getters.change(change_id);
    if (!shift_change) return null;
    //let time_off_type =
    return rootState.client.time_off_types[shift_change.time_off_type];
    //Object.freeze(time_off_type);
    //return time_off_type;
  },
  // eslint-disable-next-line no-unused-vars
  shiftChangeTime: (state, getters, rootState, rootGetters) => change_id => {
    let shift_change = state.changes[change_id]; //getters.change(change_id);
    let am_pm = rootState.client.settings.am_pm;
    let formattedStart = _formatTime(shift_change.start, am_pm);
    let formattedEnd = _formatTime(shift_change.end, am_pm);
    return formattedStart + " - " + formattedEnd;
  },
  // eslint-disable-next-line no-unused-vars
  shiftChanges: (state, getters, rootState, rootGetters) => shift_id => {
    let shift_changes = [];
    let shift = state.shifts[shift_id];
    if (!shift) return [];
    if (!shift.changes) return null;
    for (const index in shift.changes) {
      const shift_change_id = shift_changes[index];
      shift_changes.push(getters.change(shift_change_id));
    }
    //Object.freeze(shift_changes);
    return shift_changes;
  },
  // eslint-disable-next-line no-unused-vars
  shortages: state => {
    return [];
  },
  // eslint-disable-next-line no-unused-vars
  supportingShifts: state => {
    return [];
  },
  requestShift: (state, getters, rootState, rootGetters) => {
    return state.shifts[rootGetters["request/shiftSelected"]];
  },
  // eslint-disable-next-line no-unused-vars
  timeOffTypes: (state, getters, rootState) => shift_id => {
    let shift_obj = state.shifts[shift_id];
    let period = rootState.client.periods[shift_obj.period];
    let time_off_types = [];
    if (!period) return time_off_types;
    // eslint-disable-next-line no-unused-vars
    for (const index in period.time_off_types) {
      const time_off_type_id = period.time_off_types[index];
      time_off_types.push(rootState.client.time_off_types[time_off_type_id]);
    }
    return time_off_types;
  },
  // eslint-disable-next-line no-unused-vars
  modifiableTypes: (state, getters, rootState, rootGetters) => shift_id => {
    let time_off_types = getters.timeOffTypes(shift_id);
    if (time_off_types) {
      return Object.values(time_off_types)
        .filter(time_off_type => {
          return time_off_type.modify_requests_enabled;
        })
        .sort((a, b) => {
          if (a.ordinal_number < b.ordinal_number) {
            return -1;
          } else {
            return 1;
          }
        });
    }
    return [];
  },
  // eslint-disable-next-line no-unused-vars
  rescindableTypes: (state, getters, rootState, rootGetters) => shift_id => {
    let time_off_types = getters.timeOffTypes(shift_id);
    if (time_off_types) {
      return Object.values(time_off_types)
        .filter(time_off_type => {
          return time_off_type.rescind_requests_enabled;
        })
        .sort((a, b) => {
          if (a.ordinal_number < b.ordinal_number) {
            return -1;
          } else {
            return 1;
          }
        });
    }
    return [];
  }
};
