import { cloneDeep } from "bootstrap-vue/esm/utils/clone-deep";

export const initial_state = {
  default_schedule: null,
  department_view: true,
  lockouts: {},
  picker_stale: false,
  selected_month: null,
  selected_year: null,
  selected_period: null,
  selected_day: null,
  selected_schedule: "all_schedules",
  selected_locations: [],
  shortages: {},
  shortage_load_queue: []
};

export default cloneDeep(initial_state);
