// LIBS
import Vue from "vue";
import Vuex from "vuex";
import { analyticsMiddleware } from "vue-analytics";

// SCRIPTS
import state from "./state";
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import form from "./form";
import client from "./client";
import auth from "./auth";
import env from "./env";
import calendar from "./calendar";
import dashboard from "./dashboard";
import debug from "./debug";
import request from "./request";
import task from "./task";
import shifts from "./shifts";
Vue.use(Vuex);

export const store = new Vuex.Store({
  state,
  mutations,
  getters,
  actions,
  plugins: [analyticsMiddleware],
  strict: false, //process.env.NODE_ENV !== "production",
  modules: {
    calendar,
    dashboard,
    debug,
    client,
    auth,
    env,
    form,
    request,
    task,
    shifts
  }
});
