import {
  CHANGE_TYPE_CLAIM,
  CHANGE_TYPE_COVER,
  CHANGE_TYPE_MODIFY,
  CHANGE_TYPE_PARTIAL_CLAIM,
  CHANGE_TYPE_PARTIAL_COVER,
  CHANGE_TYPE_PARTIAL_TIME_OFF,
  CHANGE_TYPE_PARTIAL_TRADE,
  CHANGE_TYPE_RELEASE,
  CHANGE_TYPE_RESCIND,
  CHANGE_TYPE_TIME_OFF,
  CHANGE_TYPE_TRADE,
  SHIFT_CHANGE_TIME_TYPE_MAP,
  TIME_ADJUSTMENT_EARLY_ARRIVAL,
  TIME_ADJUSTMENT_EARLY_DISMISSAL,
  TIME_ADJUSTMENT_LATE_ARRIVAL,
  TIME_ADJUSTMENT_LATE_DISMISSAL,
  TIME_ADJUSTMENT_OFF_MIDDLE_SHIFT
} from "../../constants/shiftChange";
import { SHIFT_TYPE_ON_CALL } from "../../constants/shift";
import { PAY_PREFERENCE_TYPES } from "../../constants/request";
import { LocalDate, DateTimeFormatter } from "@js-joda/core";
// eslint-disable-next-line no-unused-vars
import { /*_formatRequest,*/ _formatHHMM, _duration } from "../../time";
import { uuid4 } from "../../constants/uuid";
import cloneDeep from "lodash.clonedeep";
import { mock_shift_times } from "../../../tests/unit/mock";

export default {
  cancelled: state => {
    return state.cancelled;
  },
  riskMessage: state => {
    if (state.min_staffing_risk === null) return null;
    if (state.min_staffing_risk.min_staffing_at_risk)
      return state.min_staffing_risk.message;
    return null;
  },
  requestType: state => {
    return state.requestType;
  },
  shiftSelected: state => {
    return state.shiftSelected;
  },
  // eslint-disable-next-line no-unused-vars
  formatCustomForms: (state, getters, rootState) => {
    let custom_form_values = [];
    rootState.form.customForm.forEach(cf_field => {
      custom_form_values.push({
        element_id: cf_field.id,
        value: rootState.form.customFormData[cf_field.ordinal_number] //cf_field.value
      });
    });
    return custom_form_values;
  },
  // eslint-disable-next-line no-unused-vars
  formattedReleaseType: (state, getters, rootState, rootGetters) => {
    if (rootState.client.settings.change_request_types.release) {
      if (rootState.form.releaseType === 1) {
        return "Full Shift Release";
      } else if (rootState.form.releaseType === 2) {
        return "Partial Shift Release";
      } else {
        return "";
      }
    } else {
      if (rootState.form.releaseType === 1) {
        return "Full Shift Cover";
      } else if (rootState.form.releaseType === 2) {
        return "Partial Shift Cover";
      } else {
        return "";
      }
    }
  },
  formattedRequest: (state, getters, rootState, rootGetters) => {
    let formatted_request = {
      client_id: rootState.client.id,
      time_off_types: []
    };
    let time_off_types = null;
    if (state.requestType !== "claim") {
      formatted_request.shift_id = state.shiftSelected;
    }
    let shift = rootGetters["shifts/requestShift"];
    let release_type = rootGetters["form/releaseType"];
    switch (state.requestType) {
      case "change":
        time_off_types = getters.formattedChangeRequest;
        const change_time = rootGetters["form/changeTime"];
        const change_type = rootGetters["form/changeType"];
        if (change_time === "multiple") {
          formatted_request["end_date"] = rootGetters[
            "form/selectedDate"
          ].toString();
        }
        if (change_type === "new") {
          formatted_request.is_request =
            state.submitAsPending === "false" ? false : state.submitAsPending;
        }
        break;
      case "holdover":
        time_off_types = getters.formattedHoldoverRequest;
        if (
          parseInt(rootState.form.personCovering) &&
          (release_type === "early_arrival" ||
            release_type === "late_dismissal")
        ) {
          // TODO: This doesn't make sense.
          formatted_request.shift_id = parseInt(rootState.form.personCovering);
          formatted_request.is_request = true;
        } else {
          formatted_request.is_request =
            state.submitAsPending === "false" ? false : state.submitAsPending;
        }
        break;
      case "trade":
        time_off_types = getters.formattedTradeRequest;
        formatted_request.is_request = true;
        break;
      case "release":
        time_off_types = getters.formattedReleaseRequest;
        formatted_request.is_request =
          state.submitAsPending === "false" &&
          shift.assigned_to !== rootState.auth.person.id
            ? false
            : state.submitAsPending;
        break;
      case "extra":
        time_off_types = getters.formattedExtraShiftRequest;
        formatted_request.is_request = true;
        break;
      case "claim":
        Object.assign(formatted_request, getters.formattedClaimShift);
        time_off_types = getters.formattedClaimRequest;
        formatted_request.is_request =
          state.submitAsPending === "false" ? false : state.submitAsPending;
        break;
      default:
        console.log(
          "Unhandled request type for formatting!",
          state.requestType
        );
    }
    if (time_off_types) {
      formatted_request["time_off_types"].push(time_off_types);
    }
    formatted_request["message_id"] = uuid4();
    return formatted_request;
  },
  formattedRequests: (state, getters, rootState, rootGetters) => {
    let formatted_requests = [];
    let formatted_request = {
      client_id: rootState.client.id,
      time_off_types: [],
      shift_id: state.shiftSelected
    };
    let time_off_types = null;
    switch (state.requestType) {
      case "change":
        time_off_types = getters.formattedChangeRequests;
        const change_type = rootGetters["form/changeType"];
        if (change_type === "new") {
          formatted_request.is_request =
            state.submitAsPending === "false" ? false : state.submitAsPending;
        }
        break; /*
      case "holdover":
        time_off_types = getters.formattedHoldoverRequest;
        break;
      case "trade":
        time_off_types = getters.formattedTradeRequest;
        break;
      case "release":
        time_off_types = getters.formattedReleaseRequest;
        break;*/
      case "extra":
        formatted_request.is_request = true;
        time_off_types = getters.formattedExtraShiftRequests;
        break; /*
      case "claim":
        Object.assign(formatted_request, getters.formattedClaimShift);
        time_off_types = getters.formattedClaimRequest;
        break;*/
      default:
        console.log(
          "Unhandled request type for formatting multiple requests!",
          state.requestType
        );
    }
    for (let time_off_type of time_off_types) {
      formatted_request["time_off_types"] = [time_off_type];
      formatted_request["message_id"] = uuid4();
      formatted_requests.push(cloneDeep(formatted_request));
    }
    return formatted_requests;
  },
  // eslint-disable-next-line no-unused-vars
  formattedChangeRequests: (state, getters, rootState, rootGetters) => {
    switch (rootGetters["form/changeType"]) {
      case "new":
        return getters.formattedChangeNewRequests;
      /*case "modify":
        return getters.formattedChangeModifyRequest;
      case "rescind":
        return getters.formattedChangeRescindRequest;*/
      default:
        console.log(
          "Unhandled request change type for formatting!",
          rootGetters["form/changeType"]
        );
        return null;
    }
  },
  // eslint-disable-next-line no-unused-vars
  formattedChangeRequest: (state, getters, rootState, rootGetters) => {
    switch (rootGetters["form/changeType"]) {
      case "new":
        return getters.formattedChangeNewRequest;
      case "modify":
        return getters.formattedChangeModifyRequest;
      case "rescind":
        return getters.formattedChangeRescindRequest;
      default:
        console.log(
          "Unhandled request change type for formatting!",
          rootGetters["form/changeType"]
        );
        return null;
    }
  },
  // eslint-disable-next-line no-unused-vars
  formattedPayPreference: (state, getters, rootState, rootGetters) => {
    let formatted_pay_preference = {};
    let pay_preference = rootGetters["form/payPreference"];
    if (pay_preference != null) {
      formatted_pay_preference["overtime_payment_type"] =
        PAY_PREFERENCE_TYPES[pay_preference];
      formatted_pay_preference["opt_split_overtime_num_hours"] =
        rootGetters["form/splitPayPreferencePaid"];
      formatted_pay_preference["opt_split_comp_time_num_hours"] =
        rootGetters["form/splitPayPreferenceTime"];
    }
    return formatted_pay_preference;
  },
  // eslint-disable-next-line no-unused-vars
  formattedNotesSubmitted: (state, getters, rootState, rootGetters) => {
    let formatted = {
      notes: rootGetters["form/notes"],
      submitted_to: rootGetters["request/selectedSupervisors"]
    };
    if (rootGetters["form/customForm"].length > 0) {
      formatted["custom_form_elements"] = getters.formatCustomForms;
    }
    if (state.requestType === "claim") {
      let pay_preference = getters.formattedPayPreference;
      Object.assign(formatted, pay_preference);
    }
    return formatted;
  },
  // eslint-disable-next-line no-unused-vars
  formattedChangeNewRequests: (state, getters, rootState, rootGetters) => {
    let time_off_types = rootState.form.timeOffTypesSelected;
    let changes = [];
    let last_start_time = null;
    let last_end_time = null;
    for (let time_off_type of time_off_types) {
      let shift_change = {
        time_off_type_id: time_off_type.id,
        shift_change_type: CHANGE_TYPE_PARTIAL_TIME_OFF
      };
      Object.assign(shift_change, getters.formattedNotesSubmitted);
      const minutes = Math.round(
        (time_off_type.hours - Math.floor(time_off_type.hours)) * 60
      );
      if (!last_end_time) {
        if (rootGetters["form/changeTime"] === "partial") {
          last_start_time = rootGetters["form/customTimeStart"];
        } else {
          let shift = rootState.shifts.shifts[getters.shiftSelected];
          last_start_time = shift.start;
        }
      } else {
        last_start_time = last_end_time;
      }
      last_end_time = last_start_time
        .plusHours(time_off_type.hours)
        .plusMinutes(minutes);

      shift_change["start_datetime"] = last_start_time.format(
        DateTimeFormatter.ISO_LOCAL_DATE_TIME
      );
      shift_change["end_datetime"] = last_end_time.format(
        DateTimeFormatter.ISO_LOCAL_DATE_TIME
      );
      //let pay_preference = getters.formattedPayPreference;
      //Object.assign(shift_change, pay_preference);
      changes.push(shift_change);
    }
    return changes;
  },
  // eslint-disable-next-line no-unused-vars
  formattedChangeNewRequest: (state, getters, rootState, rootGetters) => {
    let shift_change = {
      time_off_type_id: rootGetters["form/timeOffTypeSelected"]
    };
    Object.assign(shift_change, getters.formattedNotesSubmitted);
    const change_time = rootGetters["form/changeTime"];
    if (change_time === "partial") {
      // PARTIAL CHANGE
      shift_change["shift_change_type"] = CHANGE_TYPE_PARTIAL_TIME_OFF;
      shift_change["start_datetime"] = rootGetters[
        "form/customTimeStart"
      ].format(DateTimeFormatter.ISO_LOCAL_DATE_TIME);
      shift_change["end_datetime"] = rootGetters["form/customTimeEnd"].format(
        DateTimeFormatter.ISO_LOCAL_DATE_TIME
      );
    } else {
      // FULL SHIFT
      shift_change["shift_change_type"] = CHANGE_TYPE_TIME_OFF;
    }

    let pay_preference = getters.formattedPayPreference;
    Object.assign(shift_change, pay_preference);
    return shift_change;
  },
  formattedChangeModifyRequest: (state, getters, rootState) => {
    let shift_change = {
      time_off_type_id: rootState.form.modNewType,
      shift_change_type: CHANGE_TYPE_MODIFY,
      shift_change_to_update_id: rootState.form.modChange
    };
    Object.assign(shift_change, getters.formattedNotesSubmitted);

    let pay_preference = getters.formattedPayPreference;
    Object.assign(shift_change, pay_preference);
    return shift_change;
  },
  // eslint-disable-next-line no-unused-vars
  formattedChangeRescindRequest: (state, getters, rootState, rootGetters) => {
    return {
      notes: rootGetters["form/notes"],
      submitted_to: rootGetters["request/selectedSupervisors"],
      shift_change_type: CHANGE_TYPE_RESCIND,
      shift_change_to_update_id: rootState.form.modChange
    };
  },
  formattedHoldoverRequest: (state, getters, rootState, rootGetters) => {
    let holdoverAdjTypes = {
      "1": 1, // "early_arrival",
      "2": 2, //" late_arrival",
      "3": 3, // "early_dismissal",
      "4": 4, // "late_dismissal",
      "5": 5, //"off_middle_shift",*/
      early_arrival: 1,
      late_arrival: 2,
      early_dismissal: 3,
      late_dismissal: 4,
      off_middle_shift: 5
    };
    let holdover_start = null;
    let holdover_end = null;
    let shift = rootGetters["shifts/requestShift"];
    let release_type = rootGetters["form/releaseType"];
    const numeric_release_type = holdoverAdjTypes[release_type];
    let change_length = rootGetters["form/changeLength"];
    if (numeric_release_type === TIME_ADJUSTMENT_EARLY_ARRIVAL) {
      holdover_start = _formatHHMM(shift.start.minusMinutes(change_length));
      holdover_end = _formatHHMM(shift.start);
    } else if (numeric_release_type === TIME_ADJUSTMENT_LATE_ARRIVAL) {
      holdover_start = _formatHHMM(shift.start);
      holdover_end = _formatHHMM(shift.start.plusMinutes(change_length));
    } else if (numeric_release_type === TIME_ADJUSTMENT_EARLY_DISMISSAL) {
      holdover_start = _formatHHMM(shift.end.minusMinutes(change_length));
      holdover_end = _formatHHMM(shift.end);
    } else if (numeric_release_type === TIME_ADJUSTMENT_LATE_DISMISSAL) {
      holdover_start = _formatHHMM(shift.end);
      holdover_end = _formatHHMM(shift.end.plusMinutes(change_length));
    } else if (numeric_release_type === TIME_ADJUSTMENT_OFF_MIDDLE_SHIFT) {
      //let shift_length = _duration(shift.start, shift.end);
      //let middle_of_shift = shift.end.minusMinutes(shift_length / 2);
      holdover_start = rootGetters["form/customTimeStart"];
      holdover_end = _formatHHMM(holdover_start.plusMinutes(change_length));
      holdover_start = _formatHHMM(holdover_start);
    }

    let shift_change = {
      time_adjustment_type: numeric_release_type,
      start_time: holdover_start,
      end_time: holdover_end
    };

    if (rootState.form.personCovering > 0) {
      switch (release_type) {
        case "late_arrival":
        case "early_dismissal":
        case "off_middle_shift":
          shift_change["employee_2_id"] = parseInt(
            rootState.form.personCovering
          );
          break;
        case "early_arrival":
        case "late_dismissal":
          shift_change["employee_1_id"] = parseInt(
            rootState.shifts.shifts[rootState.form.personCovering].assigned_to
          );
          shift_change["employee_2_id"] = parseInt(rootState.auth.person.id);
          shift_change["shift_1_id"] = parseInt(rootState.form.personCovering);
          shift_change["shift_2_id"] = parseInt(state.shiftSelected);
          break;
        default:
          break;
      }
      shift_change["shift_change_type"] = CHANGE_TYPE_PARTIAL_COVER;
    }
    Object.assign(shift_change, getters.formattedNotesSubmitted);

    if (rootState.form.timeOffTypeSelected > 0) {
      shift_change["time_off_type_id"] = rootState.form.timeOffTypeSelected;
    }

    let pay_preference = getters.formattedPayPreference;
    Object.assign(shift_change, pay_preference);

    return shift_change;
  },
  formattedTradeRequest: (state, getters, rootState, rootGetters) => {
    let shift_change = {
      shift_change_type: CHANGE_TYPE_TRADE,
      shift_2_id: rootState.form.targetShift
    };
    Object.assign(shift_change, getters.formattedNotesSubmitted);

    if (rootGetters["form/changeTime"] === "partial") {
      // PARTIAL CHANGE
      shift_change["shift_change_type"] = CHANGE_TYPE_PARTIAL_TRADE;
      shift_change["partial_trade_start_datetime"] = rootGetters[
        "form/targetCustomTimeStart"
      ].format(DateTimeFormatter.ISO_LOCAL_DATE_TIME);
      shift_change["partial_trade_end_datetime"] = rootGetters[
        "form/targetCustomTimeEnd"
      ].format(DateTimeFormatter.ISO_LOCAL_DATE_TIME);
      shift_change["start_datetime"] = rootGetters[
        "form/customTimeStart"
      ].format(DateTimeFormatter.ISO_LOCAL_DATE_TIME);
      shift_change["end_datetime"] = rootGetters["form/customTimeEnd"].format(
        DateTimeFormatter.ISO_LOCAL_DATE_TIME
      );
    }
    // TODO => Account for partial trades and trade for time
    // let pay_preference = getters.formattedPayPreference;
    // Object.assign(shift_change, pay_preference);

    return shift_change;
  },
  // eslint-disable-next-line no-unused-vars
  formattedReleaseRequest: (state, getters, rootState, rootGetters) => {
    let shift_change = {
      shift_change_type: CHANGE_TYPE_RELEASE
    };
    Object.assign(shift_change, getters.formattedNotesSubmitted);

    if (rootState.form.personCovering > 0) {
      shift_change["employee_2_id"] = parseInt(rootState.form.personCovering);
      shift_change["shift_change_type"] = CHANGE_TYPE_COVER;
    }

    return shift_change;
  },
  formattedExtraShiftRequest: (state, getters, rootState, rootGetters) => {
    let shift = rootGetters["shifts/requestShift"];
    const split_options = shift.extra_shift_split_options;
    let shift_change = {
      shift_change_type:
        split_options && split_options !== 1
          ? CHANGE_TYPE_PARTIAL_CLAIM
          : CHANGE_TYPE_CLAIM
    };
    if (split_options === 5) {
      let start_time = rootState.form.customTimeStart;
      let end_time = rootState.form.customTimeEnd;
      if (start_time.isBefore(shift.start)) {
        start_time = start_time.plusDays(1);
        end_time = end_time.plusDays(1);
      }
      shift_change["start_datetime"] = start_time.format(
        DateTimeFormatter.ISO_LOCAL_DATE_TIME
      );
      shift_change["end_datetime"] = end_time.format(
        DateTimeFormatter.ISO_LOCAL_DATE_TIME
      );
    }
    Object.assign(shift_change, getters.formattedNotesSubmitted);
    let pay_preference = getters.formattedPayPreference;
    Object.assign(shift_change, pay_preference);

    return shift_change;
  },
  // eslint-disable-next-line no-unused-vars
  formattedExtraShiftRequests: (state, getters, rootState, rootGetters) => {
    let shift_change = {
      shift_change_type: CHANGE_TYPE_PARTIAL_CLAIM
    };
    Object.assign(shift_change, getters.formattedNotesSubmitted);
    let pay_preference = getters.formattedPayPreference;
    Object.assign(shift_change, pay_preference);
    let changes = [];
    for (const option of rootState.form.splitOptionsSelected) {
      let start = option.start.format(DateTimeFormatter.ISO_LOCAL_DATE_TIME);
      let end = option.end.format(DateTimeFormatter.ISO_LOCAL_DATE_TIME);
      let change = Object.assign({}, shift_change, {
        start_datetime: start,
        end_datetime: end,
        shift: {
          start_datetime: start,
          end_datetime: end
        }
      });
      changes.push(change);
    }
    return changes;
  },
  formattedClaimRequest: (state, getters, rootState, rootGetters) => {
    if (rootState.form.timeOffTypeSelected !== 0) {
      let shift_change = {
        shift_change_type: CHANGE_TYPE_TIME_OFF,
        time_off_type_id: rootState.form.timeOffTypeSelected
      };
      //Object.assign(shift_change, getters.formattedNotesSubmitted);
      //let pay_preference = getters.formattedPayPreference;
      //Object.assign(shift_change, pay_preference);
      if (rootGetters["form/customForm"].length > 0) {
        shift_change["custom_form_elements"] = getters.formatCustomForms;
      }

      return shift_change;
    }
    // claim_type: this.shift ? "additional time" : "new shift",
    return null;
  },
  // eslint-disable-next-line no-unused-vars
  formattedClaimShift: (state, getters, rootState, rootGetters) => {
    let request = {};
    let location_selected = rootState.form.locationSelected;
    let shift = rootGetters["shifts/requestShift"];
    let period = rootState.client.periods[rootState.form.fdom.toString()];
    if (location_selected === 0) {
      location_selected = Object.values(period.locations)[0];
    }
    let claim_day = rootState.form.claimDay;
    if (claim_day) {
      claim_day = LocalDate.parse(claim_day).toString();
    } else {
      claim_day = shift.day;
    }
    if (rootState.form.claimShiftType === "on-call") {
      request["shift"] = {
        day: claim_day,
        shift_type: SHIFT_TYPE_ON_CALL,
        location_id: location_selected
      };
    } else {
      let roles = period.roles;
      let job_type_id =
        shift && shift.assigned_to
          ? Object.values(roles).filter(role => {
              return rootState.client.roles[role].people.includes(
                shift ? shift.assigned_to : rootState.auth.person.id
              );
            })[0]
          : Object.values(roles).filter(role => {
              return rootState.client.roles[role].people.includes(
                rootState.form.claimTarget
              );
            })[0]
          ? Object.values(roles).filter(role => {
              return rootState.client.roles[role].people.includes(
                rootState.form.claimTarget
              );
            })[0]
          : false;
      // If we really need next day flag enable this, but claim day wasn't being updated.
      // eslint-disable-next-line no-unused-vars
      /*let next_day_shift = !LocalDate.parse(claim_day).isEqual(
        shift.start.toLocalDate()
      );*/
      request["shift"] = {
        day: claim_day,
        location_id: location_selected
        //next_day_shift:
      };
      if (job_type_id) {
        request["shift"].job_type_id = job_type_id;
      }
      let time_off_type = {
        shift_change_type: CHANGE_TYPE_CLAIM
      };
      Object.assign(time_off_type, getters.formattedNotesSubmitted);
      delete time_off_type["custom_form_elements"];
      request["time_off_types"] = [time_off_type];
    }
    if (
      rootState.form.targetUser &&
      rootState.form.targetUser !== rootState.auth.person.id
    ) {
      request["shift"]["person_id"] = rootState.form.targetUser;
    } else if (shift && shift.assigned_to !== rootState.auth.person.id) {
      request["shift"]["person_id"] = shift.assigned_to;
    } else if (!shift) {
      request["shift"]["person_id"] = rootState.auth.person.id;
    } else if (shift && shift.assigned_to) {
      request["shift"]["person_id"] = shift.assigned_to;
    }

    if (rootState.form.claimShiftTime === "custom_time") {
      let start_time = rootState.form.customTimeStart;
      let end_time = rootState.form.customTimeEnd;
      request["shift"]["start_time"] = _formatHHMM(start_time);
      //request["shift"]["start_datetime"] =
      //  claim_day + "T" + _formatHHMM(start_time);
      request["shift"]["end_time"] = _formatHHMM(end_time);
      if (rootState.client.settings.next_day_shifts_enabled) {
        request["shift"]["next_day_shift"] = !LocalDate.parse(claim_day).equals(
          start_time.toLocalDate()
        );
      }
    } else if (rootState.form.claimShiftTime === "all_day") {
      request["shift"]["start_time"] = "00:00";
      request["shift"]["end_time"] = "24:00";
      //request["shift"]["start_datetime"] = claim_day + "T00:00";
    } else {
      request["shift"]["shift_time_id"] = parseInt(
        rootState.form.claimShiftTime
      );
    }
    if (process.env.NODE_ENV !== "production") console.log("final", request);
    return request;
  },
  supervisors: state => {
    return state.supervisors;
  },
  selectedSupervisors: state => {
    return state.selectedSupervisors;
  },
  validated: state => {
    return state.validated;
  },
  validating: state => {
    return state.validating;
  },
  dismissCountDown: state => {
    return state.dismissCountDown;
  },
  errors: state => {
    return state.errors;
  },
  // eslint-disable-next-line no-unused-vars
  description: (state, getters, rootState, rootGetters) => {
    let time_off_type = null;
    switch (state.requestType) {
      case "change":
        switch (rootGetters["form/changeType"]) {
          case "new":
            time_off_type = rootGetters["form/selectedTimeType"];
            if (time_off_type) {
              return (
                SHIFT_CHANGE_TIME_TYPE_MAP[time_off_type.category] + " Request"
              );
            } else {
              return "Change Request";
            }
          case "modify":
            return "Modify Request For";
          case "rescind":
            return "Rescind Request";
          default:
            console.log(
              "Unhandled changeType ",
              rootGetters["form/changeType"]
            );
        }
        return "Change";
      case "claim":
        return rootState.client.settings.additional_time_name
          ? rootState.client.settings.additional_time_name
          : "Additional Time";
      case "extra":
        return "Claim";
      case "holdover":
        return rootState.client.settings.holdover_name
          ? rootState.client.settings.holdover_name
          : "Partial Release";
      case "trade":
        return "Trade";
      case "release":
        return "Release Request";
    }
    return "Unhandled type description: " + state.requestType;
  }
};
