export default {
  confirming: state => task_id => {
    if (!state.tasks[task_id]) return false;
    return state.tasks[task_id].confirming;
  },
  commentRequired: (state, getters, rootState) => task_id => {
    return (
      rootState.client.settings.change_requests_require_comment_for_rejection &&
      state.tasks[task_id].confirmationType === "reject"
    );
  }
};
