require("@js-joda/timezone");
// eslint-disable-next-line no-unused-vars
import { Locale } from "@js-joda/locale_en-us";
import { ChronoUnit, LocalDateTime, LocalDate, ZoneId } from "@js-joda/core";
let shift_id_seq = 0;
let change_id_seq = 0;
let time_off_type_seq = 0;
let user_id_seq = 0;
let person_id_seq = 0;
let client_id_seq = 0;
let shift_time_id_seq = 0;
let role_id_seq = 0;
let group_id_seq = 0;
let schedule_id_seq = 0;
let asset_id_seq = 0;
let title_id_seq = 0;

export function reset_ids() {
  asset_id_seq = 0;
  shift_id_seq = 0;
  change_id_seq = 0;
  time_off_type_seq = 0;
  user_id_seq = 0;
  person_id_seq = 0;
  client_id_seq = 0;
  shift_time_id_seq = 0;
  role_id_seq = 0;
  group_id_seq = 0;
  schedule_id_seq = 0;
  title_id_seq = 0;
}
export function new_title(name, abbreviation, ordinal_number) {
  title_id_seq++;
  return {
    id: title_id_seq,
    name: name,
    abbreviation: abbreviation,
    ordinal_number: ordinal_number
  };
}
export function new_asset(name, asset_type, options) {
  asset_id_seq++;
  return {
    id: asset_id_seq,
    name: name,
    asset_type: asset_type,
    barcode: options.barcode ? options.barcode : "",
    notes: options.notes ? options.notes : "",
    serial_number: options.serial_number ? options.serial_number : "",
    show_daily: options.show_daily ? options.show_daily : true
  };
}
export function new_shift_asset(asset, shift) {
  return {
    id: asset.id,
    end: shift.end,
    end_time: shift.end.toString(),
    start: shift.start,
    start_time: shift.start.toString()
  };
}
export function new_client(name, mobile_app_enabled, options) {
  client_id_seq++;
  return {
    id: client_id_seq,
    name: name,
    mobile_app_enabled: mobile_app_enabled,
    settings: options.settings
      ? options.settings
      : {
          timezone: ZoneId.of("US/Central"),
          change_request_types: { additional_time: true }
        }
  };
}

export function new_group(name, abbreviation, people, options) {
  group_id_seq++;
  return {
    id: group_id_seq,
    name: name,
    abbreviation: abbreviation,
    people: people,
    is_implied_group: options.is_implied_group
      ? options.is_implied_group
      : false,
    manpower_start_time: options.manpower_start_time
      ? options.manpower_start_time
      : null,
    manpower_end_time: options.manpower_end_time
      ? options.manpower_end_time
      : null,
    non_group_manpower_counts_as_support: options.non_group_manpower_counts_as_support
      ? options.non_group_manpower_counts_as_support
      : true,
    ordinal_number: options.ordinal_number ? options.ordinal_number : 1,
    show_even_if_no_people: options.show_even_if_no_people
      ? options.show_even_if_no_people
      : false,
    show_extra_support: options.show_extra_support
      ? options.show_extra_support
      : true,
    show_on_calendar: options.show_on_calendar
      ? options.show_on_calendar
      : true,
    policies: options.policies ? options.policies : [],
    shift_times: options.shift_times ? options.shift_times : []
  };
}

export function new_schedule(name, abbreviation, groups, options) {
  schedule_id_seq++;
  return {
    id: schedule_id_seq,
    name: name,
    abbreviation: abbreviation,
    groups: groups,
    grid_job_type_subtotals_enabled: options.grid_job_type_subtotals_enabled
      ? options.grid_job_type_subtotals_enabled
      : true,
    ordinal_number: options.ordinal_number ? options.ordinal_number : 1
  };
}

export function new_user(email, options = {}) {
  user_id_seq++;
  return {
    id: user_id_seq,
    email: email,
    is_admin: options.is_admin ? options.is_admin : false
  };
}

export function new_person(
  first_name,
  last_name,
  user,
  title,
  client,
  options = {}
) {
  person_id_seq++;
  return {
    id: person_id_seq,
    client_id: client.id,
    display: first_name + " " + last_name,
    first_name: first_name,
    user_id: user.id,
    last_name: user.id + last_name + person_id_seq,
    short_name: user.id + "Mock" + person_id_seq,
    subordinates: options.subordinates ? options.subordinates : {},
    add_notes: options.add_notes ? options.add_notes : false,
    create_extra_shifts: options.create_extra_shifts
      ? options.create_extra_shifts
      : true,
    modify_schedules: options.modify_schedules ? options.modify_schedules : {},
    active_status: options.active_status ? options.active_status : 1,
    barcode: options.barcode ? options.barcode : "",
    base_hourly_wage: options.base_hourly_wage
      ? options.base_hourly_wage
      : null,
    cell_phone: options.cell_phone ? options.cell_phone : "123-456-7890",
    city: options.city ? options.city : "Chicago",
    date_deactivated: options.date_deactivated
      ? options.date_deactivated
      : null,
    employee_id: options.employee_id ? options.employee_id : "",
    employee_role: options.employee_role ? options.employee_role : 3,
    employee_type: options.employee_type ? options.employee_type : 1,
    home_phone: options.home_phone ? options.home_phone : "",
    hourly_rate: options.hourly_rate ? options.hourly_rate : null,
    is_admin: options.is_admin ? options.is_admin : false,
    is_visible: options.is_visible ? options.is_visible : false,
    labor_class: options.labor_class ? options.labor_class : "",
    line_1: options.line_1 ? options.line_1 : "123 W. Mock Street",
    line_2: options.line_2 ? options.line_2 : "#1234",
    overtime_rate: options.overtime_rate ? options.overtime_rate : null,
    second_email: options.second_email ? options.second_email : "",
    seniority_rank: options.seniority_rank ? options.seniority_rank : 0,
    sl_account: options.sl_account ? options.sl_account : "",
    sl_subaccount: options.sl_subaccount ? options.sl_subaccount : "",
    sl_username: options.sl_username ? options.sl_username : "",
    state: options.state ? options.state : "IL",
    tasks: [],
    time_bank_balances: options.time_bank_balances
      ? options.time_bank_balances
      : {
          1: {
            balance: 1234,
            balance_cap: 18000
          },
          2: {
            balance: 4321,
            balance_cap: 18000
          },
          3: {
            balance: 3412,
            balance_cap: 18000
          }
        },
    title: title.id,
    union_code: options.union_code ? options.union_code : "",
    work_cell_phone: options.work_cell_phone ? options.work_cell_phone : "",
    work_comp_cd: options.work_comp_cd ? options.work_comp_cd : "",
    work_location: options.work_location ? options.work_location : "",
    zip_code: options.zip_code ? options.zip_code : "60661"
  };
}
export function new_time_off_type(
  abbreviation,
  name,
  category,
  client,
  options = {}
) {
  time_off_type_seq++;
  return {
    abbreviation: abbreviation,
    active: options.active ? options.active : true,
    add_to_regular_hours: options.add_to_regular_hours
      ? options.add_to_regular_hours
      : false,
    assigned_shifts_only: options.assigned_shifts_only
      ? options.assigned_shifts_only
      : false,
    can_request_as_comp_time: options.can_request_as_comp_time
      ? options.can_request_as_comp_time
      : 3,
    can_request_via_availabilities: options.can_request_via_availabilities
      ? options.can_request_via_availabilities
      : false,
    category: category,
    client: client.id,
    code: options.code ? options.code : "",
    comp_time_bank: options.comp_time_bank ? options.comp_time_bank : null,
    comp_time_code: options.comp_time_cdoe ? options.comp_time_code : "",
    custom_form: options.custom_form ? options.custom_form : null,
    description: options.description ? options.description : "",
    id: time_off_type_seq,
    include_in_shift_coverage_count: options.include_in_shift_coverage_count
      ? options.include_in_shift_coverage_count
      : true,
    modify_request_hours_prior_to_shift: options.modify_request_hours_prior_to_shift
      ? options.modify_request_hours_prior_to_shift
      : null,
    modify_requests_enabled: options.modify_requests_enabled
      ? options.modify_requests_enabled
      : false,
    name: name,
    ordinal_number: options.ordinal_number ? options.ordinal_number : 1,
    payout_rate: options.payout_rate ? options.payout_rate : null,
    people: options.people ? options.people : [],
    rescind_request_hours_prior_to_shift: options.rescind_request_hours_prior_to_shift
      ? options.rescind_request_hours_prior_to_shift
      : null,
    rescind_requests_enabled: options.rescind_requests_enabled
      ? options.rescind_requests_enabled
      : false,
    subtract_from_regular_hours: options.subtract_from_regular_hours
      ? options.subtract_from_regular_hours
      : false,
    time_off_bank: options.time_off_bank ? options.time_off_bank : null,
    use_by_default: options.use_by_default ? options.use_by_default : true,
    use_by_default_in_extra_shifts: options.use_by_default_in_extra_shifts
      ? options.use_by_default_in_extra_shifts
      : false
  };
}
export function new_role(abbreviation, name, options = {}) {
  role_id_seq++;
  return {
    abbreviation: abbreviation,
    background_color: options.background_color ? options.background_color : 7,
    beats_enabled: options.beats_enabled ? options.beats_enabled : true,
    color: options.color ? options.color : 0,
    id: role_id_seq,
    is_supervisor_job_type: options.is_supervisor_job_type
      ? options.is_supervisor_job_type
      : false,
    name: name,
    omit_job_type_from_reports: options.omit_job_type_from_reports
      ? options.omit_job_type_from_reports
      : false,
    ordinal_number: options.ordinal_number ? options.ordinal_number : 1,
    people: options.people ? options.people : [1],
    seniority_list: options.seniority_list ? options.seniority_list : "[1]"
  };
}
export function new_shift(
  day,
  local_day,
  start,
  end,
  job,
  location,
  shift_type,
  shift_time,
  options
) {
  shift_id_seq++;
  let period = start.year() + "-" + start.monthValue() + "-01";
  return {
    assets: options.assets ? options.assets : [],
    assigned_to: options.assigned_to_id ? options.assigned_to_id : null,
    changes: options.changes ? options.changes : [],
    day: day,
    local_day: local_day,
    end: end, // DT Object
    end_datetime: end.toString(),
    end_time: options.end_time ? options.end_time : null,
    group: options.group ? options.group : null,
    hard_coded: options.hard_coded ? options.hard_coded : true,
    id: shift_id_seq,
    is_extra_shift: options.is_extra_shift ? options.is_extra_shift : false,
    job: job,
    job_2: options.job_2 ? options.job_2 : null,
    job_3: options.job_3 ? options.job_3 : null,
    location: location,
    next_day_shift: false,
    notes: options.note ? options.note : "",
    period: period,
    permanent_shift_assignment: options.permanent_shift_assignment
      ? options.permanent_shift_assignment
      : null, //31,
    shift_time: shift_time,
    shift_time_id: shift_time,
    shift_type: shift_type,
    start: start, // DT Object
    start_datetime: start.toString(),
    start_time: options.start_time ? options.start_time : null
  };
}
export function new_change(
  shift,
  person,
  start,
  end,
  shift_change_type,
  options = {}
) {
  change_id_seq++;
  return {
    administrator_note: options.administrator_note
      ? options.administrator_note
      : null,
    approval_status: options.approval_status ? options.approval_status : 1,
    employee_1: person ? person.id : null,
    employee_2: options.person_2 ? options.person_2.id : null,
    end: end,
    end_datetime: end.toString(),
    first_level_administrator_note: options.first_level_administrator_note
      ? options.first_level_administrator_note
      : null,
    first_level_moderation_by: options.first_level_moderation_by
      ? options.first_level_moderation_by
      : null,
    first_level_moderation_time: options.first_level_moderation_time
      ? options.first_level_moderation_time
      : null,
    id: change_id_seq,
    moderation_by: options.moderation_by ? options.moderation_by : null,
    moderation_time: options.moderation_time ? options.moderation_time : null,
    num_minutes: start.until(end, ChronoUnit.MINUTES),
    opt_split_comp_time_num_minutes: options.opt_split_comp_time_num_minutes
      ? options.opt_split_comp_time_num_minutes
      : null,
    opt_split_overtime_num_minutes: options.opt_split_overtime_num_minutes
      ? options.opt_split_overtime_num_minutes
      : null,
    overtime_payment_type: options.overtime_payment_type
      ? options.overtime_payment_type
      : 0,
    payout_rate: options.payout_rate ? options.payout_rate : null,
    period: shift.period,
    person_time_off_bank: options.person_time_off_bank
      ? options.person_time_off_bank
      : null,
    proposed_by: options.proposed_by ? options.proposed_by : null,
    proposed_by_notes: options.proposed_by_notes
      ? options.proposed_by_notes
      : null,
    proposed_time: options.proposed_time ? options.proposed_time : null, // "2018-12-20T20:22:34.534483Z",
    released_to_employee: options.released_to_employee
      ? options.released_to_employee
      : null,
    shift_1: shift.id,
    shift_2: options.shift_2 ? options.shift_2.id : null,
    shift_change_to_update: options.shift_change_to_update
      ? options.shift_change_to_update
      : null,
    shift_change_type: shift_change_type,
    start: start,
    start_datetime: start.toString(),
    //overtime_payment_type: options.overtime_payment_type ? options.overtime_payment_type : null,
    submitted_to_ids: options.submitted_to_ids ? options.submitted_to_ids : "",
    time_off_type: options.time_off_type ? options.time_off_type : null,
    tt_form_response: options.tt_form_response ? options.tt_form_response : null
  };
}

export const custom_forms = {
  13: {
    elements: {
      37: {
        form: 13,
        form_element_choices: "",
        form_type: 1,
        id: 37,
        name: "Case Number",
        ordinal_number: 1
      }
    },
    id: 13
  },
  61: {
    elements: {
      148: {
        form: 61,
        form_element_choices: "",
        form_type: 1,
        id: 148,
        name: "Reason for OT",
        ordinal_number: 2
      }
    },
    id: 61
  },
  71: {
    elements: {
      132: {
        form: 71,
        form_element_choices: "",
        form_type: 1,
        id: 132,
        name: "Type of Sickness",
        ordinal_number: 1
      },
      133: {
        form: 71,
        form_element_choices: "",
        form_type: 1,
        id: 133,
        name: "Call-in Time",
        ordinal_number: 2
      }
    },
    id: 71
  }
};

export function new_shift_time(
  abbreviation,
  start_time,
  end_time,
  name,
  options = {}
) {
  shift_time_id_seq++;
  let now = LocalDate.now();
  let time_start = LocalDateTime.parse(now.toString() + "T" + start_time);
  let time_end = LocalDateTime.parse(now.toString() + "T" + end_time);
  let duration = time_start.until(time_end, ChronoUnit.SECONDS);
  return {
    abbreviation: abbreviation,
    end_time: end_time,
    id: shift_time_id_seq,
    start_time: start_time,
    name:
      start_time.replace(":", "").slice(0, -2) +
      "-" +
      end_time.replace(":", "").slice(0, -2) +
      " (" +
      name +
      ")",
    hours_paid: options.hours_paid ? options.hours_paid : null,
    code: options.code ? options.code : "",
    default_job_type: options.default_job_type ? options.default_job_type : 1,
    duration: duration,
    include_in_shift_coverage_count: options.include_in_shift_coverage_count
      ? options.include_in_shift_coverage_count
      : true,
    next_day_shift: options.next_day_shift ? options.next_day_shift : false,
    shift_count: options.shift_count ? options.shift_count : 1
  };
}

export const titles = {
  1: {
    abbreviation: "SGT",
    id: 1,
    name: "Sergeant",
    ordinal_number: 1
  },
  2: {
    abbreviation: "OFC",
    id: 2,
    name: "Officer",
    ordinal_number: 2
  },
  3: {
    abbreviation: "DET",
    id: 3,
    name: "Detective",
    ordinal_number: 3
  },
  4: {
    abbreviation: "CSO",
    id: 4,
    name: "Community Service Officer",
    ordinal_number: 4
  },
  5: {
    abbreviation: "DC",
    id: 5,
    name: "Deputy Chief",
    ordinal_number: 5
  },
  6: {
    abbreviation: "REC",
    id: 6,
    name: "Records",
    ordinal_number: 6
  }
};
