import * as Sentry from "@sentry/browser";

export function decycle(obj, stack = []) {
  if (!obj || typeof obj !== "object") return obj;

  if (stack.includes(obj)) return null;

  let s = stack.concat([obj]);

  return Array.isArray(obj)
    ? obj.map(x => decycle(x, s))
    : Object.fromEntries(
        Object.entries(obj).map(([k, v]) => [k, decycle(v, s)])
      );
}

export const setScope = (
  url = null,
  payload = null,
  api_response = null,
  json_response = null,
  form = null,
  request = null,
  shift_change = null,
  shift = null
) => {
  Sentry.configureScope(scope => {
    scope.setExtra("url", url);
    scope.setExtra("payload", payload);
    scope.setExtra("api_response", api_response);
    scope.setExtra("json_response", json_response);
    scope.setExtra("form", form);
    scope.setExtra("request", request);
    scope.setExtra("shift_change", shift_change);
    scope.setExtra("shift", shift);
  });
};
