export const BUCKET_SHIFTS = "BUCKET_SHIFTS";
export const CHECK_MIN_STAFFING = "CHECK_MIN_STAFFING";
export const CHECK_SHORTAGES = "CHECK_SHORTAGES";
export const DELETE_SHIFT = "DELETE_SHIFT";
export const DELETE_SHIFT_CHANGE = "DELETE_SHIFT_CHANGE";
export const DELETE_SHIFT_TIME_DAY_DETAIL = "DELETE_SHIFT_TIME_DAY_DETAIL";
export const QUEUE_SHIFT = "QUEUE_SHIFT";
export const SAVE_MIN_STAFFING_RISK = "SAVE_MIN_STAFFING_RISK";
export const APPEND_CHANGE_TO_SHIFT = "APPEND_CHANGE_TO_SHIFT";
export const SAVE_SHIFT = "SAVE_SHIFT";
export const SAVE_INITIAL_SHIFT = "SAVE_INITIAL_SHIFT";
export const SAVE_SHIFTS = "SAVE_SHIFTS";
export const SAVE_SHIFT_CHANGE = "SAVE_SHIFT_CHANGE";
export const SAVE_INITIAL_SHIFT_CHANGE = "SAVE_INITIAL_SHIFT_CHANGE";
export const SAVE_SHIFT_CHANGES = "SAVE_SHIFT_CHANGES";
export const SAVE_SHIFT_TIME_DAY_DETAIL = "SAVE_SHIFT_TIME_DAY_DETAIL";
export const SET_PROCESSING_SHORTAGE_REQUEST =
  "SET_PROCESSING_SHORTAGE_REQUEST";
export const SET_SHIFTS = "SET_SHIFTS";
export const SET_CHANGES = "SET_CHANGES";
export const SET_MIN_STAFFING_RISK = "SET_MIN_STAFFING_RISK";
export const UPDATE_MULTIPLE_SHIFTS = "UPDATE_MULTIPLE_SHIFTS";
export const UPDATE_SHIFT_IDS = "UPDATE_SHIFT_IDS";
export const UPDATE_PERSON_SHIFT_RANGE = "UPDATE_PERSON_SHIFT_RANGE";
export const UPDATE_SHIFT = "UPDATE_SHIFT";
export const UPDATE_SHIFT_CHANGE = "UPDATE_SHIFT_CHANGE";
export const UPDATE_SHIFT_TIME = "UPDATE_SHIFT_TIME";
export const UPDATE_SHIFT_TIME_DAY_DETAIL = "UPDATE_SHIFT_TIME_DAY_DETAIL";
export const UPDATE_MODERATORS = "UPDATE_MODERATORS";
export const RESET_STATE = "RESET_STATE";
export const SYNC_SHIFTS = "SYNC_SHIFTS";
export const SYNC_CHANGES = "SYNC_CHANGES";
