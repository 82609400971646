import {
  APPROVE,
  CANCEL,
  CANCEL_CONFIRM,
  CHECK_DELETE_TASK,
  CONFIRM,
  REJECT,
  SAVE_MIN_STAFFING_RISK,
  SAVE_TASKS,
  SELECT_REQUEST,
  SET_ADMIN_COMMENT,
  SET_CONFIRMING,
  SET_MIN_STAFFING_RISK,
  SET_MISSING_COMMENT,
  SET_PROCESSING,
  SET_PROCESSING_TYPE,
  SET_TASKS,
  UNSET_CONFIRMING,
  UNSET_MISSING_COMMENT
} from "./mutation_types";
//import Vue from "vue";
//import cloneDeep from "lodash.clonedeep";
import { Vueset } from "../../vuehack/reactivehack";
import { SET_ERRORS } from "../request/mutation_types";
import { del } from "../../vuehack/reactivehack";
import { SET_VALUE } from "../../mutation-types";

export default {
  async [SET_VALUE](state, payload) {
    Vueset(state, payload.key, payload.value);
  },
  [APPROVE](state, payload) {
    // TODO => ADD PERMISSION CHECK FOR ADMIN VS. FIRST_LEVEL APPROVAL
    Vueset(state.tasks[payload], "confirmationType", "approve");
    Vueset(state.tasks[payload], "confirming", true);
    Vueset(state, "tasks", state.tasks);
  },
  [CANCEL](state, payload) {
    Vueset(state.tasks[payload], "confirmationType", "cancel");
    Vueset(state.tasks[payload], "confirming", true);
    Vueset(state, "tasks", state.tasks);
  },
  [CANCEL_CONFIRM](state, payload) {
    Vueset(state.tasks[payload], "confirming", false);
    Vueset(state.tasks[payload], "confirmationType", null);
    Vueset(state, "tasks", state.tasks);
  },
  [CHECK_DELETE_TASK](state, payload) {
    let tasks = state.tasks;
    del(tasks, payload);
    Vueset(state, "tasks", tasks);
  },
  [CONFIRM](state, payload) {
    Vueset(state.tasks[payload], "confirming", true);
    Vueset(state, "tasks", state.tasks);
  },
  [REJECT](state, payload) {
    Vueset(state.tasks[payload], "confirmationType", "reject");
    Vueset(state.tasks[payload], "confirming", true);
    Vueset(state, "tasks", state.tasks);
  },
  [SAVE_MIN_STAFFING_RISK](state, payload) {
    Vueset(state.min_staffing_risk, payload.task_id, payload.data);
    Vueset(state, "min_staffing_risk", state.min_staffing_risk);
  },
  [SAVE_TASKS](state, payload) {
    let tasks = Object.values(payload.tasks);
    if (!tasks.length) {
      Vueset(state, "tasks", {});
      return;
    }
    for (let task of tasks) {
      // del(state.tasks, task);
      Vueset(state.tasks, task.id, {
        id: task.id,
        start: task.start,
        adminComment: "",
        confirmationType: null,
        confirming: false,
        missingComment: false,
        selectedRequests: []
      });
    }
    let task_ids = Object.keys(payload.tasks);
    for (let task of Object.values(state.tasks)) {
      if (!task_ids.includes(task.id.toString())) {
        del(state.tasks, task.id);
      }
    }
    Vueset(state, "tasks", state.tasks);
  },
  [SELECT_REQUEST](state, payload) {
    let selected_requests = state.tasks[payload.task_id].selectedRequests;

    if (selected_requests.includes(payload.request_id)) {
      selected_requests.splice(
        selected_requests.indexOf(payload.request_id),
        1
      );
    } else {
      selected_requests = selected_requests.concat(payload.request_id);
    }
    Vueset(state.tasks[payload.task_id], "selectedRequests", selected_requests);
    Vueset(state, "tasks", state.tasks);
  },
  [SET_ADMIN_COMMENT](state, payload) {
    Vueset(state.tasks[payload.task_id], "adminComment", payload.comment);
    Vueset(state, "tasks", state.tasks);
  },
  [SET_CONFIRMING](state, payload) {
    Vueset(state.tasks[payload], "confirming", true);
    Vueset(state, "tasks", state.tasks);
  },
  [SET_MISSING_COMMENT](state, payload) {
    Vueset(state.tasks[payload], "missingComment", true);
    Vueset(state, "tasks", state.tasks);
  },
  [SET_MIN_STAFFING_RISK](state, payload) {
    Vueset(state, "min_staffing_risk", payload);
  },
  [SET_PROCESSING](state, payload) {
    Vueset(state, "processing", payload);
  },
  [SET_PROCESSING_TYPE](state, payload) {
    Vueset(state, "processing_type", payload);
  },
  [SET_ERRORS](state, payload) {
    console.log("set errors", state, payload);
  },
  [SET_TASKS](state, payload) {
    Vueset(state, "tasks", payload);
  },
  [UNSET_CONFIRMING](state, payload) {
    Vueset(state.tasks[payload], "confirming", false);
    Vueset(state, "tasks", state.tasks);
  },
  [UNSET_MISSING_COMMENT](state, payload) {
    Vueset(state.tasks[payload], "missingComment", false);
    Vueset(state, "tasks", state.tasks);
  }
};
