// LIBS
import { store } from "./index";

export async function _getAPI(path, access_token = null) {
  return await fetch(`${store.state.api_url}/${path}/`, {
    headers: {
      Authorization: "Bearer " + access_token
    },
    method: "GET"
  })
    .then(async response => {
      const data = await response.json();
      return { success: response.ok, data: data };
    })
    .catch(error => {
      console.log(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.messages &&
        error.response.data.messages.length > 0
      ) {
        return { success: false, error: error.response.data.messages[0] };
      } else {
        return {
          success: false,
          error: error.response ? error.response : "An error occurred"
        };
      }
    });
}

export const getSchedule = (schedule_id, token) => {
  const response = _getAPI(`schedules/${schedule_id}`, token);
  if (response.success) {
    return response.data;
  }
  return;
};

/*
  This should all probably be part of vuex, changed to fetch for now.
 */
// POST CALLS
export async function _postAPI(path, data, access_token = null) {
  return await fetch(`${store.state.api_url}/${path}/`, {
    headers: {
      Authorization: "Bearer " + access_token
    },
    method: "POST",
    body: JSON.stringify(data)
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.messages &&
        error.response.data.messages.length > 0
      ) {
        return { success: false, error: error.response.data.messages[0] };
      } else {
        return { success: false, error: error.response.data };
      }
    });
}

// DELETE ACTION
// TAKES PRE-FORMATTED REQUEST OBJECT AND TOKEN
export const submitDeleteRequest = (requestObj, token) => {
  return _postAPI("request/shifts/delete", requestObj, token);
};

// TASK ACTION
// TAKES SHIFT_CHANGE_ID + MODERATOR_ID + ADMINISTRATOR_NOTE + APPROVAL_STATUS (0-3) + IP_ADDRESS
export const moderateTask = (actionObj, token) => {
  return _postAPI("request/moderate", actionObj, token);
};
