//import Vue from "vue";
import { LocalDate, ZoneId } from "@js-joda/core";
import {
  ADD_GROUP_SHIFT,
  DELETE_PERIOD_FROM_QUEUE,
  DELETE_QUEUED_PERIOD_DATA,
  INITIAL_PERIOD_LOADED,
  REMOVE_GROUP_SHIFT,
  REMOVE_SHIFT_FROM_GROUPS,
  SAVE_INITIAL_CLIENT,
  SAVE_PERIOD,
  SAVE_PHONE_NUMBERS,
  SAVE_SELECTED_ID,
  SAVE_SHORTAGES,
  SAVE_TIME_BANK_BALANCES,
  SET_DAY_NOTES,
  SET_GROUPS,
  SET_INITIAL_PERIOD_LOADED,
  SET_LOADING_REQUESTED,
  SET_LOCATIONS,
  SET_PEOPLE,
  SET_PERIOD_LIST,
  SET_PERIOD_QUEUE,
  SET_PERIODS,
  SET_QUEUED_PERIOD_DATA,
  SET_ROLES,
  SET_SCHEDULES,
  SET_SELECTED_ID,
  SET_SETTINGS,
  SET_SHIFT_TIMES,
  SET_TIME_BANK_BALANCES,
  SET_TIME_OFF_TYPES
} from "./mutation_types";
import {
  DELETE_SHIFT_TIME_DAY_DETAIL,
  SAVE_SHIFT_TIME_DAY_DETAIL
} from "../shifts/mutation_types";
import { Vueset, del } from "../../vuehack/reactivehack";
import {
  DELETE_MODEL,
  SAVE_MODEL,
  time_off_type_processor
} from "../../constants/update_urls";
import { SET_VALUE } from "../../mutation-types";
import { TIME_REGEX } from "../../constants/time";
const us_central = "US/Central";
const us_eastern = "US/Eastern";
const us_mountain = "US/Mountain";
const us_pacific = "US/Pacific";
const us_alaska = "US/Alaska";
const us_hawaii = "US/Hawaii";
const matchAll = require("string.prototype.matchall");
matchAll.shim();
export const timezone_id_map = {
  1: us_eastern,
  2: us_central,
  3: us_mountain,
  4: us_pacific,
  5: us_alaska,
  6: us_hawaii,
  us_hawaii: 6,
  us_alaska: 5,
  us_pacific: 4,
  us_mountain: 3,
  us_central: 2,
  us_eastern: 1
};
export default {
  async [REMOVE_SHIFT_FROM_GROUPS](state, payload) {
    if (!payload.shift_id) return;
    for (const group_index in state.groups) {
      const group = state.groups[group_index];
      if (!group || !group.shift_ids) continue;
      if (group.shift_ids.includes(payload.shift_id)) {
        for (let i = 0; i <= group.length - 1; i++) {
          if (group[i] === payload.shift_id) {
            group.splice(i, 1);
          }
        }
        Vueset(state.groups, group.id, group);
      }
    }
    for (const person_index in state.people) {
      const person = state.people[person_index];
      if (!person) continue;
      if (!person.shift_ids) {
        person.shift_ids = [];
      }
      if (person.shift_ids.includes(payload.shift_id)) {
        for (let i = 0; i <= person.length - 1; i++) {
          if (person[i] === payload.shift_id) {
            person.splice(i, 1);
          }
        }
        Vueset(state.people, person.id, person);
      }
    }
  },
  async [REMOVE_GROUP_SHIFT](state, payload) {
    const group = state.groups[payload.group_id];
    if (group.shift_ids.includes(payload.shift_id)) {
      for (let i = 0; i <= group.length - 1; i++) {
        if (group[i] === payload.shift_id) {
          group.splice(i, 1);
        }
      }
      Vueset(state.groups, group.id, group);
    }
  },
  async [ADD_GROUP_SHIFT](state, payload) {
    //console.log(ADD_GROUP_SHIFT, payload);
    if (!payload) return;
    if (payload.group_id) {
      const group = state.groups[payload.group_id];
      //console.log(group);
      if (group && !group.shift_ids.includes(payload.shift_id)) {
        group.shift_ids.push(payload.shift_id);
        Vueset(state.groups, group.id, group);
      }
    }
    if (payload.assigned_to) {
      const person = state.people[payload.assigned_to];
      if (
        person &&
        person.shift_ids &&
        !person.shift_ids.includes(payload.shift_id)
      ) {
        person.shift_ids.push(payload.shift_id);
        Vueset(state.people, person.id, person);
      }
    }
  },
  [DELETE_QUEUED_PERIOD_DATA](state, value) {
    del(state.queued_period_data, value);
    Vueset(state, "queued_period_data", state.queued_period_data);
  },
  [DELETE_PERIOD_FROM_QUEUE](state, payload) {
    if (state.period_queue.includes(payload)) {
      for (let i = 0; i <= state.period_queue.length - 1; i++) {
        if (state.period_queue[i] === payload) {
          state.period_queue.splice(i, 1);
        }
      }
    }
    Vueset(state, "period_queue", state.period_queue);
  },
  [SAVE_SELECTED_ID](state, value) {
    Vueset(state, "selected_id", value);
  },
  [INITIAL_PERIOD_LOADED](state) {
    Vueset(state, "initial_period_loaded", true);
  },
  [SAVE_INITIAL_CLIENT](state, value) {
    //console.log(value);
    Vueset(state, "id", value.id);
    Vueset(state, "timezone_id", value.timezone_id);
    let banks = {};
    for (let tb of Object.values(value.banks)) {
      banks[parseInt(tb.id)] = tb;
    }
    Vueset(state, "banks", banks);
    Vueset(state, "custom_forms", value.custom_forms);
    for (const custom_form_id in value.custom_forms) {
      Vueset(
        state["custom_forms"],
        custom_form_id,
        state["custom_forms"][custom_form_id]
      );
      Vueset(
        state["custom_forms"][custom_form_id],
        "elements",
        state["custom_forms"][custom_form_id]["elements"]
      );
    }
    Vueset(state, "custom_forms", value.custom_forms);
    Vueset(state, "name", value.name);
    let time_bank_balances = {};
    for (const person_index in value.people) {
      const person = value.people[person_index];
      let person_time_bank_balances = person.time_bank_balances;
      let person_banks_to_client_banks_mapping = {};
      let client_bank_balances = {};
      for (const key in person_time_bank_balances) {
        const balance = person_time_bank_balances[key];
        person_banks_to_client_banks_mapping[key] = balance.time_off_bank_id;
        client_bank_balances[balance.time_off_bank_id] = balance;
      }
      person.time_bank_balances = client_bank_balances;
      person.time_bank_mapping = person_banks_to_client_banks_mapping;
      time_bank_balances[parseInt(person.id)] = {};
      person.shift_ids = [];
    }
    Vueset(state, "time_bank_balances", time_bank_balances);
    Vueset(state, "people", value.people);
    Vueset(state, "day_notes", {});
    Vueset(state, "time_off_types", {});
    Vueset(state, "groups", {});
    Vueset(state, "locations", {});
    Vueset(state, "schedules", {});
    Vueset(state, "shift_times", {});
    Vueset(state, "smart_shift_time_names", {});
    Vueset(state, "periods", {});
    Vueset(state, "titles", value.titles);
    Vueset(state, "url", value.url);
    Vueset(state, "assets", value.assets);
    Vueset(state, "subdomain", value.subdomain);
    let first_bank = Object.values(value.banks)[0];
    let tradeable_benefit_time_bank = null;
    if (first_bank) {
      tradeable_benefit_time_bank = first_bank.id;
    }
    Vueset(state, "skin", value.skin);
    Vueset(state, "calendar_display_type", value.calendar_display_type);
    let holdover_choices = value.holdover_choices;
    let client_min_mapping = {
      shift_time: value.minute_mapping_enabled,
      time_off: value.minute_mapping_enabled,
      assignments: value.minute_mapping_enabled,
      overtime: value.minute_mapping_enabled
    };
    Vueset(state, "period_list", []); //[period];
    let timezone = timezone_id_map[state.timezone_id];
    const deadline =
      value.extra_shift_deadline !== undefined && value.extra_shift_deadline
        ? value !== ""
          ? parseInt(value.extra_shift_deadline)
          : null
        : null;
    Vueset(state, "settings", {
      calendar_viewable_by_non_supervisors:
        value.calendar_viewable_by_non_supervisors !== undefined
          ? value.calendar_viewable_by_non_supervisors
          : true,
      check_for_conflicts_enabled: value.check_for_conflicts_enabled,
      accrual_decimal_places: value.accrual_decimal_places,
      all_schedules_enabled: value.all_schedules_enabled,
      extra_shift_claim_minimum_minutes:
        value.extra_shift_claim_mininum_minutes, // Spelling error on purpose, don't change unless serializer and client column fixed in db.
      //Integer field that specifies the minimum number of minutes a person
      // can claim via the Extra Shifts Claim form. If this is set to None or
      // 0, this means there is no minimum length.

      timezone: ZoneId.of(timezone),
      timebanks: value.accrual_module_enabled,
      next_day_shifts_enabled: value.next_day_shifts_enabled,
      comp_time_enabled: value.comp_time_enabled,
      overtime_enabled: value.overtime_enabled,
      am_pm: false,
      grid_views_enabled: value.grid_views_enabled,
      show_roles_on_calendar: value.show_roles_on_calendar,
      mapping: client_min_mapping,
      on_call_enabled: value.on_call_enabled,
      calendar_day_offset: value.calendar_day_offset,
      calendar_type: parseInt(value.calendar_type),
      holdover_length_minute_increment: value.holdover_length_minute_increment,
      shift_length_minute_increment: value.shift_length_minute_increment,
      daily_shifts_include_assignments: value.daily_shifts_include_assignments,
      daily_shifts_include_assets: value.daily_shifts_include_assets,
      create_empty_shifts_enabled: value.create_empty_shifts_enabled,
      only_admin_sees_shift_notes: value.only_admins_see_shift_notes,
      change_requests_enabled: value.change_requests_enabled,
      partial_shift_change_requests_enabled:
        value.partial_shift_change_requests_enabled,
      change_requests_require_comment_for_rejection:
        value.change_requests_require_comment_for_rejection,
      change_requests_modify_enabled: value.change_requests_modify_enabled,
      change_requests_rescind_enabled: value.change_requests_rescind_enabled,
      additional_time_name: value.additional_time_name,
      assignment_name: value.assignment_name,
      special_assignments_name: value.special_assignment_name,
      request_overtime_as_comp_time_enabled:
        value.request_overtime_as_comp_time_enabled,
      holdover_enabled: value.holdover_enabled,
      holdover_name: value.holdover_name,
      holdover_choices: holdover_choices,
      extra_shift_deadline: deadline,
      change_request_types: {
        assignment: value.beat_assignment_module_enabled,
        partial_cover: value.change_requests_partial_cover_enabled,
        partial_trade: value.change_requests_partial_trade_enabled,
        benefit_time_trade: false,
        claim: value.change_requests_claim_enabled,
        comp_time: value.change_requests_comp_time_enabled,
        trade: value.change_requests_trade_enabled,
        holdover: value.change_requests_holdover_enabled,
        holdover_overtime: value.holdover_overtime_configuration,
        overtime: value.change_requests_overtime_enabled,
        change: value.change_requests_enabled,
        release: value.change_requests_release_enabled,
        release_help_text: value.request_release_help_text,
        cover: value.change_requests_cover_enabled,
        // Client.additional_time_enabled refers to whether Addtional Time is enabled at all.
        //    Whether additional time is enabled for non-admin requests is determined by Client.change_requests_claim_enabled.
        additional_time: value.additional_time_enabled,
        additional_time_assignments: value.additional_time_assignments_enabled,
        additional_time_overtime: value.overtime_enabled,
        additional_time_special_assignments: value.special_assignment_enabled,
        special_assignment: value.change_requests_special_assignment_enabled,
        time_off: value.change_requests_time_off_enabled,
        tradeable_benefit_time_bank: tradeable_benefit_time_bank
      },

      change_requests_claim_approval_process:
        value.change_requests_claim_approval_process,
      change_requests_cover_approval_process:
        value.change_requests_cover_approval_process,
      change_requests_release_approval_process:
        value.change_requests_release_approval_process,
      change_requests_trade_approval_process:
        value.change_requests_trade_approval_process,
      change_requests_time_off_approval_process:
        value.change_requests_time_off_approval_process,
      change_requests_special_assignment_approval_process:
        value.change_requests_special_assignment_approval_process,
      change_requests_overtime_approval_process:
        value.change_requests_overtime_approval_process,
      change_requests_comp_time_approval_process:
        value.change_requests_comp_time_approval_process,
      change_requests_holdover_approval_process:
        value.change_requests_holdover_approval_process,
      change_requests_modify_approval_process:
        value.change_requests_modify_approval_process,
      change_requests_rescind_approval_process:
        value.change_requests_rescind_approval_process
    });
    Vueset(state, "settings", state.settings);
    //console.log(state);
  },
  // eslint-disable-next-line no-unused-vars
  [SAVE_SHORTAGES](state, payload) {
    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!save shortages");
  },
  async [DELETE_SHIFT_TIME_DAY_DETAIL](state, payload) {
    for (
      let i = 0;
      i <= state.periods[payload.first_day_of_month].notes.length - 1;
      i++
    ) {
      if (
        state.periods[payload.first_day_of_month].notes[i] ===
        payload.shift_time_day_detail_id
      ) {
        state.periods[payload.first_day_of_month].notes.splice(i, 1);
      }
    }
    del(state.day_notes, payload.shift_time_day_detail_id);
  },
  async [SAVE_PERIOD](state, payload) {
    let period = Object.keys(payload)[0];
    const local_date_period = LocalDate.parse(period);
    let periods = Object.assign({}, Object.freeze(Object.values(payload)[0]));
    let p = {};
    let notes = [];
    for (const note_index in periods.notes) {
      const note = periods.notes[note_index];
      //console.log(note, note.cc_groups);
      notes.push(note.id);
      note.period = local_date_period;
      note.day = LocalDate.parse(note.day);
      let groups = [];
      let split_groups = note.cc_groups.split(",");
      if (split_groups.length && split_groups[0].length) {
        //console.log(split_groups);
        for (let group_index in split_groups) {
          const group = split_groups[group_index];
          groups.push(parseInt(group));
        }
        note.groups = groups;
      } else if (note.cc_groups.length) {
        note.groups = parseInt(note.cc_groups);
      } else {
        note.groups = [];
      }
      Vueset(state.day_notes, note.id, note);
    }
    periods.notes = notes;
    let shift_ids = Object.keys(periods.shifts).map(Number);
    let change_ids = Object.keys(periods.shift_changes).map(Number);
    let role_ids = Object.keys(periods.roles).map(Number);
    let group_ids = Object.keys(periods.groups).map(Number);
    let location_ids = Object.keys(periods.locations).map(Number);
    let schedule_ids = Object.keys(periods.schedules).map(Number);
    let shift_time_ids = Object.keys(periods.shift_times).map(Number);
    let time_off_type_ids = Object.keys(periods.time_off_types).map(Number);

    for (const key in periods.time_off_types) {
      let value = periods.time_off_types[key];
      value = time_off_type_processor(value);
      Vueset(state.time_off_types, key, value);
    }
    for (let [key, value] of Object.entries(
      //TODO: Investigate spread operator impact.
      Object.values(periods.roles).reduce(
        (acc, curr) => ({ ...acc, [curr.id]: curr }),
        []
      )
    )) {
      Vueset(state.roles, key, value);
    }
    for (const key in periods.groups) {
      const value = periods.groups[key];
      let group_shift_times = [];
      if (value.shift_times) {
        let split_shift_times = value.shift_times.split(",");
        if (split_shift_times.length) {
          for (const shift_time_index in split_shift_times) {
            const shift_time = split_shift_times[shift_time_index];
            group_shift_times.push(parseInt(shift_time));
          }
        } else {
          if (value.shift_times !== "") {
            group_shift_times.push(parseInt(value.shift_times));
          }
        }
      }
      value.shift_times = group_shift_times;
      let group_policies = [];
      if (value.policies !== undefined && value.policies.length) {
        for (const group_policy_index in value.policies) {
          const group_policy = value.policies[group_policy_index];
          let requirements = [];
          if (group_policy.requirements) {
            for (const requirement_index in group_policy.requirements) {
              const requirement = group_policy.requirements[requirement_index];
              let req_job_types = [];

              if (requirement.cc_job_types) {
                let split_job_types = requirement.cc_job_types.split(",");
                if (split_job_types.length) {
                  for (const job_type_index in split_job_types) {
                    const job_type = split_job_types[job_type_index];
                    req_job_types.push(parseInt(job_type));
                  }
                } else {
                  if (requirement.cc_job_types !== "") {
                    req_job_types.push(parseInt(requirement.cc_job_types));
                  }
                }
              }
              requirement.cc_job_types = req_job_types;
              requirements.push(requirement);
            }
          }
          group_policy.requirements = requirements;
          group_policies.push(group_policy);
        }
      }
      value.policies = group_policies;
      value.shift_ids = [];
      Vueset(state.groups, key, value);
    }
    for (const key in periods.locations) {
      const value = periods.locations[key];
      Vueset(state.locations, key, value);
    }
    for (const key in periods.schedules) {
      let value = periods.schedules[key];
      let valid_groups = [];
      let cc_restricted_people = [];
      for (const group_index in value.groups) {
        const group = value.groups[group_index];
        if (group_ids.includes(group)) valid_groups.push(group);
      }
      if (value.cc_restricted_people) {
        let split_people = value.cc_restricted_people.split(",");
        if (split_people.length) {
          for (const person_index in split_people) {
            const person = parseInt(split_people[person_index]);
            cc_restricted_people.push(person);
          }
        } else {
          if (value.cc_restricted_people !== "") {
            cc_restricted_people.push(parseInt(value.cc_restricted_people));
          }
        }
      }
      value.groups = valid_groups;

      value.cc_restricted_people = cc_restricted_people;
      Vueset(state.schedules, key, value);
    }
    for (const key in periods.shift_times) {
      let value = periods.shift_times[key];
      //console.log(key, value);
      Vueset(state.shift_times, key, value);
      if (state.smart_shift_time_names[value.name]) continue;
      let k9 = false;
      let search_string = value.name;
      if (search_string.toLowerCase().includes("k9")) {
        k9 = true;
        search_string = search_string.replace("k9", "").trim();
        search_string = search_string.replace("K9", "").trim();
      }
      if (!search_string.length) {
        // K9 only name
        Vueset(state.smart_shift_time_names, value.name, "K9");
        continue;
      }
      //console.log(search_string);
      let result = search_string.matchAll(TIME_REGEX);
      let start_time_detected = false;
      //console.log(result);
      for (let r of result) {
        //let r = result[r_index];
        if (process.env.NODE_ENV !== "production") console.log("r", r, value);
        if (!r) continue;
        let start_time_found = null;

        let am = true;
        if (r[5]) {
          let lowered_time_suffix = r[5].toLowerCase();
          if (
            lowered_time_suffix === "p" ||
            lowered_time_suffix === "pm" ||
            lowered_time_suffix === "p.m."
          ) {
            am = false;
          }
        }
        if (r[2] && r[2].length === 4) {
          if (r[2].includes(":")) {
            if (am) {
              start_time_found =
                "0" + r[2][0] + ":" + r[2][2] + r[2][3] + ":00";
            } else {
              start_time_found =
                12 + parseInt(r[2][0]) + ":" + r[2][2] + r[2][3] + ":00";
            }
          } else {
            start_time_found =
              r[2][0] + r[2][1] + ":" + r[2][2] + r[2][3] + ":00";
          }
        }
        if (r[2] && r[2].length === 1) {
          if (am) {
            start_time_found = "0" + r[2] + ":00:00";
          } else {
            start_time_found = 12 + parseInt(r[2]) + ":00:00";
          }
        }
        if (r[2] && r[2].length === 2) {
          start_time_found = r[2] + ":00:00";
        }
        if (r[2] && r[2].length === 3) {
          if (am) {
            start_time_found =
              "0" + r[2][0] + ":" + r[2][1] + "" + r[2][2] + ":00";
          } else {
            start_time_found =
              12 + parseInt(r[2][0]) + ":" + r[2][1] + "" + r[2][2] + ":00";
          }
        }
        if (r[2] && r[2].length === 5) {
          if (am) {
            start_time_found = r[2] + ":00";
          } else {
            start_time_found = r[2] + ":00";
          }
        }
        if (start_time_found === value.start_time) {
          start_time_detected = true;
        }
        if (!start_time_detected) {
          if (r[2] && r[2].length === 1) {
            start_time_found = 12 + parseInt(r[2]) + ":00:00";
          }
          if (r[2] && r[2].length === 3) {
            start_time_found =
              12 + parseInt(r[2][0]) + ":" + r[2][1] + "" + r[2][2] + ":00";
          }
          if (start_time_found === value.start_time) {
            start_time_detected = true;
          }
        }
        if (start_time_detected) {
          let stripped_value = search_string.replace(r[0], "");
          stripped_value = stripped_value.replace("(", "");
          stripped_value = stripped_value.replace(")", "");
          Vueset(
            state.smart_shift_time_names,
            value.name,
            k9
              ? "K9" +
                  (stripped_value.trim() !== ""
                    ? " " + stripped_value.trim()
                    : "")
              : stripped_value.trim()
          );
          //console.log('strip', stripped_value)
        }
      }
      if (!result || !start_time_detected) {
        //console.log('not found', value);
        Vueset(state.smart_shift_time_names, value.name, value.name);
      }
    }
    Vueset(state, "shift_times", state.shift_times);
    periods.roles = role_ids;
    periods.time_off_types = time_off_type_ids;
    periods.groups = group_ids;
    periods.locations = location_ids;
    periods.schedules = schedule_ids;
    periods.shift_times = shift_time_ids;
    periods.shifts = shift_ids;
    periods.changes = change_ids;
    p[period] = periods;
    Vueset(state.periods, period, periods);
    state.period_list.push(period);
    Vueset(state, "period_list", state.period_list);
    Vueset(state, "periods", state.periods);
  },
  async [SAVE_PHONE_NUMBERS](state, payload) {
    Vueset(state.person_phone_numbers, payload.person, payload.numbers);
    Vueset(state, "person_phone_numbers", state.person_phone_numbers);
  },
  async [SAVE_SHIFT_TIME_DAY_DETAIL](state, payload) {
    payload.data.period = payload.first_day_of_month;

    let groups = [];
    let split_groups = payload.data.cc_groups.split(",");
    if (split_groups.length && split_groups[0].length) {
      for (const group_index in split_groups) {
        const group = split_groups[group_index];
        groups.push(parseInt(group));
      }
      payload.data.groups = groups;
    } else if (payload.data.cc_groups.length) {
      payload.data.groups = parseInt(payload.data.cc_groups);
    } else {
      payload.data.groups = [];
    }
    payload.data.day = LocalDate.parse(payload.data.day);
    Vueset(state.day_notes, payload.shift_time_day_detail_id, payload.data);

    Vueset(state, "day_notes", state.day_notes);
    const period = state.periods[payload.first_day_of_month];
    if (!period) return;
    let has_note_already =
      period && period.notes
        ? Object.values(period.notes).filter(note_id => {
            return note_id === payload.shift_time_day_detail_id;
          }).length > 0
        : false;
    if (!has_note_already && period) {
      if (period.notes) {
        period.notes.push(payload.shift_time_day_detail_id);
      } else {
        period.notes = [payload.shift_time_day_detail_id];
      }
    }
    Vueset(state.periods[payload.first_day_of_month], "notes", period.notes);
  },
  async [SAVE_TIME_BANK_BALANCES](state, payload) {
    for (const data_index in payload.data) {
      const data = payload.data[data_index];
      if (state.time_bank_balances[parseInt(data.person_id)]) {
        Vueset(
          state.time_bank_balances[parseInt(data.person_id)],
          data.current_day,
          data.balances
        );
      }
    }
    Vueset(state, "time_bank_balances", state.time_bank_balances);
  },
  async [SET_LOADING_REQUESTED](state, payload) {
    state.period_queue.push(payload.period);
    Vueset(state, "period_queue", state.period_queue);
    Vueset(state.queued_period_data, payload.period, payload);
  },
  /*
  async [UPDATE_TIME_OFF_TYPE](state, payload) {
    //del(state.time_off_types, parseInt(payload.id));
    Vueset(state.time_off_types, parseInt(payload.id), payload);
  },*/
  async [SET_SELECTED_ID](state, payload) {
    Vueset(state, "selected_id", payload);
  },
  async [SET_GROUPS](state, payload) {
    Vueset(state, "groups", payload);
  },
  async [SET_LOCATIONS](state, payload) {
    Vueset(state, "locations", payload);
  },
  async [SET_PEOPLE](state, payload) {
    Vueset(state, "people", payload);
  },
  async [SET_PERIODS](state, payload) {
    Vueset(state, "periods", payload);
  },
  async [SET_ROLES](state, payload) {
    Vueset(state, "roles", payload);
  },
  async [SET_SCHEDULES](state, payload) {
    Vueset(state, "schedules", payload);
  },
  async [SET_SHIFT_TIMES](state, payload) {
    Vueset(state, "shift_times", payload);
  },
  async [SET_TIME_OFF_TYPES](state, payload) {
    Vueset(state, "time_off_types", payload);
  },
  async [SET_SETTINGS](state, payload) {
    Vueset(state, "settings", payload);
  },
  async [SET_DAY_NOTES](state, payload) {
    Vueset(state, "day_notes", payload);
  },
  async [SET_PERIOD_QUEUE](state, payload) {
    Vueset(state, "period_queue", payload);
  },
  async [SET_QUEUED_PERIOD_DATA](state, payload) {
    Vueset(state, "queued_period_data", payload);
  },
  async [SET_PERIOD_LIST](state, payload) {
    Vueset(state, "period_list", payload);
  },
  async [SET_TIME_BANK_BALANCES](state, payload) {
    Vueset(state, "time_bank_balances", payload);
  },
  async [SET_INITIAL_PERIOD_LOADED](state, payload) {
    Vueset(state, "initial_period_loaded", payload);
  },
  async [SET_VALUE](state, payload) {
    Vueset(state, payload.key, payload.value);
  },
  async [SAVE_MODEL](state, payload) {
    await payload.model_update_data.update_mutation(state, payload);
  },
  async [DELETE_MODEL](state, payload) {
    await payload.model_update_data.delete_mutation(state, payload);
  }
};
