export const REQUEST_TYPES = {
  change: [8, 9],
  release: [3],
  cover: [1, 5],
  trade: [0, 4],
  "open shift": [2, 6],
  holdover: [7, 10],
  modify: [11],
  rescind: [12]
};

export const PAY_PREFERENCE_TYPES = { paid: 0, time: 1, split: 2 };
export const PAY_PREFERENCE_PAID = 0;
export const PAY_PREFERENCE_TIME = 1;
export const PAY_PREFERENCE_SPLIT = 2;
