import {
  APPROVAL_APPROVED,
  CHANGE_TYPE_MODIFY,
  CHANGE_TYPE_RESCIND,
  SHIFT_CHANGE_TIME_OFF_TYPE_OVERTIME
} from "../../constants/shiftChange";
import { _duration, _now } from "../../time";
import { SO_ANY } from "../../constants/shift";
import { Duration } from "@js-joda/core";

export default {
  customShiftLengthHours: state => {
    if (!state.selectedDate) return 0;
    let start = state.selectedDate
      .withHour(state.shiftOtherTimeStart["HH"])
      .withMinute(state.shiftOtherTimeStart["mm"]);
    let end = state.selectedDate
      .withHour(state.shiftOtherTimeEnd["HH"])
      .withMinute(state.shiftOtherTimeEnd["mm"]);
    if (start.isAfter(end)) {
      end.plusHours(24);
    }
    let duration = _duration(start, end);
    return Math.round((duration / 60) * 100) / 100;
  },
  customShiftChangeLengthHours: (state, getters, rootState, rootGetters) => {
    let extra_request = rootState.request.requestType === "extra";
    if (!extra_request) {
      if (!state.selectedDate) return 0;
      if (!state.shiftChangeEnd || !state.shiftChangeStart) return 0;
      let start = state.selectedDate
        .withHour(state.shiftChangeStart["HH"])
        .withMinute(state.shiftChangeStart["mm"]);
      let end = state.selectedDate
        .withHour(state.shiftChangeEnd["HH"])
        .withMinute(state.shiftChangeEnd["mm"]);
      if (start.isAfter(end)) {
        end.plusHours(24);
      }
      let duration = _duration(start, end);
      return Math.round((duration / 60) * 100) / 100;
    }

    let shift = rootGetters["shifts/requestShift"];
    let start = rootState.form.customTimeStart;
    let end = rootState.form.customTimeEnd;
    //console.log("here", shift, start.toString(), end.toString())
    if (!start) return 0;
    if (!end) return 0;
    if (start.isBefore(shift.start)) {
      start = start.plusHours(24);
    } else if (end.isBefore(start)) {
      end = end.plusHours(24);
    }
    let duration = _duration(start, end);
    return Math.round((duration / 60) * 100) / 100;
  },
  // eslint-disable-next-line no-unused-vars
  changeLength: (state, getters, rootState, rootGetters) => {
    //console.log("changeLength")
    let extra_request = rootState.request.requestType === "extra";
    if (state.isExtraShift || extra_request) {
      let shift = rootGetters["shifts/requestShift"];
      if (
        state.selectedShiftTime === "other" ||
        state.changeTimeSelected === "partial" ||
        (extra_request && shift.extra_shift_split_options === SO_ANY) ||
        state.splitOptionsSelected.length
      ) {
        if (
          state.changeTimeSelected === "partial" ||
          (extra_request && shift.extra_shift_split_options === SO_ANY)
        ) {
          return getters.customShiftChangeLengthHours * 60;
        } else if (state.splitOptionsSelected.length) {
          let total_length = 0;
          for (const option of state.splitOptionsSelected) {
            let option_duration = Duration.between(
              option.start,
              option.end
            ).toMinutes();
            total_length += option_duration;
          }
          return total_length;
        } else {
          return getters.customShiftLengthHours * 60;
        }
      } else {
        let shift_time = rootState.client.shift_times[state.selectedShiftTime];
        if (!shift_time) {
          let shift = rootGetters["shifts/requestShift"];
          if (shift) {
            shift_time = rootState.client.shift_times[shift.shift_time];
          } else {
            return 0;
          }
        }
        if (!shift_time) return 0;
        return Math.round((shift_time.duration / 60) * 100) / 100;
      }
    }
    if (rootState.request.requestType !== "claim") {
      //console.log("changeLength - not claim", rootState.request.requestType)
      if (rootState.request.requestType === "holdover") {
        return state.holdoverLength;
      }
      if (getters.changeTime === "full") {
        //console.log("changeLength - full")
        return getters.shiftDuration;
      } else {
        if (!getters.customTimeStart) {
          //console.log("changeLength - not custom time start")
          return getters.shiftDuration;
        }
        //console.log("changeLength - customTimeDuration")
        return getters.customTimeDuration;
      }
    } else {
      if (state.claimShiftTime === "custom_time") {
        return getters.customTimeDuration;
      } else if (state.claimShiftTime === "all_day") {
        return 24 * 60;
      } else if (
        getters.claimShiftTime !== null &&
        getters.claimShiftTime !== ""
      ) {
        return (
          rootState.client.shift_times[getters.claimShiftTime].duration / 60
        );
      } else {
        return Object.values(rootState.client.shift_times)[0].duration / 60;
      }
    }
  },
  // eslint-disable-next-line no-unused-vars
  changeLengthHours: (state, getters) => {
    //console.log('change length hours', getters.changeLength)
    const hours = Math.round((getters.changeLength / 60) * 100) / 100;
    if (hours > 24) {
      return hours - 24;
    }
    return hours;
  },
  changeTime: (state, getters, rootState, rootGetters) => {
    //console.log("changeLength")
    //console.log("changeLength - is extra")
    let shift = rootGetters["shifts/requestShift"];
    //console.log(state.changeTimeSelected, state);

    let cstart = rootState.form.customTimeStart;
    let cend = rootState.form.customTimeEnd;
    if (
      (shift &&
        shift.extra_shift_split_options === SO_ANY &&
        (!shift.start.equals(cstart) || !shift.end.equals(cend))) ||
      state.splitOptionsSelected.length
    ) {
      return "partial";
    }
    return state.changeTimeSelected;
  },
  changeTimeInvalid: state => {
    return !state.changeTimeSelected;
  },
  // eslint-disable-next-line no-unused-vars
  changeTimeVisible: (state, getters, rootState, rootGetters) => {
    return (
      rootState.client.settings.partial_shift_change_requests_enabled &&
      !["rescind", "modify"].includes(state.changeTypeSelected)
    );
  },
  changeTimeOffTypeVisible: (state, getters) => {
    let change_type_full = getters.changeType === "full";
    return (
      !["rescind", "modify"].includes(state.changeTypeSelected) &&
      (change_type_full || (!getters.invalidStart && !getters.invalidEnd))
    ); // && this.requestSettings   (Not really sure what this had to do with anything.)
  },
  changeType: state => {
    return state.changeTypeSelected;
  },
  // eslint-disable-next-line no-unused-vars
  changeTypeVisible: (state, getters, rootState, rootGetters) => {
    let shift = rootGetters["shifts/requestShift"];
    if (shift === undefined) return false;
    return (
      shift.changes &&
      Object.values(shift.changes).length > 0 &&
      (getters.modifiable || getters.rescindable)
    );
  },
  claimShiftTime: state => {
    return state.claimShiftTime;
  },
  claimShiftType: state => {
    return state.claimShiftType;
  },
  // eslint-disable-next-line no-unused-vars
  claimShiftTypeVisible: (state, getters, rootState, rootGetters) => {
    return (
      rootState.client.settings.on_call_enabled &&
      (rootGetters["auth/isAdmin"] || rootGetters["auth/isSupervisor"])
    );
  },
  claimTimeOffTypeVisible: (state, getters, rootState /*, rootGetters*/) => {
    //let shift = rootGetters["shifts/requestShift"];
    let request_settings = rootState.client.settings.change_request_types;
    if (state.claimShiftType === "on-call") return false;
    if (state.claimShiftTime === null) return false;
    if (state.claimShiftTime === "") return false;
    return (
      request_settings.additional_time_overtime ||
      request_settings.additional_time_special_assignments
    );
  },
  customForm: state => {
    return state.customForm;
  },
  customFormData: state => element_id => {
    let data = state.customFormData[element_id];
    if (data === undefined) return null;
    return data;
  },
  customTimeEnd: state => {
    return state.customTimeEnd;
  },
  customTimeInvalid: state => {
    return state.invalidTimeStart || state.invalidTimeEnd;
  },
  // eslint-disable-next-line no-unused-vars
  customTimeDuration: (state, getters, rootState, rootGetters) => {
    if (!state.customTimeStart || !state.customTimeEnd) return 0;
    return _duration(
      state.customTimeStart, //ZonedDateTime.parse(state.customTimeStart),
      state.customTimeEnd //ZonedDateTime.parse(state.customTimeEnd)
    ); //.asMinutes();
  },
  customTimeStart: state => {
    return state.customTimeStart;
  },
  invalidChangeTimeOffType: state => {
    return (
      (!state.singleTimeOffType &&
        (state.timeOffTypeTimeRemaining !== 0.0 ||
          state.timeOffTypesSelected.length === 0)) ||
      (state.singleTimeOffType &&
        state.timeOffTypeSelected === 0 &&
        !["modify", "rescind"].includes(state.changeTypeSelected))
    );
  },
  // eslint-disable-next-line no-unused-vars
  invalidClaimTimeOffType: state => {
    return false;
  },
  invalidShiftClaimTime: state => {
    if (!state.customTimeStart) return true;
    if (!state.customTimeEnd) return true;
    let change_start = state.customTimeStart;
    let change_end = state.customTimeEnd;
    return !change_start || !change_end || change_start.isEqual(change_end);
  },
  holdoverLength: state => {
    return state.holdoverLength;
  },
  holdoverLengthInvalid: state => {
    return state.holdoverLength === 0;
  },
  invalidStart: state => {
    return state.invalidTimeStart;
  },
  invalidEnd: state => {
    return state.invalidTimeEnd;
  },
  timeOffTypeSelected: state => {
    return state.timeOffTypeSelected;
  },
  modChange: state => {
    return state.modChange;
  },
  modChangeVisible: (state, getters) => {
    return ["rescind", "modify"].includes(getters.changeType);
  },
  modChangeInvalid: state => {
    return state.modChange === 0;
  },
  modifyOriginal: (state, getters, rootState, rootGetters) => {
    if (state.modChange === 0) return false;
    let shift = rootGetters["shifts/requestShift"];
    if (!shift) return false;
    return state.changeTypeSelected === "modify" && shift.changes
      ? shift.changes[state.modChange].pay_pref !== undefined
      : false;
  },
  modNewType: state => {
    return state.modNewType;
  },
  modNewTypeVisible: (state, getters) => {
    return getters.changeType === "modify" && state.modChange !== 0;
  },
  modNewTypeInvalid: state => {
    return state.modNewType === 0;
  },
  modifiable: (state, getters, rootState, rootGetters) => {
    let shift = rootGetters["shifts/requestShift"];
    let modifiable_types = rootGetters["shifts/modifiableTypes"](shift.id);
    if (modifiable_types.length === 0) return false;
    return (
      Object.values(modifiable_types).filter(time_off_type => {
        let has_time_off_type = false;
        if (!shift.changes) return has_time_off_type;
        Object.values(shift.changes).forEach(change_id => {
          let change = rootGetters["shifts/change"](change_id);
          if (change && change.time_off_type === time_off_type.id) {
            if (time_off_type.modify_request_hours_prior_to_shift) {
              let duration =
                _duration(
                  _now(rootState.client.settings.timezone),
                  shift.start
                ) / 60;
              has_time_off_type =
                time_off_type.modify_request_hours_prior_to_shift <= duration;
            } else {
              has_time_off_type = true;
            }
          }
        });
        return has_time_off_type;
      }).length > 0
    );
  },
  notes: state => {
    return state.note;
  },
  payPreference: state => {
    return state.payPreference;
  },
  payoutHours: (state, getters) => {
    if (getters.selectedTimeType) {
      return getters.changeLengthHours;
    }
    return null;
  },
  selectedDate: state => {
    return state.selectedDate;
  },
  selectedPeriod: (state, getters, rootState, rootGetters) => {
    let shift = rootGetters["shifts/requestShift"];
    return rootState.client.periods[shift.period];
  },
  selectedTimeType: (state, getters, rootState) => {
    if (state.changeTypeSelected === "modify" && state.modNewType > 0) {
      return rootState.client.time_off_types[state.modNewType];
    }
    if (state.timeOffTypeSelected > 0) {
      return rootState.client.time_off_types[state.timeOffTypeSelected];
    }
    if (rootState.requestType === "extra") {
      return Object.values(getters.selectedPeriod.time_off_types).filter(
        change => {
          return change.category === SHIFT_CHANGE_TIME_OFF_TYPE_OVERTIME;
        }
      )[0];
    }
    return null;
  },
  splitOptionsInvalid: (state, getters, rootState, rootGetters) => {
    const shift = rootGetters["shifts/requestShift"];
    //console.log(shift);
    if (!shift) return true;
    const split_option = shift.extra_shift_split_options;
    if (split_option === 0 || split_option === 1) return false;
    if (split_option !== 5) {
      return state.splitOptionsSelected.length === 0;
    }
    return !state.customTimeStart || !state.customTimeEnd;
  },
  claimPayPreferenceVisible: (state, getters, rootState) => {
    let selected_time_type = getters.selectedTimeType;
    if (!selected_time_type) return false;
    /*console.log(
      "claim pay pref vis",
      state.timeOffTypeSelected > 0,
      getters.claimTimeOffTypeVisible,
      getters.bankObj,
      selected_time_type.category === SHIFT_CHANGE_TIME_OFF_TYPE_OVERTIME,
      selected_time_type.comp_time_bank,
      rootState.client.settings.request_overtime_as_comp_time_enabled
    );*/
    return (
      state.timeOffTypeSelected > 0 &&
      getters.claimTimeOffTypeVisible &&
      ((getters.bankObj && rootState.client.settings.time_banks) ||
        !rootState.client.settings.time_banks) &&
      selected_time_type.category === SHIFT_CHANGE_TIME_OFF_TYPE_OVERTIME &&
      (selected_time_type.comp_time_bank ||
        !rootState.client.settings.time_banks) &&
      rootState.client.settings.request_overtime_as_comp_time_enabled
    );
  },
  changePayPreferenceVisible: (state, getters, rootState, rootGetters) => {
    let selected_time_type = getters.selectedTimeType;
    let shift = rootGetters["shifts/requestShift"];
    if (!shift) return false;
    if (!selected_time_type) return false;
    if (!shift.assigned_to) return false;

    let time_off_type_selection_visible =
      getters.changeTimeOffTypeVisible || getters.modNewTypeVisible;
    if (!time_off_type_selection_visible) return false;
    let time_off_type_selected_not_modify =
      state.timeOffTypeSelected > 0 && state.changeTypeSelected !== "modify";
    let modify_time_off_type_selected_and_modify =
      state.modNewType > 0 && state.changeTypeSelected === "modify";
    let time_off_types_selected =
      time_off_type_selected_not_modify ||
      modify_time_off_type_selected_and_modify;
    if (!time_off_types_selected) return false;
    /*
    console.log(
      "change pay pref vis",
      ((getters.bankObj && rootState.client.settings.time_banks) ||
        !rootState.client.settings.time_banks), // Does the same thing as comp_time_bank line probably.
      selected_time_type.category === SHIFT_CHANGE_TIME_OFF_TYPE_OVERTIME,
      selected_time_type.comp_time_bank,
      rootState.client.settings.request_overtime_as_comp_time_enabled,
      time_off_type_selection_visible
    );*/
    return (
      ((getters.bankObj && rootState.client.settings.time_banks) ||
        !rootState.client.settings.time_banks) && // Does the same thing as comp_time_bank line probably.
      selected_time_type.category === SHIFT_CHANGE_TIME_OFF_TYPE_OVERTIME &&
      (selected_time_type.comp_time_bank ||
        !rootState.client.settings.time_banks) &&
      rootState.client.settings.request_overtime_as_comp_time_enabled &&
      time_off_type_selection_visible
    );
  },
  holdoverPayPreferenceVisible: (state, getters, rootState) => {
    let selected_time_type = getters.selectedTimeType;
    //let shift = rootGetters["shifts/requestShift"];
    return (
      state.timeOffTypeSelected > 0 &&
      ((getters.bankObj && rootState.client.settings.time_banks) ||
        !rootState.client.settings.time_banks) &&
      selected_time_type.category === SHIFT_CHANGE_TIME_OFF_TYPE_OVERTIME &&
      (selected_time_type.comp_time_bank ||
        !rootState.client.settings.time_banks) &&
      rootState.client.settings.request_overtime_as_comp_time_enabled
    );
  },
  payPreferenceInvalid: (state, getters, rootState, rootGetters) => {
    let shift = rootGetters["shifts/requestShift"];
    if (shift && !shift.isExtraShift) {
      return !shift.assigned_to && state.payPreference === null;
    } else {
      return state.payPreference === null;
    }
  },
  // eslint-disable-next-line no-unused-vars
  bank: (state, getters, rootState, rootGetters) => {
    let selected_time_type = getters.selectedTimeType;
    if (!selected_time_type) return null;
    if (
      getters.timeOffTypeSelected > 0 ||
      (getters.modNewType > 0 && getters.changeType === "modify")
    ) {
      if (selected_time_type.time_off_bank) {
        return selected_time_type.time_off_bank;
      } else {
        if (state.payPreference === "time" || state.payPreference === "split") {
          return selected_time_type.comp_time_bank;
        }
      }
    }
    if (rootState.request.requestType === "extra") {
      return getters.bankObj.id;
    }
    return null;
  },
  // eslint-disable-next-line no-unused-vars
  bankObj: (state, getters, rootState, rootGetters) => {
    if (rootState.request.requestType === "trade") {
      return rootState.client.banks[
        rootState.client.settings.change_request_types
          .tradeable_benefit_time_bank
      ];
    }
    let selected_time_type = getters.selectedTimeType;
    if (!selected_time_type) return null;

    //console.log("bank obj", selected_time_type);
    if (rootState.request.requestType === "extra") {
      let bank_id = selected_time_type.time_off_bank
        ? selected_time_type.time_off_bank
        : selected_time_type.comp_time_bank;
      //console.log("extra", bank_id);
      return rootState.client.banks[bank_id];
    }
    //console.log("next");
    if (
      selected_time_type &&
      (selected_time_type.time_off_bank || selected_time_type.comp_time_bank)
    ) {
      let bank_id = selected_time_type.time_off_bank
        ? selected_time_type.time_off_bank
        : selected_time_type.comp_time_bank;
      //console.log("bank_id", bank_id, rootState.client);
      return Object.values(rootState.client.banks).filter(bank => {
        return bank.id === bank_id;
      })[0];
    }
    return null;
  },
  rescindable: (state, getters, rootState, rootGetters) => {
    let shift = rootGetters["shifts/requestShift"];
    let rescindable_types = rootGetters["shifts/rescindableTypes"](shift.id);
    if (rescindable_types.length === 0) return false;

    return (
      Object.values(rescindable_types).filter(time_off_type => {
        let has_time_off_type = false;
        if (!shift.changes) return has_time_off_type;
        Object.values(shift.changes).forEach(change_id => {
          let change = rootGetters["shifts/change"](change_id);
          if (change && change.time_off_type === time_off_type.id) {
            if (time_off_type.rescind_request_hours_prior_to_shift) {
              let duration =
                _duration(
                  _now(rootState.client.settings.timezone),
                  shift.start
                ) / 60;
              has_time_off_type =
                time_off_type.rescind_request_hours_prior_to_shift <= duration;
            } else {
              has_time_off_type = true;
            }
          }
        });
        return has_time_off_type;
      }).length > 0
    );
  },
  // eslint-disable-next-line no-unused-vars
  modifiableOrRescindableChanges: (state, getters, rootState, rootGetters) => {
    let shift = rootGetters["shifts/requestShift"];
    let relevant_time_off_types = null;
    switch (getters.changeType) {
      case "modify":
        relevant_time_off_types = rootGetters["shifts/modifiableTypes"](
          shift.id
        );
        break;
      case "rescind":
        relevant_time_off_types = rootGetters["shifts/rescindableTypes"](
          shift.id
        );
        break;
      default:
        //console.log("Unknown changeType", state.changeType);
        return [];
    }

    return Object.values(shift.changes).filter(change_id => {
      let change = rootGetters["shifts/change"](change_id);
      if (!change) return false;
      if (change.approval_status !== APPROVAL_APPROVED) return false;
      return (
        change.shift_change_type !== CHANGE_TYPE_MODIFY &&
        change.shift_change_type !== CHANGE_TYPE_RESCIND &&
        Object.values(relevant_time_off_types).filter(time_off_type => {
          if (time_off_type.id === change.time_off_type) {
            let rescindable = true;
            let modifiable = true;
            if (time_off_type.rescind_request_hours_prior_to_shift) {
              let duration =
                _duration(
                  _now(rootState.client.settings.timezone),
                  shift.start
                ) / 60;
              rescindable =
                time_off_type.rescind_request_hours_prior_to_shift <= duration;
            }
            if (time_off_type.modify_request_hours_prior_to_shift) {
              let duration =
                _duration(
                  _now(rootState.client.settings.timezone),
                  shift.start
                ) / 60;
              modifiable =
                time_off_type.modify_request_hours_prior_to_shift <= duration;
            }
            return modifiable || rescindable;
          }
          return false;
        }).length > 0
      );
    });
  },
  personCovering: (state, getters, rootState) => {
    return rootState.client.people[state.personCovering];
  },
  releaseType: state => {
    return state.releaseType;
  },
  releaseTypeInvalid: state => {
    return state.releaseType === 0;
  },
  shiftDuration: (state, getters, rootState, rootGetters) => {
    let shift = rootGetters["shifts/requestShift"];
    if (!shift) return 0;
    return _duration(shift.start, shift.end);
  },
  splitPayPreferencePaid: state => {
    return state.splitPayPreferencePaid;
  },
  splitPayPreferenceTime: state => {
    return state.splitPayPreferenceTime;
  },
  targetCustomTimeEnd: state => {
    return state.targetCustomTimeEnd;
  },
  targetCustomTimeStart: state => {
    return state.targetCustomTimeStart;
  },
  targetInvalidTimeEnd: state => {
    return state.targetInvalidTimeEnd;
  },
  targetInvalidTimeStart: state => {
    return state.targetInvalidTimeStart;
  },

  targetLength: (state, getters) => {
    if (getters.changeTime === "full") {
      return getters.shiftDuration;
    } else {
      if (!getters.targetCustomTimeStart) {
        return getters.shiftDuration;
      }
      return getters.targetTimeDuration;
    }
  },
  // eslint-disable-next-line no-unused-vars
  targetLengthHours: (state, getters) => {
    return Math.round((getters.targetLength / 60) * 100) / 100;
  },
  targetTimeDuration: state => {
    return _duration(
      state.targetCustomTimeStart, //LocalDateTime.parse(state.targetCustomTimeStart),
      state.targetCustomTimeEnd //LocalDateTime.parse(state.targetCustomTimeEnd)
    );
  },
  targetTimeInvalid: state => {
    return state.targetInvalidTimeStart || state.targetInvalidTimeEnd;
  },
  targetShift: state => {
    return state.targetShift;
  },
  targetShiftVisible: (state, getters, rootState) => {
    return (
      state.tradeType !== "cover" &&
      state.tradeTarget > 0 &&
      (state.tradeType === "shift" ||
        !rootState.client.settings.change_request_types.benefit_time_trade)
    );
  },
  targetShiftInvalid: state => {
    return !state.targetShift; /* ||
            this.conflictShifts.filter(sh => {
              return sh.id === this.targetShift;
            }).length > 0,*/
  },
  tradeTarget: state => {
    return state.tradeTarget;
  },
  tradeTargetInvalid: state => {
    return !state.tradeTarget;
  },
  tradeType: state => {
    return state.tradeType;
  },
  // eslint-disable-next-line no-unused-vars
  tradeLengthVisible: (state, getters, rootState, rootGetters) => {
    return (
      (state.tradeTarget ||
        rootGetters["shifts/requestShift"].assigned_to !==
          rootState.auth.person.id) &&
      rootState.client.settings.change_request_types.partial_trade
    );
  },
  tradeTypeInvalid: state => {
    return !state.tradeType;
  },
  tradeTypeVisible: (state, getters, rootState, rootGetters) => {
    return (
      (state.changeTime &&
        rootGetters["shifts/requestShift"].assigned_to !==
          rootState.auth.person.id &&
        rootState.client.settings.change_request_types.cover) ||
      rootState.client.settings.change_request_types.benefit_time_trade
    );
  },

  validFieldValues: state => {
    return state.validFieldValues;
  }
};
/*
      if (this.timeTypes) {
        if (this.rescindableTypes.length > 0) {
          return (
            Object.values(this.rescindableTypes).filter(type => {
              if (type.rescind_hours_prior) {
                return (
                  type.rescind_hours_prior <=
                    moment.duration(this.shiftStart.diff(moment())).asHours() &&
                  this.shiftChangeTimeTypes.includes(type.id)
                );
              } else {
                return this.shiftChangeTimeTypes.includes(type.id);
              }
            }).length > 0
          );
        }
        return false;
      }
      return false;
 */
