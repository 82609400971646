import { RESET_STATE, SET_VALUE } from "./mutation_types";
import { initial_state } from "../auth/state";
import cloneDeep from "lodash.clonedeep";
export default {
  async [RESET_STATE]({ commit }) {
    let state_keys = Object.keys(initial_state);
    for (let key of state_keys) {
      await commit(SET_VALUE, {
        key: key,
        value: cloneDeep(initial_state[key])
      });
    }
  }
};
