import { cloneDeep } from "bootstrap-vue/esm/utils/clone-deep";

export const initial_state = {
  activePeriod: null,
  cancelled: false,
  shiftSelected: null,
  supervisors: [],
  selectedSupervisors: [],
  requestType: null,
  releaseType: "release",
  validating: false,
  validated: false,
  dismissSecs: 5,
  dismissCountDown: 0,
  errors: [],
  min_staffing_risk: null,
  processingRequest: false,
  fromScreen: null,
  submitAsPending: true
};

export default cloneDeep(initial_state);
