import { LocalDate } from "@js-joda/core";
export const CT_MONTHLY = 0;
export const CT_28_DAY = 1;
export const CT_14_DAY = 2;
export const CALENDAR_TYPE_CHOICES = [
  [CT_MONTHLY, "Monthly"],
  [CT_28_DAY, "28 day"],
  [CT_14_DAY, "14 day"]
];

export const CALENDAR_28_DAY_START = new LocalDate(2015, 1, 1);
export const CALENDAR_14_DAY_START = new LocalDate(2015, 1, 1);
export const TRADE_RANGE_CUSTOM_DAYS = 1;
export const TRADE_RANGE_CUSTOM_MONTHS = 2;
export const TRADE_RANGE_CUSTOM_PAY_PERIODS = 3;
export const TRADE_RANGE_CUSTOM_CALENDARS = 4;
export const TRADE_RANGE_CHOICES = [
  [TRADE_RANGE_CUSTOM_DAYS, "Custom # of days"],
  [TRADE_RANGE_CUSTOM_MONTHS, "Custom # of months"],
  [TRADE_RANGE_CUSTOM_PAY_PERIODS, "Custom # of pay periods"],
  [TRADE_RANGE_CUSTOM_CALENDARS, "Custom # of calendar periods"]
];
export const PP_END_OF_MONTH = 1;
export const PP_HALF_MONTH = 2;
export const PP_BIWEEKLY = 3;
export const PP_WEEKLY = 4;
export const PP_CUSTOM = 5;
export const PP_HALF_MONTH_10 = 6;

export const PAY_PERIOD_CHOICES = [
  [PP_END_OF_MONTH, "-------"],
  [PP_HALF_MONTH, "Bi-weekly (15th and last day of month)"],
  [PP_BIWEEKLY, "Bi-weekly (every other Friday)"],
  [PP_HALF_MONTH_10, "Bi-weekly (10th and 25th of month)"],
  // (PP_WEEKLY, "Fridays weekly"), # No clients currently use this, so I am removing it to simplify
  [PP_CUSTOM, "Custom pay period"]
];

export const AP_NONE = 1;
export const AP_SUPERVISOR = 2;
export const AP_ADMIN = 3;
export const AP_SUPERVISOR_OR_ADMIN = 4;
export const AP_SUPERVISOR_AND_ADMIN = 5;
export const AP_OTHER_PERSON = 6;
export const AP_OTHER_PERSON_THEN_SUPERVISOR_OR_ADMIN = 7;
export const AP_OTHER_PERSON_THEN_SUPERVISOR_AND_ADMIN = 8;
export const AP_OTHER_PERSON_TYPES = [
  AP_OTHER_PERSON,
  AP_OTHER_PERSON_THEN_SUPERVISOR_OR_ADMIN,
  AP_OTHER_PERSON_THEN_SUPERVISOR_AND_ADMIN
];
