import { cloneDeep } from "bootstrap-vue/esm/utils/clone-deep";

export const initial_state = {
  isExtraShift: false,
  // Change Type
  changeTimeSelected: "full",
  changeTypeSelected: "new",

  // Change Mod
  modChange: 0,
  modNewType: 0,

  // Claim Shift
  claimDay: null,
  claimShiftType: "work",
  claimShiftTime: null,
  personCovering: 0,
  selectedPartialClaimOption: 1,

  // Custom Form
  customForm: [],
  customFormData: {},

  // Custom Time
  customTimeStart: null,
  customTimeEnd: null,
  customTimeStartsOnPreviousDay: false,
  invalidTimeStart: false,
  invalidTimeEnd: false,

  targetCustomTimeStart: null,
  targetCustomTimeEnd: null,
  targetInvalidTimeStart: false,
  targetInvalidTimeEnd: false,
  splitOptionsSelected: [],
  // Custom Field
  customField: null,

  // Dismissal Field
  dismissalSelected: null,

  // HoldOver
  holdoverLength: 0,

  locationSelected: 0,
  // Note
  note: null,

  // Pay Prefs
  payPreference: null,
  splitPayPreferenceTime: null,
  splitPayPreferencePaid: null,

  // Edit Modal State
  shiftEditState: {
    id: 0,
    loaded: false,
    showModal: false,
    shiftAssets: [],
    shiftLocation: 0,
    shiftRole: 0,
    shiftChanges: [],
    customTimeStart: { HH: "06", mm: "00" },
    customTimeEnd: { HH: "14", mm: "00" },
    note: ""
  },
  // Release
  releaseType: 0,
  selectedShiftTime: 0,
  shiftOtherTimeStart: { HH: "06", mm: "00" },
  shiftOtherTimeEnd: { HH: "14", mm: "00" },
  shiftChangeStart: null,
  shiftChangeEnd: null,
  errorSelectedDate: null,
  invalidSelectedDate: false,
  selectedDate: new Date(),
  // Target Shift
  targetShift: null,

  // Target User
  targetUser: null,

  // Time Off Type
  timeOffTypeSelected: 0,
  timeOffTypesSelected: [],
  timeOffTypeTimeRemaining: 0.0,
  singleTimeOffType: true,

  // Trade
  tradeLength: "full",
  tradeTarget: 0,
  tradeType: "shift",

  // Validation
  validFieldValues: true,
  fdom: null
};

export default cloneDeep(initial_state);
