// PARTIALS
//import { store } from "./store";
//import router from "./router";

// ON MESSAGE FUNCTION

export function onMessage(msg) {
  // AUTH MESSAGES FROM? TO? REACT NATIVE
  // I dont think this is event used.
  console.log("!!!!!!!!!! Got message from react!!!!!!", msg);
  /*
  if (msg.user) {
    store.state.authedUser = msg.user;
    // was var declared.
    msg = JSON.stringify({
      bridge: msg.user
    });
    window.postMessage(msg, "*");

    router.push("/");
  }*/
}
