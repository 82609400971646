import { cloneDeep } from "bootstrap-vue/esm/utils/clone-deep";

export const initial_state = {
  tasks: {},
  min_staffing_risk: {},
  processing_type: null,
  processing: false
};

export default cloneDeep(initial_state);
