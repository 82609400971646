import {
  ADD_SELECTED_SUPERVISOR,
  DISMISS_ERROR_COUNTDOWN,
  RESET_STATE,
  RESET_MIN_STAFFING_RISK,
  REMOVE_SELECTED_SUPERVISOR,
  SET_ACTIVE_PERIOD,
  SET_CANCELLED,
  SET_ERRORS,
  SET_FROM_SCREEN,
  SET_RELEASE_TYPE,
  SET_REQUEST_TYPE,
  SET_SHIFT_SELECTED,
  SET_SUPERVISORS,
  SET_VALIDATED,
  SET_VALIDATING,
  SOFT_RESET_STATE,
  SHOW_ERROR_ALERT,
  SET_MIN_STAFFING_RISK,
  SET_PROCESSING_REQUEST,
  SET_SUBMIT_AS_PENDING
} from "./mutation_types";

import { SET_VALUE } from "../../mutation-types";
import { Vueset } from "../../vuehack/reactivehack";
import Vue from "vue";

export default {
  async [SET_VALUE](state, payload) {
    Vueset(state, payload.key, payload.value);
  },
  async [SET_CANCELLED](state, payload) {
    Vueset(state, "cancelled", payload);
  },
  [ADD_SELECTED_SUPERVISOR](state, value) {
    if (value === "all") {
      for (const supervisor of state.supervisors) {
        if (!state.selectedSupervisors.includes(supervisor)) {
          state.selectedSupervisors.push(supervisor);
        }
      }
    } else {
      if (!state.selectedSupervisors.includes(value)) {
        state.selectedSupervisors.push(value);
      }
    }
    Vueset(state, "selectedSupervisors", state.selectedSupervisors);
  },
  [DISMISS_ERROR_COUNTDOWN](state, value) {
    Vueset(state, "dismissCountDown", value);
  },
  [REMOVE_SELECTED_SUPERVISOR](state, value) {
    for (let i = 0; i <= state.selectedSupervisors.length - 1; i++) {
      if (state.selectedSupervisors[i] === value) {
        state.selectedSupervisors.splice(i, 1);
      }
    }
    Vueset(state, "selectedSupervisors", state.selectedSupervisors);
  },
  [RESET_STATE](state) {
    Vueset(state, "activePeriod", null);
    //Vueset(state, "requestType", null); PACE-3397 - resetting request type will cause a router loop.
    Vueset(state, "selectedSupervisors", []);
    Vueset(state, "shiftSelected", null);
    Vueset(state, "supervisors", []);
    Vueset(state, "validating", false);
    Vueset(state, "validated", false);
    Vueset(state, "fromScreen", null);
    Vueset(state, "min_staffing_risk", null);
    Vue.set(state, "submitAsPending", true);
  },
  [SOFT_RESET_STATE](state) {
    Vueset(state, "validating", false);
    Vueset(state, "validated", false);
    //Vueset(state, "requestType", null); PACE-3397 - resetting request type will cause a router loop.
    Vueset(state, "selectedSupervisors", []);
    Vueset(state, "min_staffing_risk", null);
    Vue.set(state, "submitAsPending", true);
  },
  [RESET_MIN_STAFFING_RISK](state) {
    Vueset(state, "min_staffing_risk", null);
  },
  [SET_ACTIVE_PERIOD](state, value) {
    Vueset(state, "activePeriod", value);
  },
  [SET_ERRORS](state, value) {
    Vueset(state, "errors", value);
  },
  [SET_FROM_SCREEN](state, value) {
    Vueset(state, "fromScreen", value);
  },
  [SET_MIN_STAFFING_RISK](state, value) {
    Vueset(state, "min_staffing_risk", value.data);
  },
  [SET_PROCESSING_REQUEST](state, value) {
    Vueset(state, "processingRequest", value);
  },
  [SET_RELEASE_TYPE](state, value) {
    Vueset(state, "releaseType", value);
  },
  [SET_REQUEST_TYPE](state, value) {
    Vueset(state, "requestType", value);
  },
  [SET_SHIFT_SELECTED](state, value) {
    Vueset(state, "shiftSelected", value);
  },
  [SET_SUBMIT_AS_PENDING](state, value) {
    Vue.set(state, "submitAsPending", value);
  },
  [SET_SUPERVISORS](state, value) {
    Vueset(state, "supervisors", value);
  },
  [SET_VALIDATED](state, value) {
    Vueset(state, "validated", value);
  },
  [SET_VALIDATING](state, value) {
    Vueset(state, "validating", value);
  },
  [SHOW_ERROR_ALERT](state) {
    Vueset(state, "dismissCountDown", state.dismissSecs);
  }
};
