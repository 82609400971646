import {
  CHECK_APP_VERSION,
  LOAD_CLIENTS,
  LOGIN,
  LOGOUT,
  REFRESH_LOGIN,
  RESET_STATE,
  SAVE_USER_INFO,
  SET_AUTHENTICATING,
  SET_ACCESS_TOKEN,
  SET_TWO_FACTOR,
  SET_ERRORS,
  SET_LAST_VERSION_CHECK,
  SET_SELECTED_CLIENT_ID,
  SET_LOADING,
  TOGGLE_CLIENT,
  USER_AUTHENTICATED,
  SOCKET_AUTH,
  GET_OTP,
  REFRESH_PERMISSIONS,
  SET_PERSON_SUBORDINATES,
  LOAD_SSO_PROVIDERS,
  SSO_LOGIN,
  TWO_FACTOR_LOGIN,
  GET_PERSON_PERMISSIONS,
  SET_PERSON_PERMISSIONS
} from "./mutation_types";
import router from "@/router";
import { LocalDateTime } from "@js-joda/core";
// eslint-disable-next-line no-unused-vars
import Vue from "vue";
import {
  NEEDS_AUTH,
  SET_VALUE,
  SOCKET_AUTHED,
  SOFT_RESET_STATE
} from "../../mutation-types";
import { initial_state } from "./state";
import cloneDeep from "lodash.clonedeep";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import { setScope } from "../../utils";
axios.defaults.headers.common["Content-Type"] = "application/json";

function parseJwt(token) {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}

export default {
  async [RESET_STATE]({ commit }) {
    let state_keys = Object.keys(initial_state);
    for (let key of state_keys) {
      await commit(SET_VALUE, {
        key: key,
        value: cloneDeep(initial_state[key])
      });
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [CHECK_APP_VERSION]({ dispatch, commit, state, rootState }) {
    console.log("Is this even used?");
    let now = LocalDateTime.now();
    let last_check = state.last_version_check
      ? state.last_version_check.plusMinutes(1)
      : null;
    if (!state.last_version_check || now.isAfter(last_check)) {
      await commit(SET_LAST_VERSION_CHECK, now);
      let url = rootState.api_url + `/version`;
      setScope(url);
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          Sentry.configureScope(scope => {
            scope.setExtra("api_response", response);
          });
          if (response.status === 200) {
            let jd = response.json();

            return jd.then(j => {
              Sentry.configureScope(scope => {
                scope.setExtra("json_response", j);
              });
              if (j.version) {
                if (j.version > process.env.VUE_APP_VERSION) {
                  window.update_js();
                  dispatch(LOGOUT, {});
                }
              }
            });
          }
        })
        .catch(error => {
          Sentry.captureException(error);
          console.log(error);
        });
    }
  },
  async [SOCKET_AUTH]({ dispatch, rootState }) {
    await dispatch(SOCKET_AUTHED, null, { root: true });
    await dispatch(
      "sendMessage",
      JSON.stringify({
        client_id: rootState.client.selected_id,
        token: rootState.auth.access_token
      }),
      { root: true }
    );
  },
  async [LOAD_CLIENTS]({ dispatch, commit, rootState }) {
    let url = rootState.api_url + `/mobileclients/`;
    setScope(url);
    let clients = null;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + rootState.auth.access_token
      }
    })
      .then(async response => {
        Sentry.configureScope(scope => {
          scope.setExtra("api_response", response);
        });
        response
          .json()
          .then(async jd => {
            if (response.status === 200) {
              if (jd) {
                Sentry.configureScope(scope => {
                  scope.setExtra("json_response", jd);
                });
                clients = jd.clients;
              }
            } else {
              if (response.status === 403) {
                await dispatch(LOGOUT, {});
                await commit(SET_ERRORS, [jd.detail]);
                if (router.currentRoute.path !== "/auth") {
                  router.push("/auth");
                }
              }
              return;
            }

            let demo_client = null;
            let first_client = null;
            let mobile_app_enabled = false;
            for (let client of clients) {
              if (client.mobile_app_enabled) {
                if (first_client === null) {
                  first_client = client;
                }
                mobile_app_enabled = true;
                await commit("SAVE_CLIENT", client);
              }
              if (client.id === 1) {
                demo_client = client;
              }
            }
            if (!mobile_app_enabled) {
              console.log("Mobile app not enabled!");
              await window.localStorage.setItem("otp", null);
              await window.localStorage.setItem("email", null);
              await window.localStorage.setItem("autoLogin", "false");
              await window.localStorage.setItem("mobileAppDisabled", "true");
              await commit(SET_ERRORS, [
                "Sorry, the Pace Scheduler Mobile App is currently not enabled for your organization."
              ]);
              await dispatch(LOGOUT);
              if (router.currentRoute.path !== "/auth") router.push("/auth");
              return;
            } else {
              await window.localStorage.setItem("mobileAppDisabled", "false");
            }

            const vm = new Vue();
            if (demo_client) {
              await dispatch("client/SAVE_SELECTED_ID", demo_client.id, {
                root: true
              });
              vm.$connect(rootState.websocket_url + demo_client.id + "/");

              await dispatch("client/LOAD_CLIENT", demo_client.id, {
                root: true
              });
            } else {
              await dispatch("client/SAVE_SELECTED_ID", first_client.id, {
                root: true
              });
              vm.$connect(rootState.websocket_url + first_client.id + "/");

              await dispatch("client/LOAD_CLIENT", first_client.id, {
                root: true
              });
            }
            vm.$nextTick(() => {
              dispatch(REFRESH_PERMISSIONS);
              window.scrollTo(0, 0);
              dispatch(NEEDS_AUTH, {}, { root: true });
            });
          })
          .catch(error => {
            Sentry.captureException(error);
            console.log(error);
          });
      })
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  },
  async [LOAD_SSO_PROVIDERS]({ dispatch, commit, rootState }) {
    let url = rootState.api_url + `/mobile/sso_providers/`;
    setScope(url);
    let sso_providers = null;
    try {
      await axios({
        method: "GET",
        url: url,
        withCredentials: true
      })
        .then(async response => {
          Sentry.configureScope(scope => {
            scope.setExtra("api_response", response);
          });
          if (response.status === 200) {
            let jd = response.data;
            if (jd) {
              Sentry.configureScope(scope => {
                scope.setExtra("json_response", jd);
              });
              sso_providers = jd;
            }
            commit("LOAD_SSO_PROVIDERS", sso_providers);
          }
        })
        .catch(error => {
          Sentry.captureException(error);
          console.log(error);
        });
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
    }
  },
  // eslint-disable-next-line no-unused-vars
  /**
   * @return {boolean}
   */
  async [REFRESH_LOGIN]({ dispatch, commit, state, rootState }, payload) {
    let url = rootState.api_url + `/refresh/`;
    setScope(url);
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ refresh: state.refresh_token })
    })
      .then(response => {
        Sentry.configureScope(scope => {
          scope.setExtra("api_response", response);
        });
        if (response.status === 200) {
          return response.json().then(jd => {
            Sentry.configureScope(scope => {
              scope.setExtra("json_response", jd);
            });
            if (jd.access) {
              commit(SET_ACCESS_TOKEN, jd.access);
              // Retry failed action.
              if (payload.action) {
                dispatch(payload.action, payload.payload, { root: true });
              }
              return true;
            } else {
              dispatch(LOGOUT, {});
              if (router.currentRoute.path !== "/auth") {
                router.push("/auth");
              }
              return false;
            }
          });
        }
        dispatch(LOGOUT, {});
        if (router.currentRoute.path !== "/auth") {
          router.push("/auth");
        }
        return false;
      })
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
        dispatch(LOGOUT, {});
        if (router.currentRoute.path !== "/auth") {
          router.push("/auth");
        }
        return false;
      });
  },
  // TODO: Set a timer that logs out user if loading never completes.
  // eslint-disable-next-line no-unused-vars

  async [REFRESH_PERMISSIONS]({ commit, rootState }) {
    // If we hit this point the user wanted to be remembered, however if the OTP
    // request fails we still want to save the username at a minimum
    let url = rootState.api_url + `/person/subordinates/`;

    setScope(url);
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + rootState.auth.access_token
      },
      body: JSON.stringify({ client_id: rootState.client.selected_id })
    })
      .then(response => {
        Sentry.configureScope(scope => {
          scope.setExtra("api_response", response);
        });
        response.json().then(jd => {
          Sentry.configureScope(scope => {
            scope.setExtra("json_response", jd);
          });
          if (jd) {
            if (response.status === 200) {
              commit(SET_PERSON_SUBORDINATES, JSON.parse(jd));
            } else {
              console.log("error getting subordinates", jd);
              //commit(SET_ERRORS, jd);
            }
          } else {
            Sentry.captureMessage("No JSON response from server");
            //commit(SET_ERRORS, jd);
          }
        });
      })
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  },
  async [GET_OTP]({ commit, dispatch, rootState }) {
    // If we hit this point the user wanted to be remembered, however if the OTP
    // request fails we still want to save the username at a minimum
    window.localStorage.setItem("email", rootState.auth.user.email);
    let device_id = window.localStorage.getItem("deviceId");
    let url = rootState.api_url + `/get/otp/`;
    setScope(url);
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + rootState.auth.access_token,
        "X-Device": device_id
      }
    })
      .then(async response => {
        Sentry.configureScope(scope => {
          scope.setExtra("api_response", response);
        });
        if (response.status === 500) {
          return;
        }
        response.json().then(async jd => {
          if (jd) {
            Sentry.configureScope(scope => {
              scope.setExtra("json_response", jd);
            });
            if (response.status === 200) {
              window.localStorage.setItem("otp", jd.token);
            } else {
              console.log("error getting otp", jd);
              if (response.status === 403) {
                await dispatch(LOGOUT, {});
                await commit(SET_ERRORS, [jd.detail]);
                if (router.currentRoute.path !== "/auth") {
                  router.push("/auth");
                }
              }
            }
          } else {
            console.log("error getting otp", response);
          }
        });
      })
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  },
  async [LOGIN]({ dispatch, commit, rootState }, { userObj }) {
    commit(SET_AUTHENTICATING, true);
    let device_id = window.localStorage.getItem("deviceId");
    let url = rootState.api_url + `/token/`;
    setScope(url);
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Device": device_id
      },
      body: JSON.stringify(userObj)
    })
      .then(async response => {
        Sentry.configureScope(scope => {
          scope.setExtra("api_response", response);
        });
        if (response.status === 500) {
          commit(SET_LOADING, false);
          commit(SET_ERRORS, [
            "Sorry, there was an error when processing your request. The developers have been notified and will fix this bug ASAP"
          ]);
          return;
        }
        response
          .json()
          .then(async jd => {
            if (jd) {
              if (response.status === 200) {
                Sentry.configureScope(scope => {
                  scope.setExtra("json_response", jd);
                });
                if (jd.access) {
                  commit(USER_AUTHENTICATED, jd);
                  commit(SET_LOADING, true);
                  commit(SET_ERRORS, []);
                  let token_claims = parseJwt(jd.access);
                  userObj["id"] = token_claims.user_id;
                  commit(SAVE_USER_INFO, userObj);
                  if (!rootState.dev_mode) {
                    let auth_string = JSON.stringify({
                      bridge: {
                        setAuthenticated: {
                          userId: token_claims.user_id,
                          accessToken: jd.access,
                          firebaseToken: "not_needed?"
                        },
                        authenticated: true
                      }
                    });
                    window.postMessage(auth_string, "*");
                  }
                  window.scrollTo(0, 0);
                  if (router.currentRoute.path !== "/") {
                    router.push("/");
                  }
                  dispatch(LOAD_CLIENTS);
                  if (userObj.remember) {
                    dispatch(GET_OTP);
                  } else {
                    window.localStorage.setItem("otp", null);
                    window.localStorage.setItem("email", null);
                  }
                } else if (jd.needs_two_factor) {
                  window.localStorage.setItem("2faEmail", userObj.email);
                  window.localStorage.setItem("2faRemember", userObj.remember);
                  commit(SET_TWO_FACTOR, jd);
                  await router.push("/2fa");
                } else {
                  commit(SET_ERRORS, jd);
                }
              } else {
                Sentry.configureScope(scope => {
                  scope.setExtra("json_response", jd);
                });
                //console.log("token", jd);
                commit(SET_ERRORS, jd.detail);
              }
            }
            commit(SET_AUTHENTICATING, false);
          })
          .catch(error => {
            Sentry.captureException(error);
            console.log(error);
          });
      })
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  },
  async [SSO_LOGIN]({ dispatch, commit, rootState }, payload) {
    commit(SET_AUTHENTICATING, true);
    let device_id = window.localStorage.getItem("deviceId");
    let url = rootState.api_url + `/sso/token/${payload.queryString}`;
    setScope(url);
    const urlParams = new URLSearchParams(payload.queryString);
    const req_payload = {
      email: payload.email,
      password: "unused",
      remember: payload.remember,
      code: urlParams.get("code"),
      session_state: urlParams.get("session_state"),
      client_info: urlParams.get("client_info")
    };
    try {
      axios({
        method: "POST",
        url: url,
        withCredentials: true,
        headers: {
          "X-Device": device_id
        },
        data: req_payload
      }).then(async response => {
        Sentry.configureScope(scope => {
          scope.setExtra("api_response", response);
        });
        if (response.status === 500) {
          commit(SET_LOADING, false);
          commit(SET_ERRORS, [
            "Sorry, there was an error when processing your request. The developers have been notified and will fix this bug ASAP"
          ]);
          return;
        }
        let jd = response.data;
        if (jd) {
          Sentry.configureScope(scope => {
            scope.setExtra("json_response", jd);
          });
          if (response.status === 200) {
            if (jd.access) {
              commit(USER_AUTHENTICATED, jd);
              commit(SET_LOADING, true);
              commit(SET_ERRORS, []);
              let token_claims = parseJwt(jd.access);
              payload["id"] = token_claims.user_id;
              commit(SAVE_USER_INFO, payload);
              if (!rootState.dev_mode) {
                let auth_string = JSON.stringify({
                  bridge: {
                    setAuthenticated: {
                      userId: token_claims.user_id,
                      accessToken: jd.access,
                      firebaseToken: "not_needed?"
                    },
                    authenticated: true
                  }
                });
                window.postMessage(auth_string, "*");
              }
              window.scrollTo(0, 0);
              if (router.currentRoute.path !== "/") {
                router.push("/");
              }
              dispatch(LOAD_CLIENTS);
              if (payload.remember) {
                dispatch(GET_OTP);
              } else {
                window.localStorage.setItem("otp", null);
                window.localStorage.setItem("email", null);
              }
            } else {
              commit(SET_ERRORS, jd);
            }
          } else {
            commit(SET_ERRORS, jd.detail);
          }
        }
        commit(SET_AUTHENTICATING, false);
      });
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      commit(SET_ERRORS, [
        "Sorry, there was an error when processing your request. Please try again later."
      ]);
      commit(SET_AUTHENTICATING, false);
    }
  },
  async [TWO_FACTOR_LOGIN]({ dispatch, commit, rootState }, token) {
    commit(SET_AUTHENTICATING, true);
    const device_id = window.localStorage.getItem("deviceId");
    const email = window.localStorage.getItem("2faEmail");
    const remember = window.localStorage.getItem("2faRemember") == "true";
    let url = rootState.api_url + `/2fa/verify/`;
    setScope(url);
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Device": device_id
      },
      body: JSON.stringify({ email: email, token: token })
    })
      .then(async response => {
        Sentry.configureScope(scope => {
          scope.setExtra("api_response", response);
        });
        if (response.status === 500) {
          commit(SET_LOADING, false);
          commit(SET_ERRORS, [
            "Sorry, there was an error when processing your request. The developers have been notified and will fix this bug ASAP"
          ]);
          return;
        }
        response
          .json()
          .then(async jd => {
            if (jd) {
              if (response.status === 200) {
                Sentry.configureScope(scope => {
                  scope.setExtra("json_response", jd);
                });
                if (jd.access) {
                  commit(USER_AUTHENTICATED, jd);
                  commit(SET_LOADING, true);
                  commit(SET_ERRORS, []);
                  let token_claims = parseJwt(jd.access);
                  commit(SAVE_USER_INFO, {
                    email: email,
                    id: token_claims.user_id
                  });
                  if (!rootState.dev_mode) {
                    let auth_string = JSON.stringify({
                      bridge: {
                        setAuthenticated: {
                          userId: token_claims.user_id,
                          accessToken: jd.access,
                          firebaseToken: "not_needed?"
                        },
                        authenticated: true
                      }
                    });
                    window.postMessage(auth_string, "*");
                  }
                  window.scrollTo(0, 0);
                  if (router.currentRoute.path !== "/") {
                    window.localStorage.setItem("2faEmail", null);
                    window.localStorage.setItem("2faRemember", null);
                    commit(SET_TWO_FACTOR, null);
                    router.push("/");
                  }
                  dispatch(LOAD_CLIENTS);
                  if (remember) {
                    dispatch(GET_OTP);
                  } else {
                    window.localStorage.setItem("otp", null);
                    window.localStorage.setItem("email", null);
                  }
                } else {
                  commit(SET_ERRORS, jd);
                }
              } else {
                Sentry.configureScope(scope => {
                  scope.setExtra("json_response", jd);
                });
                //console.log("token", jd);
                commit(SET_ERRORS, jd.detail);
              }
            }
            commit(SET_AUTHENTICATING, false);
          })
          .catch(error => {
            Sentry.captureException(error);
            console.log(error);
          });
      })
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  },
  async [LOGOUT]({ dispatch, commit }) {
    await dispatch("RESET_STATE", {}, { root: true });
    await commit(LOGOUT);
    await dispatch("request/RESET_STATE", {}, { root: true });
    await dispatch("client/RESET_STATE", {}, { root: true });
    await dispatch("calendar/RESET_STATE", {}, { root: true });
    //await dispatch("dashboard/RESET_STATE", {}, { root: true });
    await dispatch("form/RESET_STATE", {}, { root: true });
    await dispatch("shifts/RESET_STATE", {}, { root: true });
    await dispatch("task/RESET_STATE", {}, { root: true });
    const vm = new Vue();
    vm.$disconnect();
    if (router.currentRoute.path !== "/auth") {
      router.push("/auth");
      router.go();
      //window.location.reload(true);
    }
  },
  async [TOGGLE_CLIENT]({ dispatch, commit, rootState }, payload) {
    let new_client_id = cloneDeep(payload.target.value);
    await dispatch(SOFT_RESET_STATE, {}, { root: true });
    await commit(SET_SELECTED_CLIENT_ID, new_client_id);
    await commit(SET_LOADING, true);
    const vm = new Vue();
    vm.$disconnect();
    await dispatch("client/RESET_STATE", {}, { root: true });
    await dispatch("calendar/RESET_STATE", {}, { root: true });
    await dispatch("shifts/RESET_STATE", {}, { root: true });
    await dispatch("request/RESET_STATE", {}, { root: true });
    await dispatch("form/RESET_STATE", {}, { root: true });
    await dispatch("client/LOAD_CLIENT", new_client_id, { root: true });
    await dispatch("client/SAVE_SELECTED_ID", new_client_id, {
      root: true
    });
    try {
      vm.$connect(rootState.websocket_url + payload.target.value + "/");
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
    }
    await dispatch(NEEDS_AUTH, {}, { root: true });
  },
  async [GET_PERSON_PERMISSIONS]({ dispatch, commit, rootState }, client_id) {
    let url = rootState.api_url + `/person/permissions/${client_id}/`;
    setScope(url);
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + rootState.auth.access_token
      }
    })
      .then(async response => {
        Sentry.configureScope(scope => {
          scope.setExtra("api_response", response);
        });
        if (response.status === 500) {
          return;
        }
        response
          .json()
          .then(async jd => {
            if (jd) {
              if (response.status === 200) {
                Sentry.configureScope(scope => {
                  scope.setExtra("json_response", jd);
                });
                commit(SET_PERSON_PERMISSIONS, jd);
              } else {
                Sentry.configureScope(scope => {
                  scope.setExtra("json_response", jd);
                });
                commit(SET_ERRORS, jd.detail);
              }
            }
          })
          .catch(error => {
            Sentry.captureException(error);
            console.log(error);
          });
      })
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  }
};
