import {
  DELETE_SHIFT_CHANGE,
  RESET_STATE,
  SAVE_SHIFT,
  SAVE_SHIFTS,
  SAVE_SHIFT_CHANGE,
  SAVE_SHIFT_CHANGES,
  SET_PROCESSING_SHORTAGE_REQUEST,
  DELETE_SHIFT,
  SAVE_INITIAL_SHIFT_CHANGE,
  SAVE_INITIAL_SHIFT,
  APPEND_CHANGE_TO_SHIFT,
  SET_MIN_STAFFING_RISK,
  SET_CHANGES,
  SET_SHIFTS
} from "./mutation_types";
// eslint-disable-next-line no-unused-vars
import Vue from "vue";
//import moment from "moment-timezone";
import { del, Vueset } from "../../vuehack/reactivehack";
//import { del } from "../../vuehack/reactivehack";
// eslint-disable-next-line no-unused-vars
import cloneDeep from "lodash.clonedeep";

import _ from "lodash";
import { store } from "../index";
function sync_shifts() {
  Vueset(store.state, "shifts", store.state.shifts);
}
function sync_changes() {
  Vueset(store.state, "changes", store.state.changes);
}
import { SET_VALUE } from "../../mutation-types";
//import { _now } from "../../time";
export default {
  async [SET_VALUE](state, payload) {
    Vueset(state, payload.key, payload.value);
  },
  async [APPEND_CHANGE_TO_SHIFT](state, payload) {
    state.shifts[payload.shift_id].changes.push(payload.change_id);

    Vue.nextTick(() => {
      _.debounce(sync_shifts, 50);
    });
  },
  async [RESET_STATE](state) {
    //del(state, "shifts");
    //del(state, "changes");
    Vueset(state, "shifts", {});
    Vueset(state, "changes", {});
  },
  async [SAVE_INITIAL_SHIFT](state, value) {
    if (!value.changes) value.changes = [];
    Vueset(state.shifts, value.id, value);
    Vue.nextTick(() => {
      _.debounce(sync_shifts, 50);
    });
  },
  async [SAVE_SHIFT](state, value) {
    if (!value.changes) value.changes = [];
    Vueset(state.shifts, value.id, value);
    Vueset(state.shifts[value.id], "assets", value.assets);
    Vue.nextTick(() => {
      _.debounce(sync_shifts, 50);
    });
  },
  async [SAVE_SHIFTS](state, value) {
    for (let shift of value) {
      if (!shift.changes) shift.changes = [];
      Vueset(state.shifts, shift.id, shift);
    }
    Vue.nextTick(() => {
      _.debounce(sync_shifts, 50);
    });
  },
  async [SAVE_INITIAL_SHIFT_CHANGE](state, value) {
    Vueset(state.changes, value.id, value);
    Vue.nextTick(() => {
      _.debounce(sync_changes, 50);
    });
  },
  async [SAVE_SHIFT_CHANGE](state, value) {
    Vueset(state.changes, value.id, value);
    let shift = state.shifts[value.shift_1];
    if (shift) {
      let change = Object.values(shift.changes).filter(change => {
        return change === value.id;
      });
      if (change.length === 0) {
        shift.changes.push(value.id);
        //Vueset(state.shifts, value.shift_1, shift);
      }
    }
    Vue.nextTick(() => {
      _.debounce(sync_changes, 50);
      _.debounce(sync_shifts, 50);
    });
  },
  async [SAVE_SHIFT_CHANGES](state, value) {
    Object.values(value).forEach(change => {
      Vueset(state.changes, change.id, change);
    });
    Vue.nextTick(() => {
      _.debounce(sync_changes, 50);
    });
  },
  async [SET_PROCESSING_SHORTAGE_REQUEST](state, value) {
    Vueset(state, "processing_shortage_request", value);
  },
  async [SET_CHANGES](state, value) {
    Vueset(state, "changes", value);
  },
  async [SET_SHIFTS](state, value) {
    Vueset(state, "shifts", value);
  },
  async [SET_MIN_STAFFING_RISK](state, value) {
    Vueset(state, "min_staffing_risk", value);
  },
  async [DELETE_SHIFT](state, value) {
    let shift = state.shifts[value.shift_id];
    if (shift && shift.changes) {
      for (let change_id of shift.changes) {
        del(state.changes, change_id);
        Vue.nextTick(() => {
          _.debounce(sync_changes, 50);
        });
      }
    }
    del(state.shifts, value.shift_id);
    Vue.nextTick(() => {
      _.debounce(sync_shifts, 50);
    });
  },
  async [DELETE_SHIFT_CHANGE](state, value) {
    let shift = state.shifts[value.shift_id];
    if (shift) {
      let index = shift.changes.indexOf(parseInt(value.shift_change_id));
      if (index > -1) {
        shift.changes.splice(index, 1);
      }
    }
    if (state.changes[value.shift_change_id]) {
      del(state.changes, value.shift_change_id);
    }
    Vue.nextTick(() => {
      _.debounce(sync_changes, 50);
    });
  }
};
