import {
  DELETE_LOCKOUT,
  DELETE_SHORTAGE,
  QUEUE_SHORTAGE_REQUEST,
  DELETE_SHORTAGE_FROM_QUEUE,
  RESET_STATE,
  SAVE_SHORTAGE,
  SET_DEPARTMENT_VIEW,
  SET_SELECTED_DAY,
  SET_SELECTED_PERIOD,
  SET_SELECTED_SCHEDULE,
  TOGGLE_LOCATION,
  UPDATE_LOCKOUT,
  SET_PICKER_STALE,
  SET_LOCATIONS,
  SET_SHORTAGES,
  SET_SHORTAGE_LOAD_QUEUE,
  SET_LOCKOUTS,
  SET_DEFAULT_SCHEDULE,
  SELECT_MONTH,
  SELECT_YEAR
} from "./mutation_types";
import { del, Vueset } from "../../vuehack/reactivehack";
import { LocalDate, ZonedDateTime } from "@js-joda/core";
//import Vue from "vue";
import { SET_VALUE } from "../../mutation-types";
import _ from "lodash";
import Vue from "vue";
export default {
  [DELETE_LOCKOUT](state, payload) {
    del(state.lockouts, payload);
    Vueset(state, "lockouts", state.lockouts);
  },
  [DELETE_SHORTAGE](state, payload) {
    let split_payload = payload.split("|");
    let day = split_payload[0];
    let group = parseInt(split_payload[1]);
    let req = split_payload[2];
    let day_shortage = state.shortages[day];
    if (!day_shortage) return;
    let group_shortage = day_shortage.groups[group];
    if (!group_shortage) return;
    let requirement = group_shortage.reqs[req];
    if (!requirement) return;
    del(group_shortage.reqs, req);
    if (Object.values(group_shortage.reqs).length !== 0) return;
    del(day_shortage.groups, group);
    if (Object.values(day_shortage.groups).length !== 0) return;
    del(state.shortages, day);
    Vueset(state, "shortages", state.shortages);
  },
  [DELETE_SHORTAGE_FROM_QUEUE](state, payload) {
    if (state.shortage_load_queue.includes(payload)) {
      for (let i = 0; i <= state.shortage_load_queue.length - 1; i++) {
        if (state.shortage_load_queue[i] === payload) {
          state.shortage_load_queue.splice(i, 1);
          break;
        }
      }
    }
    Vueset(state, "shortage_load_queue", state.shortage_load_queue);
  },
  [QUEUE_SHORTAGE_REQUEST](state, payload) {
    let queue = state.shortage_load_queue;
    queue.push(payload);
    Vueset(state, "shortage_load_queue", queue);
  },
  [RESET_STATE](state) {
    state.selected_period = null;
    state.selected_day = null;
    state.selected_schedule = null;
    state.selected_locations = [];
    state.department_view = true;
    state.lockouts = {};
    state.shortages = {};
    Vueset(state, "shortage_load_queue", []);
  },
  [SAVE_SHORTAGE](state, payload) {
    /*
    let day = payload.day;
    let group = parseInt(payload.group);
    let req = payload.abbreviation;
    let day_shortage = state.shortages[day];
    let shortage = {
      required: payload.required,
      actual: payload.actual,
      name: payload.name,
      end_time: payload.end_time,
      ordinal_number: payload.ordinal_number,
      start_time: payload.start_time,
      roles: payload.req_roles,
      specials: payload.req_specials
    };
    if (!day_shortage) {
      Vueset(state.shortages, payload.day, {
        groups: {
          [group]: {
            reqs: {
              [req]: shortage
            }
          }
        }
      });
    } else {
      let day_group = day_shortage.groups[group];
      if (!day_group) {
        day_shortage.groups[group] = { reqs: { [req]: shortage } };
      } else {
        day_group.reqs[req] = shortage;
      }
    }*/
    let shortages = state.shortages[payload[0]];
    if (!shortages) {
      Vue.set(state.shortages, payload[0], payload[1]);
    } else {
      Vue.set(state.shortages, payload[0], _.assign(shortages, payload[1]));
    }
    Vue.set(state, "shortages", state.shortages);
  },
  [SET_LOCATIONS](state, payload) {
    Vueset(state, "locations", payload);
  },
  [SELECT_MONTH](state, payload) {
    Vueset(state, "selected_month", payload);
  },
  [SELECT_YEAR](state, payload) {
    Vueset(state, "selected_year", payload);
  },
  [SET_PICKER_STALE](state, payload) {
    Vueset(state, "picker_stale", payload);
  },
  async [SET_SELECTED_PERIOD](state, period) {
    Vueset(state, "selected_period", period);
  },
  [SET_DEPARTMENT_VIEW](state, value) {
    Vueset(state, "department_view", value);
  },
  [SET_SELECTED_DAY](state, value) {
    Vueset(state, "selected_day", value);
  },
  [SET_DEFAULT_SCHEDULE](state, value) {
    Vueset(state, "default_schedule", value);
  },
  [SET_SELECTED_SCHEDULE](state, value) {
    Vueset(state, "selected_schedule", value);
  },
  [SET_SHORTAGES](state, value) {
    Vueset(state, "shortages", value);
  },
  [SET_SHORTAGE_LOAD_QUEUE](state, value) {
    Vueset(state, "shortage_load_queue", value);
  },
  [SET_LOCKOUTS](state, value) {
    Vueset(state, "lockouts", value);
  },
  async [SET_VALUE](state, payload) {
    Vueset(state, payload.key, payload.value);
  },
  [TOGGLE_LOCATION](state, value) {
    if (!state.selected_locations) state.selected_locations = [];
    if (state.selected_locations.includes(value)) {
      for (let i = 0; i <= state.selected_locations.length - 1; i++) {
        if (state.selected_locations[i] === value) {
          state.selected_locations.splice(i, 1);
          break;
        }
      }
    } else {
      state.selected_locations.push(value);
    }
    Vueset(state, "selected_locations", state.selected_locations);
  },
  [UPDATE_LOCKOUT](state, value) {
    value.end = ZonedDateTime.parse(value.end_time);
    value.start = ZonedDateTime.parse(value.start_time);
    value.first_day_of_month = LocalDate.parse(value.first_day_of_month);
    if (value.cc_schedules !== "") {
      if (value.cc_schedules.includes(",")) {
        let split_cc_schedules = value.cc_schedules.split(",");
        value.cc_schedules = [];
        for (const cc_schedule_index in split_cc_schedules) {
          const cc_schedule = split_cc_schedules[cc_schedule_index];
          value.cc_schedules.push(parseInt(cc_schedule));
        }
      } else {
        value.cc_schedules = [parseInt(value.cc_schedules)];
      }
    } else {
      value.cc_schedules = [];
    }
    Vueset(state.lockouts, value.id, value);
  }
};
