import {
  ChronoUnit,
  Duration,
  LocalDate,
  Month,
  //LocalDateTime,
  //ZonedDateTime,
  TemporalAdjusters
} from "@js-joda/core";
//import { timeMixin } from "./time";
import {
  CT_14_DAY,
  CT_28_DAY,
  CT_MONTHLY,
  CALENDAR_14_DAY_START,
  CALENDAR_28_DAY_START,
  TRADE_RANGE_CUSTOM_DAYS,
  TRADE_RANGE_CUSTOM_MONTHS,
  TRADE_RANGE_CUSTOM_PAY_PERIODS,
  TRADE_RANGE_CUSTOM_CALENDARS,
  PP_HALF_MONTH,
  PP_END_OF_MONTH,
  PP_HALF_MONTH_10,
  PP_WEEKLY,
  PP_BIWEEKLY,
  PP_CUSTOM
} from "../constants/client";
export var visiblePeopleMixin = {
  computed: {
    visiblePeople() {
      return Object.values(this.$store.state.client.people).filter(person => {
        return person.is_visible && person.active_status === 1;
      });
    }
  }
};

export var clientMonthRangeMixin = {
  //mixins: [timeMixin],
  computed: {
    isCalendarMonthly() {
      return this.$store.state.client.settings.calendar_type === CT_MONTHLY;
    },
    isCalendar28Day() {
      return this.$store.state.client.settings.calendar_type === CT_28_DAY;
    },
    isCalendar14Day() {
      return this.$store.state.client.settings.calendar_type === CT_14_DAY;
    },
    getCalendar28DayStart() {
      let day_offset = this.$store.state.client.settings.calendar_day_offset;
      if (day_offset) {
        return CALENDAR_28_DAY_START.plusDays(day_offset);
      }
      return CALENDAR_28_DAY_START;
    },
    getCalendar14DayStart() {
      let day_offset = this.$store.state.client.settings.calendar_day_offset;
      if (day_offset) {
        return CALENDAR_14_DAY_START.plusDays(day_offset);
      }
      return CALENDAR_14_DAY_START;
    },
    getAllPayPeriodRanges() {
      //Returns all pay periods ranges for the client as tuples.

      const first_fdom = this.getFirstFdom;
      const last_fdom = this.getLastFdom;
      const pay_period_days = this.getCalendarPayPeriodDays(
        first_fdom,
        true,
        last_fdom
      );
      let pay_period_ranges = [];
      let prev_end_date = null;
      for (let pay_period_day of pay_period_days) {
        if (!prev_end_date) {
          prev_end_date = pay_period_day;
          continue;
        }
        pay_period_ranges.push([prev_end_date.plusDays(1), pay_period_day]);
        prev_end_date = pay_period_day;
      }
      return pay_period_ranges;
    }
  },
  methods: {
    getCalendarPayPeriodDays(
      first_day_of_month,
      include_before_start = false,
      end_date = null
    ) {
      /*
    :type client: client.models.Client
    :type first_day_of_month: datetime.date
    :type include_before_start: bool
    :type end_date: datetime.date
    If end_date is not None, it will fetch all pay periods between first_day_of_month and end_date
    */

      let fdom,
        ldom = this.getClientMonthRange(first_day_of_month);
      if (end_date) {
        ldom = end_date;
      }
      const pay_period_type = this.$store.state.client.settings.pay_period_type;
      let pay_periods = [];
      let current_day = fdom;
      const pay_period_length = this.$store.state.client.pay_period_length;
      let start = this.$store.state.client.pay_period_start
        ? this.$store.state.client.pay_period_start
        : LocalDate.of(2015, 1, 2);
      let start_day_of_week = start.dayOfWeek().value();
      switch (pay_period_type) {
        case PP_END_OF_MONTH:
          while (current_day.isBefore(ldom) || current_day.isEqual(ldom)) {
            const max_day_in_month = Month.from(current_day).maxLength;
            if (max_day_in_month === current_day.dayOfMonth()) {
              pay_periods.push(current_day);
            }
            current_day = current_day.plusDays(1);
          }
          break;
        case PP_HALF_MONTH:
          while (current_day.isBefore(ldom) || current_day.isEqual(ldom)) {
            const day = current_day.dayOfMonth();
            if (day === 15) {
              pay_periods.push(current_day);
            } else {
              const max_day_in_month = Month.from(current_day).maxLength;
              if (max_day_in_month === day) {
                pay_periods.push(current_day);
              }
            }
            current_day = current_day.plusDays(1);
          }
          break;
        case PP_HALF_MONTH_10:
          while (current_day.isBefore(ldom) || current_day.isEqual(ldom)) {
            const day = current_day.dayOfMonth();
            if ([10, 25].includes(day)) {
              pay_periods.push(current_day);
            }
            current_day = current_day.plusDays(1);
          }
          break;
        case PP_WEEKLY:
          current_day = this.getFriday(first_day_of_month);
          while (current_day.isBefore(ldom) || current_day.isEqual(ldom)) {
            pay_periods.push(current_day);
            current_day = current_day.plusDays(7);
          }
          break;
        case PP_BIWEEKLY:
          if (include_before_start) {
            // if we're calculating back, need to start from friday (?)
            if (start_day_of_week === 5) {
              start = start.minusDays(1);
            } else if (start_day_of_week !== 4) {
              start = this.getFriday(start);
            }
            const period_length = 14;
            const days_count = Duration.between(start, ldom).toDays();
            const days_to_last_period =
              Math.ceil(days_count / parseFloat(period_length)) * period_length;
            current_day = start.minusDays(days_to_last_period);
            while (current_day.isAfter(fdom) || current_day.isEqual(fdom)) {
              pay_periods.push(current_day);
              current_day = current_day.minusDays(days_to_last_period);
            }
            pay_periods = pay_periods.sort((a, b) => {
              if (a.isBefore(b)) {
                return -1;
              }
              return 1;
            });
          } else {
            if (start_day_of_week !== 4) {
              // Friday
              start = this.getFriday(start);
            }
            current_day = this.getFriday(first_day_of_month);
            // trying to determine whether the start is the friday or the friday after that
            // by counting the weeks number from the start (Jan 3, 2015)
            // if it's even, then it's the friday, otherwise, it's the next friday
            const diff_days = this.getDaysRange(start, current_day);
            const weeks_count = (diff_days.length - 1) / 7;
            current_day = current_day.plus(7 * (weeks_count % 2));
            while (current_day.isBefore(ldom)) {
              pay_periods.push(current_day);
              current_day = current_day.plusDays(14);
            }
          }
          break;
        case PP_CUSTOM:
          start = start.plusDays(pay_period_length - 1);
          current_day = start;
          if (include_before_start) {
            const days_count = Duration.between(start, ldom).toDays();
            const days_to_last_period =
              Math.ceil(days_count / parseFloat(pay_period_length)) *
              pay_period_length;
            current_day = start.minusDays(days_to_last_period);
            while (current_day.isAfter(fdom) || current_day.isEqual(fdom)) {
              pay_periods.push(current_day);
              current_day = current_day.minusDays(days_to_last_period);
            }
            pay_periods = pay_periods.sort((a, b) => {
              if (a.isBefore(b)) {
                return -1;
              }
              return 1;
            });
          } else {
            while (current_day.isBefore(ldom) || current_day.isEqual(ldom)) {
              if (current_day.isAfter(fdom) || current_day.isEqual(fdom)) {
                pay_periods.push(current_day);
              }
              current_day = current_day.plusDays(pay_period_length);
            }
          }
          break;
      }
      return pay_periods;
    },
    getTradeRangeDates(day) {
      const trade_range_granularity = this.$store.state.client.settings
        .change_requests_trade_range_granularity;
      const trade_range_quantity = this.$store.state.client.settings
        .change_requests_trade_range_quantity;
      let start_date = day;
      let end_date = day;

      let current_index = null;
      let quantity = trade_range_quantity;
      switch (trade_range_granularity) {
        case TRADE_RANGE_CUSTOM_DAYS:
          if (trade_range_quantity > 1) {
            quantity = trade_range_quantity - 1;
            start_date = day.minusDays(quantity);
            end_date = day.plusDays(quantity);
          }
          break;
        case TRADE_RANGE_CUSTOM_MONTHS:
          start_date, (end_date = this.getClientMonthRangeMonthly(day));
          if (trade_range_quantity > 1) {
            quantity = trade_range_quantity - 1;
            let start_result = this.getClientMonthRangeMonthly(
              day.minusMonths(quantity)
            );
            let end_result = this.getClientMonthRangeMonthly(
              day.plusMonths(quantity)
            );
            start_date = start_result[0];
            end_date = end_result[1];
          }
          break;
        case TRADE_RANGE_CUSTOM_PAY_PERIODS:
          for (let [pay_period, index] of this.getAllPayPeriodRanges) {
            const start_pay_period = pay_period[0];
            const end_pay_period = pay_period[1];
            const day_is_after_or_equal_start =
              start_pay_period.isBefore(day) || start_pay_period.isEqual(day);
            const day_is_before_or_equal_end =
              end_pay_period.isAfter(day) || end_pay_period.isEqual(day);
            if (day_is_after_or_equal_start && day_is_before_or_equal_end) {
              current_index = index;
              break;
            }
          }
          [start_date, end_date] = this.getAllPayPeriodRanges[current_index]; // # set initial to prevent error
          if (current_index && trade_range_quantity > 1) {
            quantity = trade_range_quantity - 1;
            const start_index = current_index - quantity;
            const end_index = current_index + quantity;
            const start_result = this.getAllPayPeriodRanges[start_index];
            start_date = start_result[0];
            const end_result = this.getAllPayPeriodRanges[end_index];
            end_date = end_result[1];
          }
          break;
        case TRADE_RANGE_CUSTOM_CALENDARS:
          [start_date, end_date] = this.getClientMonthRange(day);
          if (trade_range_quantity > 1) {
            quantity = trade_range_quantity - 1;
            const fdom = start_date;
            start_date = this.getPrevCalendarMonth(fdom, quantity);
            const next_month = this.getNextCalendarMonth(fdom, quantity);
            const end_result = this.getClientMonthRange(next_month);
            end_date = end_result[1];
          }
          break;
      }
      return [start_date, end_date];
    },
    getFriday(dt) {
      /*
        :type dt: datetime.date
        :rtype: datetime.date
        */
      const friday_weekday = 4;
      return this.firstDay(dt, friday_weekday);
    },
    getSaturday(dt) {
      /*
      :type dt: datetime.date
      :rtype: datetime.date
      */
      const saturday_weekday = 5;
      return this.firstDay(dt, saturday_weekday);
    },
    firstDay(dt, weekday) {
      /*
      :type dt: datetime.date
      :type weekday: int
      :rtype: datetime.date
      */
      if (![1, 2, 3, 4, 5, 6, 7].includes(weekday)) throw "Weekday is invalid!";
      const day_weekday = dt.dayOfWeek();

      if (day_weekday > weekday) {
        weekday += 7;
      }

      return dt.plusDays(weekday - day_weekday);
    },
    getDaysRange(start_date, end_date) {
      /* list of days from start_date to end_date

      @param date start_date:
      @param date end_date:
      @return list(date):
      */
      let days = [];
      let current = start_date;
      while (current.isBefore(end_date) || current.isEqual(end_date)) {
        days.push(current);
        current = current.plusDays(1);
      }
      return days;
    },
    getAllFirstDayOfMonths(first_day_of_month = null) {
      let first_fdom = first_day_of_month
        ? first_day_of_month
        : this.getFirstFdom();
      let last_fdom = this.getLastFdom();
      let fdom_list = [];
      let fdom = first_fdom;
      while (fdom.isBefore(last_fdom)) {
        fdom_list.push(fdom);
        fdom = this.getNextCalendarMonth(fdom);
      }
      let years = [];
      let map = new Map();
      for (let fdom of fdom_list) {
        if (!map.has(fdom.year())) {
          map.set(fdom.year(), true);
          years.push(fdom.year());
        }
      }
      let yearly_set = [];
      for (let year of years) {
        let yearly_fdom_list = fdom_list.filter(list_fdom => {
          return list_fdom.year() === year;
        });
        yearly_set.push([year, yearly_fdom_list]);
      }
      return yearly_set;
    },
    getFdomsRange(start_date, end_date) {
      /*
      Returns a list of unique first_day_of_month dates
      for all dates in the range.
      */
      let days = this.getDaysRange(start_date, end_date);
      let fdoms = [];
      let map = new Map();
      for (let day of days) {
        // noinspection JSUnusedLocalSymbols
        // eslint-disable-next-line no-unused-vars
        let [fdom, ldom] = this.getClientMonthRange(day);
        if (!map.has(fdom.toString())) {
          map.set(fdom.toString(), true);
          fdoms.push(fdom);
        }
      }
      return fdoms;
    },
    getFdomsForMonth(d) {
      if (typeof d === "string") {
        d = LocalDate.parse(d);
      }
      if (!d) {
        d = LocalDate.now(this.$store.state.client.settings.timezone);
      }
      // Return list of fdoms for any given date for the month of the date.
      return this.getFdomsRange(
        d.withDayOfMonth(1),
        d.with(TemporalAdjusters.lastDayOfMonth())
      );
    },
    getFirstFdom() {
      // noinspection JSUnusedLocalSymbols
      // eslint-disable-next-line no-unused-vars
      let [fdom, ldom] = this.getClientMonthRange(new LocalDate(2014, 11, 1));
      return fdom;
    },
    getLastFdom() {
      /*Returns the last fdom that we care about. i.e. the last
       month where we automatically create recurring objects.
       */
      let now_date = this.cachedNowDate; //.toLocalDate();
      let last_fdom = null;
      let fdom = null;
      // eslint-disable-next-line no-unused-vars
      let ldom = null;
      let days = 0;
      if (this.isCalendar28Day) {
        [fdom, ldom] = this.getClientMonthRange(now_date);
        days = 25 * 28;
        last_fdom = fdom.plusDays(days);
      } else if (this.isCalendar14Day) {
        [fdom, ldom] = this.getClientMonthRange(now_date);
        days = 50 * 14;
        last_fdom = fdom.plusDays(days);
      } else {
        last_fdom = now_date.plusMonths(25);
        last_fdom = last_fdom.withDayOfMonth(1);
      }
      return last_fdom;
    },
    getCalendarOffset(dt) {
      let first_date = new LocalDate(2015, 1, 1);
      return first_date.until(dt, ChronoUnit.DAYS);
    },
    getClientMonthRangeMonthly(dt) {
      if (!dt) {
        dt = LocalDate.now(this.$store.state.client.settings.timezone);
      } else if (dt.toLocalDate) {
        dt = dt.toLocalDate();
      }
      let fdom = dt.withDayOfMonth(1);
      // eslint-disable-next-line no-unused-vars
      let last_day = fdom.lengthOfMonth();
      let ldom = dt.withDayOfMonth(last_day);
      return [fdom, ldom];
    },
    getClientMonthRangeNDay(dt, start, n_days) {
      if (!dt) {
        dt = LocalDate.now(this.$store.state.client.settings.timezone);
      }
      let diff_days = start.until(dt, ChronoUnit.DAYS);
      let nth_month = Math.floor(diff_days / n_days);
      let fdom = start.plusDays(nth_month * n_days);
      let ldom = fdom.plusDays(n_days - 1);
      return [fdom, ldom];
    },
    getClientMonthRange(dt, calendar_type = null) {
      /*
    given a date, get first_day_of_month and last_day_of_month from that date
    considering client's calendar type
    */
      if (calendar_type === null) {
        calendar_type = this.$store.state.client.settings.calendar_type;
        //console.log("cal type", calendar_type, this.$store.state);
      }
      let start = null;
      if (calendar_type === CT_28_DAY) {
        start = this.getCalendar28DayStart;
        //console.log('start', start);
        return this.getClientMonthRangeNDay(dt, start, 28);
      } else if (calendar_type === CT_14_DAY) {
        start = this.getCalendar14DayStart;
        //console.log("14d");
        return this.getClientMonthRangeNDay(dt, start, 14);
      } else {
        //console.log("else");
        return this.getClientMonthRangeMonthly(dt);
      }
    },
    getClientFirstDayOfMonth(dt, calendar_type = null) {
      return this.getClientMonthRange(dt, calendar_type)[0];
    },
    getNextCalendarMonth(fdom, months = 1) {
      let next_fdom = null;
      if (this.isCalendarMonthly) {
        next_fdom = fdom.plusMonths(months);
      } else {
        let days = 0;
        if (this.isCalendar28Day) {
          days = months * 28;
        } else if (this.isCalendar14Day) {
          days = months * 14;
        }
        next_fdom = fdom.plusDays(days);
      }
      return next_fdom;
    },
    getPrevCalendarMonth(fdom, months = 1) {
      let prev_fdom = null;
      if (this.isCalendarMonthly) {
        prev_fdom = fdom.minusMonths(months);
      } else {
        let days = 0;
        if (this.isCalendar28Day) {
          days = months * 28;
        } else if (this.isCalendar14Day) {
          days = months * 14;
        }
        prev_fdom = fdom.minusDays(days);
      }
      return prev_fdom;
    }
  }
};
