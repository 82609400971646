export const ADD_GROUP_SHIFT = "ADD_GROUP_SHIFT";
export const REMOVE_GROUP_SHIFT = "REMOVE_GROUP_SHIFT";
export const REMOVE_SHIFT_FROM_GROUPS = "REMOVE_SHIFT_FROM_GROUPS";
export const DELETE_PERIOD_FROM_QUEUE = "DELETE_PERIOD_FROM_QUEUE";
export const DELETE_QUEUED_PERIOD_DATA = "DELETE_QUEUED_PERIOD_DATA";
export const INITIAL_PERIOD_LOADED = "INITIAL_PERIOD_LOADED";
export const LOAD_CLIENT = "LOAD_CLIENT";
export const LOAD_PERIOD = "LOAD_PERIOD";
export const LOAD_TIME_BANK_BALANCES = "LOAD_TIME_BANK_BALANCES";
export const LOAD_PHONE_NUMBERS = "LOAD_PHONE_NUMBERS";
export const POP_PERIOD_REQUEST = "POP_PERIOD_REQUEST";
export const QUEUE_PERIOD = "QUEUE_PERIOD";
export const RESET_STATE = "RESET_STATE";
export const SAVE_INITIAL_CLIENT = "SAVE_INITIAL_CLIENT";
export const SAVE_PERIOD = "SAVE_PERIOD";
export const SAVE_PHONE_NUMBERS = "SAVE_PHONE_NUMBERS";
export const SAVE_SELECTED_ID = "SAVE_SELECTED_ID";
export const SAVE_SHORTAGES = "SAVE_SHORTAGES";
export const SAVE_TIME_BANK_BALANCES = "SAVE_TIME_BANK_BALANCES";
export const SET_SELECTED_ID = "SET_SELECTED_ID";
export const SET_GROUPS = "SET_GROUPS";
export const SET_LOCATIONS = "SET_LOCATIONS";
export const SET_PEOPLE = "SET_PEOPLE";
export const SET_ROLES = "SET_ROLES";
export const SET_PERIODS = "SET_PERIODS";
export const SET_SCHEDULES = "SET_SCHEDULES";
export const SET_SHIFT_TIMES = "SET_SHIFT_TIMES";
export const SET_TIME_OFF_TYPES = "SET_TIME_OFF_TYPES";
export const SET_SETTINGS = "SET_SETTINGS";
export const SET_DAY_NOTES = "SET_DAY_NOTES";
export const SET_PERIOD_QUEUE = "SET_PERIOD_QUEUE";
export const SET_QUEUED_PERIOD_DATA = "SET_QUEUED_PERIOD_DATA";
export const SET_PERIOD_LIST = "SET_PERIOD_LIST";
export const SET_TIME_BANK_BALANCES = "SET_TIME_BANK_BALANCES";
export const SET_INITIAL_PERIOD_LOADED = "SET_INITIAL_PERIOD_LOADED";
export const SET_LOADING_REQUESTED = "SET_LOADING_REQUESTED";
export const UPDATE_TIME_OFF_TYPE = "UPDATE_TIME_OFF_TYPE";
export const UPDATE_TIME_BANK_BALANCE = "UPDATE_TIME_BANK_BALANCE";
export const UPDATE_MODEL = "UPDATE_MODEL";
export const UPDATE_MONTHLY_MODEL = "UPDATE_MONTHLY_MODEL";
export const UPDATE_PERSON = "UPDATE_PERSON";
