export default {
  isAdmin: state => {
    return state.user.permissions.admin;
  },
  isSupervisor: (state, getters, rootState, rootGetters) => {
    let shift = rootGetters["shifts/requestShift"];
    if (!shift) return false;
    if (!getters.isAdmin && state.person.subordinates.modify_schedules) {
      let modifySchedules =
        state.person.subordinates.modify_schedules[shift.assigned_to];
      if (modifySchedules) {
        return (
          modifySchedules.days.includes(shift.day) ||
          modifySchedules.periods.includes(shift.period) ||
          modifySchedules.shifts.includes(shift.id)
        );
      }
    }

    return false;
  },
  user: state => {
    return state.user;
  },
  selectedClient: state => {
    return state.selected_client_id;
  },
  clients: state => {
    if (state.clients === null) return {};
    return state.clients;
  },
  person: state => {
    return state.person;
  },
  token: state => {
    return state.token;
  },
  slides: state => {
    return state.slides;
  },
  authenticated: state => {
    return state.authenticated;
  },
  authenticating: state => {
    return state.authenticating;
  },
  submitted: state => {
    return state.submitted;
  },
  errors: state => {
    return state.errors;
  },
  loading: state => {
    return state.loading;
  },
  needs_two_factor: state => {
    return state.needs_two_factor;
  },
  type_two_factor: state => {
    return state.type_two_factor;
  },
  has_two_factor: state => {
    return state.has_two_factor;
  },
  label_two_factor: state => {
    return state.label_two_factor;
  }
};
