import {
  HIDE_EDIT_MODAL,
  RESET_CHANGE_FORM,
  RESET_CLAIM_FORM,
  RESET_EDIT_FORM,
  RESET_EXTRA_FORM,
  RESET_HOLDOVER_FORM,
  RESET_PAY_PREFERENCE,
  RESET_RELEASE_FORM,
  RESET_STATE,
  RESET_TRADE_FORM,
  SET_CHANGE_TIME,
  SET_CHANGE_TYPE,
  SET_CLAIM_SHIFT_TIME,
  SET_CLAIM_SHIFT_TYPE,
  SET_PARTIAL_CLAIM_OPTION,
  SET_CUSTOM_TIME_END,
  SET_CUSTOM_TIME_START,
  SET_CUSTOM_TIME_STARTS_ON_PREVIOUS_DAY,
  SET_EDIT_SHIFT_ASSET_EXPANDED,
  SET_EDIT_SHIFT_ASSET_REMOVE,
  SET_EDIT_SHIFT_CHANGE_EXPANDED,
  SET_EDIT_SHIFT_CHANGE_REMOVE,
  SET_EDIT_SHIFT_CUSTOM_TIME_END,
  SET_EDIT_SHIFT_CUSTOM_TIME_START,
  SET_EDIT_SHIFT_GROUP,
  SET_EDIT_SHIFT_NOTE,
  SET_EDIT_SHIFT_STATE,
  SET_EDIT_SHIFT_TIME,
  SET_INVALID_CUSTOM_TIME_END,
  SET_INVALID_CUSTOM_TIME_START,
  SET_INVALID_TARGET_CUSTOM_TIME_END,
  SET_INVALID_TARGET_CUSTOM_TIME_START,
  SET_HOLDOVER_LENGTH,
  SET_NOTE,
  SET_MOD_CHANGE,
  SET_MOD_NEW_TYPE,
  SET_PAY_PREFERENCE,
  SET_PERSON_COVERING,
  SET_SELECTED_DATE,
  SET_RELEASE_TYPE,
  SET_SPLIT_PAY_PREFERENCE_PAID,
  SET_SPLIT_PAY_PREFERENCE_TIME,
  SET_TARGET_CUSTOM_TIME_END,
  SET_TARGET_CUSTOM_TIME_START,
  SET_TARGET_SHIFT,
  SET_TIME_OFF_TYPE,
  SET_TIME_OFF_TYPE_REMAINING_TIME,
  SET_TRADE_TARGET,
  SET_TRADE_TYPE,
  UPDATE_CUSTOM_FORM,
  UPDATE_CUSTOM_FORM_VALUE,
  UPDATE_PAY_PREFERENCE_SPLIT,
  SET_EDIT_SHIFT_LOCATION,
  SET_EDIT_SHIFT_ROLE,
  SET_IS_EXTRA_SHIFT,
  SET_SHIFT_CHANGE_END,
  SET_SHIFT_CHANGE_START,
  SET_SHIFT_TIME,
  SET_SHIFT_OTHER_TIME_END,
  SET_SHIFT_OTHER_TIME_START,
  SHOW_EDIT_MODAL,
  SET_FDOM,
  SET_CLAIM_DAY,
  SET_SINGLE_TIME_OFF_TYPE,
  ADD_TIME_OFF_TYPE,
  REMOVE_TIME_OFF_TYPE,
  ADD_SPLIT_TIME_OPTION
} from "./mutation_types";
import { initial_state } from "./state";
import { SET_VALUE } from "../../mutation-types";
import cloneDeep from "lodash.clonedeep";

export default {
  async [ADD_SPLIT_TIME_OPTION]({ commit }, payload) {
    await commit(ADD_SPLIT_TIME_OPTION, payload);
  },
  async [ADD_TIME_OFF_TYPE]({ commit }, payload) {
    await commit(ADD_TIME_OFF_TYPE, payload);
  },
  async [HIDE_EDIT_MODAL]({ commit }) {
    await commit(HIDE_EDIT_MODAL);
  },
  async [RESET_CHANGE_FORM]({ commit }) {
    await commit(RESET_CHANGE_FORM);
  },
  async [SET_IS_EXTRA_SHIFT]({ commit }, payload) {
    await commit(SET_IS_EXTRA_SHIFT, payload);
  },
  async [REMOVE_TIME_OFF_TYPE]({ commit }, payload) {
    //console.log(REMOVE_TIME_OFF_TYPE, payload);
    await commit(REMOVE_TIME_OFF_TYPE, payload);
  },
  async [RESET_CLAIM_FORM]({ commit }) {
    await commit(RESET_CLAIM_FORM);
  },
  async [RESET_EDIT_FORM]({ commit }) {
    await commit(RESET_EDIT_FORM);
  },
  async [RESET_EXTRA_FORM]({ commit }) {
    await commit(RESET_EXTRA_FORM);
  },
  async [RESET_HOLDOVER_FORM]({ commit }) {
    await commit(RESET_HOLDOVER_FORM);
  },
  async [RESET_PAY_PREFERENCE]({ commit }) {
    await commit(RESET_PAY_PREFERENCE);
  },
  async [RESET_TRADE_FORM]({ commit, rootState }) {
    await commit(RESET_TRADE_FORM, rootState.client.settings);
  },
  async [RESET_RELEASE_FORM]({ commit, rootState }, payload) {
    await commit(RESET_RELEASE_FORM, {
      isCover: !(
        rootState.client.settings.change_request_types.release &&
        (payload.myShift || payload.canEdit)
      )
    });
  },
  async [RESET_STATE]({ commit }) {
    let state_keys = Object.keys(initial_state);
    for (let key of state_keys) {
      await commit(SET_VALUE, {
        key: key,
        value: cloneDeep(initial_state[key])
      });
    }
  },
  async [SET_CHANGE_TIME]({ commit }, payload) {
    await commit(SET_CHANGE_TIME, payload.target.value);
  },
  async [SET_CHANGE_TYPE]({ commit }, payload) {
    await commit(SET_CHANGE_TYPE, payload.target.value);
  },
  async [SET_CLAIM_DAY]({ commit }, payload) {
    await commit(
      SET_CLAIM_DAY,
      payload.target ? payload.target.value : payload
    );
  },
  async [SET_CLAIM_SHIFT_TIME]({ commit }, payload) {
    await commit(SET_CLAIM_SHIFT_TIME, payload.target.value);
  },
  async [SET_CLAIM_SHIFT_TYPE]({ commit }, payload) {
    await commit(SET_CLAIM_SHIFT_TYPE, payload.target.value);
  },
  async [SET_CUSTOM_TIME_END]({ commit, dispatch }, payload) {
    await commit(SET_CUSTOM_TIME_END, payload);
    await dispatch(UPDATE_PAY_PREFERENCE_SPLIT);
  },
  async [SET_CUSTOM_TIME_START]({ commit, dispatch }, payload) {
    await commit(SET_CUSTOM_TIME_START, payload);
    await dispatch(UPDATE_PAY_PREFERENCE_SPLIT);
  },
  async [SET_CUSTOM_TIME_STARTS_ON_PREVIOUS_DAY]({ commit }, payload) {
    await commit(SET_CUSTOM_TIME_STARTS_ON_PREVIOUS_DAY, payload);
  },
  async [SET_PARTIAL_CLAIM_OPTION]({ commit }, payload) {
    await commit(SET_PARTIAL_CLAIM_OPTION, payload);
  },
  async [SET_EDIT_SHIFT_ASSET_EXPANDED]({ commit }, payload) {
    await commit(SET_EDIT_SHIFT_ASSET_EXPANDED, payload);
  },
  async [SET_EDIT_SHIFT_ASSET_REMOVE]({ commit }, payload) {
    await commit(SET_EDIT_SHIFT_ASSET_REMOVE, payload);
  },
  async [SET_EDIT_SHIFT_CHANGE_EXPANDED]({ commit }, payload) {
    await commit(SET_EDIT_SHIFT_CHANGE_EXPANDED, payload);
  },
  async [SET_EDIT_SHIFT_CHANGE_REMOVE]({ commit }, payload) {
    await commit(SET_EDIT_SHIFT_CHANGE_REMOVE, payload);
  },
  async [SET_EDIT_SHIFT_CUSTOM_TIME_END]({ commit }, payload) {
    await commit(SET_EDIT_SHIFT_CUSTOM_TIME_END, payload);
  },
  async [SET_EDIT_SHIFT_CUSTOM_TIME_START]({ commit }, payload) {
    await commit(SET_EDIT_SHIFT_CUSTOM_TIME_START, payload);
  },
  async [SET_EDIT_SHIFT_GROUP]({ commit }, payload) {
    await commit(SET_EDIT_SHIFT_GROUP, payload);
  },
  async [SET_EDIT_SHIFT_LOCATION]({ commit }, payload) {
    await commit(SET_EDIT_SHIFT_LOCATION, payload);
  },
  async [SET_EDIT_SHIFT_NOTE]({ commit }, payload) {
    await commit(SET_EDIT_SHIFT_NOTE, payload);
  },
  async [SET_EDIT_SHIFT_ROLE]({ commit }, payload) {
    await commit(SET_EDIT_SHIFT_ROLE, payload);
  },
  async [SET_EDIT_SHIFT_STATE]({ commit }, payload) {
    await commit(SET_EDIT_SHIFT_STATE, payload);
  },
  async [SET_EDIT_SHIFT_TIME]({ commit }, payload) {
    await commit(SET_EDIT_SHIFT_TIME, payload);
  },
  async [SET_FDOM]({ commit }, payload) {
    await commit(SET_FDOM, payload);
  },
  async [SET_HOLDOVER_LENGTH]({ commit }, payload) {
    await commit(SET_HOLDOVER_LENGTH, payload.target.value);
  },
  async [SET_INVALID_CUSTOM_TIME_END]({ commit }, payload) {
    await commit(SET_INVALID_CUSTOM_TIME_END, payload);
  },
  async [SET_INVALID_CUSTOM_TIME_START]({ commit }, payload) {
    await commit(SET_INVALID_CUSTOM_TIME_START, payload);
  },
  async [SET_INVALID_TARGET_CUSTOM_TIME_END]({ commit }, payload) {
    await commit(SET_INVALID_TARGET_CUSTOM_TIME_END, payload);
  },
  async [SET_INVALID_TARGET_CUSTOM_TIME_START]({ commit }, payload) {
    await commit(SET_INVALID_TARGET_CUSTOM_TIME_START, payload);
  },
  async [SET_NOTE]({ commit }, payload) {
    await commit(SET_NOTE, payload.target.value);
  },
  async [SET_MOD_CHANGE]({ commit }, payload) {
    await commit(SET_MOD_CHANGE, payload.target.value);
  },
  async [SET_MOD_NEW_TYPE]({ commit, dispatch }, payload) {
    await commit(SET_MOD_NEW_TYPE, payload.target.value);
    await dispatch(UPDATE_CUSTOM_FORM);
  },
  async [SET_PAY_PREFERENCE]({ commit, dispatch }, payload) {
    await commit(SET_PAY_PREFERENCE, payload.target.value);
    await dispatch(UPDATE_PAY_PREFERENCE_SPLIT);
  },
  async [SET_PERSON_COVERING]({ commit }, payload) {
    await commit(SET_PERSON_COVERING, parseInt(payload.target.value));
  },
  async [SET_RELEASE_TYPE]({ commit, dispatch }, payload) {
    await dispatch("request/RESET_MIN_STAFFING_RISK", {}, { root: true });
    await commit(SET_HOLDOVER_LENGTH, 0);
    await commit(SET_TIME_OFF_TYPE, 0);
    await commit(SET_RELEASE_TYPE, payload.target.value);
  },
  async [SET_SELECTED_DATE]({ commit }, payload) {
    await commit(SET_SELECTED_DATE, payload);
  },
  async [SET_SHIFT_TIME]({ commit }, payload) {
    await commit(SET_SHIFT_TIME, payload);
  },
  async [SET_SHIFT_OTHER_TIME_END]({ commit }, payload) {
    await commit(SET_SHIFT_OTHER_TIME_END, payload);
  },
  async [SET_SHIFT_OTHER_TIME_START]({ commit }, payload) {
    await commit(SET_SHIFT_OTHER_TIME_START, payload);
  },
  async [SET_SHIFT_CHANGE_END]({ commit }, payload) {
    await commit(SET_SHIFT_CHANGE_END, payload);
  },
  async [SET_SHIFT_CHANGE_START]({ commit }, payload) {
    await commit(SET_SHIFT_CHANGE_START, payload);
  },
  async [SET_SINGLE_TIME_OFF_TYPE]({ commit }, payload) {
    //console.log(SET_SINGLE_TIME_OFF_TYPE, payload);
    await commit(SET_SINGLE_TIME_OFF_TYPE, payload);
  },
  async [SET_SPLIT_PAY_PREFERENCE_PAID]({ commit, getters }, payload) {
    let value = payload.target.value;
    let change_hours = getters.changeLengthHours;
    if (value >= 0 && value <= change_hours) {
      await commit(SET_SPLIT_PAY_PREFERENCE_PAID, value);
      await commit(SET_SPLIT_PAY_PREFERENCE_TIME, change_hours - value);
    }
  },
  async [SET_SPLIT_PAY_PREFERENCE_TIME]({ commit, getters }, payload) {
    let value = payload.target.value;
    let change_hours = getters.changeLengthHours;
    if (value >= 0 && value <= change_hours) {
      await commit(SET_SPLIT_PAY_PREFERENCE_TIME, value);
      await commit(SET_SPLIT_PAY_PREFERENCE_PAID, change_hours - value);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [SET_TARGET_CUSTOM_TIME_END]({ commit, dispatch }, payload) {
    await commit(SET_TARGET_CUSTOM_TIME_END, payload);
    //await dispatch(UPDATE_PAY_PREFERENCE_SPLIT);
  },
  // eslint-disable-next-line no-unused-vars
  async [SET_TARGET_CUSTOM_TIME_START]({ commit, dispatch, getters }, payload) {
    await commit(SET_TARGET_CUSTOM_TIME_START, payload);
    //console.log(payload);
    await commit(
      SET_TARGET_CUSTOM_TIME_END,
      payload.plusHours(getters.changeLength)
    );
    //await dispatch(UPDATE_PAY_PREFERENCE_SPLIT);
  },
  async [SET_TARGET_SHIFT]({ commit }, payload) {
    await commit(SET_TARGET_SHIFT, payload.target.value);
  },
  async [SET_TIME_OFF_TYPE]({ commit, dispatch }, payload) {
    //console.log(SET_TIME_OFF_TYPE, payload);
    await commit(SET_TIME_OFF_TYPE, parseInt(payload.target.value));
    await dispatch(UPDATE_CUSTOM_FORM);
  },
  async [SET_TIME_OFF_TYPE_REMAINING_TIME]({ commit }, payload) {
    await commit(SET_TIME_OFF_TYPE_REMAINING_TIME, payload);
  },
  async [SET_TRADE_TARGET]({ commit, rootState }, payload) {
    if (rootState.client.settings.change_request_types.partial_trade) {
      //state.requestTime = "full";
      //await commit(SET_REQUEST_TIME, "full")
    }
    if (rootState.client.settings.change_request_types.benefit_time_trade) {
      await commit(SET_TRADE_TYPE, "shift");
    }
    await commit(SET_TRADE_TARGET, payload.target.value);
  },
  async [SET_TRADE_TYPE]({ commit }, payload) {
    await commit(SET_TRADE_TYPE, payload.target.value);
  },
  async [SHOW_EDIT_MODAL]({ commit }) {
    await commit(SHOW_EDIT_MODAL);
  },
  async [UPDATE_CUSTOM_FORM]({ commit, state, rootState }, payload) {
    let custom_form = [];
    let custom_form_id = 0;
    let time_off_types = rootState.client.time_off_types;
    if (state.modNewType > 0) {
      custom_form_id = time_off_types[state.modNewType].custom_form;
    } else if (state.timeOffTypeSelected > 0) {
      custom_form_id = time_off_types[state.timeOffTypeSelected].custom_form;
    } else {
      if (!payload) {
        await commit(UPDATE_CUSTOM_FORM, custom_form);
        return;
      }
    }
    if (!custom_form_id) {
      await commit(UPDATE_CUSTOM_FORM, custom_form);
      return;
    }
    let client_custom_forms = rootState.client.custom_forms;
    if (Object.keys(client_custom_forms).length === 0) {
      await commit(UPDATE_CUSTOM_FORM, custom_form);
      return;
    }
    /*
    This breaks reactivity....
    if (
      state.customForm.length > 0 &&
      custom_form_id === state.customForm[0].form_id
    ) {
      /// Seems redundant...
      console.log("redundant");
      custom_form = state.customForm;
      await commit(UPDATE_CUSTOM_FORM, custom_form);
      return;
    }*/
    const form = client_custom_forms[custom_form_id];
    if (form) {
      Object.values(form.elements).forEach(field => {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        custom_form.push({
          value: "",
          form_id: custom_form_id,
          choices: field.form_element_choices,
          form_type: field.form_type,
          form_type_display: field.form_type_display,
          id: field.id,
          name: field.name,
          ordinal_number: field.ordinal_number,
          required: field.required,
        });
      });
    }
    await commit(UPDATE_CUSTOM_FORM, custom_form);
  },
  async [UPDATE_CUSTOM_FORM_VALUE]({ commit }, payload) {
    await commit(UPDATE_CUSTOM_FORM_VALUE, payload);
  },
  // eslint-disable-next-line no-unused-vars
  async [UPDATE_PAY_PREFERENCE_SPLIT]({ commit, getters }) {
    let split_hours = getters.changeLengthHours / 2;
    await commit(SET_SPLIT_PAY_PREFERENCE_TIME, split_hours);
    await commit(SET_SPLIT_PAY_PREFERENCE_PAID, split_hours);
  }
};
