import {
  CHECK_MIN_STAFFING,
  CHECK_SHORTAGES,
  DELETE_SHIFT_CHANGE,
  DELETE_SHIFT_TIME_DAY_DETAIL,
  RESET_STATE,
  //SAVE_INITIAL_SHIFT,
  SAVE_INITIAL_SHIFT_CHANGE,
  SAVE_SHIFT,
  SAVE_SHIFT_CHANGE,
  SAVE_SHIFTS,
  SAVE_SHIFT_CHANGES,
  SAVE_SHIFT_TIME_DAY_DETAIL,
  UPDATE_MODERATORS,
  UPDATE_SHIFT_IDS,
  UPDATE_SHIFT,
  UPDATE_SHIFT_CHANGE,
  UPDATE_SHIFT_TIME,
  UPDATE_SHIFT_TIME_DAY_DETAIL,
  QUEUE_SHIFT,
  BUCKET_SHIFTS,
  SET_PROCESSING_SHORTAGE_REQUEST,
  DELETE_SHIFT,
  APPEND_CHANGE_TO_SHIFT,
  UPDATE_MULTIPLE_SHIFTS,
  UPDATE_PERSON_SHIFT_RANGE
} from "./mutation_types";
import { CHECK_DELETE_TASK } from "../task/mutation_types";
import { APPROVAL_CANCELLED } from "../../constants/shiftChange";
import { ZonedDateTime, LocalDate } from "@js-joda/core";
import {
  SET_SCHEDULER_BUSY,
  SET_TASKS_UPDATE_REQUESTED,
  SET_VALUE
} from "../../mutation-types";
import Vue from "vue";
//import { QUEUE_SHORTAGE_REQUEST } from "../calendar/mutation_types";
import { initial_state } from "./state";
import cloneDeep from "lodash.clonedeep";
import {
  //_formatTime,
  _get_client_first_day_of_month,
  _get_next_calendar_month
} from "../../time";
import { SET_ERRORS } from "../auth/mutation_types";
import _ from "lodash";
import * as Sentry from "@sentry/browser";
import {
  ADD_GROUP_SHIFT,
  REMOVE_SHIFT_FROM_GROUPS
} from "../client/mutation_types";
import { setScope } from "../../utils";
// eslint-disable-next-line no-unused-vars
const array_chunks = (array, chunk_size) =>
  Array(Math.ceil(array.length / chunk_size))
    .fill()
    .map((_, index) => index * chunk_size)
    .map(begin => array.slice(begin, begin + chunk_size));
export default {
  // eslint-disable-next-line no-unused-vars
  async [CHECK_SHORTAGES]({ commit, dispatch, state, rootState }, payload) {
    await dispatch(SET_SCHEDULER_BUSY, true, { root: true });
    await commit(SET_PROCESSING_SHORTAGE_REQUEST, true);
    let url = rootState.api_url + `/shortages/` + rootState.client.id + `/`;
    let data = JSON.stringify(payload);
    if (!data.length) {
      await commit(SET_PROCESSING_SHORTAGE_REQUEST, false);
      await dispatch(SET_SCHEDULER_BUSY, false, { root: true });
      return;
    }
    setScope(url);
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + rootState.auth.access_token
      },
      body: data
    })
      .then(async response => {
        Sentry.configureScope(scope => {
          scope.setExtra("api_response", response);
        });
        if (response.status === 401) {
          await dispatch(
            "auth/REFRESH_LOGIN",
            { action: "shifts/CHECK_SHORTAGES", payload: payload },
            { root: true }
          );
        } else if (response.status === 200) {
          response
            .json()
            .then(async jd => {
              Sentry.configureScope(scope => {
                scope.setExtra("json_response", jd);
              });
              //await dispatch("calendar/DELETE_SHORTAGES", jd.delete, { root: true });
              Vue.nextTick(() => {
                dispatch("calendar/SAVE_SHORTAGES", JSON.parse(jd.data), {
                  root: true
                });
              });
              //  });
              await commit(SET_PROCESSING_SHORTAGE_REQUEST, false);
              await dispatch(SET_SCHEDULER_BUSY, false, { root: true });
            })
            .catch(async e => {
              Sentry.captureException(e);
              await commit(SET_PROCESSING_SHORTAGE_REQUEST, false);
              await dispatch(SET_SCHEDULER_BUSY, false, { root: true });
              console.log(e);
            });
        } else {
          await commit(SET_PROCESSING_SHORTAGE_REQUEST, false);
          await dispatch(SET_SCHEDULER_BUSY, false, { root: true });
        }
      })
      .catch(async e => {
        Sentry.captureException(e);
        await commit(SET_PROCESSING_SHORTAGE_REQUEST, false);
        await dispatch(SET_SCHEDULER_BUSY, false, { root: true });
        console.log(e);
      });
  },
  // eslint-disable-next-line no-unused-vars
  async [CHECK_MIN_STAFFING]({ commit, dispatch, state, rootState }, payload) {
    await dispatch("request/RESET_MIN_STAFFING_RISK", {}, { root: true });
    payload["data"]["client_id"] = rootState.client.id;
    let data = JSON.stringify(payload["data"]);
    let url = rootState.api_url + `/min/staffing/risk/`;
    setScope(
      url,
      payload["data"],
      null,
      null,
      rootState.form,
      rootState.request,
      null,
      null
    );
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + rootState.auth.access_token
      },
      body: data
    })
      .then(async response => {
        Sentry.configureScope(scope => {
          scope.setExtra("api_response", response);
        });
        if (response.status === 401) {
          await dispatch(
            "auth/REFRESH_LOGIN",
            { action: "shifts/CHECK_MIN_STAFFING", payload: payload },
            { root: true }
          );
          return;
        }
        if (response.status === 200) {
          response
            .json()
            .then(async jd => {
              Sentry.configureScope(scope => {
                scope.setExtra("json_response", jd);
              });
              if (payload.request) {
                await dispatch(
                  "request/SET_MIN_STAFFING_RISK",
                  { data: jd },
                  { root: true }
                );
              } else if (payload.task_id) {
                await dispatch(
                  "task/SAVE_MIN_STAFFING_RISK",
                  {
                    task_id: payload.task_id,
                    data: jd
                  },
                  { root: true }
                );
              }
            })
            .catch(async e => {
              Sentry.captureException(e);
              console.log(e);
            });
        }
      })
      .catch(async e => {
        Sentry.captureException(e);
        console.log(e);
      });
  },
  async [RESET_STATE]({ commit }) {
    for (const key in initial_state) {
      const value = initial_state[key];
      await commit(SET_VALUE, {
        key: key,
        value: cloneDeep(value)
      });
    }
  },
  async [QUEUE_SHIFT]({ dispatch, commit }, payload) {
    Vue.nextTick(() => {
      let shift = payload.shift; //{ ...payload.shift }; Why use spread operator?
      shift.start = ZonedDateTime.parse(shift.start_datetime);
      shift.end = ZonedDateTime.parse(shift.end_datetime);
      shift.period = LocalDate.parse(payload.period);
      shift.shift_time_id =
        shift.shift_time_id !== undefined
          ? shift.shift_time_id
          : shift.shift_time;
      shift.assigned_to =
        shift.person_id !== undefined ? shift.person_id : shift.assigned_to;
      shift.job = shift.job_id !== undefined ? shift.job_id : shift.job;
      shift.job_2 = shift.job_2_id !== undefined ? shift.job_2_id : shift.job_2;
      shift.job_3 = shift.job_3_id !== undefined ? shift.job_3_id : shift.job_3;
      for (const asset_index in shift.assets) {
        const asset = shift.assets[asset_index];
        if (asset.start_time) {
          asset.start = ZonedDateTime.parse(asset.start_time);
        }
        if (asset.end_time) {
          asset.end = ZonedDateTime.parse(asset.end_time);
        }
      }
      shift.local_day = LocalDate.parse(shift.day);

      Vue.nextTick(() => {
        commit(SAVE_SHIFT, shift);
      });
      if (shift.group || shift.assigned_to) {
        Vue.nextTick(() => {
          dispatch(
            "client/" + ADD_GROUP_SHIFT,
            {
              shift_id: shift.id,
              group_id: shift.group,
              assigned_to: shift.assigned_to
            },
            { root: true }
          );
        });
      }
    });
  },
  async [BUCKET_SHIFTS]({ dispatch }, payload) {
    for (const shift_index in payload.bucket) {
      const shift = payload.bucket[shift_index];
      dispatch(QUEUE_SHIFT, { shift: shift, period: payload.period });
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [SAVE_SHIFTS]({ state, dispatch, commit, rootState }, value) {
    const period = value.period;
    const shifts = value.shifts;
    //let non_person_shifts = [];
    for (const shift_index in shifts) {
      const shift = shifts[shift_index];
      Vue.nextTick(() => {
        dispatch(QUEUE_SHIFT, { shift: shift, period: period });
      });
    }
    //for (let shift of shifts) {
    //if (rootState.auth.person.id === shift.assigned_to) {
    //  dispatch(QUEUE_SHIFT, { shift: shift, period: period });
    //} else {
    //  non_person_shifts.push(shift);
    //}
    //}
    /*
    setTimeout( () => {
      let delay = 30;
      let chunk_size = 15;
      let i = 1;
      let non_person_shift_chunks = array_chunks(non_person_shifts, chunk_size);
      for (let chunk of non_person_shift_chunks) {
        setTimeout( () => {
          dispatch(BUCKET_SHIFTS, {
            bucket: chunk,
            period: period
          });
        }, delay * i);
        i++;
      }
    }, 50);*/
  },
  async [SAVE_SHIFT]({ commit }, shift) {
    //Object.freeze(shift);
    Vue.nextTick(() => {
      commit(SAVE_SHIFT, shift);
    });
  } /*
  async [SAVE_INITIAL_SHIFT]({ commit }, shift) {
    await commit(SAVE_INITIAL_SHIFT, shift);
  },*/,
  async [SAVE_INITIAL_SHIFT_CHANGE]({ commit, state }, change) {
    Vue.nextTick(() => {
      commit(SAVE_INITIAL_SHIFT_CHANGE, change);
    });
    Vue.nextTick(() => {
      let shift = state.shifts[change.shift_1];
      if (shift) {
        for (const change_index in shift.changes) {
          const shift_change = shift.changes[change_index];
          if (shift_change === change.id) return;
        }
        commit(APPEND_CHANGE_TO_SHIFT, {
          shift_id: shift.id,
          change_id: change.id
        });
      }
    });
  },
  // eslint-disable-next-line no-unused-vars
  async [SAVE_SHIFT_CHANGES]({ state, dispatch, rootState }, value) {
    let period = value.period;
    let changes = value.changes;
    let zone_id = rootState.client.settings.timezone;
    if (typeof period === "string") {
      period = LocalDate.parse(period);
    }
    // TODO: Investigate change start/end time not being saved/sent with timezone info.string only has a Z at the end.
    for (const change_index in changes) {
      let change = changes[change_index];
      Vue.nextTick(() => {
        change.period = period;
        change.start = ZonedDateTime.parse(change.start_datetime);
        if (zone_id) change.start = change.start.withZoneSameInstant(zone_id);
        change.end = ZonedDateTime.parse(change.end_datetime);
        if (zone_id) change.end = change.end.withZoneSameInstant(zone_id);
        dispatch(SAVE_INITIAL_SHIFT_CHANGE, change);
      });
    }
  },
  async [SAVE_SHIFT_CHANGE]({ commit, state }, change) {
    commit(SAVE_SHIFT_CHANGE, change);
    change.shift_1 =
      change.shift_1_id !== undefined ? change.shift_1_id : change.shift_1;
    change.shift_2 =
      change.shift_2_id !== undefined ? change.shift_2_id : change.shift_2;
    let shift = state.shifts[change.shift_1];
    if (shift) {
      for (const change_index in shift.changes) {
        const shift_change = shift.changes[change_index];
        if (shift_change === change.id) return;
      }
      await commit(APPEND_CHANGE_TO_SHIFT, {
        shift_id: shift.id,
        change_id: change.id
      });
    }
  },
  async [SET_PROCESSING_SHORTAGE_REQUEST]({ commit }, value) {
    await commit(SET_PROCESSING_SHORTAGE_REQUEST, value);
  },
  async [UPDATE_MODERATORS]({ dispatch }) {
    await dispatch(SET_TASKS_UPDATE_REQUESTED, true, { root: true });
  },
  // eslint-disable-next-line no-unused-vars
  async [UPDATE_MULTIPLE_SHIFTS]({ dispatch, commit }, payload) {
    switch (payload.data.action) {
      case "create" /*
        for (let shift_id of payload.data.shift_ids) {
          await dispatch(UPDATE_SHIFT, { data: { shift_id: shift_id } });
        }*/:
        await dispatch(UPDATE_SHIFT_IDS, {
          data: {
            shift_ids: payload.data.shift_ids,
            start_date: payload.data.start,
            end_date: payload.data.end
          }
        });
        break;
      case "update":
        await dispatch(UPDATE_SHIFT_IDS, {
          data: {
            shift_ids: payload.data.shift_ids,
            start_date: payload.data.start,
            end_date: payload.data.end
          }
        });
        break;
      case "remove":
        for (let shift_id of payload.data.shift_ids) {
          dispatch(
            "client/" + REMOVE_SHIFT_FROM_GROUPS,
            { shift_id: shift_id },
            { root: true }
          );
          await commit(DELETE_SHIFT, { shift_id: shift_id });
        }
        break;
      default:
        console.log("Unhandled bulk update operation - ", payload.data.action);
        break;
    }
  },
  async [UPDATE_SHIFT_IDS](
    // eslint-disable-next-line no-unused-vars
    { commit, dispatch, state, rootState },
    payload
  ) {
    let start_date = LocalDate.from(
      _get_client_first_day_of_month(LocalDate.parse(payload.data.start_date))
    );
    let end_date = LocalDate.from(
      _get_client_first_day_of_month(LocalDate.parse(payload.data.end_date))
    );
    let out_payload = {
      shift_ids: payload.data.shift_ids,
      fdom: {
        year: start_date.year(),
        month: start_date.monthValue(),
        day: start_date.dayOfMonth()
      },
      ldom: {
        year: end_date.year(),
        month: end_date.monthValue(),
        day: end_date.dayOfMonth()
      }
    };
    //console.log("shift payload", out_payload);

    let url = rootState.api_url + `/loadshifts/` + rootState.client.id + `/`;
    setScope(url, out_payload);
    let data = JSON.stringify(out_payload);
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + rootState.auth.access_token
      },
      body: data
    })
      .then(async response => {
        Sentry.configureScope(scope => {
          scope.setExtra("api_response", response);
        });
        //console.log("update person shifts API resp", response);

        if (response.status === 401) {
          await dispatch(
            "auth/REFRESH_LOGIN",
            { action: "shifts/UPDATE_PERSON_SHIFT_RANGE", payload: payload },
            { root: true }
          );
        }
        if (response.status === 200) {
          response
            .json()
            .then(async jd => {
              Sentry.configureScope(scope => {
                scope.setExtra("json_response", jd);
              });
              //console.log(jd);
              for (const shift_index in jd.shifts) {
                const shift = jd.shifts[shift_index];
                let period = _get_client_first_day_of_month(
                  ZonedDateTime.parse(shift.start_datetime)
                );
                const period_string = period.toString();
                let payload = { shift: shift, period: period_string };
                //console.log("queue ", payload);
                if (rootState.client.period_list.includes(period_string)) {
                  // Don't update/store shifts from other periods.
                  dispatch(QUEUE_SHIFT, payload);
                }
              }
            })
            .catch(error => {
              Sentry.captureException(error);
              console.log(error);
            });
        }
      })
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  },
  async [UPDATE_PERSON_SHIFT_RANGE](
    // eslint-disable-next-line no-unused-vars
    { commit, dispatch, state, rootState },
    payload
  ) {
    let start_date = LocalDate.parse(payload.data.start_date);
    let end_date = null;
    if (payload.data.end_date !== "None")
      end_date = LocalDate.parse(payload.data.end_date);
    let start_period = null;
    let end_period = null;
    let shifts = _.filter(Object.values(state.shifts), shift => {
      let assigned_to = shift.assigned_to === payload.data.person_id;
      if (!assigned_to) return false;
      let valid_date = false;
      let shift_start = LocalDate.from(shift.start);
      if (start_date && end_date) {
        valid_date =
          (shift_start.isAfter(start_date) ||
            shift_start.isEqual(start_date)) &&
          (shift_start.isBefore(end_date) || shift_start.isEqual(end_date));
      } else {
        valid_date =
          shift_start.isAfter(start_date) || shift_start.isEqual(start_date);
      }
      if (valid_date) {
        let period = shift.period;
        if (typeof shift.period === "string") {
          period = LocalDate.parse(shift.period);
        }
        if (!start_period) {
          start_period = period;
        } else if (period.isBefore(start_period)) {
          start_period = period;
        }
        if (!end_period) {
          end_period = period;
        } else if (period.isAfter(end_period)) {
          end_period = period;
        }
        return true;
      }
      return false;
    });
    if (!shifts.length) return;
    end_period = LocalDate.from(_get_next_calendar_month(end_period));
    //console.log("shift ids to update", _.map(shifts, "id"));
    let out_payload = {
      shift_ids: _.map(shifts, "id"),
      fdom: {
        year: start_period.year(),
        month: start_period.monthValue(),
        day: start_period.dayOfMonth()
      },
      ldom: {
        year: end_period.year(),
        month: end_period.monthValue(),
        day: end_period.dayOfMonth()
      }
    };
    //console.log("shift payload", out_payload);

    let url =
      rootState.api_url +
      `/personshifts/` +
      rootState.client.id +
      `/` +
      payload.data.person_id +
      `/`;
    setScope(url, out_payload);
    let data = JSON.stringify(out_payload);
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + rootState.auth.access_token
      },
      body: data
    })
      .then(async response => {
        Sentry.configureScope(scope => {
          scope.setExtra("api_response", response);
        });
        //console.log("update person shifts API resp", response);

        if (response.status === 401) {
          await dispatch(
            "auth/REFRESH_LOGIN",
            { action: "shifts/UPDATE_PERSON_SHIFT_RANGE", payload: payload },
            { root: true }
          );
        }
        if (response.status === 200) {
          response
            .json()
            .then(async jd => {
              Sentry.configureScope(scope => {
                scope.setExtra("json_response", jd);
              });
              //console.log(jd);

              _.forEach(Object.values(jd.shifts), shift => {
                let period = _get_client_first_day_of_month(
                  ZonedDateTime.parse(shift.start_datetime)
                );
                let payload = { shift: shift, period: period.toString() };
                //console.log("queue ", payload);
                dispatch(QUEUE_SHIFT, payload);
              });
            })
            .catch(error => {
              Sentry.captureException(error);
              console.log(error);
            });
        }
      })
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  },
  async [UPDATE_SHIFT]({ commit, dispatch, state, rootState }, payload) {
    let data = payload.data;
    let url = rootState.api_url + `/shifts/` + data.shift_id + `/`;
    setScope(
      url,
      null,
      null,
      null,
      null,
      null,
      null,
      state.shifts[data.shift_id]
    );
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + rootState.auth.access_token
      }
    })
      .then(async response => {
        Sentry.configureScope(scope => {
          scope.setExtra("api_response", response);
        });
        if (response.status === 500) {
          commit(SET_ERRORS, [
            "Sorry, there was an error when processing your request. The developers have been notified and will fix this bug ASAP"
          ]);
          return;
        }
        if (response.status === 401) {
          await dispatch(
            "auth/REFRESH_LOGIN",
            { action: "shifts/UPDATE_SHIFT", payload: payload },
            { root: true }
          );
        }
        if (response.status === 200) {
          response
            .json()
            .then(async jd => {
              Sentry.configureScope(scope => {
                scope.setExtra("json_response", jd);
              });
              let shift_result = jd[data.shift_id];
              for (let asset of shift_result.assets) {
                if (asset.start_time) {
                  asset.start = ZonedDateTime.parse(asset.start_time);
                }
                if (asset.end_time) {
                  asset.end = ZonedDateTime.parse(asset.end_time);
                }
              }
              shift_result.local_day = LocalDate.parse(shift_result.day);
              shift_result.start = ZonedDateTime.parse(
                shift_result.start_datetime
              );
              shift_result.end = ZonedDateTime.parse(shift_result.end_datetime);
              if (typeof data.first_day_of_month === "string") {
                data.first_day_of_month = LocalDate.parse(
                  data.first_day_of_month
                );
              }
              shift_result.period = data.first_day_of_month;
              shift_result.shift_time_id = shift_result.shift_time;
              shift_result.assigned_to = shift_result.person_id
                ? shift_result.person_id
                : shift_result.assigned_to;
              shift_result.job = shift_result.job_id
                ? shift_result.job_id
                : shift_result.job;
              shift_result.job_2 = shift_result.job_2_id
                ? shift_result.job_2_id
                : shift_result.job_2_id;
              shift_result.job_3 = shift_result.job_3_id
                ? shift_result.job_3_id
                : shift_result.job_3_id;
              await commit(SAVE_SHIFT, shift_result);
              dispatch(
                "client/" + ADD_GROUP_SHIFT,
                {
                  shift_id: shift_result.id,
                  group: shift_result.group_id,
                  assigned_to: shift_result.assigned_to
                },
                { root: true }
              );
              /*
          let shift_selected = rootState.request.shiftSelected;
          let request_data = null;
          if (shift_selected) {
            let shift = state.shifts[shift_selected];
            if (rootState.form.changeTimeSelected === "full") {
              request_data = {
                shift_id: shift_selected,
                start_time: _formatTime(
                  shift.start,
                  rootState.client.settings.am_pm
                ),
                end_time: _formatTime(shift.end, rootState.client.settings.am_pm)
              };
              dispatch(CHECK_MIN_STAFFING, { request: true, data: request_data });
            } else {
              // PARTIAL REQUEST
              let custom_start = rootState.form.customTimeStart;
              let custom_end = rootState.form.customTimeEnd;
              if (custom_start && custom_end) {
                request_data = {
                  shift_id: shift_selected,
                  start_time: _formatTime(
                    custom_start,
                    rootState.client.settings.am_pm
                  ),
                  end_time: _formatTime(custom_end, rootState.client.settings.am_pm)
                };
                dispatch(CHECK_MIN_STAFFING, { request: true, data: request_data });
              }
            }
          }*/
            })
            .catch(error => {
              Sentry.captureException(error);
              console.log(error);
            });
        }
        if (response.status === 404) {
          await commit(DELETE_SHIFT, data);
          // remove shift.
        }
      })
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  },
  async [UPDATE_SHIFT_CHANGE]({ commit, dispatch, state, rootState }, payload) {
    let data = payload.data;
    let url = rootState.api_url + `/shiftchanges/` + data.shift_change_id + `/`;
    let shift_change = state.changes[data.shift_change_id];
    setScope(
      url,
      null,
      null,
      null,
      null,
      null,
      shift_change,
      shift_change ? state.shifts[shift_change.shift_1] : null
    );
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + rootState.auth.access_token
      }
    })
      .then(async response => {
        Sentry.configureScope(scope => {
          scope.setExtra("api_response", response);
        });
        if (response.status === 500) {
          commit(SET_ERRORS, [
            "Sorry, there was an error when processing your request. The developers have been notified and will fix this bug ASAP"
          ]);
          return;
        }
        if (response.status === 401) {
          await dispatch(
            "auth/REFRESH_LOGIN",
            { action: "shifts/UPDATE_SHIFT_CHANGE", payload: payload },
            { root: true }
          );
        }
        let shift = rootState.shifts.shifts[parseInt(data.shift_id)];
        let shift_update_payload = {};
        if (shift) {
          shift_update_payload = {
            data: {
              client_id: rootState.client.id,
              shift_id: data.shift_id,
              first_day_of_month: shift.period
            }
          };
        } else {
          shift_update_payload = {
            data: {
              client_id: rootState.client.id,
              shift_id: data.shift_id,
              first_day_of_month: data.first_day_of_month
            }
          };
        }
        if (response.status === 404) {
          await commit(DELETE_SHIFT_CHANGE, data);
          await dispatch("task/" + CHECK_DELETE_TASK, data.shift_change_id, {
            root: true
          });
          await dispatch(UPDATE_SHIFT, shift_update_payload);
        }
        if (response.status === 200) {
          response.json().then(async jd => {
            if (jd) {
              Sentry.configureScope(scope => {
                scope.setExtra("json_response", jd);
              });
              let shift_change_result = jd[data.shift_change_id];
              if (shift_change_result.approval_status === APPROVAL_CANCELLED) {
                await dispatch(
                  "task/" + CHECK_DELETE_TASK,
                  shift_change_result.id,
                  {
                    root: true
                  }
                );
                await commit(DELETE_SHIFT_CHANGE, data);
                await dispatch(UPDATE_SHIFT, shift_update_payload);
              } else {
                let zone_id = rootState.client.settings.timezone;
                //jd.period = period;
                shift_change_result.start = ZonedDateTime.parse(
                  shift_change_result.start_datetime
                );
                if (zone_id)
                  shift_change_result.start = shift_change_result.start.withZoneSameInstant(
                    zone_id
                  );
                shift_change_result.end = ZonedDateTime.parse(
                  shift_change_result.end_datetime
                );
                if (zone_id)
                  shift_change_result.end = shift_change_result.end.withZoneSameInstant(
                    zone_id
                  );
                await commit(SAVE_SHIFT_CHANGE, shift_change_result);
                await dispatch(UPDATE_SHIFT, shift_update_payload);
              }
            }
          });
        }
      })
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  },
  async [UPDATE_SHIFT_TIME](context, payload) {
    console.log("update shift time");
    console.log(payload);
  },
  async [UPDATE_SHIFT_TIME_DAY_DETAIL](
    { dispatch, rootState, commit },
    payload
  ) {
    let data = payload.data;
    let url =
      rootState.api_url +
      `/shifttimedaydetails/` +
      data.shift_time_day_detail_id +
      `/`;
    setScope(url);
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + rootState.auth.access_token
      }
    })
      .then(async response => {
        Sentry.configureScope(scope => {
          scope.setExtra("api_response", response);
        });
        if (response.status === 500) {
          commit(SET_ERRORS, [
            "Sorry, there was an error when processing your request. The developers have been notified and will fix this bug ASAP"
          ]);
          return;
        }
        if (response.status === 401) {
          await dispatch(
            "auth/REFRESH_LOGIN",
            {
              action: "shifts/" + UPDATE_SHIFT_TIME_DAY_DETAIL,
              payload: payload
            },
            { root: true }
          );
        }
        if (response.status === 404) {
          await dispatch("client/" + DELETE_SHIFT_TIME_DAY_DETAIL, data, {
            root: true
          });
        }
        if (response.status === 200) {
          response.json().then(async jd => {
            Sentry.configureScope(scope => {
              scope.setExtra("json_response", jd);
            });
            if (jd) {
              data.data = jd[data.shift_time_day_detail_id];
              await dispatch("client/" + SAVE_SHIFT_TIME_DAY_DETAIL, data, {
                root: true
              });
            }
          });
        }
      })
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  }
};
