<template>
  <transition
    appear
    :duration="transitionTime"
    name="custom-classes-transition"
    mode="out-in"
    enter-active-class="animated fadeIn"
    leave-active-class="animated fadeOut"
  >
    <router-view
      :cached-now="cachedNow"
      :cached-now-date="cachedNowDate"
      :cached-now-date-string="cachedNowDateString"
    />
  </transition>
</template>

<script>
// LIBS
//import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";

import { onMessage } from "./webviewMessage.js";
import * as Sentry from "@sentry/browser";
import { POP_SHORTAGE_REQUEST } from "./store/calendar/mutation_types";
import {
  POP_PERIOD_REQUEST,
  QUEUE_PERIOD
} from "./store/client/mutation_types";
import { SOCKET_AUTH } from "./store/auth/mutation_types";
import { clientMonthRangeMixin } from "./mixins/client";
import {
  SET_LAST_PING,
  SET_APP_ACTIVE,
  SET_DATA_SYNCING,
  RESET_STATE
} from "./mutation-types";
import { UPDATE_TASKS } from "./store/task/mutation_types";
import { timeMixin } from "./mixins/time";
import { _now } from "./time";
import { LocalDateTime } from "@js-joda/core";
import { REFRESH_LOGIN } from "@/store/auth/mutation_types";

export default {
  // DATA & PROPS
  mixins: [clientMonthRangeMixin, timeMixin],
  data() {
    return {
      locationWatchId: null,
      locationOptions: {
        enableHighAccuracy: false,
        timeout: Infinity,
        maximumAge: 0
      },
      schedulerTimer: null,
      transitionTime: 1000,
      created_at: null
    };
  },
  computed: {
    ...mapGetters(["dataSynced"]),
    ...mapGetters("client", ["client"]),
    ...mapState("auth", ["user"]),
    ...mapGetters("env", ["env"]),
    cachedNow() {
      return this.now;
    },
    cachedNowDate() {
      return this.cachedNow.toLocalDate();
    },
    cachedNowDateString() {
      return this.cachedNowDate.toString();
    },
    localNow() {
      //console.log(this.cachedNow);
      return this.cachedNow._dateTime;
    }
  },

  // LIFE CYCLE
  async created() {
    this.resetState();
    this.created_at = LocalDateTime.now();
    this.schedulerTimer = setInterval(this.tickScheduler, 500);
    // iOS KEYBOARD DISMISSAL BUGFIX
    /*
     * Yeah this is definitely needed I think, we'll just make sure it doesn't rapid fire on us.
     * Should probably make sure we are also not on a request screen, or maybe it's better this way.
     *
     * Appears to be broken even without debounce.
     */
    document.addEventListener(
      "focusout",
      //_.debounce(
      function() {
        this.$nextTick(() => {
          window.scrollTo(0, 0);
        });
      }.bind(this) //, 50)
    );

    // CODE RECEIVED
    window.RNMessage = function(data) {
      onMessage(data);
    };

    window.appInactive = function() {
      if (this.$store.state.auth.user && this.$store.state.auth.person.id) {
        this.setAppActive(false);
      }
    }.bind(this);
    window.appWillUpdate = async function() {
      let refresh_count = localStorage.getItem("updateRefreshes");
      if (
        refresh_count === null ||
        refresh_count === "null" ||
        refresh_count === "undefined"
      ) {
        refresh_count = 0;
      } else {
        refresh_count = parseInt(refresh_count);
      }
      if (refresh_count > 1) {
        await fetch(`https://mobileapp.${window.APP_NAME}`, {
          cache: "reload" //,
          //credentials: "include"
        });
        await fetch(`https://mobileapp.${window.APP_NAME}/auth`, {
          cache: "reload" //,
          //credentials: "include"
        });
        await fetch(`https://mobileapp.${window.APP_NAME}/index_bundle.js`, {
          cache: "reload" //,
          //credentials: "include"
        });
      }
      refresh_count += 1;
      localStorage.setItem("updateRefreshes", refresh_count.toString());
    }.bind(this);

    window.refreshData = function() {
      console.log("app called refresh data");
      const now = LocalDateTime.now();
      if (this.$store.state.auth.user && this.$store.state.auth.person.id) {
        this.setAppActive(true);
        if (
          !this.$store.state.auth.last_token_refresh ||
          now.isAfter(this.$store.state.auth.last_token_refresh.plusHours(1)) ||
          now.isAfter(this.$store.state.last_ping.plusHours(1))
        ) {
          // Refreshing the page has the same effect as iterating the periods loaded and ensures the data is valid
          // and removes unwanted future and past data that might have been loaded, filling limited memory.
          window.location.reload(true);
        }
      } else if (now.isAfter(this.created_at.plusHours(8))) {
        // App was opened but never logged in, new version might be available but missed by auto updater.
        window.location.reload(true);
      }
    }.bind(this);
    if (!window.getMeta) {
      window.getMeta = function(metaName) {
        const metas = document.getElementsByTagName("meta");

        for (let i = 0; i < metas.length; i++) {
          if (metas[i].getAttribute("name") === metaName) {
            return metas[i].getAttribute("content");
          }
        }

        return "";
      };
    }
    console.log("checking for newer version...");
    if (window.getMeta("vue-app-version") > process.env.VUE_APP_VERSION) {
      console.log("new version is available, refresh resources...");
      await window.appWillUpdate().catch(e => {
        console.log("error refreshing resources", e);
      });
      console.log("reloading...");
      window.location.reload(true);
    }
  },
  methods: {
    ...mapActions("calendar", {
      setSelectedDay: "SET_SELECTED_DAY",
      tickShortageQueue: POP_SHORTAGE_REQUEST
    }),
    ...mapActions("client", {
      tickPeriodQueue: POP_PERIOD_REQUEST,
      queuePeriodRequest: QUEUE_PERIOD
    }),
    ...mapActions("auth", {
      socketAuth: SOCKET_AUTH,
      refreshLogin: REFRESH_LOGIN
    }),
    ...mapActions("task", {
      updateTasks: UPDATE_TASKS
    }),
    ...mapActions({
      setAppActive: SET_APP_ACTIVE,
      setSyncing: SET_DATA_SYNCING,
      ping: SET_LAST_PING,
      resetState: RESET_STATE
    }),
    async tickScheduler() {
      if (!this.$store.state.auth.authenticated) {
        this.transitionTime = 1000;
        return;
      }
      this.$nextTick(() => {
        //console.log(this.localNow, this.$store.state.last_ping);
        if (this.$store.state.last_ping) {
          let n = _now();
          if (n.isAfter(this.$store.state.last_ping.plusMinutes(1))) {
            this.$nextTick(() => {
              this.ping();
            });

            if (
              n.isAfter(
                this.$store.state.auth.last_token_refresh.plusMinutes(2)
              )
            ) {
              this.refreshLogin({
                action: null,
                payload: null
              });
            }
          }
        }
        if (this.$store.state.scheduler_busy) {
          return;
        }
        if (
          this.$store.state.socket_needs_auth &&
          this.$store.state.socket.isConnected
        ) {
          this.socketAuth();
        }
        if (
          !this.$store.state.client.initial_period_loaded &&
          this.$store.state.client.settings.timezone
        ) {
          if (!this.$store.state.data_syncing) {
            let current_fdom = this.getClientFirstDayOfMonth(
              this.cachedNowDate
            );
            this.setSyncing(true);
            this.$nextTick(() => {
              this.$nextTick(() => {
                this.queuePeriodRequest({
                  client_id: this.$store.state.client.id,
                  period: current_fdom.toString(),
                  month: current_fdom.monthValue(),
                  year: current_fdom.year(),
                  day: current_fdom.dayOfMonth()
                });
                this.$nextTick(() => {
                  this.tickPeriodQueue();
                });

                this.$nextTick(() => {
                  let fdoms = this.getFdomsForMonth(current_fdom);
                  for (let fdom of fdoms) {
                    this.$nextTick(() => {
                      if (!fdom.isEqual(current_fdom)) {
                        this.queuePeriodRequest({
                          client_id: this.$store.state.client.id,
                          period: fdom.toString(),
                          month: fdom.monthValue(),
                          year: fdom.year(),
                          day: fdom.dayOfMonth()
                        });
                      }
                    });
                  }
                });

                this.$nextTick(() => {
                  let fdoms = this.getFdomsForMonth(
                    current_fdom.minusMonths(1)
                  );
                  for (let fdom of fdoms) {
                    this.$nextTick(() => {
                      if (!fdom.isEqual(current_fdom)) {
                        this.queuePeriodRequest({
                          client_id: this.$store.state.client.id,
                          period: fdom.toString(),
                          month: fdom.monthValue(),
                          year: fdom.year(),
                          day: fdom.dayOfMonth()
                        });
                      }
                    });
                  }
                });
                this.$nextTick(() => {
                  let fdoms = this.getFdomsForMonth(current_fdom.plusMonths(1));
                  for (let fdom of fdoms) {
                    this.$nextTick(() => {
                      if (!fdom.isEqual(current_fdom)) {
                        this.queuePeriodRequest({
                          client_id: this.$store.state.client.id,
                          period: fdom.toString(),
                          month: fdom.monthValue(),
                          year: fdom.year(),
                          day: fdom.dayOfMonth()
                        });
                      }
                    });
                  }
                });

                this.$nextTick(() => {
                  let fdoms = this.getFdomsForMonth(current_fdom.plusMonths(2));
                  for (let fdom of fdoms) {
                    this.$nextTick(() => {
                      if (!fdom.isEqual(current_fdom)) {
                        this.queuePeriodRequest({
                          client_id: this.$store.state.client.id,
                          period: fdom.toString(),
                          month: fdom.monthValue(),
                          year: fdom.year(),
                          day: fdom.dayOfMonth()
                        });
                      }
                    });
                  }
                });
                this.$nextTick(() => {
                  this.updateTasks();
                  this.$nextTick(() => {
                    this.ping();
                  });
                });
              });
            });
            this.transitionTime = 30;
          }
          return;
        }
        // Should set busy status so it shouldn't matter to check results and lock thread.
        if (this.$store.state.tasks_update_requested) {
          this.$nextTick(() => {
            this.updateTasks();
          });
        }
        this.$nextTick(() => {
          this.tickPeriodQueue();
          this.$nextTick(() => {
            this.tickShortageQueue();
          });
        });
      });
    }
  },
  beforeDestroy() {
    clearInterval(this.schedulerTimer);
    /*
    if ("geolocation" in navigator) {
      navigator.geolocation.clearWatch(this.locationWatchId);
    }
    */
  },
  /*
  beforeUnmount() {
    // Was just watch id
    if ("geolocation" in navigator) {
      navigator.geolocation.clearWatch(this.locationWatchId);
    }
  },*/
  watch: {
    user: function(newVal, oldVal) {
      if (newVal && newVal.id !== oldVal.id) {
        // CHECK IF USER HAS BEEN MADE INACTIVE
        /*
        if (newVal.accounts.length > 0) {
        }
          var userAccount = Object.values(newVal.accounts).filter(account => {
            return account.client_id === newVal.active_client;
          })[0];
          if (!userAccount.active) {
            this.$store.commit("SET_LOGOUT_REASON", "Inactivate user account!");
            this.$store.dispatch("logout");
          }
        } else {
        */
        if (this.$store.state.auth.user) {
          Sentry.setUser({
            email: this.$store.state.auth.user.email,
            id: this.$store.state.auth.user.id // , Client isn't set until after this gets hit.
            //client: this.$store.state.client.id //user.active_client
          });
        }
        /*
        if (
          //newVal.person_id != null &&
          "geolocation" in navigator // &&
          //this.loaded
        ) {
          if (this.locationWatchId) {
            navigator.geolocation.clearWatch(this.locationWatchId);
          }
          this.locationWatchId = navigator.geolocation.watchPosition(
            function(position) {
              // TODO: Dispatch action to send value via API request or channels.
              if (this.$store.state.current_location !== position) {
                console.log("updating position");
                this.$store.commit("SET_CURRENT_LOCATION", position);
              }
            }.bind(this)
          );
        }*/
      }
    }
  },
  name: "Scheduler"
};
</script>

<style lang="scss">
@import "../styles/main.scss";
.pending {
  background-image: repeating-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.02),
    rgba(0, 0, 0, 0.02) 10px,
    rgba(0, 0, 0, 0.1) 10px,
    rgba(0, 0, 0, 0.1) 20px
  ) !important;
}
</style>
