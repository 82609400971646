import {
  CHECK_LOCKOUT,
  DELETE_LOCKOUT,
  DELETE_SHORTAGE,
  DELETE_SHORTAGES,
  DELETE_SHORTAGE_FROM_QUEUE,
  POP_SHORTAGE_REQUEST,
  QUEUE_SHORTAGE_REQUEST,
  RESET_STATE,
  SAVE_SHORTAGE,
  SAVE_SHORTAGES,
  SET_DEPARTMENT_VIEW,
  SET_PICKER_STALE,
  SET_SELECTED_DAY,
  SET_SELECTED_PERIOD,
  SET_SELECTED_SCHEDULE,
  TOGGLE_DEPARTMENT_VIEW,
  TOGGLE_LOCATION,
  UPDATE_LOCKOUT,
  UPDATE_SELECTED_SCHEDULE,
  UPDATE_SHORTAGES,
  LOAD_DEFAULT_SCHEDULE,
  SET_DEFAULT_SCHEDULE,
  SELECT_MONTH,
  SELECT_YEAR
} from "./mutation_types";
import { CHECK_SHORTAGES } from "../shifts/mutation_types";
import { SET_SCHEDULER_BUSY, SET_VALUE } from "../../mutation-types";
import { initial_state } from "../auth/state";
import cloneDeep from "lodash.clonedeep";
import * as Sentry from "@sentry/browser";
import { LocalDate } from "@js-joda/core";
import {
  _get_client_first_day_of_month,
  _get_fdoms_for_month,
  _get_next_calendar_month
} from "../../time";
import Vue from "vue";
import { getSchedule } from "../schedulerAPI";
import { setScope } from "../../utils";

export default {
  async [RESET_STATE]({ commit }) {
    let state_keys = Object.keys(initial_state);
    for (let key of state_keys) {
      await commit(SET_VALUE, {
        key: key,
        value: cloneDeep(initial_state[key])
      });
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [DELETE_SHORTAGES]({ commit }, payload) {
    for (let shortage of payload) {
      commit(DELETE_SHORTAGE, shortage);
    }
  },
  async [SELECT_MONTH]({ commit, state, dispatch, rootState }, payload) {
    commit(SELECT_MONTH, payload);
    let year = state.selected_year
      ? state.selected_year
      : LocalDate.now().year();

    let period_changed_to = new LocalDate(year, payload, 1);
    const fdoms = _get_fdoms_for_month(period_changed_to);
    for (const fdom_index in fdoms) {
      const fdom = fdoms[fdom_index];
      dispatch(
        "client/QUEUE_PERIOD",
        {
          client_id: rootState.client.id,
          period: fdom.toString(),
          month: fdom.monthValue(),
          year: fdom.year(),
          day: fdom.dayOfMonth()
        },
        { root: true }
      );
      dispatch(
        "calendar/QUEUE_SHORTAGE_REQUEST",
        {
          client_id: rootState.client.id,
          start: period_changed_to.toString(),
          end: period_changed_to
            .withDayOfMonth(
              period_changed_to.month().length(period_changed_to.isLeapYear())
            )
            .toString()
        },
        { root: true }
      );
    }
  },
  async [SELECT_YEAR]({ commit }, payload) {
    commit(SELECT_YEAR, payload);
  },
  // eslint-disable-next-line no-unused-vars
  async [LOAD_DEFAULT_SCHEDULE]({ commit, rootState }) {
    const schedule = await getSchedule(
      rootState.auth.person.default_schedule,
      rootState.auth.access_token
    );
    if (schedule) {
      commit(SET_DEFAULT_SCHEDULE, schedule);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [POP_SHORTAGE_REQUEST]({ state, commit, dispatch, rootState }) {
    Vue.nextTick(() => {
      if (rootState.scheduler_busy) return;
      if (state.shortage_load_queue.length === 0) return false;
      dispatch(SET_SCHEDULER_BUSY, true, { root: true });
      let shortage_request =
        state.shortage_load_queue[state.shortage_load_queue.length - 1];
      dispatch("shifts/" + CHECK_SHORTAGES, shortage_request, {
        root: true
      });
      commit(DELETE_SHORTAGE_FROM_QUEUE, shortage_request);
      //return true;
    });
  },
  async [QUEUE_SHORTAGE_REQUEST]({ commit }, payload) {
    await commit(QUEUE_SHORTAGE_REQUEST, payload);
  },
  // eslint-disable-next-line no-unused-vars
  async [UPDATE_SHORTAGES]({ commit, rootState }, payload) {
    let start_fdom = _get_client_first_day_of_month(
      LocalDate.parse(payload.data.start).atStartOfDayWithZone(
        rootState.client.settings.timezone
      )
    );
    let end_fdom = _get_client_first_day_of_month(
      LocalDate.parse(payload.data.end).atStartOfDayWithZone(
        rootState.client.settings.timezone
      )
    );
    let range_loaded = false;
    do {
      if (rootState.client.period_list.includes(start_fdom.toString())) {
        range_loaded = true;
      }
      if (!start_fdom.isEqual(end_fdom))
        start_fdom = _get_next_calendar_month(start_fdom);
    } while (!start_fdom.isEqual(end_fdom));
    if (range_loaded) {
      await commit(QUEUE_SHORTAGE_REQUEST, payload.data);
    }
  },
  async [RESET_STATE]({ commit }) {
    await commit(RESET_STATE);
  },
  // eslint-disable-next-line no-unused-vars
  async [SAVE_SHORTAGES]({ commit }, payload) {
    for (let [key, value] of Object.entries(payload)) {
      commit(SAVE_SHORTAGE, [key, value]);
    }
  },
  async [SET_PICKER_STALE]({ commit }, payload) {
    await commit(SET_PICKER_STALE, payload);
  },
  //async [SET_SELECTED_PERIOD]({ commit }, period) {
  // await commit(SET_SELECTED_PERIOD, period);
  //},
  // eslint-disable-next-line no-unused-vars
  async [SET_SELECTED_DAY]({ commit, dispatch }, payload) {
    if (!payload.day) return;
    await commit(SET_SELECTED_DAY, payload.day);
    await commit(SELECT_MONTH, payload.day.monthValue());
    await commit(SELECT_YEAR, payload.day.year());
    await dispatch(SET_SELECTED_PERIOD, payload.period);
  },
  async [SET_SELECTED_SCHEDULE](
    // eslint-disable-next-line no-unused-vars
    { commit, state, rootState, dispatch },
    payload
  ) {
    let new_schedule = null;
    if (payload.event) {
      new_schedule = payload.event.target.value;
    } else {
      new_schedule = payload.target.value;
    }
    if (new_schedule !== "all_schedules") {
      new_schedule = parseInt(new_schedule);
    }
    if (payload.event) {
      await commit(SET_SELECTED_SCHEDULE, new_schedule);
    } else {
      await commit(SET_SELECTED_SCHEDULE, new_schedule);
    }
  },
  async [SET_SELECTED_PERIOD]({ commit, dispatch }, payload) {
    await commit(SET_SELECTED_PERIOD, payload);
    await dispatch(UPDATE_SELECTED_SCHEDULE, payload.toString());
  },
  async [TOGGLE_DEPARTMENT_VIEW]({ commit, state, rootState }) {
    if (
      !state.department_view &&
      rootState.client.settings.grid_views_enabled
    ) {
      let schedule_id = state.selected_schedule;
      if (!schedule_id) {
        if (rootState.client.settings.all_schedules_enabled) {
          schedule_id = "all_schedules";
        } else {
          if (rootState.auth.person.default_schedule) {
            schedule_id = parseInt(rootState.auth.person.default_schedule);
          } else {
            schedule_id = null;
          }
        }
        await commit(SET_SELECTED_SCHEDULE, schedule_id);
      }
      let selected_schedule = null;
      let all_schedules = false;
      if (
        schedule_id === "all_schedules" &&
        rootState.client.settings.all_schedules_enabled
      )
        all_schedules = true;
      if (!all_schedules) {
        selected_schedule = rootState.client.schedules[schedule_id];
      } else {
        let groups = [];
        let period = rootState.client.periods[state.selected_period];
        if (period) {
          for (let group_id of period.groups) {
            let group = rootState.client.groups[group_id];
            if (group.show_on_calendar) {
              groups.push(group_id);
            }
          }
        }
        selected_schedule = { groups: groups };
      }
      if (!selected_schedule) return;
      if (!selected_schedule.groups) return;
    }
    await commit(SET_DEPARTMENT_VIEW, !state.department_view);
  },
  async [TOGGLE_LOCATION]({ commit }, payload) {
    await commit(TOGGLE_LOCATION, payload);
  },
  async [UPDATE_LOCKOUT]({ commit, dispatch, rootState }, payload) {
    let url = rootState.api_url + `/lockouts/` + payload.data.lockout + `/`;
    setScope(url, payload);
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + rootState.auth.access_token
      }
    })
      .then(async response => {
        Sentry.configureScope(scope => {
          scope.setExtra("api_response", response);
        });
        if (response.status === 401) {
          await dispatch(
            "auth/REFRESH_LOGIN",
            { action: "calendar/UPDATE_LOCKOUT", payload: payload },
            { root: true }
          );
        } else if (response.status === 200) {
          response
            .json()
            .then(async jd => {
              Sentry.configureScope(scope => {
                scope.setExtra("json_response", jd);
              });
              await commit(UPDATE_LOCKOUT, jd);
            })
            .catch(async err => {
              Sentry.captureException(err);
              console.log(err);
            });
        } else if (response.status === 404) {
          await commit(DELETE_LOCKOUT, payload.data.lockout);
        }
      })
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  },
  async [CHECK_LOCKOUT]({ commit, dispatch, rootState }, payload) {
    let url = rootState.api_url + `/lockout/` + payload + `/`;
    setScope(url, payload);
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + rootState.auth.access_token
      }
    })
      .then(async response => {
        Sentry.configureScope(scope => {
          scope.setExtra("api_response", response);
        });
        if (response.status === 401) {
          await dispatch(
            "auth/REFRESH_LOGIN",
            { action: "calendar/CHECK_LOCKOUT", payload: payload },
            { root: true }
          );
        } else if (response.status === 200) {
          response
            .json()
            .then(async jd => {
              Sentry.configureScope(scope => {
                scope.setExtra("json_response", jd);
              });
              for (let lockout of jd) {
                commit(UPDATE_LOCKOUT, lockout);
              }
            })
            .catch(error => {
              Sentry.captureException(error);
              console.log(error);
            });
        }
      })
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  },
  async [UPDATE_SELECTED_SCHEDULE]({ commit, state, rootState }, payload) {
    let current_schedule = rootState.client.schedules[state.selected_schedule];
    let period = rootState.client.periods[payload];
    if (!period) return;
    if (!current_schedule && state.selected_schedule !== "all_schedules") {
      let found_schedule = null;
      if (state.default_schedule) {
        const default_abbr = state.default_schedule.abbreviation;
        for (let period_schedule of period.schedules) {
          let schedule = rootState.client.schedules[period_schedule];
          if (schedule.abbreviation === default_abbr) {
            found_schedule = schedule;
            break;
          }
        }
      }
      if (!found_schedule) {
        found_schedule = rootState.client.schedules[period.schedules[0]];
      }
      if (found_schedule) {
        await commit(SET_SELECTED_SCHEDULE, found_schedule.id);
      }
      return;
    } else if (!current_schedule && state.selected_schedule === "all_schedules")
      return;
    for (let period_schedule of period.schedules) {
      let schedule = rootState.client.schedules[period_schedule];
      if (current_schedule.name === schedule.name) {
        await commit(SET_SELECTED_SCHEDULE, schedule.id);
      }
    }
  }
};
