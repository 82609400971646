import { RESET_STATE, SET_VALUE } from "./mutation_types";
import { Vueset } from "../../vuehack/reactivehack";
export default {
  [RESET_STATE](state) {
    console.log("debug reset state", state);
  },
  [SET_VALUE](state, payload) {
    Vueset(state, payload.key, payload.value);
  }
};
