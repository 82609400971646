import { LocalDate } from "@js-joda/core";
import { _now, _this_month, month_formatter } from "../../time";
import { SHIFT_TYPE_REGULAR_DAY_OFF } from "../../constants/shift";

export default {
  activeDay: (state, getters, rootState) => {
    if (state.selected_day) {
      return state.selected_day;
    }
    return LocalDate.from(_now(rootState.client.settings.timezone));
  },
  activePeriod: (state, getters, rootState) => {
    if (state.selected_period) {
      if (typeof state.selected_period !== "string") {
        return state.selected_period.toString();
      }
      return state.selected_period;
    }
    if (!rootState.client.settings) return null;
    let current_period = rootState.client.settings.current_period;

    if (!current_period) {
      return _this_month(rootState.client.settings.timezone).format(
        month_formatter
      );
    }
    if (typeof current_period !== "string") {
      return current_period.toString();
    }
    return current_period;
  },
  activePeriodObj: (state, getters, rootState) => {
    return rootState.client.periods[getters["activePeriod"]];
  },
  activeSchedule: state => {
    return state.selected_schedule;
  },
  departmentView: state => {
    return state.department_view;
  },
  dayShifts: (state, getters, rootState) => {
    let day = getters.activeDay;
    if (!day) return [];
    const shifts = rootState.shifts.shifts;
    let day_shifts = [];
    for (const index in shifts) {
      const shift = shifts[index];
      if (shift.local_day.isEqual(day)) {
        day_shifts.push(shift);
      }
    }
  },
  selectedLocations: state => {
    return state.selected_locations;
  },
  dayOpenShifts: (state, getters, rootState, rootGetters) => {
    let day = getters.activeDay;
    if (!day) return [];
    return rootGetters["shifts/openShifts"].filter(shift => {
      if (shift.shift_type === SHIFT_TYPE_REGULAR_DAY_OFF) return false;
      return shift.local_day.isEqual(day);
    });
  },
  // eslint-disable-next-line no-unused-vars
  userDayShifts: (state, getters, rootState, rootGetters) => {
    let day = getters.activeDay;
    if (!day) return [];
    return rootGetters["shifts/myShifts"].filter(shift => {
      return shift.local_day.isEqual(day);
    });
  },
  // eslint-disable-next-line no-unused-vars
  scheduleGroups: (state, getters, rootState) => {
    let schedule_id = state.selected_schedule;
    let selected_schedule = null;
    let all_schedules = false;
    if (schedule_id === "all_schedules") all_schedules = true;
    let restricted_group_ids = [];

    if (!all_schedules) {
      selected_schedule = rootState.client.schedules[schedule_id];
    } else {
      let groups = [];
      let period = rootState.client.periods[state.selected_period];
      for (const schedule_index in rootState.client.schedules) {
        const schedule = rootState.client.schedules[schedule_index];
        if (
          schedule.cc_restricted_people &&
          schedule.cc_restricted_people.includes(rootState.auth.person.id)
        ) {
          for (const schedule_group_index in schedule.groups) {
            const group_id = schedule.groups[schedule_group_index];
            restricted_group_ids.push(group_id);
          }
        }
      }
      if (period) {
        for (const period_group_index in period.groups) {
          const group_id = period.groups[period_group_index];
          const group = rootState.client.groups[group_id];
          if (group.show_on_calendar) {
            groups.push(group_id);
          }
        }
      }
      selected_schedule = { groups: groups };
    }
    if (!selected_schedule) return [];
    let available_groups = [];
    for (const selected_schedule_group_index in selected_schedule.groups) {
      const group_id = selected_schedule.groups[selected_schedule_group_index];
      if (restricted_group_ids.includes(group_id)) {
        continue;
      }
      available_groups.push(group_id);
    }
    return available_groups;
  },
  schedule: (state, getters, rootState) => {
    return rootState.client.schedules[getters["activeSchedule"]];
  },
  // eslint-disable-next-line no-unused-vars
  periodOpenShifts: (state, getters, rootState, rootGetters) => {
    let active_period = getters["activePeriod"];
    if (!active_period) return [];
    let period = active_period.slice(0, active_period.length - 3);
    let period_open_shifts = [];
    const open_shifts = rootGetters["shifts/openShifts"];
    for (const index in open_shifts) {
      const shift = open_shifts[index];
      if (shift.day.slice(0, shift.day.length - 3) === period)
        period_open_shifts.push(shift);
    }
  }
};
