import {
  LOAD_SSO_PROVIDERS,
  LOGOUT,
  SAVE_CLIENT,
  SAVE_INITIAL_PERSON,
  SAVE_USER_INFO,
  SET_ACCESS_TOKEN,
  SET_AUTHENTICATED,
  SET_AUTHENTICATING,
  SET_PERSON_PERMISSIONS,
  SET_TWO_FACTOR,
  SET_CLIENTS,
  SET_ERRORS,
  SET_LAST_VERSION_CHECK,
  SET_LOADING,
  SET_PERSON,
  SET_PERSON_SUBORDINATES,
  SET_REFRESH_TOKEN,
  SET_SELECTED_CLIENT_ID,
  SET_SLIDES,
  SET_SUBMITTED,
  SET_USER,
  USER_AUTHENTICATED
} from "./mutation_types";
//import Vue from "vue";
import { Vueset } from "../../vuehack/reactivehack";
import { SET_VALUE } from "../../mutation-types";
import { LocalDateTime } from "@js-joda/core";

export default {
  async [LOAD_SSO_PROVIDERS](state, value) {
    Vueset(state, "sso_providers", value);
    // console.log(LOAD_SSO_PROVIDERS, state);
  },
  async [SET_AUTHENTICATING](state, value) {
    Vueset(state, "submitted", true);
    Vueset(state, "authenticating", value);
  },
  async [SET_ERRORS](state, value) {
    Vueset(state, "errors", value);
  },
  async [USER_AUTHENTICATED](state, tokenInfo) {
    Vueset(state, "access_token", tokenInfo.access);
    Vueset(state, "refresh_token", tokenInfo.refresh);
    Vueset(state, "authenticated", true);
    Vueset(state, "last_token_refresh", LocalDateTime.now());
  },
  async [SAVE_CLIENT](state, client) {
    Vueset(state.clients, client.id, client);
  },
  async [SAVE_INITIAL_PERSON](state, value) {
    Vueset(value, "tasks", []);
    Vueset(value, "subordinates", {
      /* person_id: {days: [], periods: [], shifts: []} */
      modify_schedules: {},
      create_extra_shifts: true,
      add_notes: true
    });
    Vueset(state, "person", value);
    Vueset(state.user, "permissions", {
      admin: value.is_admin
    });
  },
  async [SET_ACCESS_TOKEN](state, value) {
    Vueset(state, "access_token", value);
    Vueset(state, "last_token_refresh", LocalDateTime.now());
  },
  async [SET_REFRESH_TOKEN](state, value) {
    Vueset(state, "refresh_token", value);
  },
  async [SET_AUTHENTICATED](state, value) {
    Vueset(state, "authenticated", value);
  },
  async [SET_TWO_FACTOR](state, value) {
    if (value) {
      Vueset(state, "needs_two_factor", value.needs_two_factor);
      Vueset(state, "type_two_factor", value.type_two_factor);
      Vueset(state, "has_two_factor", value.has_two_factor);
      Vueset(state, "label_two_factor", value.label_two_factor);
    } else {
      Vueset(state, "needs_two_factor", false);
      Vueset(state, "type_two_factor", "device");
      Vueset(state, "has_two_factor", false);
      Vueset(state, "label_two_factor", "Please enter your two factor code.");
    }
  },
  async [SET_SUBMITTED](state, value) {
    Vueset(state, "submitted", value);
  },
  async [SET_CLIENTS](state, value) {
    Vueset(state, "clients", value);
  },
  async [SET_USER](state, value) {
    Vueset(state, "user", value);
  },
  async [SET_PERSON](state, value) {
    Vueset(state, "person", value);
  },
  async [SET_PERSON_SUBORDINATES](state, value) {
    Vueset(state.person, "subordinates", value);
  },
  async [SET_SLIDES](state, value) {
    Vueset(state, "slides", value);
  },
  async [SET_LAST_VERSION_CHECK](state, value) {
    Vueset(state, "last_version_check", value);
  },
  async [SET_LOADING](state, value) {
    Vueset(state, "loading", value);
  },
  async [SET_SELECTED_CLIENT_ID](state, value) {
    Vueset(state, "selected_client_id", value);
  },
  async [SAVE_USER_INFO](state, value) {
    Vueset(state, "user", {
      email: value.email,
      id: value.id,
      permissions: {}
    });
  },
  async [SET_PERSON_PERMISSIONS](state, value) {
    Vueset(state.user, "permissions", value);
  },
  async [SET_VALUE](state, payload) {
    Vueset(state, payload.key, payload.value);
  },
  async [LOGOUT](state) {
    Vueset(state, "access_token", null);
    Vueset(state, "refresh_token", null);
    Vueset(state, "authenticated", false);
    Vueset(state, "authenticating", false);
    Vueset(state, "submitted", false);
    Vueset(state, "errors", []);
    Vueset(state, "clients", {});
    Vueset(state, "person", {});
    Vueset(state, "user", {});
    Vueset(state, "slides", []);
    Vueset(state, "selected_client_id", null);
  }
};
